import React, {useRef, useEffect} from 'react'
import styled from 'styled-components';
import useWindowSize from '../../../../hooks/useWindowSize';
import {glv} from "../../../../components/app_components/helper_functions/helper_functions";
import {
    draw_center_arc_white_shadow,
    draw_center_arc,
    draw_bg_arc,
    render_arc,
    set_animac
} from './functions.draw';
import {bufer_indicators, set_bufer_indicators} from "../../../pages/pro/current/static_datas";
import {get_cheked_val, get_corrected_text_value, ConvertRGBtoHex} from "../../../../helper_functions/helpers";
import SignatureIndicator from "./signature_indicator";

// Индикатор который относится кблоку манометров


const CanvasIndicator = (props) => {
    const canvas_main = useRef(null);
    const canvas_animation_indicator = useRef(null);
    const canvas_value = useRef(null);
    const canvas_text = useRef(null);

    let line_width = 6 * props.k;

    let point_center_x = 30 * props.k;

    let point_center_y = 34 * props.k;

    let radius = 18 * props.k;

    let size = useWindowSize();

    function draw(is_move_slider, not_animation) {
        // главная функция отрисовки индикатора
        let ctx_text = canvas_text.current.getContext("2d");
        let ctx_main = canvas_main.current.getContext("2d");
        let ctx_animation_indicator = canvas_animation_indicator.current.getContext("2d");
        let ctx_value = canvas_value.current.getContext("2d");

        //устанавливаем градиент
        let gradient = ctx_main.createLinearGradient(20, 0, 220, 0);

        gradient.addColorStop(0, '#F9A91A');
        gradient.addColorStop(0.5, '#F9A91A');
        gradient.addColorStop(1, '#FF5340');

        let _buf_ = bufer_indicators[props.key_id];

        // если слайдер не в движении тогда начинаем новую анимацию
        if (!is_move_slider) {
            // console.log('start first rend')
            ctx_value.clearRect(0, 0, canvas_main.current.width, canvas_main.current.height);
            ctx_main.clearRect(0, 0, canvas_main.current.width, canvas_main.current.height);

            ctx_main.fillStyle = "#e3e9f7";
            ctx_main.fillRect(0, 0, canvas_main.current.width, canvas_main.current.height);

            // отрисовка заднего слоя индиктора
            draw_bg_arc(ctx_main, point_center_x, point_center_y,
                22.5 * props.k, 360, 180,
                true, props.k, line_width)

                // отрисовка центральногоо слоя с белой тенью
            draw_center_arc_white_shadow(
                ctx_main,
                props.k,
                point_center_x,
                point_center_y
            )

            // запускаем рендер самой анимации олзунка и т д
            let old_val = _buf_.old_val;
            render_arc(
                ctx_animation_indicator,
                ctx_text,
                gradient,
                _buf_.min,
                _buf_.max,
                old_val,
                props.k,
                canvas_main,
                point_center_x,
                point_center_y,
                radius,
                props.ratio,
                line_width
                );

            // отрисовка центрального слоя
            draw_center_arc(
                ctx_value,
                props.k,
                canvas_main,
                point_center_x,
                point_center_y
                )
            return
        }

        let checked_val = get_cheked_val(_buf_?.val, _buf_?.max, _buf_?.min);
        let old_val = get_cheked_val(
            bufer_indicators[props.key_id]?.old_val,
            bufer_indicators[props.key_id]?.max,
            bufer_indicators[props.key_id]?.min);


        let animation_ = () => {
            //функция анимации
            if (checked_val > old_val && !not_animation) {
                // console.log('start animation_ up')
                old_val += (_buf_.max - _buf_.min) / 100;

                if (old_val > checked_val) {
                    old_val = checked_val;
                }
            } else if (checked_val < old_val && !not_animation) {
                // console.log('start animation_ down')
                old_val -= (_buf_.max - _buf_.min) / 100;

                if (old_val < checked_val) {
                    old_val = checked_val;
                }
            } else {
                // console.log('start animation_ end')
                render_arc(
                    ctx_animation_indicator,
                    ctx_text,
                    gradient,
                    _buf_.min,
                    _buf_.max,
                    old_val,
                    props.k,
                    canvas_main,
                    point_center_x,
                    point_center_y,
                    radius,
                    props.ratio,
                    line_width
                )
                let new_bufer = bufer_indicators[props.key_id];
                // console.log(new_bufer)
                if (old_val !== get_cheked_val(new_bufer.val, new_bufer.max, new_bufer.min)) {
                    checked_val = get_cheked_val(new_bufer.val, new_bufer.max, new_bufer.min);
                    _buf_ = new_bufer;
                    animation_()
                    return
                }
                set_animac(false, props.key_id)
                return
            }
            render_arc(
                ctx_animation_indicator,
                ctx_text,
                gradient,
                _buf_.min,
                _buf_.max,
                old_val,
                props.k,
                canvas_main,
                point_center_x,
                point_center_y,
                radius,
                props.ratio,
                line_width
            )
            setTimeout(() => animation_(), 10)
        }
        if (_buf_.trig_movie.stars &&
            !_buf_.trig_movie.animac) {
            set_animac(true, props.key_id)
            animation_()
        }
        let ratio = window.devicePixelRatio;
        resizing();

    }

    let resizing = () => {
        // функция при изменениии размеров окна

        let ratio = props.ratio;
        let w = 60 * props.k;
        let h = 50 * props.k;
        canvas_animation_indicator.current.width = Math.ceil(w * ratio);
        canvas_animation_indicator.current.height = Math.ceil(h * ratio);
        canvas_animation_indicator.current.style.width = (w) + 'px';
        canvas_animation_indicator.current.style.height = (h) + 'px';
        canvas_main.current.width = Math.ceil(w * ratio);
        canvas_main.current.height = Math.ceil(h * ratio);
        canvas_main.current.style.width = (w) + 'px';
        canvas_main.current.style.height = (h) + 'px';
        canvas_value.current.width = Math.ceil(w * ratio);
        canvas_value.current.height = Math.ceil(h * ratio);
        canvas_value.current.style.width = (w) + 'px';
        canvas_value.current.style.height = (h) + 'px';

        canvas_text.current.width = Math.ceil(w * ratio);
        canvas_text.current.height = Math.ceil(h * ratio);
        canvas_text.current.style.width = (w) + 'px';
        canvas_text.current.style.height = (h) + 'px';

        canvas_animation_indicator.current.getContext("2d").scale(ratio, ratio)
        canvas_text.current.getContext("2d").scale(ratio, ratio);
        canvas_value.current.getContext("2d").scale(ratio, ratio)
        canvas_main.current.getContext("2d").scale(ratio, ratio)


        canvas_animation_indicator.current.style.boxSizing = 'border-box';
        canvas_main.current.style.boxSizing = 'border-box';
        canvas_value.current.style.boxSizing = 'border-box';
        canvas_text.current.style.boxSizing = 'border-box';
        draw(false, true)
    }

    let sh = window.shak_ ? window.shak_ : {};
    window.shak_ = {
        ...sh,
        [props.key_id]: () => {
            resizing()
        }
    }
    window.shaks = () => {
        Object.keys(window.shak_).map((key) => {
            window.shak_[key]()
        })
    }
    React.useEffect(() => {
        draw(true)
    }, [props.value])


    React.useEffect(() => {
        resizing()
    }, [props.k, props.ratio])

    React.useEffect(() => {
        // // console.log(line_width,point_center_x, point_center_y, radius )
        draw()
    }, [])

    return (
        <div>
            <div
                className={"wrap_first_canvas"}
            >
                {
                    !props.not_show_status_onl ?
  <SignatureIndicator
      size={size}
      kof={props.k}
      isOnline={props.isOnline}
      id={props.id}
  />
   : null
                }
                <div
                    className="wrap_second_canvas"
                >
                    <canvas
                        ref={canvas_animation_indicator}
                        height={0}
                        width={0}
                    >

                    </canvas>
                </div>
                <div
                    className="wrap_third_canvas"
                >
                    <canvas
                        ref={canvas_value}
                        height={0}
                        width={0}
                    >

                    </canvas>
                </div>

                <div
                    className="wrap_four_canvas"
                >
                    <canvas
                        ref={canvas_text}
                        height={0}
                        width={0}
                    >
                    </canvas>
                </div>
                <canvas
                    ref={canvas_main}

                    height={0}
                    width={0}
                >

                </canvas>
            </div>

        </div>
    )
}

export default CanvasIndicator;