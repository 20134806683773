import React, {useState} from 'react';
import cl from './launch_regen_modal.style.module.css';
import Wrap_Overlay from "../modal_components/wrappers/overlay_wrappers/Wrap_Overlay";
import WrapModal from "../modal_components/wrappers/Wrap_Modal";
import Title_Modal from "../modal_components/Title_Modal";
import {glv} from "../../app_components/helper_functions/helper_functions";
import LaunchRegenModalContentComponent from "./launch_regen_modal_content.component";
import ActionsModalButtons from "../modal_components/Actions_Buttons_Modal";
import ProService from "../../../services/ProPageService";


const LaunchRegenModalComponent = ({open, close, sys_list, set_is_started_regen}) => {
    const [current_sys, set_current_sys] = useState(null)



    return (
        <Wrap_Overlay
            z_index={1025}
            open={open}
            hidden_body={true}
            handle_click_overlay={()=>{
                close()
            }}
            class_position_children={'overlay_children_position'}
        >
            <WrapModal>
                <Title_Modal
                    title_style={{overflow:'hidden', whiteSpace:'nowrap', textOverflow: 'ellipsis'}}
                    title_text={
                        glv(`first_level_blocks.launch_regen_modal.title` )
                    }
                />
                <LaunchRegenModalContentComponent
                    current_sys={current_sys}
                    select_sys={(sys)=>{
                        set_current_sys(sys)
                    }}
                    sys_list={sys_list}
                />
                <ActionsModalButtons
                    disabled_acc_btn={!current_sys ? true :false}
                    has_cancel={true}
                    has_accept={true}
                    text_accept_btn={glv('joint_buttons.start_regeneration')}
                    text_cancel_btn={glv('joint_buttons.cancel')}
                    style={{marginTop:30}}
                    accept_action={()=>{
                        ProService.start_regen(current_sys.id_).then(res=>{
                            if (res.code===200) {
                                set_is_started_regen(true)
                                close()
                            }
                            if (res.code===409) {
                                set_is_started_regen(true)
                                close()
                            }
                        })
                    }}
                    cancel_action={()=>{
                        close()
                    }}
                />
            </WrapModal>
        </Wrap_Overlay>
    );
};

export default LaunchRegenModalComponent;