import React, {useEffect, useRef, useState} from "react";
import Canvas from "../useful_components/canvas";
import useWindowSize from "../../hooks/useWindowSize";

const ChartPub = (props) => {
    // компонент заглушки если график не имеет значений
    const [sizes_pub, set_sizes_pub] = useState()
    const {
        width,
        height
    } = useWindowSize()
    const pub_block_ref = useRef(null);

    const draw = (context) => {
        let ctx_h = context.canvas.height;
        let ctx_w = context.canvas.width;
        let middle_w = ctx_w / 2;
        let middle_h = ctx_h / 2;
        context.lineWidth = 1;
        context.strokeStyle = "#c1c6d2";
        context.moveTo(0, 0);
        context.lineTo(ctx_w, 0);
        context.lineTo(ctx_w, ctx_h);
        context.lineTo(0, ctx_h);
        context.lineTo(0, 0);
        context.moveTo(middle_w / 2, 0);
        context.lineTo(middle_w / 2, ctx_h);
        context.moveTo(ctx_w - middle_w / 2, 0);
        context.lineTo(ctx_w - middle_w / 2, ctx_h);
        context.moveTo(0, middle_h / 2);
        context.lineTo(ctx_w, middle_h / 2);
        context.moveTo(0, ctx_h - middle_h / 2);
        context.lineTo(ctx_w, ctx_h - middle_h / 2);
        context.moveTo(ctx_w, middle_h);
        context.lineTo(0, middle_h);

        // context.moveTo(0, middle_h / 4);
        // context.lineTo(ctx_h, middle_h / 4);
        // context.fillRect(10, 10, 50, 50);

        context.stroke();

    };

    useEffect(()=>{
        if (pub_block_ref.current) {

            set_sizes_pub({
                w: pub_block_ref.current.offsetWidth,
                h: pub_block_ref.current.offsetHeight
            })
        }
    }, [width])

    return  (
        <div ref={pub_block_ref} style={{width: '100%', height: '800px', marginTop: 12}}>
            {
                sizes_pub ?
                    <Canvas draw={draw} height={sizes_pub.h} width={sizes_pub.w} /> : null
            }

        </div>

        // <div
        //     className={'wrap_chart_pub'}
        //     style={{
        //       ...custom_style
        //     }}
        // >
        //           <span
        //                 className={'pub_no_data_chart'}
        //           >No Data</span>
        //     <div className={'wrap_vertical_lines'} style={{marginTop: 20}}>
        //         {[1,2,3,4, 5, 6].map(
        //             it  => (
        //                 <div className={'wrap_vertical_line_'} key={it+ 'key_temp_chart'}>
        //                     <div className={'vertical_line_chart'} >
        //
        //                     </div>
        //                     <div className={'vertical_line_chart'} >
        //
        //                     </div>
        //                     <div className={'vertical_line_chart'} >
        //
        //                     </div>
        //                 </div>
        //
        //             )
        //         )}
        //     </div>
        //
        //     <div className={'wrap_vertical_lines'}>
        //         {[1,2,3,4].map(
        //             it  => (
        //                 <div className={'wrap_horizontal_lines_chart'}>
        //                     <div className={'horizontal_line_chart'}></div>
        //                     <div className={'horizontal_line_chart'}></div>
        //                     <div className={'horizontal_line_chart'}></div>
        //                 </div>
        //             )
        //         )}
        //     </div>
        // </div>
    );
}

export default ChartPub;
