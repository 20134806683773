import React, {useEffect, useCallback, useState} from "react";
import * as actions from "../../../../redux/actions/env.actions";
import {useDispatch, useSelector} from "react-redux";
import {current_language, get_lang_tree_context, lang_conf, set_curr_lang} from "../../../../App";
import {glv} from "../../../app_components/helper_functions/helper_functions";

const ContentSelectLangs = (props) => {
    const set_lang_tree = React.useContext(get_lang_tree_context);
    const language = useSelector((state) => state.envReducer.language);

    // const langConf = useSelector((state) => state.authReducer.lang_conf);
    const dispatch = useDispatch();

    const changeLanguage = useCallback(
        (lang) => {
            set_curr_lang(lang)
            dispatch(actions.setLanguage(lang))
        },
        [dispatch]
    );


    return (
        <div
            style={
                {zIndex: 5165}
            }
        >
            {Object.keys(lang_conf.language_arr).map((item, index) => (
                <button
                    key={index + 'lang' + item}
                    onClick={() => {
                        // set_curr_lang(item)
                        // set_lang_tree()
                        // glv('asd')
                        changeLanguage(item);

                        props.close();
                    }}
                    className={`light_button lang_btn lang_to_select_btn ${language === item ? 'selected_lang' : ''}`}
                    style={{position: 'relative'}}
                >

                    <span className={'light_button_text'}>
                               {lang_conf.language_arr[item].name}
                     </span>

                </button>
            ))}
            <div className={'ta_center mt_30'}>
                <span
                    onClick={() => props.close()}
                    className={'text_btn'}
                >{glv(`confirm_action_buttons.cancel`)}</span>
            </div>

        </div>
    );
};

export default ContentSelectLangs;
