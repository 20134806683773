import React from "react";

import { useSelector } from "react-redux";
import './styles/hm_header.css';

import {glv} from "../../app_components/helper_functions/helper_functions";
import {open_lang_modal_context} from "../../../App";
import {BurgerMenu, CloseMenuIcon, LeftArrow, RightArrow} from "../../../assets/svg_images";
import Collapse from '@material-ui/core/Collapse';
import HeaderMenuBlockHM from "./menu_block_hm";




const HeaderHM = (props) => {
    //vertical side bar

    const [hided, set_hided] = React.useState(true);

    return (
        <div className={'wrap_header_hm'}>
                <div className={'wrap_open_menu_bar'} onClick={()=>{set_hided(!hided)}}>
                    <span className={'system_name_hm'}>{props.title}</span>
                    {
                        hided ? <BurgerMenu class={'burger_menu_icon'}/> :
                            <CloseMenuIcon class={'close_menu_icon'} />
                    }
                </div>

            <Collapse in={!hided}>
                <div className={'wrap_menu_blocks_hm'}>
                    {
                        props.punkts.map(punkt=>{
                            return (
                                <HeaderMenuBlockHM
                                    child={punkt.child ? punkt.child : null}
                                    hided={hided}
                                    id_name={punkt.id}
                                    action={()=> {
                                        if (punkt.action) {
                                            punkt.action()
                                        }
                                        set_hided(!hided)
                                    }}
                                    title={glv('header.menu_buttons.'+punkt.id)}
                                />
                            )
                        })

                    }
                </div>
            </Collapse>

        </div>

    );
}
export default HeaderHM;
