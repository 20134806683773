import {get_cheked_val, get_corrected_text_value, ConvertRGBtoHex} from "../../../../helper_functions/helpers";
import {glv} from "../../../app_components/helper_functions/helper_functions";
import {bufer_indicators, set_bufer_indicators} from "../../../pages/pro/current/static_datas";

export  function drawTriangle(
    pix_color,
    val_min,
    val_max,
    val,
    ctx_animation_indicator,
    kof,
    pc_x,
    pc_y) {
    let x_ = 4 * kof;
    let y_ = 2 * kof;

    let ratio = Math.PI / (val_max - val_min);
    let angle = ((val - val_min) * ratio) + Math.PI;
    let radius_ = 9 * kof;
    ctx_animation_indicator.translate(pc_x, pc_y);
    ctx_animation_indicator.rotate(angle);
    ctx_animation_indicator.translate(radius_, 0);
    let gradient = ctx_animation_indicator.createLinearGradient(0, 2.5 * kof, 2 * kof, 1 * kof);
    gradient.addColorStop(0, '#F9A91A');
    gradient.addColorStop(1, '#FF5340');
    ctx_animation_indicator.shadowOffsetX = 0.5 * kof;
    ctx_animation_indicator.shadowOffsetY = 0.3 * kof;
    ctx_animation_indicator.shadowBlur = 0.8 * kof;
    ctx_animation_indicator.shadowColor = "rgba(0, 0, 0, 0.2)";
    ctx_animation_indicator.fillStyle = pix_color;
    ctx_animation_indicator.beginPath();
    ctx_animation_indicator.lineTo(-x_, y_);
    ctx_animation_indicator.lineTo(-x_, -y_)
    ctx_animation_indicator.lineTo(x_, 0)
    ctx_animation_indicator.closePath();
    ctx_animation_indicator.fill();
    ctx_animation_indicator.setTransform(1, 0, 0, 1, 0, 0);
    ctx_animation_indicator.shadowOffsetX = 0;
    ctx_animation_indicator.shadowOffsetY = 0;
    ctx_animation_indicator.shadowBlur = 0;
}

export function drawRoundEnd(pix_color, val_min, val_max,
                      val,
                      ctx_animation_indicator,
                      kof,pc_x, pc_y, ratio_param ) {
    let ratio = Math.PI / (val_max - val_min);
    let ratio__ = window.devicePixelRatio;
    let angle_ = ((val - val_min) * ratio) + Math.PI;
    let radius_ = 20 * kof * ratio_param;


    ctx_animation_indicator.translate(pc_x * ratio_param, pc_y * ratio_param);
    ctx_animation_indicator.rotate(angle_);
    ctx_animation_indicator.translate(radius_, 0);
    ctx_animation_indicator.fillStyle = pix_color;
    ctx_animation_indicator.beginPath();
    ctx_animation_indicator.arc(
        -1 * kof * 2 * ratio_param,
        -0.1 * kof * ratio_param ,
        3 * kof * ratio_param,
        Math.PI, 0, true);
    ctx_animation_indicator.closePath();
    ctx_animation_indicator.fill();


    ctx_animation_indicator.setTransform(ratio_param, 0, 0, ratio_param, 0, 0);
    // ctx_animation_indicator.setTransform(props.ratio, 0, 0, props.ratio, 0, 0)
    // ctx_animation_indicator.scale(ratio__, ratio__)
    ctx_animation_indicator.clearRect(
        pc_x + 14.5 * kof,
        pc_y,
        7 * kof,
        21 * kof);
}

export function drawArc(
    val_min, val_max, val, ctx_animation_indicator,
    xPos, yPos, radius, startAngle, endAngle,
    clockwise, lineColor, fillColor,
    line_width, kof) {


    let one_perc = (val_max - val_min) / 100;
    let edited_val = val - val_min;
    let perc = edited_val / one_perc;
    let new_val = 180 + perc * 1.8;



    let startAngle_ = new_val * (Math.PI / 180);
    let endAngle_ = endAngle * (Math.PI / 180);


    ctx_animation_indicator.strokeStyle = lineColor;
    ctx_animation_indicator.fillStyle = fillColor;
    ctx_animation_indicator.lineWidth = line_width;
    // fillColor &&
    // ctx_animation_indicator.fill();

    ctx_animation_indicator.beginPath();
    ctx_animation_indicator.arc(xPos, yPos, 18 * kof, startAngle_, endAngle_, clockwise);
    ctx_animation_indicator.stroke();
}

export function draw_center_arc_white_shadow(
    ctx_main,
    kof, pc_x, pc_y,) {
    let radius__ = 9 * kof;

    // let radius__ = radius-line_width-50>=0 ? radius-line_width-50 : 0;
    ctx_main.beginPath();

    ctx_main.shadowOffsetX = -1 * kof * 1.8;
    // -1*kof*3;
    ctx_main.shadowOffsetY = -1 * kof * 1;
    // -1*kof*2;
    ctx_main.shadowBlur = kof * 3.2;
    // kof*2;
    ctx_main.shadowColor = "rgba(255, 255, 255, 0.6)";
    ctx_main.fillStyle = '#eff3fb';
    ctx_main.lineWidth = kof * 2;
    ctx_main.strokeStyle = "rgba(255, 255, 255, 0)";
    ctx_main.arc(pc_x, pc_y, radius__,
        0,
        Math.PI * 2,
    );
    ctx_main.stroke();
    ctx_main.fill();
    ctx_main.shadowOffsetX = 0;
    ctx_main.shadowOffsetY = 0;
    ctx_main.shadowBlur = 0;
}

export function draw_center_arc(
    ctx_value, kof, canvas_main, pc_x, pc_y,
    ) {

    let radius_curr = kof * 9;
    let radius__ = kof * 9;
    // if (radius_curr< 50){
    //     radius_curr=50;
    // }
    ctx_value.beginPath();
    let gradient = ctx_value.createLinearGradient(0, 0, 0, canvas_main.current.width - (canvas_main.current.width / 3));
    gradient.addColorStop(0, '#d0d8e9');
    gradient.addColorStop(0.5, '#d0d8e9');
    gradient.addColorStop(1, '#ffffff');
    ctx_value.shadowOffsetX = 2 * kof;
    ctx_value.shadowOffsetY = 2 * kof;
    ctx_value.shadowBlur = 4 * kof;
    ctx_value.shadowColor = "rgba(0, 0, 0, 0.1)";
    ctx_value.fillStyle = '#eff3fb';

    ctx_value.lineWidth = kof * 1;
    // props.width===600?
    // 15 :
    // props.width===500? 10 : 60;
    ctx_value.strokeStyle = gradient;

    ctx_value.arc(pc_x, pc_y, radius_curr,
        0,
        Math.PI * 2,
    );
    ctx_value.stroke();
    ctx_value.fill();
    ctx_value.shadowOffsetX = 0;
    ctx_value.shadowOffsetY = 0;
    ctx_value.shadowBlur = 0;

    ctx_value.arc(pc_x, pc_y, radius__,
        0,
        Math.PI * 2,
    );
    ctx_value.stroke();
    ctx_value.fill();
}

export function draw_bg_arc(
    ctx_main, xPos, yPos, radius,
    startAngle, endAngle, clockwise,
    kof, line_width) {

    // console.log(ctx_main, xPos, yPos, radius, startAngle, endAngle, clockwise)
    // ctx_main.fillRect(20,20, 30, 30)

    let endAngle_ = endAngle * (Math.PI / 180);
    ctx_main.beginPath();
    ctx_main.shadowColor = "rgba(255, 255, 255, 0.6)";
    ctx_main.shadowOffsetX = -1 * kof;
    ctx_main.shadowOffsetY = -1 * kof;
    ctx_main.shadowBlur = 2 * kof;

    ctx_main.lineWidth = 0;
    // context.strokeStyle = '#dee4f3';
    ctx_main.arc(xPos, yPos, radius, 360 * (Math.PI / 180), endAngle_, clockwise);
    ctx_main.fillStyle = '#e3e9f7';
    // context.stroke();
    ctx_main.fill();


    ctx_main.beginPath();
    ctx_main.lineWidth = 0;
    ctx_main.shadowOffsetX = 1.5 * kof;
    ctx_main.shadowOffsetY = 2 * kof;
    ctx_main.shadowBlur = 5 * kof;
    ctx_main.shadowColor = "rgba(0, 0, 0, 0.1)";
    ctx_main.arc(xPos, yPos, radius, 360 * (Math.PI / 180), endAngle_, clockwise);
    // context.stroke();
    ctx_main.fill();

    ctx_main.shadowOffsetX = 0;
    ctx_main.shadowOffsetY = 0;
    ctx_main.shadowBlur = 0;

    ctx_main.beginPath();
    ctx_main.lineWidth = line_width;
    ctx_main.strokeStyle = '#dee4f3';
    // ctx_main.fillStyle = '#e3e9f7';
    ctx_main.arc(xPos, yPos, 18 * kof, 360 * (Math.PI / 180), endAngle_, clockwise);
    // ctx_main.fill();
    ctx_main.stroke();
}


export function render_arc(
    ctx_animation_indicator,
    ctx_text, gradient, min, max, value_,
    kof, canvas_main, pc_x, pc_y, radius,ratio_param, line_width) {

    ctx_animation_indicator.clearRect(0, 0, canvas_main.current.width, canvas_main.current.height);

    ctx_text.clearRect(0, 0, canvas_main.current.width, canvas_main.current.height);
    // ctx_text.setTransform(props.ratio, 0, 0, props.ratio, 0, 0)
    let cofficient_text = 1.8;
    ctx_text.font = `bold ${kof * 6}px Gilroy`;
    ctx_text.fillStyle = "#5E5AB9";
    ctx_text.textAlign = "center";
    ctx_text.fillText(get_corrected_text_value(value_),
        pc_x,
        pc_y);

    ctx_text.font = `${kof * 3}px Gilroy`;
    ctx_text.fillStyle = "#2F2A61";
    ctx_text.textAlign = "center";
    ctx_text.fillText(
        glv('first_level_blocks.manometry.types.water_consumption')
        , pc_x,

        38.5 * kof
    );
    ctx_text.textAlign = "right";
    ctx_text.fillText(
        get_corrected_text_value(min, true),
        (pc_x - 22.5 * kof - cofficient_text * kof),
        pc_y);
    ctx_text.textAlign = "left";
    ctx_text.fillText(
        get_corrected_text_value(max, true)
        ,
        (pc_x + 22.5 * kof + cofficient_text * kof)
        // 53*kof
        ,
        (pc_y)
    );
    ctx_text.textAlign = "center";

    ctx_text.fillText(
        get_corrected_text_value(max / 2, true)
        , pc_x,
        (pc_y - 22.5 * kof - cofficient_text * kof)
        // 8.4*kof
    );
    // ctx_text.setTransform(1, 0, 0, 1, 0, 0)
    drawArc(min, max, value_, ctx_animation_indicator,
        pc_x, pc_y, radius,
        360, 180, true, gradient, '#e3e9f7',
        line_width, kof
        );
    let one_perc = (max - min) / 100;
    let edited_val = value_ - min;
    let perc_ = edited_val / one_perc;
    let perc = 180 + perc_ * 1.8;
    let angle = perc * (Math.PI / 180);
    let vx = Math.cos(angle) * radius;
    let vy = Math.sin(angle) * radius;
    // console.log(ctx_animation_indicator)

    let get_pix_color_ = ctx_animation_indicator.getImageData((pc_x + vx - 10)
        * ratio_param, (pc_y + vy) * ratio_param, 1, 1);
    let get_pix_color =
        // '#F9A91A';
        get_pix_color_.data;

    // console.log(get_pix_color)
    let check_black_color =
        get_pix_color[0] === 0 &&
        get_pix_color[1] === 0 &&
        get_pix_color[2] === 0;
    let pix_color = perc === 180 || check_black_color ? '#F9A91A' :
        ConvertRGBtoHex(get_pix_color[0], get_pix_color[1], get_pix_color[2]);
    let checked_pix_color = perc < 185 ? '#F9A91A' :
        perc > 350 ? '#FF5340' :
            pix_color
    drawTriangle(
        checked_pix_color,
        min, max, value_,
        ctx_animation_indicator,
        kof, pc_x, pc_y
        );

    if (perc !== 360) {
        drawRoundEnd(
            checked_pix_color,
            min,
            max,
            value_,
            ctx_animation_indicator,
            kof,pc_x,pc_y,ratio_param
            )
    }
}


export const set_animac = (value, key_id) => {

    set_bufer_indicators({
        ...bufer_indicators,
        [key_id]: {
            ...bufer_indicators[key_id],
            trig_movie: {
                ...bufer_indicators[key_id].trig_movie,
                animac: value
            }
        }
    })
}






