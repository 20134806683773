import React from "react";
import Grow from '@material-ui/core/Grow';

const Checkbox = (props) => {

    return (
        <div className={"checkbox_container"} onClick={() => {
            props.on_change()
        }}>
            <svg
                className={'bg_conteiner_checkbox'}
                // width="28" height="28"
                viewBox="0 0 30 30"
                fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_i_82_175)">
                    <rect x="1" y="1" width="28" height="28" rx="8" fill="#DEE8F2"/>
                </g>
                <rect x="0.5" y="0.5" width="29" height="29" rx="8.5" stroke="url(#paint0_linear_82_175)"/>
                <defs>
                    <filter id="filter0_i_82_175" x="0" y="0" width="32" height="32" filterUnits="userSpaceOnUse"
                            color-interpolation-filters="sRGB">
                        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                       result="hardAlpha"/>
                        <feOffset dx="2" dy="2"/>
                        <feGaussianBlur stdDeviation="2.5"/>
                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                        <feColorMatrix type="matrix"
                                       values="0 0 0 0 0.411476 0 0 0 0 0.551208 0 0 0 0 0.680191 0 0 0 0.4 0"/>
                        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_82_175"/>
                    </filter>
                    <linearGradient id="paint0_linear_82_175" x1="1" y1="1" x2="7.15851" y2="27.5727"
                                    gradientUnits="userSpaceOnUse">
                        <stop stop-color="#D0D8E9"/>
                        <stop offset="1" stop-color="white"/>
                    </linearGradient>
                </defs>
            </svg>
            <Grow in={props.checked}
            >
                <div className={'wrap_check_img'}>
                    <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M6.89254 13.7303C6.72072 13.9034 6.48629 14 6.24278 14C5.99927 14 5.76485 13.9034 5.59303 13.7303L0.403889 8.53168C-0.13463 7.99227 -0.13463 7.11758 0.403889 6.57917L1.05365 5.92817C1.59233 5.38875 2.46457 5.38875 3.00309 5.92817L6.24278 9.17342L14.9969 0.404561C15.5356 -0.134854 16.4087 -0.134854 16.9464 0.404561L17.5961 1.05557C18.1346 1.59498 18.1346 2.46951 17.5961 3.00808L6.89254 13.7303Z"
                            fill="#5E5AB9"/>
                    </svg>
                </div>
            </Grow>
        </div>

    );
};

export default Checkbox;
