

export  default function date_with_null_time(date) {
    let y = date.getFullYear();
    let m = date.getMonth();
    let d_ = date.getDate();

    let new_date = new Date(y, m, d_, 0, 0, 0, 0)

    return new_date.getTime();
}