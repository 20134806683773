import { Tooltip } from "@material-ui/core";
import React, { useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../redux/actions/env.actions";
import uuid from 'react-uuid'
import { Fade } from '@material-ui/core';
import ShemeModal from '../modals/sheme_element_modal/shemeElementModal';
import useMousePosition from '../../hooks/useMousePosition';

const degs  ={
    left: 90,
    right: 270,
    bottom: 0,
    top: 180
}

const debounce = require('debounce');

const Manometr = (props) => {
    const dispatch = useDispatch();
  const canvasRef = useRef(null);
  const [hover, setHover] = React.useState(false);
  const blurStatus = useSelector((state) => state.envReducer.blurElems);
  const canvasRefShadow = useRef(null);
  const [highlightColor, setHighlightColor] = React.useState("");
  const [idShadow, setIdShadow] = React.useState(uuid());
  const [idSvg, setIdSvg] = React.useState(uuid());
  const bakRef = useRef(null);
  const [coord, setCoord] = React.useState(null);

  const { x, y } = useMousePosition();
  const setShemeModal = React.useCallback((color) => dispatch(actions.setShemeModal(color)), [
    dispatch,
  ]);

  const overModal = useSelector((state) => state.envReducer.overModal);
  const setShemeModalData = React.useCallback((color) => dispatch(actions.setShemeModalData(color)), [
    dispatch,
  ]);
  // useEffect(() => {
  //   const canvas = canvasRef.current;
  //   const ctx = canvas.getContext("2d");

  //   ctx.clearRect(0, 0, canvas.width, canvas.height);
  //   let image = new Image();
  //   image.onload = function () {
  //     ctx.drawImage(image, 0, 0, props.width, props.height);
  //   };
  //   image.src = data;
  // }, []);

  // useEffect(() => {
  //   const canvasShadow = canvasRefShadow.current;
  //   const ctxShadow = canvasShadow.getContext("2d");

  //   ctxShadow.shadowColor = highlightColor;
  //   ctxShadow.shadowBlur = 10;
  //   if (highlightColor && hover) {
  //     let image = new Image();
  //     image.onload = function () {
  //       ctxShadow.drawImage(image, 5, 2.5, props.width, props.height);
  //     };
  //     image.src = data;
  //   } else {
  //     ctxShadow.clearRect(0, 0, canvasShadow.width, canvasShadow.height);
  //   }
  // }, [highlightColor, hover]);

  const setBlur = React.useCallback((status) => dispatch(actions.setBlur(status)), [
    dispatch,
  ]);

  useEffect(() => {
    if (overModal) {
      return
    }
    if (hover&&coord) {
      setShemeModalData({...props, x:coord.x, y: coord.y});
      setShemeModal(true);
      setHighlightColor("#3ecd83");
      setBlur(true)
    } else {

      setShemeModal(false);
      setHighlightColor("");
      setBlur(false)
    }

  }, [hover,coord,overModal])



  return (

    <div style={ {...props.styleWrap, cursor: 'pointer'}} 
    ref={bakRef}
    onMouseOver={debounce((e) => {
      setCoord({x:e.clientX, y: e.clientY})
      if (overModal) {
        return
      }
    setHover(true)
    },300)}
onMouseLeave={debounce(() => {
  setHover(false)
},200)}
    >
        
        <div style={{ position: "relative" }}>
        {/* {hover ? (
     <ShemeModal {...props}/>
      ) : null} */}

      

<svg height={42} width={35} viewBox="0 -3 16 30"             style={{ transition: 'all 0.2s',transform: `rotate(${degs[props.direction]}deg)`, 
             filter: blurStatus&&!hover?'blur(2px) contrast(30%)':'none' }}>

<defs>

<filter id={idShadow}>
  <feDropShadow dx="0" dy="0" stdDeviation="2"
      flood-color="#3ecd83"/>
</filter>

</defs>
        <g xmlns="http://www.w3.org/2000/svg" style={{ transition: 'all 0.4s',fill:"none",filter: highlightColor && hover ?`url(#${idShadow})`: 'none'}}>
          <path id="path1423" d="M 1.00009,19.999 C 0.500092,20 9.15527e-5,19.5 9.15527e-5,19 9.15527e-5,18.219 0,15 0,15 9.15527e-5,14.5 0.500092,14 1.00009,14 H 15.0001 c 0.5,0 1,0.5 1,1 0,0 0,3.219 0,4 0,0.5 -0.5,1 -1,0.999 -2.1308,-0.0045 -10.42426,-0.0075 -14.00001,0 z" style={{fill: '#2f2a61'}} />
          <path id="path1425" d="m 3.00002,12 c -0.55229,0 -1,0.5 -1,1 v 8 c -1e-5,0.5 0.44771,1 1,1 0.55228,0 1,-0.5 1,-1 v -8 c 0,-0.5 -0.44772,-1 -1,-1 z" style={{fill: '#2f2a61'}} />
          <path id="path1427" d="m 13,12 c -0.5523,0 -1,0.5 -1,1 v 8 c 0,0.5 0.4477,1 1,1 0.5523,0 1,-0.5 1,-1 v -8 c 0,-0.5 -0.4477,-1 -1,-1 z" style={{fill: '#2f2a61'}} />
          <path id="path1429" d="m 8.00002,10 c -0.55229,0 -1,0.5 -1,1 v 8 c -1e-5,0.5 0.44771,1 1,1 0.55228,0 1,-0.5 1,-1 v -8 c 0,-0.5 -0.44772,-1 -1,-1 z" style={{fill: '#2f2a61'}} />
          <path id="path1431" d="M 12.5705,9.05564 C 13.1748,8.15117 13.4974,7.0878 13.4974,6 13.4974,4.54131 12.9179,3.14236 11.8865,2.11091 10.855,1.07946 9.45607,0.5 7.99737,0.5 6.90958,0.5 5.84621,0.822561 4.94174,1.42691 4.03727,2.03126 3.33232,2.89025 2.91604,3.89524 c -0.41628,1.005 -0.5252,2.11085 -0.31298,3.17775 0.21222,1.06689 0.73604,2.0469 1.50523,2.8161 0.76919,0.76921 1.74919,1.29301 2.81609,1.50521 1.0669,0.2122 2.17277,0.1033 3.17772,-0.313 1.005,-0.4163 1.864,-1.12119 2.4684,-2.02566 z" style={{fill: '#2f2a61', stroke: '#2f2a61'}} />
          <path id="path1433" d="m 12.3317,6 c 0,0.85728 -0.2543,1.6953 -0.7305,2.4081 C 11.1249,9.1209 10.4479,9.67645 9.65592,10.0045 8.86389,10.3326 7.99238,10.4184 7.15157,10.2512 6.31077,10.0839 5.53844,9.67112 4.93225,9.06493 4.32607,8.45874 3.91325,7.68642 3.74601,6.84561 3.57876,6.00481 3.6646,5.13329 3.99266,4.34127 4.32073,3.54925 4.87629,2.8723 5.58909,2.39602 6.30189,1.91974 7.13991,1.66553 7.99719,1.66553 c 1.14957,0 2.25201,0.45667 3.06491,1.26954 C 11.875,3.74794 12.3317,4.85043 12.3317,6 Z" style={{fill: '#ffffff'}} />
          <path id="path1435" d="M 5.61664,4.89082 9.31118,6.87888 9.53302,6.40107 Z" style={{fill: '#2f2a61'}} />
          <path id="path1437" d="M 8.41531,5.97444 C 8.41531,6.08759 8.37037,6.1961 8.29036,6.2761 8.21035,6.35611 8.10184,6.40106 7.98869,6.40106 7.87555,6.40106 7.76703,6.35611 7.68703,6.2761 7.60702,6.1961 7.56207,6.08759 7.56207,5.97444 c 0,-0.11314 0.04495,-0.22166 0.12496,-0.30167 0.08,-0.08001 0.18852,-0.12495 0.30166,-0.12495 0.11315,0 0.22166,0.04494 0.30167,0.12495 0.08001,0.08001 0.12495,0.18853 0.12495,0.30167 z" style={{fill: '#2f2a61'}} />
          <path id="path1439" d="M 4.18098,5.24362 4.16211,5.34425 4.82462,5.46848 4.84349,5.36785 Z" style={{fill: '#2f2a61'}} />
          <path id="path1441" d="M 4.29251,4.78468 4.2616,4.88229 4.90422,5.08578 4.93513,4.98816 Z" style={{fill: '#2f2a61'}} />
          <path id="path1443" d="M 4.47661,4.34233 4.43408,4.43547 5.04726,4.71543 5.08979,4.62229 Z" style={{fill: '#2f2a61'}} />
          <path id="path1445" d="m 4.69345,3.93784 -0.0535,0.08731 0.57473,0.35219 0.0535,-0.0873 z" style={{fill: '#2f2a61'}} />
          <path id="path1447" d="M 4.96335,3.57374 4.89966,3.6539 5.4274,4.07323 5.49109,3.99307 Z" style={{fill: '#2f2a61'}} />
          <path id="path1449" d="M 5.29038,3.21483 5.21753,3.28677 5.69115,3.76639 5.76401,3.69444 Z" style={{fill: '#2f2a61'}} />
          <path id="path1451" d="M 5.64861,2.89291 5.5675,2.9554 5.97888,3.48938 6.05999,3.4269 Z" style={{fill: '#2f2a61'}} />
          <path id="path1453" d="M 6.0445,2.62444 5.95642,2.67665 6.30016,3.25649 6.38824,3.20428 Z" style={{fill: '#2f2a61'}} />
          <path id="path1455" d="M 6.46762,2.4232 6.3739,2.46442 6.64527,3.08143 6.73899,3.04022 Z" style={{fill: '#2f2a61'}} />
          <path id="path1457" d="M 6.89667,2.27174 6.79865,2.30133 6.99347,2.94663 7.09149,2.91704 Z" style={{fill: '#2f2a61'}} />
          <path id="path1459" d="M 7.36944,2.1547 7.26855,2.17218 7.38363,2.83635 7.48452,2.81887 Z" style={{fill: '#2f2a61'}} />
          <path id="path1461" d="M 7.82577,2.11185 7.72351,2.11696 7.75714,2.79017 7.8594,2.78507 Z" style={{fill: '#2f2a61'}} />
          <path id="path1463" d="M 8.29836,2.12059 8.19623,2.11325 8.14792,2.78557 8.25005,2.79291 Z" style={{fill: '#2f2a61'}} />
          <path id="path1465" d="M 5.19585,8.73875 5.26776,8.81165 5.74762,8.33827 5.67572,8.26538 Z" style={{fill: '#2f2a61'}} />
          <path id="path1467" d="M 4.87971,8.38419 4.9422,8.4653 5.47619,8.05393 5.4137,7.97282 Z" style={{fill: '#2f2a61'}} />
          <path id="path1469" d="M 4.64037,8.00909 4.69263,8.09714 5.27229,7.7531 5.22003,7.66505 Z" style={{fill: '#2f2a61'}} />
          <path id="path1471" d="M 4.40806,7.56773 4.44928,7.66145 5.0663,7.39009 5.02508,7.29636 Z" style={{fill: '#2f2a61'}} />
          <path id="path1473" d="M 4.26732,7.14043 4.29681,7.23848 4.94232,7.04433 4.91282,6.94628 Z" style={{fill: '#2f2a61'}} />
          <path id="path1475" d="M 4.13816,6.65268 4.15564,6.75357 4.81981,6.63849 4.80233,6.5376 Z" style={{fill: '#2f2a61'}} />
          <path id="path1477" d="M 4.10109,6.20065 4.1062,6.3029 4.77943,6.26927 4.77432,6.16701 Z" style={{fill: '#2f2a61'}} />
          <path id="path1479" d="M 4.07949,5.71834 4.07233,5.82072 4.7464,5.86791 4.75356,5.76552 Z" style={{fill: '#2f2a61'}} />
          <path id="path1481" d="M 8.77828,2.21674 8.67767,2.19777 8.55275,2.86016 8.65336,2.87914 Z" style={{fill: '#fbb03b'}} />
          <path id="path1483" d="M 9.24139,2.3275 9.1438,2.29651 8.93976,2.93894 9.03735,2.96993 Z" style={{fill: '#fbb03b'}} />
          <path id="path1485" d="M 9.70038,2.50113 9.60724,2.4586 9.32728,3.07177 9.42042,3.1143 Z" style={{fill: '#fbb03b'}} />
          <path id="path1487" d="M 10.0906,2.73117 10.0033,2.6777 9.6513,3.25255 9.73863,3.30602 Z" style={{fill: '#fbb03b'}} />
          <path id="path1489" d="M 10.4835,2.99503 10.4033,2.9314 9.98436,3.45943 10.0646,3.52307 Z" style={{fill: '#fbb03b'}} />
          <path id="path1491" d="M 10.8377,3.32439 10.7657,3.2515 10.2859,3.72487 10.3578,3.79776 Z" style={{fill: '#fbb03b'}} />
          <path id="path1493" d="M 11.1208,3.67078 11.0582,3.58971 10.5245,4.00135 10.587,4.08243 Z" style={{fill: '#fbb03b'}} />
          <path id="path1495" d="M 11.3863,4.06398 11.334,3.97594 10.7544,4.31998 10.8066,4.40802 Z" style={{fill: '#fbb03b'}} />
          <path id="path1497" d="M 11.6208,4.49422 11.5795,4.4005 10.9625,4.67186 11.0037,4.76559 Z" style={{fill: '#fbb03b'}} />
          <path id="path1499" d="M 11.7606,4.93136 11.7311,4.83331 11.0856,5.02746 11.1151,5.12551 Z" style={{fill: '#d64b4b'}} />
          <path id="path1501" d="M 11.881,5.40539 11.8635,5.3045 11.1994,5.41958 11.2168,5.52046 Z" style={{fill: '#d64b4b'}} />
          <path id="path1503" d="M 11.9274,5.88046 11.9223,5.7782 11.2491,5.81186 11.2542,5.91412 Z" style={{fill: '#d64b4b'}} />
          <path id="path1505" d="M 11.9193,6.32701 11.9266,6.22488 11.2543,6.17657 11.247,6.2787 Z" style={{fill: '#d64b4b'}} />
          <path id="path1507" d="M 11.8476,6.798 11.8672,6.69751 11.2057,6.56832 11.1861,6.66881 Z" style={{fill: '#d64b4b'}} />
          <path id="path1509" d="M 11.7349,7.25727 11.7666,7.1599 11.1256,6.95149 11.0939,7.04885 Z" style={{fill: '#d64b4b'}} />
          <path id="path1511" d="M 11.5526,7.67917 11.5958,7.58635 10.9847,7.3019 10.9415,7.39472 Z" style={{fill: '#d64b4b'}} />
          <path id="path1513" d="M 11.3232,8.09344 11.3773,8.00652 10.8051,7.65031 10.7509,7.73724 Z" style={{fill: '#d64b4b'}} />
          <path id="path1515" d="M 11.0491,8.49054 11.1133,8.41081 10.5885,7.98789 10.5242,8.06761 Z" style={{fill: '#d64b4b'}} />
          <path id="path1517" d="M 10.7304,8.8176 10.8038,8.74625 10.3341,8.26285 10.2606,8.3342 Z" style={{fill: '#d64b4b'}} />
        </g>
      </svg>
          {/* <canvas
            ref={canvasRef}
            {...props}
            width={props.width}
            height={props.height}
            style={{ transform: `rotate(${degs[props.direction]}deg)`, 
             filter: blurStatus&&!hover?'blur(2px)':'none' }}
          />

          <canvas
            ref={canvasRefShadow}
            {...props}
            width={props.width + 10}
            height={props.height + 10}
            style={{
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: `translate(-50%,-50%)`,
              zIndex: -1,

     
            }}
          /> */}
        </div>
    </div>

  );
};

export default Manometr;
