import React from "react";
// import PropTypes from "prop-types";

const Canvas = ({ draw, height, width }) => {
    const canvas = React.useRef();

    React.useEffect(() => {
        const context = canvas.current.getContext("2d");
        draw(context);
    });

    return <canvas ref={canvas} height={height} width={width} />;
};

export default Canvas;
