import '../../../App.css';
import Indicator from '../../indicator_components/manometrs/old/Indicator';
import LineProgress from '../../indicator_components/linear_progress/LineProgress';
import RadialProgress from '../../indicator_components/radial_progress/old/RadialProgress';
import React, {useEffect, useState} from 'react';
import SettingPage from './sub_pages/settings/settingPage';
import LangPage from './sub_pages/lang/langPage';
import InfoPage from './sub_pages/info/infoPage';
import ReportPage from './sub_pages/report/reportPage';
import Backdrop from "@material-ui/core/Backdrop";
import CustomImageLoader from "react-custom-image-loader.";
import { makeStyles } from "@material-ui/core/styles";
import {
    MP_ID_Context,
    Values_to_MP_Context,
    set_env_urls,
    open_lang_modal_context, IS_DESKTOP_CONTEXT
} from "../../../App";

import {glv, setlogos} from '../../../components/app_components/helper_functions/helper_functions';
import './main_page.style.css';
import MailOutlineOutlinedIcon from "@material-ui/icons/MailOutlineOutlined";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
// import Button from "@material-ui/core/Button";
import Button from '@mui/material/Button';
import Slide from '@mui/material/Slide';
import Fade from '@mui/material/Fade';
import moment  from 'moment';
import LogoutIcon from '@mui/icons-material/Logout';
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../redux/actions/env.actions";
import ConfirmModal from "../../modals/confirm_modal/ConfirmModal";
import Cookies from "js-cookie";
import ReportModal from '../../modals/report_modal/ReportModal';
import ProService from "../../../services/ProPageService";
import MenuIcon from '@material-ui/icons/Menu';
import NativeIndicator from "../../indicator_components/manometrs/current/NativeIndicator";
import ChartHomeModal from "../../modals/chart_home_modal/chart_home_modal";
import ManomentryComponent from "../../manometrs_block/Manometrs";
import RenderIndicators from "../../manometrs_block/render_indicators";
import useMediaQuery from "@mui/material/useMediaQuery";
import HlpsBtnsAftManomsComponent from "./utils_components/hlps_btns_aft_manoms.component";
import BlocksWrapper from "../../wrappers/blocks_wrapper";
import {useParams} from "react-router-dom";
import {bufer_indicators, set_bufer_indicators, template_setting_form} from "../pro/current/static_datas";
import HeaderHD from "../../header_components/horizontal_desktop/header_hd";
import HeaderVD from "../../header_components/vertical_desktop/header_vd";
import HeaderHM from "../../header_components/horizontal_mobile/header_hm";
import HeaderVM from "../../header_components/vertical_mobile/header_vm";
import useWindowSize from "../../../hooks/useWindowSize";
import StatisticIndicator from "../../statistic_components/block_for_one_indicator";
import StatisticComponent from "../../statistic_components/Statistic";
import ShemeMainHomeModalComponent from "../../modals/sheme_main_home_modal/sheme_main_home_modal.component";
import AddNewSysModal from "../../modals/add_new_sys/add_new_sys_modal";
import LaunchRegenModalComponent from "../../modals/launch_regen_modal/launch_regen_modal.component";


export const TimezonesContext = React.createContext();

const format_tw = (hours,mins, )=>{

    let theBigDay = new Date();
    theBigDay.setMinutes(mins);
    theBigDay.setHours(hours);
    return theBigDay
}
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    vsplyv: {
        position: "absolute",
        right: 70,
        bottom: 6,
        background: "rgba(97, 97, 97, 0.9)",
        // boxShadow: "-9px -9px 24px rgba(255, 255, 255, 0.570094), 20px 22px 40px rgba(0, 0, 0, 0.0826595)",
        borderRadius: 5,
        color: "#f2f2f2",
        padding: "3px 10px",
        opacity: 0.5,
        "&::after": {
            content: "",
            position: "absolute",
            top: "50%",
            left: "100%",
            marginTop: "-5px",
            borderWidth: "5px",
            borderStyle: "solid",
            borderColor: "transparent transparent transparent black",
        },
    },
}));



function MainPage() {
    const {width, height} = useWindowSize();

    const [header_menu_punkts, set_header_menu_punkts] = useState([
        {
            id: 'add_new_system', action: () => {
                set_open_modal_add_new_sys(true)
            }
        },

        {
            id: 'scheme', action: () => {
                set_open_modal_sheme(true)
            }
        },
        {
            id: 'report', action: () => {
                setOpenReportModal(true)
            }
        },

        {
            id: 'setting',
        },
        {
            id: 'info',
            action: () => {
                set_open_chart_page(false)
                setSettPage(false)
                setReportPage(false)
                set_open_confirm_modal(false)
                set_open_mob_menu(false)
                setLangPage(false)
                setInfoPage(true)
            },
        },
        {
            id: 'select_lang', action: () => {
                set_open_lang_modal(true)
            }
        },
        {
            id: 'logout', action: () => {
                set_open_confirm_modal(true)
            }
        },
    ]);

    const {id} = useParams();
    const is_desktop = React.useContext(IS_DESKTOP_CONTEXT);
    const [setting_form, set_setting_form] = useState({...template_setting_form});
    const [open_lang_modal, set_open_lang_modal] = React.useContext(open_lang_modal_context);
    const dispatch = useDispatch();
    const language = useSelector((state) => state.envReducer.language);
    const setUrls =  React.useCallback((status) => dispatch(actions.setUrls(status)), [
        dispatch,
    ]);
    const changeLanguage = React.useCallback(
        (lang) => dispatch(actions.setLanguage(lang)),
        [dispatch]
    );
    const [added_padding_left_container, set_added_padding_left_container] = React.useState(0);

    const [dimensions_container, set_dimensions_container] = React.useState(false);

    const [hided, set_hided] = React.useState(true);
    const classes = useStyles();
    const [settPage, setSettPage] = React.useState(false);

    const [id_dev_setting, set_id_dev_setting] = React.useState(false);

    const [langPage, setLangPage] = React.useState(false);
    const [infoPage, setInfoPage] = React.useState(false);
    const [reportPage, setReportPage] = React.useState(false);
    // const [indicatorsData, set_indicatorsData] = React.useContext(Values_to_MP_Context);
    const [mp_id, set_mp_id] = React.useContext(MP_ID_Context);

    const [openReportModal, setOpenReportModal] = React.useState("");


    const [open_modal_add_new_sys, set_open_modal_add_new_sys] = React.useState(false);

    const [open_modal_sheme, set_open_modal_sheme] = React.useState(false);
    const [open_mob_menu, set_open_mob_menu] = React.useState(false);
    const [open_chart_page, set_open_chart_page] = React.useState(false);
    const [ids, set_ids] = React.useState(null);
    const [open_confirm_modal, set_open_confirm_modal] = React.useState(false);

    const [open_modal_launch_regen, set_open_modal_launch_regen] = React.useState(false);
    const [is_started_regen, set_is_started_regen] = React.useState(false);
    const [sys_list_to_regen, set_sys_list_to_regen] = React.useState([]);

    const [indicatorsData, setIndicatorsData] = React.useState(null);
    const [accessData, setAccessData] = React.useState(null);
    const [online_offline_inds_statuts, set_online_offline_inds_statuts] = React.useState(null);

    const [timezones, set_timezones] = React.useState([]);


    const hash = useSelector((state) => state.envReducer.hash);
    const urls = useSelector((state) => state.envReducer.urls);


    const create_bufer_indicator = (data) => {
        // создание буфера индикатора по шаблону

        let new_bufer_obj = {};
        let new_bufer_temp = {
            min: 0,
            max: 1000,
            val: 0,
            old_val: 0,
            trig_movie: {
                stars: false,
                animac: false
            }
        };
        Object.keys(data).forEach(item => {
            new_bufer_obj = {...new_bufer_obj, [item]: new_bufer_temp}
        })

        set_bufer_indicators(new_bufer_obj);
    }

    const set_indicators_data = (data) => {

        // устанавливаются значения индикатора

        let copy_data_values = {...data.values};
        if (!bufer_indicators) {
            // console.log('created bufer maniak')
            create_bufer_indicator(copy_data_values);
        }
        // console.log(bufer_indicators)

        Object.keys(copy_data_values).forEach(item => {
            // console.log(bufer_indicators, item)
            let val = bufer_indicators[item].val;
            let max = bufer_indicators[item].max;
            let min = bufer_indicators[item].min;

            if (copy_data_values[item].value !== null
                && copy_data_values[item].value !== undefined) {
                bufer_indicators[item].old_val = val;
                bufer_indicators[item].val = copy_data_values[item].value;
            }
            if (copy_data_values[item].sys_info.max !== null
                && copy_data_values[item].sys_info.max !== undefined) {

                bufer_indicators[item].max = copy_data_values[item].sys_info.max;
            }
            if (copy_data_values[item].sys_info.min !== null
                && copy_data_values[item].sys_info.min !== undefined) {

                bufer_indicators[item].min = copy_data_values[item].sys_info.min;
            }

            if (bufer_indicators[item].val !== val) {
                bufer_indicators[item].trig_movie.stars = true;
            }
            if (bufer_indicators[item].max !== max) {
                bufer_indicators[item].trig_movie.stars = true;
            }
            if (bufer_indicators[item].min !== min) {
                bufer_indicators[item].trig_movie.stars = true;
            }

            // if(bufer_indicators[item].trig_movie.stars && !bufer_indicators[item].trig_movie.animac){
            //   bufer_indicators[item].trig_movie.animac = true;
            // }
        })

        setIndicatorsData({...data})
    }

    function getData() {
        ProService.getIndicatorsData((data)=>set_env_urls(data) , hash, id).then((res)=>{
            if(res&&(res.code===200||res.code===201)){

                if (res?.info?.constructor===Array) {
                    set_online_offline_inds_statuts({})
                    set_ids([])
                    setIndicatorsData([])
                    return
                }
                let o_of = {};
                Object.keys(res.info.values).map((item)=>{
                    let rf_ =  res.info.values[item];
                   // console.log(((moment().unix()  - rf_.timestamp_upd)/60),rf_.timestamp_upd)
                    o_of[rf_.name] =(moment().unix()  - rf_.timestamp_upd)/60 <= 4
                })
                set_online_offline_inds_statuts(o_of)
                // setIndData(res.info);

                let confs_devs =
                    Object.keys(res.info.values)
                        .map(item=>({id_: item, name:res.info.values[item].name }));
                set_header_menu_punkts(
                    punkts=>punkts.map(punkt=>{
                    if (punkt.id==='setting'){
                        let properties  =  {};
                        if (confs_devs.length===1) {
                            properties.action = ()=>{

                                set_id_dev_setting(confs_devs[0])
                                set_open_chart_page(false)
                                setReportPage(false)
                                setInfoPage(false)
                                set_open_confirm_modal(false)
                                set_open_mob_menu(false)
                                // setLangPage(false)
                                setSettPage(true)
                            }
                        } else {
                            properties.child=confs_devs.map(item=>{
                                return {
                                    id: item.id_,
                                    custom_name: item.name,
                                    action: ()=>{
                                        set_id_dev_setting(item)
                                        set_open_chart_page(false)
                                        setReportPage(false)
                                        setInfoPage(false)
                                        set_open_confirm_modal(false)
                                        set_open_mob_menu(false)
                                        // setLangPage(false)
                                        setSettPage(true)
                                    }
                                }
                            })
                        }
                        return {
                           ...punkt,
                            ...properties
                        }
                    }
                        return punkt
                }))
                set_sys_list_to_regen(confs_devs)
                set_ids(confs_devs)



                set_indicators_data({...res.info})

                set_is_started_regen(false);
            }
        }).catch((error) => {
            setlogos(JSON.stringify({error:error.name, info: error.message }),"request error on pro page when get indicators data" );
        });
        setTimeout(() => {
            getData();
        }, 8000);
    }
    function getAccessedData() {

        ProService.getAccessData((data)=>set_env_urls(data), hash, id).then((res)=>{

            if (res.code===400||res.code===409) {
                window.location.href = 'https://i.bregus.eu/page_info#404';
            }
            let timezones =                 {
                tz_obj: res.info?.timezones,
                tz_arr: Object.keys(res.info?.timezones).map(key_tz=>{

                    return {
                        id: key_tz,
                        value:res.info.timezones[key_tz]
                    }
                })
            }

            set_timezones(timezones)
            if (        !res.info?.access_control?.scheme.visible||
                !res.info?.sheme_obj?.status) {

                set_header_menu_punkts(punkts=>punkts.filter(punkt=>punkt.id!=='scheme'))
            }

            setAccessData({...res.info, datetime: res.datetime})
        }).catch((error) => {
            setlogos(JSON.stringify({error:error.name, info: error.message }),"request error on pro page when get access data" );
        });
    }

    React.useEffect(() => {
        let get_header_setting_ls = localStorage.getItem('setting_form')
        if (get_header_setting_ls) {
            let old_saved_setting_form = JSON.parse(get_header_setting_ls);
            set_setting_form({...old_saved_setting_form})
        } else {
            localStorage.setItem('setting_form', JSON.stringify(template_setting_form))
        }
        getAccessedData();
    }, []);


    useEffect(() => {
        if (is_desktop&& !setting_form.header_position.desktop_is_horizontal) {
            if (hided) {
                set_added_padding_left_container(27)
            } else {
                set_added_padding_left_container(165)
            }
        } else {
            set_added_padding_left_container(0)
        }
    }, [
        is_desktop ,
        setting_form.header_position.desktop_is_horizontal,
        hided
    ]);

    React.useEffect(() => {
        if (accessData) {
            document.title = 'Bregus | '+accessData.name;
            getData();
        }
    }, [accessData]);

    let padding_sum_content_blocks = is_desktop ?100 : 50 ;
    let max_width_blocks = width-padding_sum_content_blocks-added_padding_left_container;


    let dev_data_for_setting =id_dev_setting ? indicatorsData.values[id_dev_setting.id_] : null;



    return !ids||!indicatorsData||!accessData||!online_offline_inds_statuts ?
        <Backdrop style={{zIndex: 55455}} open={true}>
  <CustomImageLoader
    image={`${process.env.PUBLIC_URL}/favicon.ico`}
    isLoaded={true}
    circle={false}
    speed={2}
    animationType={"pulse"}
  />
</Backdrop> :

    (
        <TimezonesContext.Provider value={timezones}>
                 <div className="main">
              {is_desktop ?
                  setting_form.header_position.desktop_is_horizontal ? (
                      <HeaderHD
                          punkts={header_menu_punkts}
                          title={accessData.name}
                          report_btn_action={() => {
                              setOpenReportModal(true)
                          }}
                          info_btn_action={() => {
                              // setOpenHelpModal(true)
                          }}
                          lang_btn_action={() => {
                          }}
                          logaut_btn_action={() => {
                              set_open_confirm_modal(true)
                          }}
                      />
                  ) : (
                      <HeaderVD
                          hided_data={[hided, set_hided]}
                          punkts={header_menu_punkts}
                          title={accessData.name}
                          report_btn_action={() => {
                              setOpenReportModal(true)
                          }}
                          info_btn_action={() => {
                              // setOpenHelpModal(true)
                          }}
                          lang_btn_action={() => {
                          }}
                          logaut_btn_action={() => {
                              set_open_confirm_modal(true)
                          }}
                      />
                  ) : setting_form.header_position.mobile_is_horizontal ?
                      <HeaderHM
                          hided_data={[hided, set_hided]}
                          punkts={header_menu_punkts}
                          title={accessData.name}
                          report_btn_action={() => {
                              setOpenReportModal(true)
                          }}
                          info_btn_action={() => {
                              setInfoPage(true)
                          }}
                          lang_btn_action={() => {
                          }}
                          logaut_btn_action={() => {
                              set_open_confirm_modal(true)
                          }}
                      /> : (
                          <HeaderVM
                              hided_data={[hided, set_hided]}
                              punkts={header_menu_punkts}
                              title={accessData.name}
                              report_btn_action={() => {
                                  setOpenReportModal(true)
                              }}
                              info_btn_action={() => {
                                  // setOpenHelpModal(true)
                              }}
                              lang_btn_action={() => {
                              }}
                              logaut_btn_action={() => {
                                  set_open_confirm_modal(true)
                              }}
                          />
                      )
              }

          {
              accessData.access_control.scheme.visible&&
              accessData?.sheme_obj?.status ?
                  <ShemeMainHomeModalComponent
                      title={accessData.name}
                      open={open_modal_sheme}
                      close={()=>{set_open_modal_sheme(false)}}
                      accessData={accessData}
                      onl_offl={online_offline_inds_statuts}
                      indicatorsData={indicatorsData}
                  /> : null
          }
                     {dev_data_for_setting  ?
                         <SettingPage
                             dev_={id_dev_setting}
                             full_w={is_desktop ? '' : 'full_width'}
                             open={settPage}
                             access={dev_data_for_setting?.settings.sys.access}
                             vacation={dev_data_for_setting?.settings.sys.vacation}
                             l2_max={dev_data_for_setting?.sys_info?.limits?.l2?.max}
                             wash_to_day={dev_data_for_setting?.settings.wash_to_day}
                             time_wash={
                                 format_tw(dev_data_for_setting?.settings.time_wash.hours,
                                     dev_data_for_setting?.settings.time_wash.minutes
                                 )
                             }
                             bef_wash_not={dev_data_for_setting?.settings.to_notification.before_wash}
                             email={dev_data_for_setting?.settings.to_notification.e_mail}
                             sms={dev_data_for_setting?.settings.to_notification.sms}
                             teleg={dev_data_for_setting?.settings.to_notification.telegram}

                             salt_tank={dev_data_for_setting?.settings.salt_tank}
                             access_write={dev_data_for_setting?.settings.sys.access.settings.write}
                             access_admin={dev_data_for_setting?.settings.sys.access.settings.admin}
                             using_mob={!is_desktop}
                             delays={dev_data_for_setting?.settings.sys.delays}
                             time_zone={dev_data_for_setting?.settings.time_wash.timezone}
                             day_wash={dev_data_for_setting?.settings.day_wash}

                             wash_notification={dev_data_for_setting?.settings.wash_notification}
                             cancelSetting={() => {
                                 set_id_dev_setting(null)
                                 setSettPage(false)
                             }}
                             saveSetting={(form) => {

                                 ProService.save_settings_dev(id_dev_setting.id_, form).then((res) => {

                                 })
                                 set_id_dev_setting(null)
                                 setSettPage(false)
                             }}
                         />
                         :null}


          <LaunchRegenModalComponent
            open={open_modal_launch_regen}
            sys_list={sys_list_to_regen}
            set_is_started_regen={(status)=>set_is_started_regen(status)}
            close={()=>{
                set_open_modal_launch_regen(false)
            }}
          />

          <AddNewSysModal
            open={open_modal_add_new_sys}
            close={()=>{
                set_open_modal_add_new_sys(false)
            }}
          />
          <ReportModal
              id={id}
              open={openReportModal}
              close={() =>setOpenReportModal(false)}
              openOvl={(status) =>{ }}
          />

              <ConfirmModal
              open={open_confirm_modal}
              content={glv('second_level_blocks.confirm_modal.logaut_text')}
              close={()=>set_open_confirm_modal(false)}
              f_to_will_confirm ={
                  async () => {
                  // let hash = await Cookies.get("hash_key", {
                  //     domain: ".bregus.eu",
                  // });
                 ProService.log_out(set_env_urls ,hash)
                     .then(() => {
                         Cookies.remove("hash_key", {
                             domain: ".bregus.eu",
                         });
                         window.location.href = urls.login;
                     });
              }
          }/>



    {
        // langPage ? <LangPage conf_lang={configLang} currLang={language} changeLang={(lang)=> {
        //         // setCurrentLang(lang)
        //         changeLanguage(lang)
        //     }} closeLangFrame={()=>setLangPage(false)} /> :
        open_chart_page ?
            <ChartHomeModal
                sys_id={id}
                accessData={accessData}
                ids={ids}
                indicatorsData={indicatorsData}
                close={()=>{
                    set_open_chart_page(false)
                }}
                is_desktop={is_desktop}
            />

        :
     infoPage ?
         <Fade  in={true} >
             <div>
         <InfoPage cancelInfoFrame={()=>setInfoPage(false)}
                   access_name ={accessData.name}
                   l2_max={indicatorsData.values[Object.keys(indicatorsData.values)[0]].sys_info?.limits?.l2?.max}
                   _filtered={
                       indicatorsData.values[Object.keys(indicatorsData.values)[0]].statuts.hasOwnProperty('r1')
                           ?
                       indicatorsData.values[Object.keys(indicatorsData.values)[0]].statuts['r1'] : false
                   }
                   lat={Number(accessData.coordinate.n.replace(/#/g, ''))}
                   lng={Number(accessData.coordinate.e.replace(/#/g, ''))}
                   users_arr = {
                       accessData.users_arr
                   }

                   setts = {
                       indicatorsData.values[Object.keys(indicatorsData.values)[0]].settings
                   }
                   current_state={
                       moment(accessData.dates.now).diff(moment.unix(accessData.dates.last_upd), "minute") >= 1
                           ?'offline'
                           :  moment.unix(accessData.dates.last_upd).format('ll HH:mm')
                   }
                   using_mob={!is_desktop}
                   use_date={moment.unix(accessData.dates.install).format("ll")}
         />
             </div>
         </Fade>
             :

      <main className="main_container">
      <div id="content_page">
    <div id="content_rec">
      {/*<div id="c_f_header">{indicatorsData.values[Object.keys(indicatorsData.values)[0]].name}</div>*/}
        <BlocksWrapper
            id_content_wrap={'content_wrap_manometry'}
            id_block={'manometry'}
            max_width={max_width_blocks}
            no_mt={true}
            has_gag={accessData.access_control.manometry.gag}
            set_dimensions_container={set_dimensions_container}
            is_desktop={is_desktop}
            header_title={  glv(`first_level_blocks.block_titles.manometry`)}
        >
            {
                dimensions_container ?
                    <>
                        <RenderIndicators
                            style={{marginTop: is_desktop? 25 : 0 }}
                            id_scroll_wrapper={'content_wrap_manometry'}
                            manomentry_block_max_width={max_width_blocks}
                            setting_data={setting_form}
                            is_desktop={is_desktop}
                            indicatorsData={indicatorsData}
                            dimensions_container={dimensions_container}
                        />
                        <HlpsBtnsAftManomsComponent
                            is_desktop={is_desktop}
                            regen_btn_click={()=>{
                                set_open_modal_launch_regen(true)
                            }}
                            chart_btn_click={()=>{
                                set_open_mob_menu(false)
                                setInfoPage(false)
                                setSettPage(false)
                                set_open_chart_page(true)
                            }}


                            dimensions_container={dimensions_container}
                            info_btn_visible={indicatorsData.values[Object.keys(indicatorsData.values)[0]].settings.sys.access.graph.read  }
                            margin_btn_info={
                                indicatorsData.values[Object.keys(indicatorsData.values)[0]].f_pos !==1
                                || !indicatorsData.values[Object.keys(indicatorsData.values)[0]].settings.sys.access.btn_regen.read
                                    ? 0 : 20
                            }
                            regen_btn_visible={
                                is_started_regen
                                ||
                                Object.keys(indicatorsData.values).some((key, index)=>{
                                    return !indicatorsData.values[Object.keys(indicatorsData.values)[index]].settings.sys.access.btn_regen.read
                                })  ||
                                Object.keys(indicatorsData.values).some((key, index)=>{
                                    return indicatorsData.values[Object.keys(indicatorsData.values)[index]].f_pos!==1
                                })


                            }
                            regen_btn_opacity={
                                indicatorsData.values[Object.keys(indicatorsData.values)[0]].settings.sys.access.btn_regen.write ?
                                    1 : 0.2
                            }
                            regen_btn_bg={indicatorsData.values[Object.keys(indicatorsData.values)[0]].f_pos!==1 ? '#5E5AB9' : '#5E5AB9'}
                            is_class_regen={indicatorsData.values[Object.keys(indicatorsData.values)[0]].f_pos!==1}
                            visible_regen_svg={
                            true
                            // indicatorsData.values[Object.keys(indicatorsData.values)[0]].f_pos!==1
                        }
                        />
                    </> : null
            }

        </BlocksWrapper>
    </div>

          <StatisticComponent
              max_width={max_width_blocks}
              is_desktop={is_desktop}
              indicatorsData={indicatorsData}
              onl_offl={online_offline_inds_statuts}
              accessData={accessData} />

  </div>

      </main>

   }
        <div className="description_step" />
    {/*<div className="skip_next" id="skne" >*/}
    {/*  <div className="skip_interaktive" id="skip" onClick={()=>window.skipDemo()}> Skip</div>*/}
    {/*  <div className="next_move" id="nm" onClick={()=>window.next_step()}>Next <svg id="arrow_next_move" width={24} height={24} xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd"><path d="M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z" /></svg></div>*/}
    {/*  <div className="next_move " style={{display: 'none'}} id="close_int" onClick={()=>window.skipDemo()}>Close <svg id="arrow_next_move" width={24} height={24} xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd"><path d="M12 11.293l10.293-10.293.707.707-10.293 10.293 10.293 10.293-.707.707-10.293-10.293-10.293 10.293-.707-.707 10.293-10.293-10.293-10.293.707-.707 10.293 10.293z" /></svg></div>*/}
    {/*</div>*/}
    <canvas id="overlay_canvas" width={30} height={15} style={{border: '1px solid #d3d3d3'}} />
    {/*<div style={{position: 'absolute', bottom: 10, left: 20, }}> {appInfo.version}</div>*/}
     </div>
        </TimezonesContext.Provider>
    );
  }
  
  export default MainPage;