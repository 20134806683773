import React from 'react';

import ChartComponent from "../../charts_components/chart_block/Chart";
import {glv} from "../../../components/app_components/helper_functions/helper_functions";
import Fade from '@mui/material/Fade';
import TableComponent from "../../tables/Table";

const ChartHomeModal = (props) => {
    const [regenNotifCheked, setRegenNotifCheked] = React.useState('');
    const [tab, set_tab] = React.useState(0);
    // const [degTear, setDegTear] =

    return (
        <div className="mt0 w100">

            <section className="chart_home_wrap">
                <div className="ok_cnc_btns mb25 ml25 mr25" style={{width: 'initial'}}>
                    <div className={`flex_1_ mr25 home_chart_modal_btn  ${tab===0? 'canc_sett_btn_active': ''}`}

                         onClick={()=> {
                        set_tab(0)
                    }}>
                        <span style={{fontWeight:tab===0?'bold' : '' }}>{   glv(`first_level_blocks.block_titles.chart`)}</span>
                        </div>
                    <div className={` flex_1_ ml25  home_chart_modal_btn  ${tab===1? 'canc_sett_btn_active': ''}`} onClick={()=> {
                        set_tab(1)
                    }}>
                        <span style={{fontWeight:tab===1?'bold' : '' }}>
                                            {
                                                glv(`first_level_blocks.block_titles.table`)
                                            }
                        </span>
        </div>
                    {/*<div className="canc_sett_btn" onClick={()=>props.close()}>Cancel</div>*/}
                </div>
                {
                    tab===0?
                        <Fade  in={true} >
                        <div style={{ padding: "0px 25px" }} >
                            <ChartComponent
                                no_pd = {true}
                                no_mt={true}
                                no_header_id={true}
                                no_ml_sel = {true}
                                ai_cen = {true}
                                sys_id={props.sys_id}
                                accessData={props.accessData}
                                ids={props.ids}
                                indicatorsData={props.indicatorsData}
                                is_desktop={props.is_desktop}
                            />
                        </div>
                        </Fade>
                            :

                        <Fade  in={true} >
                            <div>
                                <TableComponent
                                    cust_style={{marginLeft: 25, marginRight: 25, padding: 0, boxShadow: 'none', }}
                                    no_mt={true}
                                    no_header_id={true}
                                    no_container_shadow={true}
                                    no_pre_options={true}
                                    sys_id={props.sys_id}
                                    accessData={props.accessData}
                                    ids={props.ids}
                                    ind_data={props.indicatorsData}
                                />
                            </div>
                        </Fade>
                }

                <div className="ok_cnc_btns mb25">
                    <div className="home_chart_modal_btn " onClick={()=>props.close()} style={{minWidth: 200}}>{glv('joint_buttons.close')}</div>
                    {/*<div className="canc_sett_btn" onClick={()=>props.close()}>Cancel</div>*/}
                </div>
            </section>
        </div>

    )
}

export default  ChartHomeModal;