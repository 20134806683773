import date_with_null_time from "./format_date_with_null_time";

export default function generateDateTableBody(curr_props_date, is_range_date, first_day_week_index) {
    let is_true_range = curr_props_date.selected_date.length > 1;
    let curr_date_start = curr_props_date.selected_date[0];
    let curr_date_finish = curr_props_date.selected_date[1] ? curr_props_date.selected_date[1] : new Date();

    let proper_date = curr_props_date.curr_view_date;

    let first_date_of_proper_month = new Date(proper_date.getFullYear(), proper_date.getMonth(), 1);
    let week_day_fd = first_date_of_proper_month.getDay();

    let result_arr = [];
    let counter = 0;

    let startDatOffset = week_day_fd * (-1) + 1;

    let y = proper_date.getFullYear();
    let m = proper_date.getMonth();
    let d_ = proper_date.getDate();

    const check_is_selected = (view_date) => {

        if (is_range_date && is_true_range) {
            let same_date = date_with_null_time(view_date) >= date_with_null_time(curr_date_start)
                &&
                date_with_null_time(view_date) <= date_with_null_time(curr_date_finish);

            if (same_date) {
                return true
            }
        }
        return view_date.getFullYear() === curr_date_start.getFullYear() &&
            view_date.getMonth() === curr_date_start.getMonth() &&
            view_date.getDate() === curr_date_start.getDate()
    }

    const check_is_disabled = (view_date) => {
        if (is_range_date && !is_true_range) {
            let not_same_y = view_date.getFullYear() < curr_date_start.getFullYear();
            let not_same_m = view_date.getMonth() < curr_date_start.getMonth();
            let not_same_d = view_date.getDate() < curr_date_start.getDate();

            if (not_same_y && not_same_m && not_same_d) {
                return true
            }
        }
        return false
    }

    for (let week = 0; week < 5; week++) {
        let temp_arr = [];

        for (let day = 0; day < 7; day++) {

            let day_ = counter + startDatOffset;
            // startDatOffset;
            // console.log('day:',day_, 'counter:',counter , 'startDatOffset:', startDatOffset)
            let d = new Date(y, m, day_, 0, 0, 0, 0);
            let is_selected = check_is_selected(d);
            let is_disabled = check_is_disabled(d)
            let new_day_obj = {
                id: d.getTime() + 'ymd',
                day_text: d.getDate(),
                date_value: d,
                year: d.getFullYear(),
                month: d.getMonth(),
                is_selected,
                is_curr_month: d.getMonth() == m,
                is_disabled,
                week_day: d.getDay()

            }
            temp_arr.push({...new_day_obj})
            counter++;
        }

        result_arr.push([...temp_arr]);
        temp_arr = [];
    }

    return result_arr;
}