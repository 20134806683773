import React from 'react';
import {set_env_urls} from "../../App";
import {glv} from '../../components/app_components/helper_functions/helper_functions'
import MaterialTable from "material-table";
import ProService from "../../services/ProPageService";
import {useDispatch, useSelector} from "react-redux";
import {format} from 'mathjs'
import moment from 'moment';
import UpdateRoundedIcon from '@material-ui/icons/UpdateRounded';
import Fade from "@material-ui/core/Fade";
import GagPub from "../pubs/gag_pub";
import InputSelect from "../form_components/Input_Select";
import BlocksWrapper from "../wrappers/blocks_wrapper";

let moment_tz = require('moment-timezone');

// компонент блока таблицы

const TableComponent = (props) => {

    const {accessData} = props;
    let sys = props.ind_data?.system || null;
    const [id_, set_id_] = React.useState(
        props.ids[0].id_
    )
    const [is_load, set_is_load] = React.useState(
        true
    )
    const [data_, set_data_] = React.useState(
        null
    )
    const [table_data, set_table_data] = React.useState(
        []
    )

    const hash = useSelector((state) => state.envReducer.hash);
    const constructor_table_data = (data) => {

        let new_arr = data[id_].sort(function (a, b) {
            return new Date(b.date_crt) - new Date(a.date_crt);
        })
        let curr_tz = Intl.DateTimeFormat().resolvedOptions().timeZone
        new_arr = new_arr.map((item, index) => {
            let new_date = new Date(item.date_crt * 1000);
            let new_date_with_tz = moment_tz.tz(new_date, curr_tz).format('MM-DD-YYYY HH:mm')
            let obj = {
                date_crt: new_date_with_tz,
                sim_signal: item.sim_signal + ' ' + glv('first_level_blocks.chart.nums.sim_signal'),
                f_bufwsize: format(item.f_bufwsize / 1000, {precision: 3}) + ' ' + glv('first_level_blocks.chart.nums.f_bufwsize'),
                f_litmin: format(item.f_litmin / 1000, {precision: 3}) + ' ' + glv('first_level_blocks.chart.nums.f_litmin')
            };
            if (index === new_arr.length - 1) {
                obj.rowStyle = {
                    borderBottomRightRadius: 12,
                    borderBottomLeftRadius: 12
                }
            }
            return obj

        });
        set_table_data(new_arr)
    }

    const getData = () => {
        set_is_load(true)
        let sub_date = moment().subtract(2, 'hour');

        ProService.get_chart_table_data(
            (data) => set_env_urls(data),
            hash,
            props.sys_id,
            'auto',
            'group',
            moment(sub_date).unix(),
            moment().unix()
        ).then(result => {
            if (id_ && result?.info?.constructor === Object) {
                // if ( !result?.info[id_]){
                //     set_id_(props.ids[0].id_)
                // }
                set_data_(result?.info)
                constructor_table_data(result?.info)
                set_is_load(false)
            } else {
                set_is_load(false)
            }
        })
    }
    React.useEffect(() => {
        if (id_ && !data_) {
            getData()
        }
        if (id_ && data_) {
            constructor_table_data(data_)
        }

    }, [id_, data_])

    let cust_style = props.cust_style ? props.cust_style : {}
    // изменить стили
    return (
        <BlocksWrapper
            id_block={'table'}
            max_width={props.max_width}
            header_title={glv(`first_level_blocks.block_titles.table`)}
            has_gag={accessData.access_control.table.gag}
            is_desktop={props.is_desktop}
            wrap_cls={'wrap_table_pro'}
        >
                <div className={'wrap_selects'} style={{
                    display: props.no_pre_options ? 'none' : 'flex',
                }}>
                    <div style={{flex: 1}}></div>

                    <div
                        className={'upd_table_btn bg_shad_init'} onClick={() => {
                        getData()
                    }}>

                        <UpdateRoundedIcon style={{fill: '#5e5ab9'}}/>
                    </div>

                    <InputSelect
                        struct_style={{}}
                        // default_height = {21}
                        no_mt={true}
                        name={'select id'}
                        value={id_}
                        options={
                            props.ids.map((item) => ({
                                text: item.name,
                                id: item.id_,
                            }))
                        }
                        placeholder={'select id'}
                        on_change={(name, value) => {
                            set_id_(value)
                        }}
                    />
                </div>


                <MaterialTable
                    style={{
                        marginTop: props.no_pre_options ? 0 : 15,
                        paddingLeft: '20px',
                        paddingRight: '20px',
                        // marginBottom: 16,
                        boxShadow: 'none',
                        // width: "100%",
                        background: 'transparent',
                        fontFamily: 'gilroy'
                    }}
                    localization={{
                        body:{
                            emptyDataSourceMessage: glv('joint_buttons.empty_table')
                        }
                    }}
                    isLoading={is_load}
                    components={{
                        OverlayLoading: props => {
                            // console.log(props)
                            return <Fade in={true}>
                                <div className={'center_flex'}
                                     style={{
                                         backgroundColor: 'rgba(0,0,0,0.2)',
                                         width: '100%',
                                         height: '100%',
                                         borderRadius: '12px'
                                     }}>
                                     <span className={'color_wh'}>

                                          {
                                              // glv(`first_level_blocks.pub_block.text`)
                                              'Loading...'
                                          }
                                     </span>
                                </div>
                            </Fade>
                        }
                    }}
                    columns={
                        [
                            "date_crt",
                            "sim_signal",
                            "f_litmin",
                            "f_bufwsize",
                            // "time_upwork"
                        ].map((item) => ({
                            title: glv('first_level_blocks.table.titles.' + item), field: item,
                        }))
                    }
                    data={table_data}

                    options={{
                        toolbar: false,
                        header: true,
                        sorting: false,
                        showTitle: false,
                        grouping: false,
                        search: false,
                        filtering: false,
                        draggable: false,
                        paging: false,
                        rowStyle: {

                            fontSize: 15,
                            fontFamily: 'gilroy',

                            borderSpacing: 10,
                            background: 'transparent',
                            borderTop: '1px solid #c4c9d5'
                        },
                        headerStyle: {

                            fontSize: 15,
                            fontWeight: 600,
                            // background: '#5e5ab9',
                            width: 'auto !important',
                            background: 'transparent',
                            color: '#2e2a60',
                            fontFamily: 'gilroy',
                            borderBottom: '1px solid #5E5AB9',
                            textAlign: 'center'
                        },
                        cellStyle: {
                            border: 'none',
                            textAlign: 'center',
                            width: 'auto',
                        }
                    }}
                />
        </BlocksWrapper>
    )
}

export default TableComponent;