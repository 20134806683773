import React from "react";

const PurpleButton = (props) => {
    let cust_style= props.style ? props.style : {};
    let cls_outer = props.cls_outer ? props.cls_outer : '';
    let cls_inner = props.cls_inner ? props.cls_inner : '';
    let cls_text = props.cls_text ? props.cls_text : '';
    let is_disabled_cls = props.disabled ? 'disabled ' : '';
    return (
        <div
            onClick={props.disabled ? ()=>{}:()=>props.on_click()}
            className={'purple_button_outer '+is_disabled_cls+cls_outer }
            style={cust_style}
        >

            <div className={'purple_button_inner ' + cls_inner}>
                {
                    props.is_icon_button ?
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'}}>
                            {props.icon}
                        </div>
                        :
                        <span className={'purple_button_text '+cls_text}>{props.text}</span>
                }

            </div>
        </div>

    );
};

export default PurpleButton;
