

export let bufer_indicators = null;

export let set_bufer_indicators = (new_bufer_indicators) => {
    bufer_indicators = {...new_bufer_indicators}
}

export const states_look_manometrs = ['all_column', 'all_slider', 'rows_slider'];

export let template_setting_form = {
    header_position: {
        desktop_is_horizontal: true,
        mobile_is_horizontal: true
    },
    // ['all_column', all_slider, rows_slider]
    look_manometrs:  {
        desktop_look: 'all_column',
        mobile_look: 'all_slider',
        is_two_column_all_column_d: true,
        is_two_column_all_column_m: false
    }
};
export const set_local_strage_current_sett_form =(sett_form)=>{
    localStorage.setItem('setting_form', JSON.stringify({...sett_form}))
}

export const get_local_strage_current_sett_form =()=>{
    let get_header_setting_ls = localStorage.getItem('setting_form')
    if (!get_header_setting_ls) {
        set_local_strage_current_sett_form({...template_setting_form})
        return {...template_setting_form}
    }
    let old_saved_setting_form = JSON.parse(get_header_setting_ls);
    return old_saved_setting_form;
}


export let blocks_ids_list = [
    'manometry',
    'chart',
    'scheme',
    'static',
    'table'
]