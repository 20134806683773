import React, { useRef, useEffect } from "react";
import { Tooltip } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../redux/actions/env.actions";
import uuid from 'react-uuid'
import { Fade } from '@material-ui/core';
import ShemeModal from '../modals/sheme_element_modal/shemeElementModal';
import useMousePosition from '../../hooks/useMousePosition';
const debounce = require('debounce');
const degs  ={
    left: 90,
    right: 270,
    bottom: 0,
    top: 180
}

const data = `data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAG4AAADcCAMAAACmh2S3AAACDVBMVEUAAAAAAAAAAIBVAFVAQIArK1UkJG0gIGAzM2YuLl0nJ2I3JFstLVorK2MoKF4xJGExJ2IuLmQzK14xKWMwKGAtLWIwKWA0J2ItLWAyLGQxLGIuKWAwLGEvK2ItKV8tKWMwLGAvK2IuK2EuKl8xKWIvLGItKmIwKWAwKWMwLF8wKWAvKWIvLGAuK2EuK2EtK2MvKmEuKWEwKWEwK2MwK2AvKmAuKmEwKWIvK2AvKmIuLGEuK2EwKmEvKmAvKWIwKmEvKmIvKmEvKmEvKmIvKmEvKmAvKWEwKmIvKmEuKmEvKmIvKmEvKWEvKWEvKmAvKmEvKmEwKWAwKWIvKmEvKmIuKmEvKmEvKmEwKmEvKmEvKmEuKmEvKmEvK2EvKmEvKmEvKmAvKmEvKmEvKmEvK2EvKmEvKmAvKmEvKmEvKmEfo/4fpP8glu0gmfEgm/Qgnvggn/ggn/kgov0hjuIhkechk+khlOohlOshluwhmPAiidwiit4ijOAjhtcjh9kjidsji94keskkfMskfcwkgNEldMIldcIleMYmb7omcbwnZa4nZ7AnabMoXqUoZKwoZK0pW6EqS4wqTo8qUJIqUJMqVJcqVJgqVZgrRoQrRoYsP3wtNG0tN3EtN3ItOXQtOnUtOnYtQH0uLGMuLmcuMWouMmwuNG8uNW8uOHMvKmEvK2IvLGQvLWUvMGn////6bL7TAAAAanRSTlMAAQIDBAYHCAoLDQ4REhMVGhweHyAiJScoKTQ4Ojw+PkBBQkNERklKS0tQUVJUWVpcZGRkZW1ucHJze4SGh4iLjZGYmZ2kra+ytbe4usXJzs/R0dPT1tfa3N3f4eLk5enu7/H19vj5+fr+R+lGIgAAAAFiS0dErrlrk6cAAAJbSURBVHja7dzXU9RAAMfxYC/YBRW7IFasKBZsWFFBUVFBRU9Q7oiKYu+AvfeOiiDqV/B/9AFOZhyObC57jg+/71N2JrOf2clkZ/MSx/EsuQAAynMcx8kKtQ+KU5wENZ+Oyh3HKYoOViSKWxwVcBxnT/R6TYK0rLI/XElJSTh6fWhRAqz+kzNCdF1kRvogy1qvImJXSlmyXS6Fbjmm2uVSPbhMceLaG+HBpdvlkvK75bb1s/yeJw2P/epNGNkzAftYTC7VMjRk5vINuytickf3blo5Z5il3XLS6mJMKs2b1jcoNnrVwc4Jm1oaGv/uY0tr5w2H89KCYOPzI+3zfH95v+7KxSq3y46fvvHw1Y8OsWB6Urwr2wJA27N7Z1zvLtx60QxA4dh4sN65YYD3d0+4plXffgsQWTfQ/9K2A7y7WeX66vpzgNJxPrWMEPCp3vXf1S/AkXm+tNlh+Pn0pBtPNQ+aIJLjQ8ushOZ6N96ufYBItvnBJAQttW78nf0MYdPn16MQfl12g3SuAfYPMOOygTtusC61Qa7ZJnkAXrtBewIVg024WdB2LDBX/Q2WmHDr4Y0bvMdQYMLtgzoLXC1UGhwp+gDnLXA1rTDGmxsKnLLAuQ0w0ezjo8oG12j0oSJOnDhx4sSJEydOnDhx4sSJEydOnDhx4sSJEydOnDhx4sSJEydOnDhx4sSJEydOnDhx4sSJEydOnDhx4sSJEydOnLhEc2nAIxt9hYXe3BTstUycOHHixIkTJ06cD27uv+UWWOSWenOjNm7esctGO7euNfjzj1JKKaXU/9hvho0fpZzH9k8AAAAASUVORK5CYII=`

const BakTwo = (props) => {
  const dispatch = useDispatch();
  const canvasRef = useRef(null);
  const canvasRefShadow = useRef(null);
  const bakRef = useRef(null);
  const [hover, setHover] = React.useState(false);
  const [highlightColor, setHighlightColor] = React.useState('');
  const [idShadow, setIdShadow] = React.useState(uuid());
  const [idSvg, setIdSvg] = React.useState(uuid());
  const blurStatus = useSelector((state) => state.envReducer.blurElems);
  const overModal = useSelector((state) => state.envReducer.overModal);

  const { x, y } = useMousePosition();

  const [coord, setCoord] = React.useState(null);
  // useEffect(() => {
  //   const canvas = canvasRef.current;
  //   const ctx = canvas.getContext("2d");

  //   ctx.clearRect(0, 0, canvas.width, canvas.height);
  //   let image = new Image();
  //   image.onload = function () {
  //     ctx.drawImage(image, 0, 0, props.width, props.height);
  //   };
  //   image.src = data;
  // }, []);

  // useEffect(() => {
  //   const canvasShadow = canvasRefShadow.current;
  //   const ctxShadow = canvasShadow.getContext("2d");

  //   ctxShadow.shadowColor = highlightColor;
  //   ctxShadow.shadowBlur = 10;
  //   if (highlightColor && hover) {
  //     let image = new Image();
  //     image.onload = function () {
  //       ctxShadow.drawImage(image, 6, 3, props.width-5, props.height-5);
  //     };
  //     image.src = data;
  //   } else {
  //     ctxShadow.clearRect(0, 0, canvasShadow.width, canvasShadow.height);
  //   }
  // }, [highlightColor, hover]);

  const setBlur = React.useCallback((status) => dispatch(actions.setBlur(status)), [
    dispatch,
  ]);
  const currTrumpet = useSelector((state) => state.envReducer.currTrumpet);
  const setCurrTrumpet = React.useCallback((color) => dispatch(actions.setCurrTrumpet(color)), [
    dispatch,
  ]);

  const setShemeModal = React.useCallback((color) => dispatch(actions.setShemeModal(color)), [
    dispatch,
  ]);
  const shemeModalData = useSelector((state) => state.envReducer.shemeModalData);
  
  const setShemeModalData = React.useCallback((color) => dispatch(actions.setShemeModalData(color)), [
    dispatch,
  ]);

  useEffect(() => {
// console.log(blurStatus);
  }, [blurStatus])
  useEffect(() => {

  }, [shemeModalData]);

  useEffect(() => {
    if (overModal) {
      return
    }
    if (hover&&coord) {
 
     let a = {...props, x:coord.x, y: coord.y};

      setShemeModalData(a);
      setShemeModal(props.tooltip.id);
  
      setCurrTrumpet("bakTwo");
      setHighlightColor("#3ecd83");
      setBlur(true)

    } else {
      setShemeModal(hover); 
      setCurrTrumpet("");
      setHighlightColor("");
      setBlur(false)
      // setShemeModalData(null);
      setShemeModal(false);
    }

  }, [hover, coord,overModal])
  useEffect(() => {
    // if (hover) {
    //   setHighlightColor('#3ecd83')
    
    // }else {
    //   setHighlightColor('')
    
      
    // }
    // const canvas = canvasRef.current;
    // const ctx = canvas.getContext("2d");
    // //Our first draw

    // if (hover) {
    //   ctx.clearRect(0, 0, canvas.width, canvas.height);
    //   ctx.beginPath();
    
    //   ctx.shadowBlur = 10;
    // ctx.shadowColor = "black";
    // ctx.lineJoin = "round";
    // let image = new Image();
    // image.onload = function() {
    //   ctx.drawImage(image, 5, 5,props.width-10, props.height-10);
    // };
    // image.src= data;
    //  console.log('true');
    // }else {
    //   ctx.clearRect(0, 0, canvas.width, canvas.height);
    //   ctx.shadowBlur = 0;
    // ctx.shadowColor = "none";
    
    //   let image = new Image();
    //   image.onload = function() {
    //     ctx.drawImage(image, 0, 0,props.width, props.height);
    //   };
    //   image.src= data;
      
    // }
    
//     ctx.fillStyle = props.bg;
//     ctx.lineWidth = 3;
//     ctx.strokeStyle  = props.color;
//     ctx.moveTo(0, 30);
//     ctx.bezierCurveTo(0, 0, ctx.canvas.width, 0, ctx.canvas.width, 30);


//     ctx.lineTo(ctx.canvas.width,ctx.canvas.height-30);
// // ctx.lineTo(ctx.canvas.width,20);
// ctx.lineTo(0,ctx.canvas.height-30);
// // ctx.lineTo(ctx.canvas.width/4+ctx.canvas.width/2,ctx.canvas.height);
// // ctx.lineTo(ctx.canvas.width/4, ctx.canvas.height);
// ctx.lineTo(0, ctx.canvas.height-30);
// // ctx.lineTo(0, 20);
// // ctx.lineTo(ctx.canvas.width/4, 0);
// ctx.closePath();
// ctx.fill();

// ctx.stroke();

// ctx.fillStyle = '#000000'

// ctx.fillRect(0, ctx.canvas.height, ctx.canvas.width, 20)
  }, [hover]);

  useEffect(() => {


  }, [hover]);

  return (
 
    <div         ref={bakRef} style={props.alertMsg? {...props.styleWrap, cursor: 'pointer'}: {...props.styleWrap, cursor: 'pointer'}}
          
          // onClick={()=>props.handleScrollToStat()}
    
          onMouseOver={debounce((e) => {
            // console.log("true");


            setCoord({x:e.clientX, y: e.clientY})
  
            if (overModal) {
              // setTimeout(() => {
              //   if (!overModal) {
              //     setHover(true)
              //   }
              // }, 300);
              return
            }
          setHover(true)
          // setShemeModalData({...props, x:e.clientX, y: e.clientY});
            // setShemeModal(props.tooltip.id);
          },300)}
      onMouseLeave={debounce(() => {
        if (overModal) {return}
        setHover(false)
      },200)}
    // className={'hover_shadow'}
    
    >
     
        <div style={{ position: "relative" }}>
        {/* {hover ? (
     <ShemeModal {...props}/>
      ) : null} */}

      
          {/* <canvas
            ref={canvasRef}
            {...props}
            width={props.width}
            height={props.height}
            style={{ transform: `rotate(${degs[props.direction]}deg)`,  filter: blurStatus&&!hover?'blur(2px)':'none' }}
          /> */}

<svg width="110" height="220" viewBox="0 -3 60 140" style={{transition: 'all 0.2s', }} version="1.1" xmlns="http://www.w3.org/2000/svg"  className={blurStatus&&!hover?'blur_mode':''}>
<defs>

    <filter id={idShadow}>
      <feDropShadow dx="0" dy="0" stdDeviation="0"
          flood-color="#3ecd83">

<animate   attributeName="stdDeviation"   values="0;5"  begin={idSvg.replace(/-/g, '')+'.mouseover'}  dur="0.4s" fill="freeze"> </animate>
  	   <animate  attributeName="stdDeviation"   values="5;0"  begin={idSvg.replace(/-/g, '')+'.mouseout'}  dur="0.25s" fill="freeze"> </animate>
          </feDropShadow>
              	
  	   {/* <animate  attributeName="stdDeviation"   values="5;0" dur="0.25s" fill="freeze"> </animate> */}
    </filter>

  </defs>
<g xmlns="http://www.w3.org/2000/svg"     id={idSvg.replace(/-/g, '')} style={{ transition: 'all 0.4s',fill:"none",filter: highlightColor && hover ?`url(#${idShadow})`: 'none'}}   >
      <path id="path8297" d="M 46.9941,22.1854 V 125.872 c -0.118,0.055 -0.3104,0.111 -0.5629,0.128 H 1.56877 c -0.2525,-0.017 -0.44491,-0.073 -0.56288,-0.128 V 22.1854 c 0,-2.8898 1.85548,-5.6628 5.14851,-7.7633 C 9.43415,12.3301 14.0178,11 19.1103,11 h 9.773 c 5.0925,0 9.6779,1.3301 12.9593,3.4222 3.2948,2.1007 5.1515,4.8737 5.1515,7.7632 z M 0.904742,125.813 c 3e-6,-0.001 3.7e-4,0 0.001055,0 z"  style={{fill:"#1fa4ff",stroke:'#2f2a61',strokeWidth:2 }}/>
      <path id="path8299" d="M 48,111 H 0 v 16 h 48 z"  style={{fill:"#2f2a61"}}/>
      <path id="path8301" d="M 31,9.53916 C 31,9.79355 30.7487,10 30.4402,10 H 19.5598 C 19.2513,10 19,9.79355 19,9.53916 V 4 c 0,-0.5 0.5,-1 1,-1 h 10 c 0.5,0 1,0.5 1,1 z"  style={{fill:"#2f2a61"}}/>
      <path id="path8303" d="M 32,11 H 16 V 3 c 0,-0.5 0.5,-1 1,-1 h 1 c 2,0 10.5,0 12,0 h 1 c 0.5,0 1,0.5 1,1 z" 
     style={{fill:"#2f2a61"}}/>
      <path id="path8305" d="M 23,2.8025 C 23,2.91152 22.8743,3 22.7201,3 H 17.2799 C 17.1257,3 17,2.91152 17,2.8025 V 1 c 0,-0.5 0.5,-1 1,-1 h 4 c 0.5,0 1,0.5 1,1 z"  style={{fill:"#2f2a61"}}/>
      <path id="path8307" d="M 31,2.8025 C 31,2.91152 30.8743,3 30.7201,3 H 25.2799 C 25.1257,3 25,2.91152 25,2.8025 V 1 c 0,-0.5 0.5,-1 1,-1 h 4 c 0.5,0 1,0.5 1,1 z"  style={{fill:"#2f2a61"}}/>
      <path id="path8309" d="m 16,3.00001 h -1 c -0.5,0 -1,0.5 -1,1 v 4 c 0,0.5 0.5,1.00002 1,1 h 1 z"  style={{fill:"#2f2a61"}}/>
      <path id="path8311" d="m 32,3.00001 h 1 c 0.5,0 1,0.5 1,1 v 4 c 0,0.5 -0.5,1.00002 -1,1 h -1 z"  style={{fill:"#2f2a61"}}/>
    </g>

</svg>

          {/* <canvas
            ref={canvasRefShadow}
            {...props}
            width={props.width + 10}
            height={props.height + 10}
            style={{
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: `translate(-50%,-50%)`,
              zIndex: -1,
            }}
          /> */}
        </div>
  
    </div>

  );
};

export default BakTwo;
