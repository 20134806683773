import React from "react";

const SignatureIndicator = (props) => {
    // компонент подписи айдишника под индикатором и статуса "в сети"

    let custom_style = props.style ? props.style : {};
    return  (
        <div
            className={'signature_indicator'}
            style={{
                ...custom_style,

                fontSize: props.size.width * 22 / 1700 > 30 ? 30 : props.size.width * 22 / 1700
            }}>
            <div className={'signature_indicator_block_text'}>

                                <span
                                    className={'id_indicator'}
                                    style={{
                                        fontSize: props.kof * 3,
                                    }}
                                >{props.id ? props.id : null}

                                </span>
                <div
                    className={'status_online_offline'}
                    style={{
                        background: props.isOnline ? '#50fc75' : '#fc5050',
                    }}
                >
                </div>
            </div>
        </div>
    );
}

export default SignatureIndicator;
