import React, { useRef, useEffect, useCallback } from 'react'
import {glv} from "../../../components/app_components/helper_functions/helper_functions";
import Wrap_Overlay from "../modal_components/wrappers/overlay_wrappers/Wrap_Overlay";
import WrapModal from "../modal_components/wrappers/Wrap_Modal";
import ContentHelpModal from "./content/content_help_modal";
import ActionsModalButtons from "../modal_components/Actions_Buttons_Modal";
import Title_Modal from "../modal_components/Title_Modal";
import useWindowSize from "../../../hooks/useWindowSize";
// Leaflet.Icon.Default.imagePath =
//   '//cdnjs.cloudflare.com/ajax/libs/leaflet/1.0.3/images/';


const HelpModal = props => {
    const size = useWindowSize();

  return       (
      <Wrap_Overlay
          open={props.open}
          hidden_body={true}
          handle_click_overlay={()=>{
              props.close()
              // set_opened_modal(false)
          }}
          class_position_children={'overlay_children_position'}
      >
          <WrapModal>
              <Title_Modal
                  title_text={glv(`first_level_blocks.info_modal.title`)}
              />
              <ContentHelpModal

                  {...props}
                  style={{maxHeight: size.height-210}}
              />

              <ActionsModalButtons
                  disabled_acc_btn={
                      false
                  }
                  has_cancel={false}
                  has_accept={true}
                  text_accept_btn={glv('second_level_blocks.confirm_modal.cancel')}
                  style={{marginTop:30}}
                  accept_action={()=>{
                      props.close()
                  }}
                  cancel_action={()=>{
                      props.close()
                      // set_opened_modal(false)
                  }}
              />
          </WrapModal>
      </Wrap_Overlay>
  )

}

export default HelpModal;