import React, { useRef, useEffect } from "react";
import { Tooltip } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../redux/actions/env.actions";
import { Fade } from '@material-ui/core';
import uuid from 'react-uuid'
import ShemeModal from '../modals/sheme_element_modal/shemeElementModal';
import useMousePosition from '../../hooks/useMousePosition';
const degs  ={
    left: 90,
    right: 270,
    bottom: 0,
    top: 180
}

const debounce = require('debounce');

const Basket = (props) => {
  const dispatch = useDispatch();
  const canvasRef = useRef(null);
  const canvasRefShadow = useRef(null);

  const bakRef = useRef(null);
  const { x, y } = useMousePosition();

  const overModal = useSelector((state) => state.envReducer.overModal);
  const [hover, setHover] = React.useState(false);
  const [highlightColor, setHighlightColor] = React.useState('');
  const [idShadow, setIdShadow] = React.useState(uuid());
  const [idSvg, setIdSvg] = React.useState(uuid());
  const blurStatus = useSelector((state) => state.envReducer.blurElems);
  // useEffect(() => {
  //   const canvas = canvasRef.current;
  //   const ctx = canvas.getContext("2d");

  //   ctx.clearRect(0, 0, canvas.width, canvas.height);
  //   let image = new Image();
  //   image.onload = function () {
  //     ctx.drawImage(image, 0, 0, props.width, props.height);
  //   };
  //   image.src = data;
  // }, []);

  // useEffect(() => {
  //   const canvasShadow = canvasRefShadow.current;
  //   const ctxShadow = canvasShadow.getContext("2d");

  //   ctxShadow.shadowColor = highlightColor;
  //   ctxShadow.shadowBlur = 10;
  //   if (highlightColor && hover) {
  //     let image = new Image();
  //     image.onload = function () {
  //       ctxShadow.drawImage(image, 6, 2.5, props.width-5, props.height-5);
  //     };
  //     image.src = data;
  //   } else {
  //     ctxShadow.clearRect(0, 0, canvasShadow.width, canvasShadow.height);
  //   }
  // }, [highlightColor, hover]);

  const setShemeModal = React.useCallback((color) => dispatch(actions.setShemeModal(color)), [
    dispatch,
  ]);
  const [coord, setCoord] = React.useState(null);
  
  const setShemeModalData = React.useCallback((color) => dispatch(actions.setShemeModalData(color)), [
    dispatch,
  ]);
  const currTrumpet = useSelector((state) => state.envReducer.currTrumpet);
  const setCurrTrumpet = React.useCallback((color) => dispatch(actions.setCurrTrumpet(color)), [
    dispatch,
  ]);

  
  const setBlur = React.useCallback((status) => dispatch(actions.setBlur(status)), [
    dispatch,
  ]);

  useEffect(() => {
    if (overModal) {
      return
    }
    if (hover&&coord) {
      setShemeModalData({...props, x:coord.x, y: coord.y});
      setShemeModal(true);
      setCurrTrumpet("basket");
      setHighlightColor("#3ecd83");
      setBlur(true)
    } else {

      setShemeModal(false);
      setCurrTrumpet("");
      setHighlightColor("");
      setBlur(false)
    }

  }, [hover,coord,overModal])
  


  useEffect(() => {

    // if (hover) {
    //   setHighlightColor('#3ecd83')
    
    // }else {
    //   setHighlightColor('')
    
      
    // }
    // const canvas = canvasRef.current;
    // const ctx = canvas.getContext("2d");

    // if (hover) {
    //   ctx.clearRect(0, 0, canvas.width, canvas.height);
    //   ctx.beginPath();
    
    //   ctx.shadowBlur = 10;
    // ctx.shadowColor = "black";
    // ctx.lineJoin = "round";
    // let image = new Image();
    // image.onload = function() {
    //   ctx.drawImage(image, 5, 5,props.width-10, props.height-10);
    // };
    // image.src= data;
    //  console.log('true');
    // }else {
    //   ctx.clearRect(0, 0, canvas.width, canvas.height);
    //   ctx.shadowBlur = 0;
    // ctx.shadowColor = "none";
    
    //   let image = new Image();
    //   image.onload = function() {
    //     ctx.drawImage(image, 0, 0,props.width, props.height);
    //   };
    //   image.src= data;
      
    // }
    //Our first draw
//     ctx.lineWidth = 3;
//     ctx.fillStyle = props.color
//     ctx.fillRect(3, 0, ctx.canvas.width-4, 3)
//     ctx.fillRect(ctx.canvas.width-5, 5, 5, 10)
//     ctx.strokeStyle  = props.color;

//     ctx.moveTo(3,4);
// ctx.lineTo(ctx.canvas.width-3,4);
// ctx.lineTo(ctx.canvas.width-10,ctx.canvas.height-5);
// ctx.lineTo(10,ctx.canvas.height-5);
// ctx.lineTo(5,5);

// ctx.fillStyle = props.bg
// ctx.fill();
// ctx.stroke();
// ctx.fillStyle = props.color
// ctx.fillRect(10, ctx.canvas.height, ctx.canvas.width-10, 5)

  }, [hover]);

  return (

    <div style={{...props.styleWrap, 
      // left:props.styleWrap.left-5,
      // top:props.styleWrap.top+5
          }} 
          ref={bakRef}
          onMouseOver={debounce((e) => {
            setCoord({x:e.clientX, y: e.clientY})
            if (overModal) {
              return
            }
          setHover(true)
          },300)}
      onMouseLeave={debounce(() => {
        setHover(false)
      },200)}
    >
    
    <div style={{ position: "relative" }}>
    {/* {hover? (
     <ShemeModal {...props}/>
      ) : null} */}

      

<svg width={props.width} height={props.height}  viewBox="0 -3 35 60" style={{transition: 'all 0.4s',filter: blurStatus&&!hover?'blur(2px) contrast(30%)':'none'}}
version="1.1" xmlns="http://www.w3.org/2000/svg" 
>
<defs>

    <filter id={idShadow}>
      <feDropShadow dx="0" dy="0" stdDeviation="0"
          flood-color="#3ecd83">

<animate   attributeName="stdDeviation"   values="0;5"  begin={idSvg.replace(/-/g, '')+'.mouseover'}  dur="0.4s" fill="freeze"> </animate>
  	   <animate  attributeName="stdDeviation"   values="5;0"  begin={idSvg.replace(/-/g, '')+'.mouseout'}  dur="0.25s" fill="freeze"> </animate>
          </feDropShadow>
              	
  	   {/* <animate  attributeName="stdDeviation"   values="5;0" dur="0.25s" fill="freeze"> </animate> */}
    </filter>

  </defs>
<g xmlns="http://www.w3.org/2000/svg"     id={idSvg.replace(/-/g, '')} style={{ transition: 'all 0.2s',fill:"none",filter:`url(#${idShadow})`}} width="30">
      <path id="path1166-4-5" d="M 35.7201,3 H 0.279924 C 0,3 0,2 0,1 0,0.5 0.5,0 1,0 h 34 c 0.5,0 1,0.5 1,1 0,1 0,2 -0.2799,2 z" style={{fill:"#2f2a61"}} />
      <path id="path1168-1-8" d="M 30.5511,51 H 5.45171 L 1.09488,3 H 35 v 4.94848 z"  style={{fill:"#a6b1bf",fillOpacity:1,stroke:"#2f2a61",strokeWidth:2}}/>
      <path id="path1170-8-8" d="M 31,46 H 5 v 5 h 26 z"  style={{fill:"#2f2a61"}} />
      <path id="path1172-5-4" d="M 35.1975,7 C 35.0885,7 35,6.87434 35,6.72008 V 1.27992 C 35,1.12566 35.0885,1 35.1975,1 H 37 c 0.5,0 1,0.5 1,1 v 4 c 0,0.5 -0.5,1 -1,1 z"    style={{fill:"#2f2a61"}}/>
    </g>

</svg>
          {/* <canvas
            ref={canvasRef}
            {...props}
            width={props.width}
            height={props.height}
           
          /> */}

          {/* <canvas
            ref={canvasRefShadow}
            {...props}
            width={props.width + 10}
            height={props.height + 10}
            style={{
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: `translate(-50%,-50%)`,
              filter: blurStatus&&!hover?'blur(2px)':'none',
              zIndex: -1,
            }}
          /> */}
        </div>
    
    </div>

  );
};

export default Basket;
