import React from "react";

const LightButton = (props) => {
    let cust_style= props.style ? props.style : {};
    let cust_style_text= props.style_text ? props.style_text : {};
    let cls = props.cls ? props.cls : '';
    let cls_text = props.cls_text ? props.cls_text : '';


    return (
        <button
            type={'button'}
            onClick={props.disabled ? ()=>{}:()=>props.on_click()}
            className={`light_button ${cls}`}
            style={cust_style}
        >
            {
                props.is_icon_button ?
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'}}>
                        {props.icon}
                    </div>
                    :
                    <span
                        style={cust_style_text}
                        className={'light_button_text '+cls_text}>
                      {props.text}
                     </span>
            }


        </button>
    );
};

export default LightButton;
