import * as actionTypes from "../types/env.types";


const initialState = {
  urls: null,
  language: 'ru',
  lang_values: null,
  lang_conf: null,
  currentPage: window.location.pathname,
  confUser: null,
  confStyle: null,
  hash: '',
  treeVal: null,
  blueTrumpet: null,
  purpleTrumpet: null,
  greyTrumpet: null,
  highlightColor: '',
  currTrumpet: '',
  blurElems: false, 
  indicatorsData: null, 

  overModal: false, 
  shemeModal: false,
  shemeModalData: null
};

export default function envReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_URLS:
      return {
        ...state,
        urls: action.urls,
      };

      case actionTypes.INDICATORS_DATA:
        return {
          ...state,
          indicatorsData: action.indicatorsData,
        };

      case actionTypes.OVER_MODAL:
        return {
          ...state,
          overModal: action.overModalStatus,
        };

      case actionTypes.SET_SHEME_MODAL_DATA:
        return {
          ...state,
          shemeModalData: action.shemeModalData,
        };

        case actionTypes.SET_SHEME_MODAL:
          return {
            ...state,
            shemeModal: action.shemeModal,
          };

      case actionTypes.SET_BLUR:
        return {
          ...state,
          blurElems: action.blurElems,
        };

      case actionTypes.CURR_TRUMPET:
        return {
          ...state,
          currTrumpet: action.trumpet,
        };
  

      case actionTypes.SET_HIGHTLIGHT_COLOR:
        return {
          ...state,
          highlightColor: action.color,
        };
      case actionTypes.HASH:
        return {
          ...state,
          hash: action.hash,
        };
        case actionTypes.CONF_STYLE:
          return {
            ...state,
            confStyle: action.style,
          };
        case actionTypes.TREE_VALID:
          return {
            ...state,
            treeVal: action.treeVal,
          };
      case actionTypes.CONF_USER:
        return {
          ...state,
          confUser: action.confUser,
        };
      case actionTypes.CURRENT_PAGE:
        return {
          ...state,
          currentPage: action.currentPage,
        };

    case actionTypes.LANG_CONF:
      return {
        ...state,
        lang_conf: action.lang_conf,
      };

    case actionTypes.LANG_VALUES:
      return {
        ...state,
        lang_values: action.lang_values,
      };
    case actionTypes.SET_LANGUAGE:
      return {
        ...state,
        language: action.language,
      };

    default:
      return state;
  }
}
