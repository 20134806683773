import React from 'react';
import RenderIndicators from "./render_indicators";
import BlocksWrapper from "../wrappers/blocks_wrapper";
import {glv} from "../app_components/helper_functions/helper_functions";



const ManomentryComponent = (props) => {
    // компонент блока манометров

    const {accessData,indicatorsData } = props;

    const [dimensions_container, set_dimensions_container] = React.useState(false);


    React.useEffect(() => {
        // console.log(dimensions_container)
    }, [dimensions_container]);
    React.useEffect(() => {
        document.getElementById('content_wrap_manometry').addEventListener('scroll', (e)=>{
            e.preventDefault();
        })
    }, []);

    return (
        <BlocksWrapper
            id_content_wrap={'content_wrap_manometry'}
            id_block={'manometry'}
            max_width={props.max_width}
            no_mt={true}
            has_gag={accessData.access_control.manometry.gag}
            set_dimensions_container={set_dimensions_container}
            is_desktop={props.is_desktop}
            header_title={  glv(`first_level_blocks.block_titles.manometry`)}
        >
            {
                dimensions_container ?
                    <RenderIndicators
                        id_scroll_wrapper={'content_wrap_manometry'}
                        manomentry_block_max_width={props.manomentry_block_max_width}
                        setting_data={props.setting_data}
                        is_desktop={props.is_desktop}
                        indicatorsData={indicatorsData}
                        dimensions_container={dimensions_container}
                    />
                    : null
            }
        </BlocksWrapper>
    )
}

export default ManomentryComponent;