import React, {useRef} from 'react';

import { useEffect } from 'react';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import {get_cheked_val, get_corrected_text_value} from "../../../../helper_functions/helpers";
import {glv} from "../../../../components/app_components/helper_functions/helper_functions";
import {format} from 'mathjs'
import useWindowSize from "../../../../hooks/useWindowSize";

function getGrd(ctx) {
    let grd = ctx.createLinearGradient(0,0,244,0);
    grd.addColorStop(0.01,"#17C8FF");
    grd.addColorStop(0.8,"#7EE6FF");
    return grd;
}

let old_vals={};

const CanvasRadial = (props) => {
    const {width, height} = useWindowSize();
    let k_ = props.k;
        // get_k(width)
    const canvas_main = useRef(null);
    const canvas_animation_indicator = useRef(null);
    const canvas_text = useRef(null);

    const [perc, setPerc] = React.useState(0);
    const canvasRef = React.useRef(null)

    const radial_query = useMediaQuery('(max-width:500px)');
    let point_center_x =  15*k_;

    let point_center_y =  15*k_;

    function draw_center_arc_white_shadow(ctx_main) {

        let radius__ = 9*k_;

        // let radius__ = radius-line_width-50>=0 ? radius-line_width-50 : 0;
        ctx_main.beginPath();

        ctx_main.shadowOffsetX = -1*k_*1.8;
        // -1*props.k*3;
        ctx_main.shadowOffsetY = -1*k_*1;
        // -1*props.k*2;
        ctx_main.shadowBlur =k_*3.2;
        // props.k*2;
        ctx_main.shadowColor = "rgba(255, 255, 255, 0.6)";
        ctx_main.fillStyle = '#eff3fb';
        ctx_main.lineWidth = k_*2;
        ctx_main.strokeStyle = "rgba(255, 255, 255, 0)";
        ctx_main.arc(point_center_x, point_center_y,radius__ ,
            0,
            Math.PI*2,
        );
        ctx_main.stroke();
        ctx_main.fill();
        ctx_main.shadowOffsetX = 0;
        ctx_main.shadowOffsetY = 0;
        ctx_main.shadowBlur = 0;
    }

    function draw_center_arc(ctx_value) {

        let radius_curr = k_*9;

        ctx_value.beginPath();
        let gradient = ctx_value.createLinearGradient(0,0, 0,canvas_main.current.width-(canvas_main.current.width/3));
        gradient.addColorStop(0, '#d0d8e9');
        gradient.addColorStop(0.5, '#d0d8e9');
        gradient.addColorStop(1, '#ffffff');
        ctx_value.shadowOffsetX = 2*k_;
        ctx_value.shadowOffsetY = 2*k_;
        ctx_value.shadowBlur = 4*k_;
        ctx_value.shadowColor = "rgba(0, 0, 0, 0.1)";
        ctx_value.fillStyle = '#eff3fb';

        ctx_value.lineWidth =k_*1.9;
        // props.width===600?
        // 15 :
        // props.width===500? 10 : 60;
        ctx_value.strokeStyle = gradient;

        ctx_value.arc(point_center_x, point_center_y, radius_curr,
            0,
            Math.PI*2,
        );
        ctx_value.stroke();
        ctx_value.fill();
        ctx_value.shadowOffsetX = 0;
        ctx_value.shadowOffsetY = 0;
        ctx_value.shadowBlur = 0;

        ctx_value.arc(point_center_x, point_center_y, radius_curr,
            0,
            Math.PI*2,
        );
        ctx_value.stroke();
        ctx_value.fill();
    }

    function draw_bg_arc_indicator(ctx_value) {

        let radius_curr = k_*7.75;

        ctx_value.beginPath();

        ctx_value.fillStyle =  '#eff3fb';

        ctx_value.lineWidth =k_*1.3;
        // props.width===600?
        // 15 :
        // props.width===500? 10 : 60;
        ctx_value.strokeStyle = '#dde3f3';

        ctx_value.arc(point_center_x, point_center_y, radius_curr,
            0,
            Math.PI*2,
        );
        ctx_value.stroke();
        ctx_value.fill();
        ctx_value.shadowOffsetX = 0;
        ctx_value.shadowOffsetY = 0;
        ctx_value.shadowBlur = 0;

        ctx_value.arc(point_center_x, point_center_y, radius_curr,
            0,
            Math.PI*2,
        );
        ctx_value.stroke();
        ctx_value.fill();
    }

    function draw_indicator(ctx_value, val) {

        let radius_curr =k_*8.1;

        ctx_value.beginPath();

        ctx_value.fillStyle =  '#eff3fb';

        ctx_value.lineWidth =k_*0.6;

        ctx_value.strokeStyle = getGrd(ctx_value);
        ctx_value.arc(point_center_x,point_center_y,
            radius_curr,-Math.PI/2,
            (- Math.PI/2-(Math.PI*2-Math.PI*2*((100-val)/100))), true)

        ctx_value.stroke();

    }

    const draw_text = (ctx_text, val)=> {

        ctx_text.clearRect(0, 0, canvas_main.current.width, canvas_main.current.height);

        ctx_text.font = `bold ${k_*5}px Gilroy`;
        ctx_text.fillStyle = "#5E5AB9";
        ctx_text.textAlign = "center";
        ctx_text.fillText( get_corrected_text_value(Number(format(val, { precision: 3 }))), point_center_x, point_center_y);

        ctx_text.font = `${k_*3}px Gilroy`;
        ctx_text.fillStyle = "#2F2A61";
        ctx_text.textAlign = "center";
        ctx_text.fillText(
            glv('first_level_blocks.statistic.radial.'+props.key_name+'.quantity')
            , point_center_x,

            19.2*k_
        );

    }
    
    function draw(move_indicator) {

        let ctx_text = canvas_text.current.getContext("2d");
        let ctx_main = canvas_main.current.getContext("2d");
        let ctx_animation_indicator = canvas_animation_indicator.current.getContext("2d");
        ctx_text.font = `bold ${k_*3}px Gilroy`;


        let max = props.max ? props.max : 100;
        let min = props.min ? props.min : 0;
        let checked_value = get_cheked_val(props.value,max,  min)



        let one_perc = (max - min)/100;
        let edited_val = checked_value-min;
        let perc_ = edited_val/one_perc;



        if(!move_indicator) {
            ctx_main.clearRect(0,0,
                ctx_main.canvas.width,
                ctx_main.canvas.height,)
            ctx_animation_indicator.clearRect(0,0,
                ctx_animation_indicator.canvas.width,
                ctx_animation_indicator.canvas.height,)
            ctx_text.clearRect(0,0,
                ctx_text.canvas.width,
                ctx_text.canvas.height,)
            draw_center_arc_white_shadow(ctx_main)
            draw_center_arc(ctx_main)
            draw_bg_arc_indicator(ctx_main)
            draw_indicator(ctx_animation_indicator, perc_)
            draw_text(ctx_text, checked_value)
            return
        }

        let old_perc = perc_;
        if ( old_vals.hasOwnProperty(props.key_name+props.key_id) ) {
            old_perc=       old_vals[props.key_name+props.key_id];
        }
        old_vals[props.key_name+props.key_id] = perc_;

        let animation = ()=>{
            if (perc_>old_perc) {
                old_perc+=(max-min)/2;

                if (old_perc>perc_) {
                    old_perc=perc_;
                }
            } else if (perc_<old_perc) {
                old_perc-=(max-min)/100;

                if (old_perc>perc_) {
                    old_perc=perc_;
                }
            } else {
                draw_indicator(ctx_animation_indicator, old_perc)
                return
            }
            setTimeout(()=>animation(), 4)
        }

        ctx_animation_indicator.clearRect(0,0,
            ctx_animation_indicator.canvas.width,
            ctx_animation_indicator.canvas.height,)
        ctx_text.clearRect(0,0,
            ctx_text.canvas.width,
            ctx_text.canvas.height,)
        animation()
        // draw_indicator(ctx_animation_indicator, perc_)
        draw_text(ctx_text, checked_value)
    }

    let resizing = ()=>{
        let ratio = props.ratio;
        let w= 30*k_;
        let h = 30*k_;
        // canvas_animation_indicator.current.width = w;
        // canvas_animation_indicator.current.height = h;
        // canvas_main.current.width = w;
        // canvas_main.current.height = h;
        // canvas_text.current.width = w;
        // canvas_text.current.height = h;


        canvas_animation_indicator.current.width =Math.ceil(w* ratio) ;
        canvas_animation_indicator.current.height =Math.ceil(h* ratio) ;
        canvas_animation_indicator.current.style.width = (w)+'px';
        canvas_animation_indicator.current.style.height = (h)+'px';
        canvas_main.current.width = Math.ceil(w* ratio) ;
        canvas_main.current.height = Math.ceil(h* ratio) ;
        canvas_main.current.style.width = (w)+'px';
        canvas_main.current.style.height = (h)+'px';


        canvas_text.current.width = Math.ceil(w* ratio) ;
        canvas_text.current.height = Math.ceil(h* ratio);
        canvas_text.current.style.width = (w)+'px';
        canvas_text.current.style.height = (h)+'px';

        canvas_animation_indicator.current.getContext("2d").scale(ratio, ratio)
        canvas_text.current.getContext("2d").scale(ratio, ratio);
        canvas_main.current.getContext("2d").scale(ratio, ratio)


        canvas_animation_indicator.current.style.boxSizing = 'border-box';
        canvas_main.current.style.boxSizing = 'border-box';
        canvas_text.current.style.boxSizing = 'border-box';

        draw(false, true)
    }

    let globPerc_rad_1 = 0;
    let globPerc_rad_2 = 0;
    const lineWid = 8;

    useEffect(() => {
        resizing()
    }, [k_, props.ratio]);

    useEffect(() => {
        draw(true, true)
    }, [props.value]);
    useEffect(() => {



        // if (props.max&&props.percents &&props.idPr ) {
        //     const canvas = canvasRef.current;
        //     const ctx = canvas.getContext("2d");
        //     let chPr = props.percents> props.max ? 100 : props.percents*100/props.max;
        //     // globProc = chPr;
        //     animRad(canvas, Math.round(chPr), props.idPr);
        // }

    }, [
        // props.percents, props.idPr, props.max, radial_query
    ]);
// получение цвета для радиалки по айдишнику





    // const [degTear, setDegTear] = React.useState(233);
    return (
        <div className="">


            <div
                className={"wrap_first_canvas"}
            >

                <div
                    className="wrap_second_canvas"
                >
                    <canvas
                        ref={canvas_animation_indicator}
                        height={0}
                        width={0}
                    >

                    </canvas>
                </div>
                <div
                    className="wrap_third_canvas"
                >
                    <canvas
                        ref={canvas_text}
                        height={0}
                        width={0}
                    >

                    </canvas>
                </div>


                <canvas
                    ref={canvas_main}

                    height={0}
                    width={0}
                >

                </canvas>
            </div>
                        {/*<canvas  ref={canvasRef}  id={props.id+Math.random()} width={radial_query ? 48 : 80} height={radial_query ? 48 : 80} className="canvRad" />*/}


        </div>
    )
}

export default CanvasRadial;