import React, {useEffect, useState} from "react";
import '../styles/custom_keyboard.css';
import {usePortal} from "../../../../hooks/usePortal";
import LightButton from "../../../custom_components/light_button";
import PurpleButton from "../../../custom_components/purple_button";
import Slide from '@mui/material/Slide';

const CustomKeyboard = (props) => {
    const Portal = usePortal('keyboard_portal');
    let custom_style = props.style ? props.style : {};


    let is_disable_ampm = props.enable_change_ampm ? '' : ' disabled_'


    return  (
        <Portal>
            <Slide direction="up" in={props.is_show} mountOnEnter unmountOnExit>
        <div className={'wrap_keyboard_block'}
             style={{...custom_style,
                 // animation:`0.8s 1 alternate ${animation_}`
             }}>
            <div className={'wrap_manipulate_cursor_btns'}>

                <PurpleButton
                    on_click={()=>props.handle_change(null, 'move_left')}
                    text={'left'}
                    cls_outer={'lr_cursor_btn_kb'}
                    cls_text={'text_keyboard_purple_btn'}
                />
                <PurpleButton
                    on_click={()=>props.handle_change(null, 'move_right')}
                    text={'right'}
                    cls_outer={'lr_cursor_btn_kb'}
                    cls_text={'text_keyboard_purple_btn'}
                />

            </div>

            <div className={'wrap_number_buttons'}>
                {[
                    [1,2,3],
                    [4,5,6],
                    [7,8,9]
                ].map((arr_, index) =>{
                    // console.log(arr_)
                    return (
                        <div className={'num_btns_row'} key={index+'num_btns_row'}>
                            {
                                arr_.map((number_, index)=>{
                                    return (

                                        <LightButton
                                            key={number_*index+'num_keybord_btn'}
                                            on_click={
                                                ()=>props.handle_change(number_, 'write')
                                            }
                                            text={`${number_}`}
                                            cls={'keyboard_number_button'}
                                            cls_text={'text_keyboard_num_btn'}
                                        />
                                    )
                                })
                            }

                        </div>
                    )
                })}
            </div>

            <div className={'wrap_func_btns_row'}>

                <PurpleButton
                    on_click={()=>props.handle_change(null, 'clean')}
                    text={'clean'}
                    cls_outer={'btn_remove_symbol'}
                    cls_text={'text_keyboard_purple_btn'}
                />

                <LightButton
                    on_click={
                        ()=>props.handle_change("0", 'write')
                    }
                    text={`${0}`}
                    cls={'keyboard_number_button'}
                    cls_text={'text_keyboard_num_btn'}
                />

                {
                    is_disable_ampm ? null :
                    <PurpleButton
                        on_click={()=>{
                            if (!is_disable_ampm) {
                                props.handle_change(null, 'am_pm')
                            }
                        }}
                        text={'am/pm'}
                        cls_outer={'am_pm_btn '+is_disable_ampm}
                        cls_text={'text_keyboard_purple_btn'}
                    />
                }
            </div>

            <div className={'wrap_ok_cancel_btn'}>

            </div>
        </div>
            </Slide>
        </Portal>
    );
}

export default CustomKeyboard;