import React from "react";

import {BrowserRouter, Switch, Route, Link, Redirect} from "react-router-dom";
import MainPage from "../components/pages/main_home/MainPage";
import ProPage772238 from "../components/pages/pro/current/ProPage";
import TestPage from "../components/pages/test/test_page";
import RouteError from "../error_pubs_components/route_error";
import RouterPage from "../components/pages/router_page/router_page.component";
import {IS_DESKTOP_CONTEXT} from "../App";

// Главный роутер приложения

// Main router app

const AppRouter = (props) => {
    const is_desktop = React.useContext(IS_DESKTOP_CONTEXT);

    return (
        <>
        {/*// <GuardProvider guards={[]} loading={<div></div>} error={<RouteError/>}>*/}
            <Switch>
                <Route exact={true}  path="/router_page"  component={RouterPage} />
                <Route exact={true} path="/pro/:id" exact component={ProPage772238} />
                <Route exact={true} path="/:id"  component={MainPage} />
                <Route path="/test_page" exact component={TestPage} />
                <Redirect  to={'/router_page'} />


                {/*<Redirect to={/router_page} />*/}
            </Switch>
                    </>
        // </GuardProvider>
    );
}

export default AppRouter;



