import React from 'react';
import {glv} from "../app_components/helper_functions/helper_functions";
import BlocksWrapper from "../wrappers/blocks_wrapper";
import {IS_DESKTOP_CONTEXT} from "../../App";
import './scheme.style.css';
import moment from "moment";
import ShemeElModal from "../modals/sheme_element_modal/sheme_el_modal";

const SchemeComponent = ({onl_offl, max_width, accessData,indicatorsData, options}) => {
    const is_desktop = React.useContext(IS_DESKTOP_CONTEXT)
    const [dimensions_container, set_dimensions_container] = React.useState({});
    const [overed_id, set_overed_id] = React.useState('');
    const [data_to_modal, set_data_to_modal] = React.useState({open: false});

    const coff_x =
        dimensions_container &&
    dimensions_container.width<accessData?.sheme_obj?.size?.x ?
        dimensions_container.width/accessData?.sheme_obj?.size?.x : 1;
    const coff_y =
        dimensions_container&&
        dimensions_container.width<accessData?.sheme_obj?.size?.x ?
            (accessData?.sheme_obj?.size?.y/accessData?.sheme_obj?.size?.x*dimensions_container.width)/accessData?.sheme_obj?.size?.y
            : 1;

    const mouse_over = React.useCallback((e)=>{
        set_overed_id(e.currentTarget.id)
        let ov_id = e.currentTarget.id;
        let rf_ =  Object.values(indicatorsData.values).find(item=>item.name===ov_id);

        set_data_to_modal({
            open: true,
            current_state:
                moment(accessData.dates.now).diff(moment.unix(accessData.dates.last_upd), "minute") >= 1
                    ?'offline'
                    :  moment.unix(accessData.dates.last_upd).format('ll HH:mm'),
            x: e.clientX,
            y: e.clientY,
            onl_offl: onl_offl,
            id:indicatorsData.values[ov_id]?.name||ov_id,
            id_key:ov_id,
            name_id: indicatorsData.values[ov_id]?.name||ov_id,
            indicatorsData: indicatorsData,
            speedometr_values: {
                min:  rf_?.sys_info?.min,
                max: rf_?.sys_info?.max,
                value: rf_?.value
            }
        })
    }, [indicatorsData, onl_offl,accessData]);

    const mouse_leave = React.useCallback((e)=>{
        set_overed_id('')
        set_data_to_modal({
            open: false,

        })
    }, [])

    return (
        <BlocksWrapper
            id_block={'scheme'}
            max_width={max_width}
            header_title={glv(`first_level_blocks.block_titles.scheme`)}
            has_gag={accessData.access_control.chart.gag}
            is_desktop={is_desktop}
            wrap_cls={'sheme_content_wrap'}
            set_dimensions_container={set_dimensions_container}
        >
            <ShemeElModal {...data_to_modal} />
            {
                    <div style={{
                        position: 'relative',
                        // width:dimensions_container.width
                    }}>
                        <img
                            style={{width: '100%', height: '100%'}}
                            src={accessData?.sheme_obj?.shem||'#'}/>
                        {accessData?.sheme_obj?.blocks.map((item, index)=>{
                            return (
                                <div
                                    onMouseOver={mouse_over}
                                    onMouseLeave={mouse_leave}
                                    id={item.vp_id}
                                    className={'scheme_el_block_over'}
                                    key={item.vp_id}
                                    style={
                                    {
                                        position: 'absolute',
                                        left: item.pos.x*coff_x,
                                        top: item.pos.y*coff_y,
                                        width:item.size.w*coff_x,
                                        height:item.size.h*coff_y,
                                        zIndex: 1100
                                    }}

                                >

                                </div>
                            )
                        })}
                    </div>

            }

        </BlocksWrapper>
    );
};

export default SchemeComponent;