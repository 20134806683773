import React from 'react';
import {format_to_beautiful_date_} from "../../../helper_functions/helpers";
import {CSSTransition} from "react-transition-group";
import ChartDatePickers from "./date_pickers";
import {Collapse} from "@mui/material";

const RangeDatePicker = (props) => {
    let custom_style = props.style ? props.style : {};

    return (
        <div style={{position: 'relative', ...custom_style}}>
            <div
                style={{
                    height:props.open_date_picker ? 0 : 'auto',
                    padding: props.open_date_picker ? 0 : '',
                    transition: 'height 0.4s'
            }}
                onClick={()=>props.set_open_date_picker(!props.open_date_picker)}
                className={'fake_range_input_dates'}>
                                <span>
                                    {format_to_beautiful_date_(props.form.dates[0])}{' - '}
                                </span>
                <span>
                                    {format_to_beautiful_date_(props.form.dates[1])}
                                </span>
            </div>
            {/*<Collapse in={open_date_picker} unmountOnExit={true}>*/}
            {
                props.is_popper ?
                <CSSTransition
                    in={props.open_date_picker}
                    timeout={300}
                    classNames={"date_time_picker_popper"}
                    unmountOnExit={true} mountOnEnter={true}>
                    <div className={'popper_block_datetime_picker'}>
                        <ChartDatePickers
                            current_dates={props.form.dates}
                            cancel_change={()=>{
                                props.set_open_date_picker(!props.open_date_picker)
                            }}
                            accept_change={(dates)=>{
                                props.change_form('dates', dates)
                                props.set_open_date_picker(!props.open_date_picker)
                            }}
                            // is_load_chart={is_load_chart}
                        />
                    </div>
                </CSSTransition> : <Collapse in={props.open_date_picker} unmountOnExit={true}>
                        <ChartDatePickers
                            current_dates={props.form.dates}
                            cancel_change={()=>{
                                props.set_open_date_picker(!props.open_date_picker)
                            }}
                            accept_change={(dates)=>{
                                props.change_form('dates', dates)
                                props.set_open_date_picker(!props.open_date_picker)
                            }}
                            // is_load_chart={is_load_chart}
                        />
                    </Collapse>
            }

            {/*</Collapse>*/}
        </div>
    );
};

export default RangeDatePicker;