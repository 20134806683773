import React, {useCallback, useEffect, useState} from 'react';
import App from "../../../App";
import CrashModal from "../../modals/crash_modal/crash_modal";

export let crash_modal_is_open = false;

function WrapperApp(props) {

    const [crash_modal, set_crash_modal] = useState(
        {
            open: false,
            title: 'crash when try to load init files',
            point: 'global crash when init',
            try_action: () => {
                window.location.reload()
            }
        });

    useEffect(()=>{
        crash_modal_is_open  = crash_modal.open;
    }, [crash_modal])

    return (
        <>
            <CrashModal
                crash_modal_data={crash_modal}
                goback_action={() => {
                    // eslint-disable-next-line no-restricted-globals
                    history.back()
                }}
            />
            <App crash_modal={[crash_modal, set_crash_modal]}/>
        </>
    );
}

export default WrapperApp;



