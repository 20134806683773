import React, { useRef, useEffect } from 'react'
import styled from 'styled-components';
import useWindowSize from '../../../../hooks/useWindowSize';
import { useDispatch, useSelector } from "react-redux";
import {glv} from "../../../../components/app_components/helper_functions/helper_functions";

// function percentToRadians(percentage) {
//   // convert the percentage into degrees
//   var degrees = percentage * 360 / 100;
//   // and so that arc begins at top of circle (not 90 degrees) we add 270 degrees
//   return degreesToRadians(degrees + 270);
// }
function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}
const CardWrapper = styled.div`
grid-area: c_f_a;
display: grid;
justify-content: center;
align-content: center;
min-height: 400px;
@media screen and (max-width: 600px) {
  min-height:0; 
  }
`;


const Indicator = (props) => {
    const [proc, setProc] = React.useState(0);
    const [globProc, setGlobProc] = React.useState(0);
    const [deg, setDeg] = React.useState(0);
    const canvasRef = useRef(null)
    let  size = useWindowSize();

    if (props.stat_width) {
        size.width = props.stat_width
    }
    // const [degTear, setDegTear] = React.useState(233);
    React.useEffect(() => {
      let checkedProts =props.percents.value >props.percents.max ? 100 : props.percents.value < props.percents.min ? 0 : props.percents.value*100/props.percents.max;
      setProc(checkedProts)

    }, [props.percents])

    window.asd = function ss (pr) {
      setProc(pr)
    }
    function animRad(protsy, canvas, isAnim) {
      const ctx = canvas.getContext('2d')
      // устанавливаем новые переменные, контекст канваса цвет и т д
      ctx.lineCap = "round";
  
      let grd = ctx.createLinearGradient(0,0,244,0);
    grd.addColorStop(0.08,"#DD4444");
    grd.addColorStop(0.95,"#F9A91A");
  
      let checkedProts = protsy >100 ? 100 : protsy < 0 ? 0 : protsy;
  
      // аэсд - функция которая по сути все и рисует
      function asd(p, canv, color, holst) {
          let globProc;
    
              globProc = 0;
              setProc(p)
              // globPerc_rad_1 = p;
   
  
          // if(id==="radial_2") {
          //     globProc = globPerc_rad_2;
          //     globPerc_rad_2 = p;
          // }
  
          let pr = p;
          rec();
          function rec() {
  
              // сама отрисовка
              canv.clearRect(0, 0, holst.width, holst.height);
              canv.beginPath();
              canv.lineWidth = 70;
              // canv.moveTo(holst.width/2, 10);
              canv.arc(holst.width/2,(holst.height/2), (holst.width/2)-40,0,(globProc * 360/100 )/180*-Math.PI, false);
  
              // 2*Math.PI*globProc/100
              canv.strokeStyle = color;
              canv.stroke();
              if(globProc === pr) {
                  return;
              }
              // прогрессия скорости анимации
              if(globProc > pr) {
                  globProc-=1;
                  setTimeout(rec ,4);
              }
  
              if(globProc < pr) {
                  globProc+=1;
                  setTimeout(rec ,4);
              }
  
          }
      }
  
      if(isAnim) {
          // костыль поволяющий если надо отричовать без анимации
          // ctx.clearRect(0, 0, c.width, c.height);
          // ctx.beginPath();
          // ctx.lineWidth = 6;
          // ctx.arc(c.width/2,c.height/2, c.width/2-4.5,-Math.PI/2,(- Math.PI/2-(Math.PI*2-Math.PI*2*((100-checkedProts)/100))), true);
          // ctx.strokeStyle = grd;
          // ctx.stroke();
      } else {
          asd(checkedProts,ctx,grd, canvas);
      }
  
  }
  
    return (
        <div id="c_f_area" style={props.small?{minHeight:0,
          //  transform: size.width< 1700 ?  `scale(${size.width/1700})`  : 'none'
          }:{marginLeft: 20, 
        // transform: size.width< 1700 ?  `scale(${size.width/1700})`  : 'none'
        }}>


        <div id="mask_rect">
          <div id="polubox" style={props.small?{width:size.width*300/1700, height: size.width*375/1700,  maxHeight: 375, maxWidth: 300 }:size.width<1700 ? {width:size.width*400/1700, height: size.width*400/1700} : {}}>
            <div className="hint_indicator">
              Indicator comentf tyry pry
            </div>
            <div className="pub_block"  style={
                // props.mod_modalka&&props.small ? {
                //     heigth:    163
                //     } :
                props.small?
                    {
                        height:

                         size.width<600?  
                         size.width*118/1700 :
                         size.width<750? 
                         size.width*134/1700 :
            size.width<950? 
              size.width*144/1700 :

              size.width<1250? 
              size.width*152/1700 :
              size.width<1400? 
              size.width*156/1700 :
              size.width<1800 ? 
              size.width*163/1700:
              size.width*163/1700,
              maxHeight: 163,
                    }
                    :size.width<1700 ?
                        {height:
                size.width<600?  
                size.width*118/1700 :
                size.width<750? 
                size.width*110/1700 :
                size.width<850? 
                size.width*120/1700 :
   size.width<950? 
     size.width*133/1700 :
     size.width<1050? 
     size.width*145/1700 :
     size.width<1150? 
     size.width*150/1700 :
     size.width<1250? 
     size.width*156/1700 :
     size.width<1350? 
     size.width*164/1700 :
     size.width<1400? 
     size.width*168/1700 :
     size.width<1800 ? 
     size.width*175/1700: 

     size.width*175/1700
             }
             :
                        {}}>
              <div className="wrap_shinobi" style={props.small?{width:size.width*90/1700+'%', maxHeight: 90+'%' }:size.width<1700 ?{width:size.width*95/1700+'%'}: {}}>
                <div className="shinobi_shadow" />

              </div>
            </div>
            <div className="polubox_number" id="txt_1" style={props.small?{fontSize: size.width*18/1700, left: size.width*20/1700, top: size.width*218/1700, zIndex: 1000}:size.width<1700 ?
            {fontSize: size.width*24/1700, left: size.width*1/1700, top: size.width*230/1700, zIndex: 1000}: {}}>{props.percents.min}</div>
            {/* <div className="polubox_number" id="txt_2" style={props.small?{  top: 50, zIndex: 1000}:{}}>2.0</div> */}
            <div className="polubox_number" id="txt_3" style={props.small?
            {fontSize: size.width*18/1700, right: size.width*20/1700,  top: size.width*218/1700, zIndex: 1000}
            :size.width<1700 ?{fontSize: size.width*18/1700,right: size.width*1/1700, top: size.width*230/1700, zIndex: 1000}: {}}>{props.small ? props.percents.max: props.percents.max}</div>
            <div className="a" id="indik"  style={props.small?{width:size.width*250/1700, height: size.width*250/1700, 
            maxHeight: 250,   maxWidth: 250,
            }:size.width<1700 ?{width:size.width*350/1700, height: size.width*350/1700}: {}}>
              <div className="b" id="asd" style={{transform: `rotate(${proc*1.8}deg)`, position:'relative'}}>
              {/* <canvas ref={canvasRef} {...props}  width={450} height={450} style={{position: 'absolute', top: -5, transform: `rotate(${372}deg)`}}/> */}
                <div className="polukTop">
                </div>
                <div className="polukBot">
                </div>
                <div className="c">
                </div>
              </div>
              <div id="oval_rect" className="oval_rect1" style={props.small?{width:size.width*110/1700, height: size.width*110/1700,    maxHeight: 110,   maxWidth: 110, zIndex: 1000}:size.width<1700 ?{width:size.width*150/1700, height: size.width*150/1700}: {}}>
                <ui style={props.small?{fontWeight: 600, fontSize: size.width*22/1700>30 ? 30 :size.width*22/1700}:{fontSize: size.width*22/1700>30 ? 30 :size.width*22/1700}}>{ props.percents.value.toFixed(1) }</ui>
                <a  style={props.small?{fontWeight: 250, fontSize: size.width*20/1700>30 ? 25 :size.width*20/1700, color: '#2F2A61', textAlign: 'center', }:{fontSize: size.width*20/1700>30 ? 25 :size.width*20/1700}}>{
                   glv('first_level_blocks.manometry.types.water_consumption')
                }</a>



              </div>
              <div className="toch_wrap">
                <div className="tochka" id="tochka_vr" style={{transform: `rotate(${proc*1.8}deg)`}}>
                <svg width="67" height="84" viewBox="0 0 67 84" fill="none" xmlns="http://www.w3.org/2000/svg" 
                className={'t1'} 
                style={props.small?{
                  position: 'absolute',
                  // right: 40,
                  top: -54,
                  zIndex: 70053,
                  width: size.width*100/1700,
                  transform:' rotate(233deg)',
                  height: '100px',
                  right: size.width*(-30)/1700
                }:size.width<1700 ?{ 

                  position: 'absolute',
                  // right: 40,
                  top: -54,
                  zIndex: 70053,
                  width: size.width*100/1700,
                  transform:' rotate(233deg)',
                  height: '100px',
                  right: size.width*(-5)/1700,
                  
                  }:{}}>
<g filter="url(#filter0_d)">
<path fill-rule="evenodd" clip-rule="evenodd" d="M14.8878 67.4905C21.8499 72.214 31.3075 70.3948 36.012 63.4272C40.7164 56.4596 58.1341 3.44038 58.1341 3.44038C58.1341 3.44038 15.5045 39.3543 10.8 46.3219C6.09551 53.2895 7.92566 62.767 14.8878 67.4905Z" fill="url(#paint0_linear)"/>
</g>
<defs>
<filter id="filter0_d" x="0.190247" y="0.44043" width="65.9438" height="82.6774" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
<feOffset dy="5"/>
<feGaussianBlur stdDeviation="4"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.182446 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
</filter>
<linearGradient id="paint0_linear" x1="76.6056" y1="-10.8685" x2="41.7208" y2="-26.107" gradientUnits="userSpaceOnUse">
<stop stop-color="#FF5C3D"/>
<stop offset="1" stop-color="#F9A91A"/>
</linearGradient>
</defs>
</svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
}

export default Indicator;