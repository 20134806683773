import React from "react";
import useWindowSize from "../../../hooks/useWindowSize";

const HeaderBarVM = (props) => {
    let custom_style = props.style ? props.style : {};

    const {width, height} = useWindowSize();

    React.useEffect(()=>{
        if (!props.is_hided) {
            document.body.style.overflow='hidden';
        } else {
            document.body.style.overflow='auto';
        }
    }, [props.is_hided])
    return  (
        <div className={'bg_overlay_menu_vm'}
             style={{
                 height,
                 left: props.is_hided ? -(window.innerWidth) : 0,
                 top: 0,
                 opacity: props.is_hided ? 0 : 1,
                 transition: 'all 0.4s'
             }}
        >
            <div className={'wrap_header_bar_vm'} style={{...custom_style,
                height: window.innerHeight,
                left: props.is_hided ? -(window.innerWidth
                    // *80/100
                ) : 0,
                transition: 'all 0.4s'
            }}>
                <div className={'wrap_tools_header_vm'}>
                    {props.children}
                </div>
            </div>
        </div>

    );
}

export default HeaderBarVM;