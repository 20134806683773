import React, {useState} from 'react';
import classes from './router_header.style.module.css'
import {SelectLangIcon, MailIcon} from "../../../../assets/svg_images";
import ReportModal from "../../../modals/report_modal/ReportModal";
import Backdrop from "@material-ui/core/Backdrop";
import CustomImageLoader from "react-custom-image-loader.";
import {open_lang_modal_context} from "../../../../App";
import {glv} from "../../../app_components/helper_functions/helper_functions";
import {useSelector} from "react-redux";
import LanguageIcon from '@material-ui/icons/Language';

const RouterHeaderComponent = () => {
    const [openReportModal, setOpenReportModal] = useState()
    const [openBackdrop, setOpenBackdrop] = useState()
    const [open_lang_modal, set_open_lang_modal] = React.useContext(open_lang_modal_context);
    const language = useSelector((state) => state.envReducer.language);
    return (
        <div className={classes.header_wrap}>
            <ReportModal open={openReportModal} close={() => setOpenReportModal(false)}
                         id={'router'}
                         openOvl={(status) => {

                setOpenBackdrop(status)
            }}
            />
            <Backdrop style={{zIndex: 55455}} open={openBackdrop}>
                <CustomImageLoader
                    image={`${process.env.PUBLIC_URL}/favicon.ico`}
                    isLoaded={true}
                    circle={false}
                    speed={2}
                    animationType={"pulse"}
                />
            </Backdrop>
            <div
                className={classes.wrap_header_item_block}
                onClick={()=>{
                    setOpenReportModal(true)
                }}
            >
                <MailIcon class={classes.report_item}/>
                <span className={classes.report_btn_text}>{glv('header.menu_buttons.report')}</span>
            </div>
            <div
                className={classes.wrap_header_item_block}
                style={{zIndex:1025}}
                onClick={()=>{
                    set_open_lang_modal(true)
                }}
            >
                <LanguageIcon
                    fontSize="small"
                    style={{ color: "#57559a", marginRight: 10,
                        // fill: opened_modal ? '#e3e9f7' : ''
                }}
                />
                {/*<SelectLangIcon class={classes.lang_item}/>*/}
                <span className={classes.lang_btn_text}>
                    {language.toUpperCase()}
                </span>

            </div>


        </div>
    );
};

export default RouterHeaderComponent;