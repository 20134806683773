import React, { useEffect, useCallback, useState } from "react";

const TitleModal = (props) => {

    let title_style= props.title_style ? {...props.title_style} : {};

    useEffect(() => {

    }, []);
    return (

        <div
            className={`wrap_title_modal`}
        >
            <span className={'title_modal_text'} style={title_style}>{props.title_text}</span>
        </div>
    );
};

export default TitleModal;
