import React from 'react';
import ReactDOM from 'react-dom';
export const usePortal = (id) => {
    const [portal, setPortal] = React.useState({
        render: () => null,
        remove: () => null,
    });

    const createPortal = React.useCallback(el => {
        const Portal = ({ children }) => ReactDOM.createPortal(children, el);
        const remove = () => ReactDOM.unmountComponentAtNode(el);
        return { render: Portal, remove };
    }, []);

    React.useEffect(() => {

        let curr_el = document.getElementById(id);
        if (curr_el) portal.remove();

        let new_portal = document.createElement('div');
        new_portal.id = id;
        document.body.appendChild(new_portal);
        let el = document.getElementById(id);

        const newPortal = createPortal(el);
        setPortal(newPortal);
        return () => newPortal.remove(el);
    }, [id]);

    return portal.render;
};