import React from "react";
import IconMenu from "../GetIconMenu";
import Collapse from "@material-ui/core/Collapse";
import {glv} from "../../app_components/helper_functions/helper_functions";


const HeaderMenuBlockHD = (props) => {
    const [child_list_open, set_clild_list_open] = React.useState(false)
    let custom_style = props.style ? props.style : {};
    let is_child = props.is_child_block ? ' is_child_true' : '';
    return  (
        <>
        <div className={'wrap_menu_block_hd'+is_child}
             style={{...custom_style}}
             onClick={()=>{
                 if (props.child) {
                     set_clild_list_open(!child_list_open)
                 }
                 else {
                     props.action()
                 }
             }}
        >
            <div className={'menu_block_content_hd'+is_child}>
                {!is_child ?
                    <div className={'wrap_icon_menu_block_hd' + is_child}>
                        <IconMenu id={props.id_name} mod={'hd' + is_child}/>
                    </div>
                    : null
                }
                {
                    is_child ?
                        <div className={'wrap_title_menu_block_hd'}>
                            <span className={'title_menu_block_hd'}>{props.title}</span>
                        </div>
                        : null}
            </div>
            {
                props.child ? (
                    <Collapse in={child_list_open}>
                        <div className={'struct_wrap_menu_child_blocks_hd'}>


                        <div className={'wrap_menu_child_blocks_hd'}>
                            {
                                props.child.map((punkt, index)=>{
                                    return (
                                        <HeaderMenuBlockHD
                                            key={punkt.id+index}
                                            is_child_block={true}
                                            child={punkt.child ? punkt.child : null}
                                            hided={child_list_open}
                                            id_name={punkt.id}
                                            action={()=> {
                                                if (punkt.action) {
                                                    punkt.action()
                                                }
                                            }}
                                            title={punkt.custom_name||glv('header.menu_buttons.'+punkt.id)}
                                        />
                                    )
                                })
                            }
                        </div>
                        </div>
                    </Collapse>
                ) : null
            }
        </div>

        </>
    );
}

export default HeaderMenuBlockHD;
