import React from 'react';
import {glv} from "../../components/app_components/helper_functions/helper_functions";
import StatisticIndicator from "./block_for_one_indicator";
import ChartPub from "../pubs/chart_pub";
import GagPub from "../pubs/gag_pub";
import BlocksWrapper from "../wrappers/blocks_wrapper";

const StatisticComponent = (props) => {
    //компонент блока статистики
    const {accessData, R1ref, R2ref, R3ref} = props;
    const [vals, set_vals] = React.useState(null);
    const [dimensions_container, set_dimensions_container] = React.useState({});
    React.useEffect(()=>{
        let pi_vals = props.indicatorsData.values;
        let arr =props.indicatorsData?.law_and_order?.squares ?
            props.indicatorsData?.law_and_order?.squares : [];
        if (!arr.length) {
            for (let i=0; i<Object.keys(pi_vals).length; i+=2 ){
                let temp_arr = [];
                if (Object.keys(pi_vals)[i]) {
                    temp_arr.push(Object.keys(pi_vals)[i])
                }
                if (Object.keys(pi_vals)[i+1]){
                    temp_arr.push(Object.keys(pi_vals)[i+1])
                }
                arr.push(temp_arr)
            }
        }

        set_vals(arr)
    }, [props.indicatorsData])

    return !vals ? null: (
        <BlocksWrapper
            id_block={'statistic'}
            max_width={props.max_width}
            header_title={glv(`first_level_blocks.block_titles.statistic`)}
            has_gag={accessData.access_control.static.gag}
            is_desktop={props.is_desktop}
            wrap_cls={'wrap_statistic_block'}
            set_dimensions_container={set_dimensions_container}
        >

                {
                    dimensions_container&& vals.map((arr_, index)=>{
                        return       (
                            <div
                                key={index+ arr_.length+arr_[0]}
                                ref={R1ref}
                                 style={{
                                     flexDirection: !props.is_desktop ? 'column': '',
                                     marginTop:index>0 ? 30 : 0}}
                                 className={'wrap_render_stat_blocks'}>
                                {
                                    arr_.map((item, index_) => {
                                        let is_one_block = arr_.length===1;
                                        let rf_ = props.indicatorsData.values[item];
                                        return (
                                            <StatisticIndicator
                                                container_sizes={dimensions_container}
                                                is_desktop={props.is_desktop}
                                                is_one_block={is_one_block}
                                                key={index_+item}
                                                index={index_}
                                                rf={rf_}
                                                borderNone={true}
                                                id_={rf_.name}
                                                isOnl={props.onl_offl[rf_.name]}/>
                                        )
                                    })
                                }
                            </div>)
                    })
                }

        </BlocksWrapper>
    )
}

export default StatisticComponent;