import React, {useRef, useEffect, useCallback} from 'react'

import Map from './Map';
import {glv} from "../../../app_components/helper_functions/helper_functions";

const ContentHelpModal = props => {

    const [markerPosition, setMarkerPosition] = React.useState({lat: props.lat, lng: props.lng});

    let custom_style= props.style ? props.style : {};
    return (
        <div className={'struct_wrap options_scrollbar'}>
            <div
                className={'wrap_content_help_modal'}
                style={{
                    ...custom_style,
                }}
            >

        <div style={{
            marginTop: 10,
            fontSize: 18
        }}><b> {glv(`first_level_blocks.info_modal.system`)}:</b>{' '}{props.sys_name} [ {props.sys_id} ]</div>
                <div style={{
                    marginTop: 10,
                    fontSize: 18
                }}><b> {glv(`first_level_blocks.info_modal.use_explo`)}:{' '}</b>{props.use_date}</div>
                <div style={{
                    marginTop: 10,
                    fontSize: 18
                }}><b> {glv(`first_level_blocks.info_modal.current_state`)}:{' '}</b>{props.current_state} </div>

                <div style={{marginTop: 10,}}>
                    <Map markerPosition={markerPosition} popupContent={`${props.sys_name}[ ${props.sys_id} ]`}/>
                </div>

                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                <span style={{
                    marginTop: 15,
                    opacity: 0.7,
                    fontSize: 16
                }}><b> {glv(`first_level_blocks.info_modal.sale_section`)}:</b></span>

                        <span style={{
                            marginTop: 10,
                            opacity: 0.7,
                            fontSize: 16
                        }}>{glv(`first_level_blocks.info_modal.tel`)}:{glv(`first_level_blocks.info_modal.content_sale_tel`)}</span>

                        <span style={{
                            marginTop: 10,
                            opacity: 0.7,
                            fontSize: 16
                        }}>{glv(`first_level_blocks.info_modal.mail`)}: {glv(`first_level_blocks.info_modal.content_sale_mail`)}</span>

                    </div>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                <span style={{
                    marginTop: 15,
                    opacity: 0.7,
                    fontSize: 16
                }}><b> {glv(`first_level_blocks.info_modal.support`)}:</b></span>

                        <span style={{
                            marginTop: 10,
                            opacity: 0.7,
                            fontSize: 16
                        }}>{glv(`first_level_blocks.info_modal.tel`)}:{glv(`first_level_blocks.info_modal.content_tech_tel`)}</span>

                        <span style={{
                            marginTop: 10,
                            opacity: 0.7,
                            fontSize: 16
                        }}>{glv(`first_level_blocks.info_modal.mail`)}: {glv(`first_level_blocks.info_modal.content_tech_mail`)}</span>

                    </div>
                </div>
                <div style={{
                    marginTop: 20,
                    opacity: 0.7,
                    fontSize: 16
                }}>{glv(`first_level_blocks.info_modal.content_address`)}</div>
            </div>
        </div>

        )

}

export default ContentHelpModal;