import React, { useState } from "react";
import "./slider.style.css";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import GetSliderTenplate from "./slider.templates";


 function TransitionSlider({ slides,id_slide_component, data_to_slide_component }) {
    const [index, setIndex] = useState(0);
    const [direction, setDirection] = useState("slide-right");

    const slideLeft = () => {
        const nextIndex = index - 1;
        if (nextIndex < 0) {
            setIndex(slides.length - 1);
        } else {
            setIndex(nextIndex);
        }
        setDirection("slide-left");
    };

    const slideRight = () => {
        setIndex((index + 1) % slides.length);
        setDirection("slide-right");
    };

    const childFactory = (direction) => (child) =>
        React.cloneElement(child, {
            classNames: direction,
        });

    return (
        slides.length > 0 && (
            <div className="image-slider">
                <button onClick={slideLeft}>{"<"}</button>
                <div className="image-wrapper">
                    <TransitionGroup childFactory={childFactory(direction)}>
                        <CSSTransition
                            key={slides[index]+index+'sliderhahaha'}
                            timeout={3000}
                            classNames={direction}
                        >
                            <GetSliderTenplate
                                id={id_slide_component}
                                data_to_slide_component={{
                                    ...data_to_slide_component,
                                    item_data: slides[index],
                                    index_: index
                                }}/>
                        </CSSTransition>
                    </TransitionGroup>
                </div>
                <button onClick={slideRight}>{">"}</button>
            </div>
        )
    );
}

export default TransitionSlider;