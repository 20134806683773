import React from "react";

const ChartLoader = (props) => {
    // компонент загрузки графика
    let custom_style = props.style ? props.style : {};
    return  (
        <div  className={'bg_loader_chart'}>
            <div className={'grid_inside_loader_chart'}>
                <div className={'content_loader_chart'}>
                    {
                        // glv(`first_level_blocks.pub_block.text`)
                        'Loading...'
                    }
                </div>
            </div>
        </div>
    );
}

export default ChartLoader;
