import React from "react";
import {Tooltip} from "@material-ui/core";

const PointScrollRef = (props) => {
    // точка которая показывает к каком блоку заскролил пользователь
    let custom_style = props.style ? props.style : {};
    return (
        <Tooltip title={
            props.tooltip
        } placement={'left'} arrow>
            <div
                className={'point_scroll_ref_style'}
                style={{
                    ...custom_style,
                    background: props.what_bg ? 'white' : 'rgba(0,0,0,0.5)',
                }}
                onClick={() => {
                    props.click_action()
                }}
            ></div>
        </Tooltip>
    );
}

export default PointScrollRef;
