import React from "react";
import {glv} from "../app_components/helper_functions/helper_functions";

const GagPub = (props) => {
    // компонент заглушки если функционал временно недоступен
    let custom_style = props.style ? props.style : {};
    let custom_text_style = props.style_text ? props.style_text : {};

    return  (
        <div
            className={'bg_gag'}
            style={{
            ...custom_style
            }}
        >
            <div className={'gag_inside'}>
                <div className={'gag_text'} style={{...custom_text_style}}>
                    {
                        glv(`first_level_blocks.pub_block.text`)
                    }
                </div>
            </div>
        </div>
    );
}

export default GagPub;
