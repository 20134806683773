import React, {useState, useEffect, useRef, useCallback} from 'react'

import '../styles/full_custom_input.css';
import CustomKeyboard from "../../custom_keyboard/components/custom_keyboard";
// import {useDebounce} from "../../hooks/useDebounce";
import inits_vals_by_type from "../services/functions_input_by_type";




const FullCustomInput = (props) => {
    const input_ref = useRef(null);

    const value_ref = useRef(':');
    const cursor_ref = useRef(null);

    let is_time = props.type==='time';
    let is_ampm = props.time_type&&props.time_type==='12';
    let enable_changing_ampm = is_time&&is_ampm;

    let val_am_pm  = props.val_am_pm ? props.val_am_pm : 'none';

    const [state_input, set_state_input] =
        useState(':');
    const [is_focus, set_is_focus] = useState(false);


    const [current_index_cursor, set_current_index_cursor] = useState(null);
    // const current_index_cursor_debounced = useDebounce(current_index_cursor, 0);

    const cut_cursor = useCallback(()=> {
        cursor_ref.current=null;
        set_state_input(value_ref.current)
    }, [])

    const blured = useCallback((event)=> {

        if (input_ref.current &&
            input_ref.current.id!==event.target.id&&
            event.target.id.indexOf('_not_blured')===-1
        ) {
            cut_cursor()
            set_is_focus(false);
        }
    }, [state_input])


    const clean_symbol = useCallback(()=> {
        let cursor_text = '|';
        let curr_index = cursor_ref.current;
        let curr_val = value_ref.current;

        let new_state =curr_val.slice(0,curr_index) + `|` + curr_val.slice(curr_index);

        if (curr_index===0){
            return
        }

        let curr_type_properties = inits_vals_by_type[props.type];
        if (curr_type_properties.hasOwnProperty('guard_clean')) {
            let [new_val, new_index] =curr_type_properties.guard_clean(curr_index, new_state)
            curr_index = new_index;
            new_state = new_val;
        } else {
            new_state = new_state.slice(0,curr_index-1) + new_state.slice(curr_index)
        }
        // new_state =curr_type_properties.hasOwnProperty('guard_clean') ?
        //     curr_type_properties.guard_clean(curr_index, new_state) :

        if (props.handle_change) {
            let nv_ = new_state.replace("|",`` )
            let changed_val = props.handle_change (nv_);
            value_ref.current = changed_val;
            nv_ = changed_val + '|';
            set_focus(curr_index, nv_)
            return
        }
        value_ref.current = new_state.replace("|",`` );
        set_focus(new_state.indexOf(cursor_text), new_state)
    }, [state_input ])




    const set_focus = useCallback((new_index_cursor, handle_state)=> {
        let cursor_text = '|';
        let new_state =handle_state ? handle_state : value_ref.current;

        let curr_index = cursor_ref.current;
        let index_cursor = !isNaN(Number(new_index_cursor)) ? new_index_cursor : new_state.length;

        if (curr_index===index_cursor&&!handle_state) {
            return
        }
        cursor_ref.current = index_cursor;
        let is_final_symbol = index_cursor===new_state.length;
        new_state = new_state.indexOf(cursor_text)!==-1 ?
            new_state.replace(cursor_text, '') : new_state;
        new_state = is_final_symbol ? new_state+cursor_text :
            new_state.slice(0, new_index_cursor)
            +cursor_text
            +new_state.slice(new_index_cursor);
        set_state_input(new_state)
        set_is_focus(true);
    }, [])

    const lr_cursor = useCallback((mod)=>{

        let curr_index = cursor_ref.current;
        if (mod==='move_left') {
            let new_cursr = curr_index-1<0 ? curr_index : curr_index-1;
            set_focus(new_cursr)
        }
        if (mod==='move_right') {

            let new_cursr = curr_index===value_ref.current.length ?
                curr_index : curr_index+1;
            set_focus(new_cursr)
        }

    },[])

    const set_cursor = useCallback((event, new_index)=> {
        if (event&&event.currentTarget.id !== event.target.id) {
            return
        }

        set_focus(new_index)
    }, [])
    const change_state = useCallback((key_val, mod)=> {

        if (mod==='am_pm') {
            props.change_ampm()
        }

        if (mod==='clean') {
            clean_symbol()
        }
        if (mod==='move_left'||mod==='move_right') {
            lr_cursor(mod)
        }

        if (mod==='write') {
            if (key_val==="|") {
                return
            }
            let curr_index = cursor_ref.current;
            let curr_val = value_ref.current;
            let first_peace = curr_index ? curr_val.slice(0,curr_index)+ `${key_val}|`
                : `${key_val}|` ;

            let new_val =first_peace + curr_val.slice(curr_index)
            let index_curs = new_val.indexOf('|');
                if (props.handle_change) {
                    let nv_ = new_val.replace("|",`` )
                    let changed_val = props.handle_change (nv_);
                    value_ref.current = changed_val;
                    nv_ = changed_val + '|';
                    set_focus(index_curs, nv_)
                    return
                }
            value_ref.current = new_val;
            set_state_input(new_val)
        }

    }, [props.change_ampm])

    useEffect(()=> {
        if (value_ref.current!==props.out_value) {
            let new_val = props.out_value ? props.out_value : ":";
            value_ref.current = new_val;
            set_state_input(new_val)
        }
    }, [props.out_value,])


    useEffect(()=> {
        let root_container = document.getElementById('root')
        if (is_focus) {

            root_container.addEventListener('click', (e)=>{
                blured(e)
            })
        }
        if (!is_focus) {
            root_container.removeEventListener('click', blured)
        }
    }, [is_focus])


    let main_input_class = props.main_input_class ?  props.main_input_class : '';
    let text_input_class = props.text_input_class ?  props.text_input_class : '';
    let focus_class = is_focus ?  'focused' : '';

    return (
        <div>
            <div

                id={'full_input_container'+props.id}
                ref={input_ref}
                onClick={(e)=> {
                    set_cursor(e, state_input.length)
                }}
                onFocus={()=>{

                }}
                onBlur={(e)=>{
                    blured(e)
                }}
                className={`full_custom_input ${main_input_class} ${focus_class}`}
            >
                <span
                    id={'pub_null_cursor_not_blured'}
                    className={'char_fc_input pub_null_cursor'}
                    onClick={(e)=> {
                        set_cursor(e, 0)
                    }}
                >
                    {'O'}
                </span>
                {
                    state_input.split('').map((char, index)=>{
                        let is_cursor_class = char === '|' ? 'is_cursor_true' : '';
                        return (
                            <span
                                id={char+index+'char'+'_not_blured'}
                                onClick={(e)=>{
                                    set_cursor(e, index+1)
                                }}
                                className={`char_fc_input ${is_cursor_class} ${text_input_class}`}
                                key={char+index+'char'+props.id}
                            >
                                {char}
                            </span>
                        )
                    })
                }
                {
                    is_time&&is_ampm ?
                        (
                            <span
                                onClick={(e)=> {
                                    set_cursor(e, state_input.length)
                                }}
                                id={'am-pm'+props.id+'_not_blured'}
                                className={`char_fc_input`}
                            >
                               {' '} {val_am_pm}
                            </span>
                        ) : null
                }
            </div>

                    <CustomKeyboard
                        is_show={props.time_mode&&is_focus }
                        handle_change={change_state}
                        enable_change_ampm={enable_changing_ampm}
                    />

        </div>
    );

}


export default FullCustomInput;