import React from 'react';

const RadioBtn = (props) => {
    const [checked, setChecked] = React.useState(false);

    React.useEffect (() => {

if (props.checkedId===props.id) {
    setChecked(true)
} else {
    setChecked(false)
}
    },[props.checkedId])

    // const [degTear, setDegTear] = React.useState(233);
    return (
        <div className="wrap_rad-btn" style={{marginTop: props.mt? props.mt : '', }}>
          <div className="rad_btn_bord regen_rad radi"
        //   id="del_regen"
              style={{cursor: 'pointer'}}
          onClick={() => {
              if (props.disabled) {
                  return
              }
              props.setCheckedId(props.id);
              setChecked(true)
          }}>
            <div className="in_rad_btn_bord">
              <div className="checked_rad_btn_bord"  style={checked?{display:'block'}:{}}/>
            </div>
          </div>
          <span className="label_to_rad_btn"> {props.label}</span>
        </div>
    )
}

export default RadioBtn;