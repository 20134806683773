import React, {useEffect,useCallback,  useState} from "react";
import Cookies from 'js-cookie';
import moment from "moment";
import sha1 from 'sha1';
import {setlogos} from "../components/app_components/helper_functions/helper_functions";


 // const setlogos = (errText,point)=> {
 //    let login = Cookies.get("hash_key",{domain: '.bregus.eu'});
 //    let errforLog = {
 //      timestamp: moment().unix(),
 //      login_hash: login ? login : "",
 //      point_error: point,
 //      error_msg: errText,
 //    };
 //    let logos = JSON.parse(localStorage.getItem("logos"));
 //    logos.arr.push(errforLog);
 //    localStorage.setItem(
 //      "logos",
 //      JSON.stringify({ key: sha1(logos.arr), arr: logos.arr })
 //    );
 //  }

class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }
  
    componentDidCatch(error, info) {

      // Display fallback UI
      
      this.setState({ hasError: true });
      
      let a = error;

      // JSON.stringify(info.componentStack)
      // You can also log the error to an error reporting service
      setlogos(JSON.stringify({error:a + '', info: info }),"global exeption" );
    }
  
    render() {
      return this.props.children;
    }
  }

  export default ErrorBoundary;