import React, {useState} from 'react';
import CustomSlider from "../../Slider/slider";
import CalendarBlock from "../../../components_in_development/calendar/calendar_block";
import {monthes} from "../../../components_in_development/calendar/data_consts";
import TimeInput from "../../../components_in_development/clock/clock_block";




const TestPage = (props)=>{
    const [curr_date, set_curr_date] = useState([new Date()]);
    return (
        <div style={{ width:'100%',  overflow: 'hidden', padding: '0px 20px',
            paddingBottom: '300px',
            boxSizing: 'border-box'}}>

            <CalendarBlock
                is_range={true}
              current_selected_date={curr_date}
              select_date={(new_date)=>{set_curr_date(new_date)}}
              disabled_years={[]}
              disabled_monthes={[]}
            />


        </div>
    )
}

export default TestPage;