import * as actionTypes from '../types/env.types';
import Cookies from 'js-cookie';

export const setUrls = (urls) => {
    return {
      type: actionTypes.SET_URLS,
      urls,
    };
  };

  export const setHash = (hash) => {
    return {
      type: actionTypes.HASH,
      hash,
    };
  };

  export const setBlur = (blurElems) => {
    return {
      type: actionTypes.SET_BLUR,
      blurElems,
    };
  };

  export const setShemeModal= (shemeModal) => {
    return {
      type: actionTypes.SET_SHEME_MODAL,
      shemeModal,
    };
  };

  export const setIndicatorsData= (indicatorsData) => {
    return {
      type: actionTypes.INDICATORS_DATA,
      indicatorsData,
    };
  };

  export const setOverModal= (overModalStatus) => {
    return {
      type: actionTypes.OVER_MODAL,
      overModalStatus,
    };
  };
  export const setShemeModalData = (shemeModalData) => {
    return {
      type: actionTypes.SET_SHEME_MODAL_DATA,
      shemeModalData,
    };
  };

  export const setCurrTrumpet = (trumpet) => {
    return {
      type: actionTypes.CURR_TRUMPET,
      trumpet,
    };
  };


  export const setHighlightColor = (color) => {
    return {
      type: actionTypes.SET_HIGHTLIGHT_COLOR,
      color,
    };
  };

  export const setTreeValid = (treeVal) => {
    return {
      type: actionTypes.TREE_VALID,
      treeVal,
    };
  };


  


export const setLangValues = (lang_values) => {
    return {
      type: actionTypes.LANG_VALUES,
      lang_values,
    };
  };
  export const setCurrentPages = (currentPage) => {
    
    return {
      type: actionTypes.CURRENT_PAGE,
      currentPage,
    };
  };

  export const setConfStyle = (style) => {
    
    return {
      type: actionTypes.CONF_STYLE,
      style,
    };
  };
  

  export const setLanguageConfig = (lang_conf) => {
    return {
      type: actionTypes.LANG_CONF,
      lang_conf,
    };
  };
  
  export const setLanguage = (language) => {
  
    Cookies.set('language', language, { domain: '.bregus.eu', sameSite: 'Strict'});
 
    return {
      type: actionTypes.SET_LANGUAGE,
      language,
    };
  };

  export const setConfUser = (confUser) => {
    return {
      type: actionTypes.CONF_USER,
      confUser,
    };
  };