import React, {useRef, useEffect} from 'react'
import {Tooltip} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import * as actions from "../../../redux/actions/env.actions";
import {Fade} from '@material-ui/core';
import uuid from 'react-uuid'
import useMousePosition from '../../../hooks/useMousePosition';
import LineProgress from '../../indicator_components/linear_progress/LineProgress';
import RadialProgress from '../../indicator_components/radial_progress/old/RadialProgress';
import Indicator from '../../indicator_components/manometrs/old/Indicator';
import useWindowSize from '../../../hooks/useWindowSize';
import {glv} from "../../../components/app_components/helper_functions/helper_functions";


let __path_info_ = 'second_level_blocks.info_page';

const values = {
    'blue_trumpet': 'L1-OUT',
    'purple_trumpet': 'L1-IN',
    'stock1': 'R1-T5',
    'stock2': 'R1-T6',
    'stock3': 'R2-T5',
    'stock4': 'R2-T6',
    'stock5': 'R3-T5',
    'stock6': 'R3-T6',
}

let ids_ = {
    ball_valve: ['R1-T1', 'R1-T2', 'R1-T4', 'R1-T3', 'R2-T1', 'R2-T2', 'R2-T4', 'R2-T3', 'R3-T1', 'R3-T2', 'R3-T4', 'R3-T3',
    ],
    faucet_for_taking_water_samples: ['R1-T5', 'R1-T6', 'R2-T5', 'R2-T6', 'R3-T5', 'R3-T6'],
    bypass_valve: ['L1-T1', 'L1-T2', 'L1-T3',  'J1-B1', 'J1-B2', 'J1-B3','J2-B1', 'J2-B2', 'J2-B3'],
    brine_tank: ['R1-S1', 'R2-S1', 'R3-S1'],
    measuring_pressure_gauge: ['L1-Y1', 'L1-Y2'],
    input_dirty_water: ['L1-IN', 'J1-IN', 'J2-IN'],
    outlet_filtered_water: ['L1-OUT', 'J1-OUT'],
    outlet_another_water: ['J2-OUT'],
    mech_filter: ['J1-F2', 'J1-F1', 'J2-F2', 'J2-F1']
}

function getContent(currId, path) {

    let res_key = '';

    Object.keys(ids_).map((key) => {
        if (ids_[key].find((key) => key === currId)) {
            res_key = key;
        }
    })
    return res_key
}

const ShemeElModal = (props) => {
    const {width} = useWindowSize()
    const dispatch = useDispatch();
    const shemeModalData = useSelector((state) => state.envReducer.shemeModalData);
    const size = useWindowSize();

    // useEffect(() => {
    //     return () => {
    //         document.body.style.overflow = 'hidden';
    //         // document.removeEventListener('scroll', drop_scroll)
    //     }
    // }, []);
    useEffect(() => {
        if (props.open) {
            document.body.style.overflow = 'hidden';
        }
        else {
            document.body.style.overflow = 'auto';
        }
    }, [props.open]);

    const setOverModal = React.useCallback((status) => dispatch(actions.setOverModal(status)), [
        dispatch,
    ]);

    let id_el = props.id;


    let xSide =props.open ?  props.x + 450 > size.width ? 'right' : 'left' : '';
    let ySide =props.open ?  props.y + 200 > size.height ? 'bottom' : 'top' : '';
    let xPos =props.open ?  props.x + 450 > size.width ? size.width - props.x - 1 :props.x + 1 : '';
    let yPos = props.open ?  props.y + 200 > size.height ? size.height - props.y - 2 : props.y + 2 : '';


    return props.open ? <Fade in={true} timeout={1000} style={{zIndex: 120202516516510225454}}>
            <div

                id={'modalka'}
                onMouseOver={(e) => {
                    setOverModal(true)
                }}
                onMouseLeave={(e) => {
                    setOverModal(false)
                }}
                style={{
                    position: "fixed",
                    [xSide]:width<500? 2 : xPos ,
                    [ySide]: yPos,
                    width:width<500? '98vw':'max-content',
                    height: 'max-content' ,

                    borderRadius: '24px',
                    background: "linear-gradient(0deg, #E3E9F7, #E3E9F7)",
                    boxShadow:
                        "-0.5vh -0.5vh 1.4vh rgb(255 255 255 / 57%), 1vh 1vh 2vh rgb(0 0 0 / 8%)",
                    overflow: 'hidden',
                    // zIndex: 3453453445567567567657
                }}
            >

                {
                    props.onl_offl.hasOwnProperty(id_el) ?
                        <div style={{position: 'relative', }}
                             className={'flex_ column_flex flex_end_jc'}
                        >

                            <div
                                style={{position:'absolute', left: 0,width: 40}}
                                className={`${props.onl_offl.hasOwnProperty(id_el) && !props.onl_offl[id_el] ?
                                    'offline_id_bg' : 'online_id_bg'} 
            f_sz_24 
            color_wh
            h100
            center_flex
            `}

                            >

                                <div style={{transform: 'rotate(90deg)', }} className={'ws_nw'}>
                                    {


                                            values[  props.name_id] || props.name_id
                                    }
                                </div>
                            </div>



                            <div style={{display: 'flex', flexDirection: 'column',
                                // position: 'absolute', left: 50, top: 5
                                marginLeft: 60,
                                marginRight: 20,
                                marginTop:10
                            }}>
                                <div>
                                    <div className={'flex_ column_flex '}>

                                        <div className={'flex_ wh_sp_no_wr marg_top_10'}>
                                            <span className={'fw_bold'}>{glv(__path_info_ + '.balon')}:</span>
                                            <span className={'fw_norm mg_l_10 sheme_modal_text'}>
                      {props.indicatorsData.values[props.id_key].settings.sys.ballon.id
                      + " [" + props.indicatorsData.values[props.id_key].settings.sys.ballon.size.h + 'x'
                      + props.indicatorsData.values[props.id_key].settings.sys.ballon.size.w + ']'}
                  </span>
                                        </div>
                                        <div className={'flex_ wh_sp_no_wr marg_top_10'}>
                                            <span className={'fw_bold'}>{glv(__path_info_ + '.backfill')}:</span>
                                            <span className={'fw_norm mg_l_10 sheme_modal_text'}>
                      {props.indicatorsData.values[props.id_key].settings.sys.filler.id +
                      " - " + props.indicatorsData.values[props.id_key].settings.sys.filler.name}
                  </span>
                                        </div>
                                        <div className={'flex_ wh_sp_no_wr marg_top_10'}>
                                            <span className={'fw_bold'}>{glv(__path_info_ + '.install')}:</span>
                                            <span className={'fw_norm mg_l_10 sheme_modal_text'}>
                      {props.indicatorsData.values[props.id_key].settings.sys.head.id +
                      " [" +
                      props.indicatorsData.values[props.id_key].settings.sys.head.holes.b.replace(/[^\d.-]/g, '') + "x" +
                      props.indicatorsData.values[props.id_key].settings.sys.head.holes.i.replace(/[^\d.-]/g, '') + ']'}
                  </span>
                                        </div>
                                        <div className={'flex_ wh_sp_no_wr marg_top_10'}>
                                            <span className={'fw_bold'}>{glv(__path_info_ + '.flow_meter')}:</span>
                                            <span className={'fw_norm mg_l_10 sheme_modal_text'}>
                      {props.indicatorsData.values[props.id_key].settings.sys.tyrb.id +
                      " [" +
                      props.indicatorsData.values[props.id_key].settings.sys.tyrb.probe +
                      ']'}
                  </span>
                                        </div>

                                        <div className={'flex_ wh_sp_no_wr marg_top_10'}>
                                            <span className={'fw_bold'}>{glv(__path_info_ + '.controller')}:</span>
                                            <span className={'fw_norm mg_l_10 sheme_modal_text'}>
                      {props.indicatorsData.values[props.id_key].settings.sys.control.id +
                      " [" + props.indicatorsData.values[props.id_key].settings.sys.control.ver + ']'
                      }
                  </span>
                                        </div>
                                        {
                                            props.indicatorsData.values[props.id_key].settings?.salt_tank ?
                                                <div className={'flex_ wh_sp_no_wr marg_top_10'}
                                                     style={{justifyContent: 'center'}}>
                                                    <span className={'sheme_modal_text'}>{glv(__path_info_ + '.has_salt_back')}</span>
                                                </div> : null
                                        }

                                    </div>
                                </div>
                                <div className={
                                    'color_gray '
                                }
                                     style={{
                                         marginTop: 10,
                                         zIndex: 2342342343453534,
                                         marginBottom: 10,
                                         textAlign: 'center'
                                     }}
                                >


                                <span style={{ fontSize: 18 }}>
                                    {/*sdfsdf sdfsdfd sdfsfd sdsfssdfghdfgdfg*/}
                                    {glv('first_level_blocks.statistic.f_pos.'+
                                        Object.values(props.indicatorsData.values).find(item=>item.name===id_el)?.f_pos)}</span>
                                </div>
                                {
                                    props.onl_offl.hasOwnProperty(id_el) ? null :
                                        <div style={{marginLeft: 5, textAlign: 'center', fontWeight: 600, fontSize: 18}}>
                                            {
                                                glv('second_level_blocks.sheme_tooltips.' + id_el)
                                            }
                                        </div>
                                }

                                {/*{*/}
                                {/*    props.speedometr_values ? <Indicator*/}
                                {/*        stat_width = {1500}*/}
                                {/*        mod_modalka={true}*/}
                                {/*        not_show_status_onl={true}*/}
                                {/*        small={true}*/}
                                {/*        percents={{*/}
                                {/*        ... props.speedometr_values*/}
                                {/*    }}*/}
                                {/*    /> : null*/}
                                {/*}*/}
                            </div>

                        </div>
                        : <div style={{display: 'flex', alignItems: 'center', flex: 1, height: '41px'}}>

                            {/*<div style={{*/}
                            {/*    width: 'max-content',*/}
                            {/*    height: '41px',*/}
                            {/*    paddingLeft: 10,*/}
                            {/*    paddingRight: 10,*/}
                            {/*    fontSize: 20,*/}
                            {/*    fontWeight: 600,*/}
                            {/*    color: 'white'*/}
                            {/*}} className={'center_flex online_id_bg'}>*/}
                            {/*<span className={'wh_sp_no_wr'}>*/}
                            {/*                                {*/}
                            {/*                                    values[  props.name_id] || props.name_id*/}
                            {/*                                }*/}
                            {/*</span>*/}
                            {/*</div>*/}
                            <div style={{
                                marginLeft: 15,
                                marginRight:15,
                                textAlign: 'center', fontWeight: 600, fontSize: 18}}>
                                {
                                    glv('first_level_blocks.sheme.types.' +props.name_id)
                                        // getContent( props.name_id))
                                }
                            </div>

                        </div>
                }
            </div>
        </Fade>
        : null

}

export default ShemeElModal;