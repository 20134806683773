import React, { useEffect, useCallback, useState } from "react";
import Grow from '@material-ui/core/Grow';
import {IS_DESKTOP_CONTEXT, Using_Mobile_Device} from '../../../../App';
import { useMediaQuery } from '@material-ui/core';


const WrapModal = (props) => {


    const is_desktop = React.useContext(IS_DESKTOP_CONTEXT)
    const h_w_650 = useMediaQuery('(max-height:650px)');


    const container_sizes = React.useRef(null);
    const get_container_dimensions = () => {
        if (container_sizes.current) {
            let ratio = window.devicePixelRatio;
            ratio= ratio<0? 1: ratio
            props.set_dimensions_container ? props.set_dimensions_container({
                width: container_sizes.current.offsetWidth,
                height: container_sizes.current.offsetHeight,
                ratio
            }) : console.log();
        }
    }

    React.useEffect(() => {
        get_container_dimensions();
        window.addEventListener('resize', ()=>{
            get_container_dimensions()
        });

    }, []);

    let cls_trigger =props.class_trigger ?props.class_trigger : '' ;

    let d_classes = 'wrap_modal_d ';
    let mob_classes = ' wrap_modal_d';
    let class_for_curr_device = !is_desktop ? mob_classes : d_classes;
    let class_position_children = props.class_position_children ? props.class_position_children : '';
    let class_scroll =  h_w_650 ? 'hide_scroll' : '';
    let class_full_screen =  h_w_650 ? 'full_screen_modal' : '';

    let class_full_w = props.full_w ? props.full_w : '';

    let custom_style = props.custom_style ? props.custom_style : {};
    return (

            <div
                ref={container_sizes}
                style={{...custom_style}}
                className={`wrap_modal ${class_full_w} ${class_for_curr_device} ${class_position_children} ${class_scroll} ${cls_trigger}`}
            >
                {props.children}
            </div>

    );
};

export default WrapModal;
