export const LANG_VALUES = 'LANG_VALUES';
export const SET_LANGUAGE = 'SET_LANGUAGE';
export const LANG_CONF = 'LANG_CONF';
export const SET_URLS = 'SET_URLS';
export const CURRENT_PAGE = 'CURRENT_PAGE';
export const CONF_USER = 'CONF_USER';
export const HASH = 'HASH';
export const TREE_VALID = 'TREE_VALID';
export const CONF_STYLE = 'CONF_STYLE';
export const CURR_TRUMPET = 'CURR_TRUMPET';
export const SET_HIGHTLIGHT_COLOR = 'SET_HIGHTLIGHT_COLOR';

export const SET_BLUR = 'SET_BLUR';

export const SET_SHEME_MODAL = 'SET_SHEME_MODAL';
export const SET_SHEME_MODAL_DATA = 'SET_SHEME_MODAL_DATA';

export const OVER_MODAL = 'OVER_MODAL';

export const INDICATORS_DATA = 'INDICATORS_DATA';