import React, {useCallback, useState, useRef} from 'react';
import {set_env_urls} from "../../../App";
import Chart from "./ChartProPage";
import ProService from "../../../services/ProPageService";
import {useDispatch, useSelector} from "react-redux";
import {format} from 'mathjs'
import moment from 'moment';
import {glv} from "../../app_components/helper_functions/helper_functions";
import GagPub from "../../pubs/gag_pub";
import ChartColorList from "./colors_list";
import ChartDatePickers from "./date_pickers";
import InputSelect from "../../form_components/Input_Select";
import BlocksWrapper from "../../wrappers/blocks_wrapper";
import {useForm} from "../../../hooks/useForm";
import SelectParametrsChart from "./selects_parametrs";
import ChartParametrsModal from "../../modals/chart_settings_modal/chart_setting_modal";
import {Collapse} from "@mui/material";
import {format_to_beautiful_date_} from "../../../helper_functions/helpers";
import {CSSTransition} from "react-transition-group";
import RangeDatePicker from "./range_date_picker";
import LightButton from "../../custom_components/light_button";
import {SettingIcon} from "../../../assets/svg_images";

let lbs_axis = {
    "sim_signal": "dB",
    "f_bufwsize": "L",
    "f_litmin": "L/min"
}

window.fc = 0;

let first_load_chart = true;
export let old_start = null;
export let old_finish = null;

let ids = ['R1.F1', 'R1.F2', 'R2.F1', 'R2.F2', 'R3.F1', 'R3.F2']


// компонент блока графика

const ChartComponent = (props) => {
    const dispatch = useDispatch();
    const {accessData,} = props;

    const first_load_chart_ = useRef(false)

    const [form, change_form] = useForm({
        types_time: 'hour',
        nums: 'f_litmin',
        dates: [
            new Date(moment().subtract(1, 'week').unix()*1000),
            new Date(moment().unix()*1000), ]
        // date_start:  moment().subtract(1, 'week'),
        // date_finish: moment()
    })

    // const [form, set_form] = useState({
    //     types_time: 'hour',
    //     nums: 'f_litmin',
    //     dates: [
    //         new Date(moment().subtract(1, 'week').unix()*1000),
    //         new Date(moment().unix()*1000), ]
    //     // date_start:  moment().subtract(1, 'week'),
    //     // date_finish: moment()
    // });
    //
    // const change_form = useCallback((name, value, is_full_overwrite)=>{
    //     if (is_full_overwrite) {
    //         set_form(value);
    //         return
    //     }
    //     let form_copy = {...form};
    //     form_copy[name] = value;
    //     set_form(form_copy);
    // }, [form])
    const [type_, set_type_] = React.useState(
        'hour'
    )

    const [data_, set_data_] = React.useState(
        null
    )

    const [is_load_chart, set_is_load_chart] = React.useState(
        true
    )

    const [open_setting_parametrs, set_open_setting_parametrs] = React.useState(
        false
    )

    const [open_date_picker, set_open_date_picker] = React.useState(
        false
    )


    const [colors_, set_colors_] = React.useState(
        []
    )

    const [first_chart, set_first_chart] = React.useState(
        true
    )

    const [chart_data, set_chart_data] = React.useState(
        {
            data: [],
            changed_data: true,

            first_load: true,
            all_loaded: false
        }
    )


    const hash = useSelector((state) => state.envReducer.hash);


    const constructor_chart_data = (data, val_stat, val_time) => {
        if (!data) {
            return
        }

        let stat_val = val_stat ? val_stat : form.nums;
        let time_val = val_time ? val_time : type_;
        // console.log('test', props.ind_data,data )
        let new_arr = [];

        props.ids.map(id_obj => {
            // let split_id = id_.split('.');
            let arr =

                data[id_obj.id_].map(obj => {
                    if (stat_val === 'f_litmin' || stat_val === 'f_bufwsize') {
                        obj[stat_val] = Number(format(obj[stat_val] / 1000, {precision: 3}))
                    }

                    let _val_ = obj[stat_val].toFixed(1)

                    return {
                        date: new Date(
                            moment.unix(obj.date_crt).year(),
                            moment.unix(obj.date_crt).month(),
                            moment.unix(obj.date_crt).get('date'),
                            moment.unix(obj.date_crt).get('hour'),
                            moment.unix(obj.date_crt).get('minute'),
                            moment.unix(obj.date_crt).get('second'),
                        ), [id_obj.id_]: Number(_val_)
                    }
                })
            new_arr = new_arr.concat(arr);
        })
        let _new_arr_ = [];
        new_arr.forEach((item) => {
            let ind_founded = null;

            // console.log(item, _new_arr_)
            if (_new_arr_.find((item_, index) => {
                if (moment(item_.date).isSame(item.date)) {
                    ind_founded = index;
                    return true
                } else {
                    return false
                }
            })) {
                _new_arr_[ind_founded] = {
                    ..._new_arr_[ind_founded],
                    [Object.keys(item).filter(item => item !== 'date')[0]]:
                        item[Object.keys(item).filter(item => item !== 'date')[0]]
                }
            } else {
                _new_arr_.push({...item})
            }
        })
        _new_arr_.sort(function (a, b) {
            return new Date(a.date) - new Date(b.date);
        });

        let new_ch_d = {
            ...chart_data,
            changed_data: true,
            data:
            // []
            _new_arr_
            ,
            _type_: time_val,
            all_loaded: true,
            title_axis: glv('first_level_blocks.chart.nums.' + stat_val)
        }
        if (_new_arr_.length) {
            new_ch_d.load_chart = true;
        }
        set_chart_data({
            ...new_ch_d
        })

    }

    const getData = async (modif, val_stat, val_time) => {
        // set_is_load_chart(true)
        // await   set_change_chart(false)
        let time_val = val_time ? val_time : form.types_time;
        if (modif) {
            if (time_val && form.nums) {
                set_is_load_chart(true)
                ProService.get_chart_table_data(
                    (data) => set_env_urls(data),
                    hash,
                    props.sys_id,
                    time_val,
                    form.nums,
                    form.dates[0].getTime()/1000,
                    form.dates[1].getTime()/1000
                ).then(result => {
                    if (result?.info?.constructor === Object) {
                        if (first_load_chart_.current) {
                            let arr_temp = [];
                            Object.values(result?.info)
                            if (arr_temp.length < 10) {
                                getData(true, false, 'auto')
                                set_type_('auto')
                                return
                            }
                        }
                        set_data_(result?.info)
                        constructor_chart_data(result?.info, val_stat, time_val)
                        first_load_chart_.current = false;
                        window.fc = window.fc + 1
                    } else {

                    }
                })
            } else {

            }
        } else {
            constructor_chart_data(data_, val_stat)
        }
    }

    React.useEffect(() => {
        getData(true)
    }, [form])
    //
    React.useEffect(() => {

    }, [chart_data])
    // const [degTear, setDegTear] = React.useState(233);

    let types_time_options = [
        {id: 'hour', text: glv('first_level_blocks.chart.types_time.hour'), dis: false},
        {id: 'day', text: glv('first_level_blocks.chart.types_time.day'), dis: false},
        {id: 'auto', text: glv('first_level_blocks.chart.types_time.auto'), dis: true},
    ];

    let nums_options = [
        ["sim_signal", 'signal'],
        ["f_litmin", "litry_min"],
        ["f_bufwsize", 'bufer']
    ].map((item) => ({
        text: glv('first_level_blocks.chart.nums.' + item[1]),
        id: item[0],
    }))

    let inputs_selects_of_parametrs = [
        {
            name: 'types_time',
            options: types_time_options,
            struct_style:{
                marginRight:props.is_desktop ?  24 : 0,
                marginTop: props.is_desktop ?  64 : 24},
        },
        {
            name: 'nums',
            options: nums_options,
            struct_style:{
                marginLeft: props.is_desktop ?  24 : 0,
                marginTop: props.is_desktop ?  64 : 24},
        }
    ];
    return (
        <BlocksWrapper
            no_pd={props.no_pd||false}
            id_block={'chart'}
            max_width={props.max_width}
            header_title={glv(`first_level_blocks.block_titles.chart`)}
            has_gag={accessData.access_control.chart.gag}
            is_desktop={props.is_desktop}
            wrap_cls={'wrap_chart_block'}
        >
            {
                props.is_desktop ?
                    <SelectParametrsChart
                        inputs={inputs_selects_of_parametrs}
                        form={form}
                        change_form={(name, value)=>{
                            getData(true, false, value)
                            change_form(name, value)
                        }}
                    /> : null
            }



            <Chart
                first_chart={first_chart}
                data_chart={{
                    ...chart_data,
                    stat_to_false: () => {
                        set_chart_data({
                            ...chart_data,
                            changed_data: false, first_load: false, load_chart: false
                        })
                    },
                }}
                ids={props.ids}
                _fields={ids.map((id__) => ({field: id__}))}
                set_line_colors={set_colors_}
                is_load_chart={is_load_chart}
                set_is_load_chart={set_is_load_chart}
            />

            <div
                className={'wrap_bottom_side'}
                style={{
                    justifyContent: is_load_chart ? 'flex-end' : 'space-between'
                }}>
                <ChartColorList
                    colors_={colors_}
                />

                {
                    props.is_desktop ?
                        <RangeDatePicker
                            set_open_date_picker={set_open_date_picker}
                            open_date_picker={open_date_picker}
                            form={form}
                            is_popper={true}
                            change_form={change_form}
                        />

                        :

                        <LightButton
                            cls={'setting_chart_btn'}
                            is_icon_button={true}
                            icon={<SettingIcon class={'setting_chart_btn_icon'}/>}
                            on_click={()=>{

                            set_open_setting_parametrs(true)
                        }}>
                            Setting
                        </LightButton>
                }
                {
                    !props.is_desktop ?
                    <ChartParametrsModal
                        is_desktop={props.is_desktop}
                        open={open_setting_parametrs}
                        close={()=>{set_open_setting_parametrs(false)}}
                        inputs={inputs_selects_of_parametrs}
                        change_form={(new_form)=>{
                            change_form('any',new_form, true )
                        }}
                        form={form}
                    /> : null
                }


            </div>
        </BlocksWrapper>
    )
}

export default ChartComponent;