import React from "react";
;

const ChartColorList = (props) => {
    // список цветов линий графика
    let custom_style = props.style ? props.style : {};
    return (
        <div
            className={'wrap_color_list'}>
            {
                props.colors_.map((item) => {
                    return (
                        <div className={'wrap_color_value'}>
                            <div
                                className={'color_circle'}
                                style={{background: item.color}}>
                            </div>
                            <div style={{flex: 2, whiteSpace: 'nowrap'}}>
                                <span style={{marginLeft: 2}}>{'-'}</span>
                                <span style={{marginLeft: 2}}>{item.tit}</span>
                            </div>

                        </div>
                    )
                })
            }

            <div>

            </div>
        </div>
    );
}

export default ChartColorList;
