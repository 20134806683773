import { combineReducers } from "redux";
// import authReducer from "./authReducers/authReducer";
import envReducer from "./env.reducer";


const rootReducer = combineReducers({
  envReducer,
 
  
  // authReducer,
});

export default rootReducer;
