import React, { useRef, useEffect } from 'react'
import { Tooltip } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../redux/actions/env.actions";
import { Fade } from '@material-ui/core';
import ShemeModal from '../modals/sheme_element_modal/shemeElementModal';
import useMousePosition from '../../hooks/useMousePosition';
const degs  ={
    left: 90,
    right: 270,
    bottom: 0,
    top: 180
}
const debounce = require('debounce');
const Arc = props => {
  const dispatch = useDispatch();
  const canvasRef = useRef(null)
  const [hover, setHover] = React.useState(false);
  const blurStatus = useSelector((state) => state.envReducer.blurElems);
    const currTrumpet = useSelector((state) => state.envReducer.currTrumpet);
    const overModal = useSelector((state) => state.envReducer.overModal);
    const { x, y } = useMousePosition();

    const setShemeModal = React.useCallback((color) => dispatch(actions.setShemeModal(color)), [
      dispatch,
    ]);
  
    const [coord, setCoord] = React.useState(null);
    const setShemeModalData = React.useCallback((color) => dispatch(actions.setShemeModalData(color)), [
      dispatch,
    ]);
  const setCurrTrumpet = React.useCallback((color) => dispatch(actions.setCurrTrumpet(color)), [
    dispatch,
  ]);
  const setBlur = React.useCallback((status) => dispatch(actions.setBlur(status)), [
    dispatch,
  ]);
  const bakRef = useRef(null);

  useEffect(() => {
    if (overModal) {
      return
    }
    if (hover&&coord) {
      setShemeModalData({...props, x:coord.x, y: coord.y});
      setShemeModal(true);
      setCurrTrumpet(props.id);
      setBlur(true)
    } else {


      setShemeModal(false);
      setCurrTrumpet('');
      setBlur(false)
    }

  }, [hover,coord])
  
  useEffect(() => {
    const canvas = canvasRef.current
    const ctx = canvas.getContext('2d')
    //Our first draw
    ctx.beginPath();
    ctx.strokeStyle = props.color;
    ctx.lineWidth = 3;
    ctx.moveTo(2, 0);
    ctx.bezierCurveTo(0, ctx.canvas.height, ctx.canvas.width, ctx.canvas.height, ctx.canvas.width-2, 0);
    ctx.stroke();
  }, [])


  
  return <div style={{cursor: 'pointer'}}

  ref={bakRef}
  onMouseOver={debounce((e) => {
    setCoord({x:e.clientX, y: e.clientY})
    if (overModal) {
      return
    }
  setHover(true)
  },300)}
onMouseLeave={debounce(() => {
setHover(false)
},200)}
  >

{/* {hover&&props.id ? (
     <ShemeModal {...props}/>
      ) : null} */}

    
    {/* <svg height="40" width="30"  viewBox="0 0 10 22" style={{
         filter: blurStatus&&currTrumpet!==props.id?'blur(2px)':blurStatus&&currTrumpet===props.id?'none':'none'}}>

<g xmlns="http://www.w3.org/2000/svg" style={{fill:'none'}} id="g912-6-5-2-7" >
      <path id="path901-1-4-1-9" d="M 6,0 H 8 V 8 H 6 c -2.20914,0 -4,1.79083 -4,4 0,2.2092 1.79086,4 4,4 h 2 v 8 H 6 V 18 C 2.68629,18 0,15.3137 0,12 0,8.68628 2.68629,6 6,6 Z"  
      style={{clipRule:'evenodd',fill:props.color,fillRule:'evenodd'}}/>
    </g>
</svg> */}
        <canvas ref={canvasRef} {...props} style={{...props.styleWrap,
            transition: 'all 0.2s',
        transform: `rotate(${degs[props.direction]}deg)`,
         filter: blurStatus&&currTrumpet!==props.id?'blur(2px) contrast(30%)':blurStatus&&currTrumpet===props.id?'none':'none'}}/>

  </div>
  

}

export default Arc