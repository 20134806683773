import React, { useRef, useEffect, useCallback } from 'react'
import Wrap_Overlay from "../modal_components/wrappers/overlay_wrappers/Wrap_Overlay";
import WrapModal from "../modal_components/wrappers/Wrap_Modal";
import CrashIcon from "../crash_modal/title_crash_icon";
import Title_Modal from "../modal_components/Title_Modal";
import ActionsModalButtons from "../modal_components/Actions_Buttons_Modal";
import {glv} from "../../app_components/helper_functions/helper_functions";


const ConfirmModal = (props) => {
    return (
        <Wrap_Overlay
            open={props.open}
            z_index={950}
            hidden_body={true}
            handle_click_overlay={()=>{
                // set_opened_modal(false)
                // props.close()
            }}
            class_position_children={'overlay_children_position'}
        >
            <WrapModal>

                <Title_Modal
                    title_text={props.content}
                />

                <ActionsModalButtons
                    disabled_acc_btn={false}
                    has_cancel={true}
                    has_accept={true}
                    text_accept_btn={glv('joint_buttons.yes')}
                    text_cancel_btn={glv('joint_buttons.no')}
                    style={{marginTop:30}}
                    accept_action={()=>{
                        props.f_to_will_confirm()
                        props.close()
                    }}
                    cancel_action={()=>{
                        props.close()
                    }}
                />
            </WrapModal>
        </Wrap_Overlay>
    );
};

export default ConfirmModal;