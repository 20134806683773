import React, {useCallback, useEffect} from "react";
import {useSwipeable} from "react-swipeable";
import './custom_slider.style.css';
import GetSlide from "./GetSlide";
import PointScrollRef from "../scroll_ref_service_components/scroll_ref_point";
import {glv} from "../app_components/helper_functions/helper_functions";
import useWindowSize from "../../hooks/useWindowSize";
import {elementScrollTo} from "seamless-scroll-polyfill/lib/scroll";


const CustomSlider = (props) => {

    // const {width, height}= useWindowSize();
    const [points_translate, set_points_translate] = React.useState(0);
    const [current_index, set_current_index] = React.useState(0);
    const points_ref = React.useRef(null);
    const swipe_handlers = useSwipeable({
        trackMouse: true,
        onSwipedRight: () => {
            set_current_index(prev_index=>{
                let new_index =
                    prev_index - 1 < 0 ? 0
                    : prev_index - 1;
                return new_index
            })
        },
        onSwipedLeft: () => {
            set_current_index(prev_index=>{
                let new_index =
                    prev_index + 1 === props.slides.flat().length ?
                        props.slides.flat().length - 1
                        : prev_index + 1
                return new_index
            })
        }
    });



    const handler_slide_move = useCallback(() => {
        elementScrollTo(
            document.getElementById(props.id_scroll_wrapper+'wrap_slider_scroll'),
            {
                top: 0,
                left: current_index*props.manomentry_block_max_width,
                behavior: 'smooth'
            }
            )
        // document.getElementById(props.id_scroll_wrapper+'wrap_slider_scroll').scrollTo({
        //     top: 0,
        //     left: current_index*props.manomentry_block_max_width,
        //     behavior: 'smooth'
        // });
    }, [props.id_scroll_wrapper, current_index])

    useEffect(()=>{
        handler_slide_move()
    }, [current_index])

    useEffect(()=>{
        if (points_ref.current){
            set_points_translate(points_ref.current.offsetWidth/2)
        }

    }, [props.manomentry_block_max_width, points_ref.current])

    let cls_ = props.cls_inner ? props.cls_inner : '';

    return (
        <div style={{position: 'relative'}}>
            <div
                style={{transform: `translateX(-${points_translate}px)`}}
                ref={points_ref}
                className={'points_to_slider'}>
                {
                    props.slides.flat().length>1 ?
                    props.slides.flat().map((item, index)=>{
                        return   (
                            <PointScrollRef
                                key={index+'current_slide'+props.id_scroll_wrapper+index}
                                style={{marginLeft: 10}}
                                tooltip={''}
                                what_bg={current_index === index}
                                click_action={() => {
                                    set_current_index(index)
                                }}
                            /> )
                    }) : null
                }
            </div>
            <div
                {...swipe_handlers}
                className={'swipe_block_over'}>

            </div>
            <div

                id={props.id_scroll_wrapper+'wrap_slider_scroll'}
                // onClick={props.disabled ? ()=>{}:()=>props.on_click()}
                className={'wrap_slider hide_scroll '+cls_ }
                style={{
                    // position: 'relative',
                    overflowX: 'scroll',
                    maxWidth: props.manomentry_block_max_width
                }}
            >
                {props.slides.map((item, index)=>{

                    return  (
                        <div
                            id={'current_slide'+props.id_scroll_wrapper+index}
                            className={`wrap_slider_item`}
                            key={'current_slide'+props.id_scroll_wrapper+index}>
                            <GetSlide
                                id={props.id_slide_component}
                                data_to_slide_component={{
                                    ...props.data_to_slide_component,
                                    item_data: item,
                                    index_: index
                                }}/>
                        </div>
                    )
                })}
                {/*</Slider>*/}
            </div>
        </div>


    );
};

export default CustomSlider;
