
import React, {useLayoutEffect, useCallback, useState, useRef, useEffect,} from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import moment from 'moment';
import {glv} from "../../../components/app_components/helper_functions/helper_functions";
// import { useDispatch, useSelector } from "react-redux";
// import moment from "moment";
import {format} from 'mathjs';
import ChartPub from "../../pubs/chart_pub";
import ChartLoader from "../../loaders/chart_loader";
am4core.useTheme(am4themes_animated);
const CHART_ID = 'population_chart';


const constructor_series = (seriya, y_val, x_val, id, name_, num)=> {
    // console.log(seriya, y_val, x_val, id, name_, num)
  seriya.dataFields.valueY = y_val;
  seriya.dataFields.dateX = x_val;
  seriya.strokeWidth = 3;
  seriya.tensionX = 0.8;
    seriya._id_ = name_;
  seriya.bullets.push(new am4charts.CircleBullet());
  seriya.name = name_;
  // seriya.alwaysShowTooltip= true

  seriya.strokeWidth = 2;


  seriya.tooltipText = ": [bold]{valueY.formatNumber('#.00')}[/]"+' ' +num;

  seriya.tooltip.pointerOrientation = 'right';

  seriya.tooltip.background.filters.clear()
  seriya.tooltip.getFillFromObject = true;
  seriya.showOnInit = true;
}

// компонент отрисовки графика графика

function Chart({
                 is_load_chart,
                 set_is_load_chart,
                 set_line_colors,
                 _fields,
                 data_chart,
                   ids
               }) {
  const chartRef = useRef(null);

    const [is_ready, set_is_ready] = React.useState(
        false
    )


//   const urls = useSelector((state) => state.envReducer.urls);
  useEffect(() => {

      // data_chart.changed_data=true;
    if (data_chart.changed_data) {
        if (!data_chart.data.length){
            if (!data_chart.first_load) {
                data_chart.stat_to_false();
            }
            set_is_load_chart(false)
            return;
        }
        set_is_load_chart(true)

      if (chartRef.current){
          if (!data_chart.data.length){
              chartRef.current.data = data_chart.data;
              data_chart.stat_to_false();
              set_is_load_chart(false)
              return;
          }

          // create_series()


          chartRef.current.paddingTop = 40;
          chartRef.current.yAxes._values[0].title.text =  data_chart.title_axis;
          chartRef.current.series._values.map((ser)=> {
              ser.tooltipText = ser._id_ +': [bold]{valueY}[/]'+' ' +data_chart.title_axis;
          })
          chartRef.current.xAxes._values[0].adapter.add("getTooltipText", function(text, target) {
              let tt = moment(target.tooltipDate).format(data_chart._type_ ==='day' ?'MM-DD-YYYY' : 'MM-DD-YYYY kk:mm')
              return tt;
          });

          // chartRef.current.xAxes._values[0].tooltipDateFormat = data_chart._type_ ==='day' ?'MM-DD-YYYY' : 'MM-DD-YYYY kk:mm';
          chartRef.current.data = data_chart.data;
          data_chart.stat_to_false();
          set_is_load_chart(false)
          // chartRef.current.series._values.map((it, i)=> {
          //     chartRef.current.series.removeIndex(i).dispose();
          // })
        return
      }


      // am4core.options.queue = true;
      am4core.options.commercialLicense = true;
      chartRef.current = am4core.create(CHART_ID, am4charts.XYChart);



      let root  =data_chart.data;
        chartRef.current.data = root;
      // console.log(arr[0], root[0])

      // console.log(root)
      var dateAxis = chartRef.current.xAxes.push(new am4charts.DateAxis());
        // dateAxis.tooltipDateFormat = data_chart._type_ ==='day' ?'MM-DD-YYYY' : 'MM-DD-YYYY kk:mm';
      dateAxis.adapter.add("getTooltipText", function(text, target) {
        let tt = moment(target.tooltipDate).format(data_chart._type_ ==='day' ?'MM-DD-YYYY' : 'MM-DD-YYYY kk:mm')
        return tt;
      });

      dateAxis.baseInterval = {
        "timeUnit": "minute",
      };


      var valueAxis = chartRef.current.yAxes.push(new am4charts.ValueAxis());
        valueAxis.tooltip.dx = 60;

        valueAxis.renderer.grid.template.disabled = false;
      valueAxis.paddingLeft = 5;
      valueAxis.paddingRight = 20;
      valueAxis.layout = "absolute";
      valueAxis.title.text = data_chart.title_axis;
      valueAxis.title.rotation = 0;
      valueAxis.title.align = "center";
      valueAxis.title.valign = "top";
      valueAxis.title.dy = -50;
      valueAxis.title.dx = -6;
      valueAxis.title.fontWeight = 600;
      valueAxis.title.fontSize = 16;

      let colors_ = [];

      let series = {};
        ids.forEach((item, index)=>{
            // if(index>4){
            //     return
            // }
            let RF_ = chartRef.current.series.push(new am4charts.LineSeries());
            series[item.id_] = RF_;
        })
      // [
      //   {ser:R1F1, tit: 'R1-F1'},
      //   {ser:R1F2, tit: 'R1-F2'},
      //   {ser:R2F1, tit: 'R2-F1'},
      //   {ser:R2F2, tit: 'R2-F2'},
      //   {ser:R3F1, tit: 'R3-F1'},
      //   {ser:R3F2, tit: 'R3-F2'},
      // ]
        let scrollbarX = new am4charts.XYChartScrollbar();

        ids.map((seriya, index)=>{
            // if(index>4){
            //     return
            // }
            let RF_ = series[seriya.id_];
        colors_.push({
          color: RF_.properties.fill.hex,
          tit:seriya.name
        })
        // var newstr =   seriya.name.replace(/-/i, '.');
           constructor_series(RF_, seriya.id_, 'date', seriya.id_,  seriya.name, data_chart.title_axis, )
            if (index===0){
                scrollbarX.series.push(RF_)
            }
      });

      set_line_colors(colors_)

      chartRef.current.scrollbarX = scrollbarX;
      chartRef.current.cursor = new am4charts.XYCursor();
      chartRef.current.events.on("ready", function () {
              set_is_load_chart(false)
              data_chart.stat_to_false()
          set_is_ready(true)
        if (window.fc<2) {
          dateAxis.zoomToDates(
              moment().subtract(2, 'day')._d,
              moment()._d
          );
        }
      });
    }
  }, [data_chart, set_is_load_chart]);



  return (
      <div style={{width: '100%',}}>

          {
              !data_chart.data.length ? <ChartPub/> : null
          }
        {
          is_load_chart||(!is_ready&&data_chart.data.length) ?
              <ChartLoader /> : null
        }
        <div
            id={CHART_ID}
            style={{
              display: !data_chart.data.length ? 'none' : 'block',
              width: '100%',
              height: '800px',
              // margin: '50px 0'
            }}
        />
      </div>
      )
}
export default Chart;
