import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from "react-redux";
import {glv} from "../../../components/app_components/helper_functions/helper_functions";
import moment from 'moment';
import {format} from 'mathjs'
import useWindowSize from "../../../hooks/useWindowSize";


const BorderLinearProgressBlue = withStyles((theme) => ({
    root: {
        height: 12,
        borderRadius: 5,
    },
    colorPrimary: {
        background: '#EFF3FB'
    },
    bar: {
        borderRadius: 5,
        background:
            // props.color==='red' ? 'linear-gradient( 217.76deg, #FF4D4D 3.07%, #F4713D 81.8%)'
            // :
            'linear-gradient(217.76deg, #17C8FF 3.07%, #7EE6FF 81.8%)',
        transition:'all 0.4s'
    },
}))(LinearProgress);

const BorderLinearProgressRed = withStyles((theme) => ({
    root: {
        height: 12,
        borderRadius: 5,
    },
    colorPrimary: {
        background: '#EFF3FB'
    },
    bar: {
        borderRadius: 5,
        background: 'linear-gradient( 217.76deg, #FF4D4D 3.07%, #F4713D 81.8%)',
        transition:'all 0.4s'
    },
}))(LinearProgress);


let gray_color_min_line_progress = '#a6b1bf';
const LineProgress = (props) => {
    let size = useWindowSize();
    const [max_width, set_max_width] = React.useState(400);
    const block_ref = React.useRef(null);
    let val_prog_ = props.valProg< props.min ? props.min : props.valProg;
    let is_gray = props.valProg< props.min;

    React.useEffect(()=>{
        if (block_ref.current&&size.width) {
            set_max_width(block_ref.current.offsetWidth - 80)
        }
    }, [size.width])


    window.increment_ = ()=>val_prog_+=20

    return (
    <div
        ref={block_ref}
        className="wrap_line_block" id={"lt"+props.number}>
        <div className={"line_upper_block"} >
          <span
              className={'line_upper_block_text'}
              style={{
                  maxWidth: max_width,
                  color: is_gray ? gray_color_min_line_progress : '#5E5AB9'}}>{
              glv('first_level_blocks.statistic.linear.'+('l'+props.number)+'.min')
          }</span>

              <span

                  className=" line_upper_block_text prots_state "
                 style={{color: is_gray ? gray_color_min_line_progress : '#5E5AB9'}}>{
                     window.number_to_beauty_string(val_prog_)
                  // format(val_prog_, { precision: 3 })
                     }
                  <span className=" line_upper_block_text prots_max"
                        style={{color: is_gray ? gray_color_min_line_progress : '#5E5AB9'}}>{
                      glv('first_level_blocks.statistic.linear.'+ ('l'+props.number) + '.curr_val')
                  }</span>
              </span>

        </div>

       <div style={{    boxShadow: '0 0.4vh 1vh rgb(0 0 0 / 4%), -0.3vh -0.5vh 0.8vh rgb(255 255 255 / 38%)',
        padding: '5px',
         background:'#eff3fb',
           opacity: is_gray ? '0.6': '',
         borderRadius: 12,}}>
           {
               props.color ==='red' ?
                   <BorderLinearProgressRed variant="determinate" value={props.valProg > props.max ? 100 : props.valProg*100/ props.max} />
                   :        <BorderLinearProgressBlue variant="determinate" value={props.valProg > props.max ? 100 : props.valProg*100/ props.max} />

           }
       </div>
      
        <div className="line_bottom_block">
          <span className={'line_bottom_block_text'}>{props.min}</span>
          <span className={'line_bottom_block_text'}>{
              window.number_to_beauty_string(props.max)
              // format(props.max, { precision: 3 })
              }</span>
        </div>
      </div>
    )
}

export default LineProgress;