import React from "react";
import Collapse from '@material-ui/core/Collapse';
import {glv} from "../../App";
import LightButton from "./light_button";


const LightSelectButton = (props) => {
    const [is_active_btn, set_is_active_btn] = React.useState(false);
    const [is_cols, set_is_cols] = React.useState(false);
    let cust_style= props.style ? props.style : {};
    let cust_style_text= props.style_text ? props.style_text : {};
    let cls = props.cls ? props.cls : '';

    return (
        <div className={`wrap_select_light_btn ${is_cols? 'cols_' : ''}`}>
            {
                is_cols ? null:
                <button
                    type={'button'}
                    // onClick={props.disabled ? ()=>{}:()=>props.on_click()}
                    className={`light_button select_btn ${is_active_btn? 'active' : ''}`}
                    style={cust_style}
                >

                    <div className={'wrapper_light_select_btn_text'}
                         onMouseDown={()=>{
                             set_is_active_btn(true)
                         }}

                        // onClick={()=>{
                        //     set_is_active_btn(!is_active_btn)
                        // }}
                         onMouseLeave={()=>{
                             set_is_active_btn(false)
                         }}
                         onMouseUp={()=>{

                             set_is_active_btn(false)
                             window.location.href = props.main_redirect;
                         }}
                    >
                       <span
                           style={cust_style_text}
                           className={'light_button_text'}>
                      {props.text}
                     </span>
                    </div>
                    <div
                        onClick={()=>{
                            set_is_cols(true)
                        }}
                        style={{opacity: is_active_btn ? 0 : 1}}
                        className={'wrapper_light_select_btn_icon'}>
                        <svg
                            style={{
                                transform: `rotate(${0}deg)`,
                                transition: 'transform 0.4s, fill 0.4s',
                                // fill: "#5E5AB9"
                            }}
                            className={'icon_arrow_select_btn'}
                            width="22" height="12" viewBox="0 0 22 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M21.6484 1.00295L20.9277 0.332829C20.7009 0.123552 20.3992 0.00825896 20.0767 0.00825896C19.7544 0.00825896 19.4523 0.123552 19.2255 0.332829L11.0049 7.92462L2.77488 0.324405C2.54844 0.115127 2.24634 0 1.92403 0C1.60172 0 1.29944 0.115127 1.07282 0.324405L0.352003 0.990392C-0.117334 1.42348 -0.117334 2.12895 0.352003 2.56204L10.1508 11.6435C10.3773 11.8527 10.679 12 11.0042 12H11.008C11.3304 12 11.6322 11.8525 11.8586 11.6435L21.6484 2.58665C21.8752 2.37754 21.9996 2.09046 22 1.79281C22 1.495 21.8752 1.21189 21.6484 1.00295Z"
                                />
                        </svg>
                    </div>


                </button>
            }
            <Collapse in={is_cols}>
                {
                    props.cols.map((item, index)=>{
                        let url_path = item.type==='fiz' ? `/${item.id_dev}` : `/pro/${item.id_dev}`;
                        let is_first_item = index===0;
                        let first_item_style={
                            marginTop: is_first_item ? 0 : 20,
                            display: 'flex',
                            justifyContent: 'center'
                        }
                        let text = item.type==='fiz' ? item.name :
                            <span>
                                <span style={{fontWeight: 500}}>
                                   {item.id_dev}:
                                </span>
                                <span style={{fontWeight: 'bold'}}>
                                   {' '}{item.name}
                                </span>
                            </span>

                        return (
                            <LightButton
                                key={item.id_dev+index}
                                style = {first_item_style}
                                on_click={
                                    () => {
                                        window.location.href = 'https://' + props.domain + url_path
                                    }
                                }
                                cls={'route_btn'}
                                text={
                                    text
                                }
                            />
                        )
                    })
                }
            </Collapse>

        </div>

    );
};

export default LightSelectButton;
