import React, { useEffect, useCallback, useState } from "react";
import PurpleButton from "../../custom_components/purple_button";
import LightButton from "../../custom_components/light_button";

const ActionsModalButtons = (props) => {

    // скрытие кнопок
    let hided_class_canc = !props.has_cancel ? 'hided' : '';
    let hided_class_acc = !props.has_accept ? 'hided' : '';

    // отступы кнопок
    let ml = props.has_accept  ? 'ml_modal_btn' :'';
    let mr = props.has_cancel  ? 'mr_modal_btn' :'';

    // отключение функциональности кнопок
    let disabled_acc_btn_cls = props.disabled_acc_btn ? 'disabled' : '';

    let custom_style= props.style ? props.style : {};
    return (
        <div
            className={`wrap_actions_btns_modal`}
            style={{...custom_style}}
        >
            <PurpleButton
                on_click={() => props.accept_action()}
                cls_outer={`accept_btn ${mr} ${hided_class_acc} ${disabled_acc_btn_cls}`}
                // cls_inner={}
                style={{minHeight:50}}
                disabled={props.disabled_acc_btn}
                text={props.text_accept_btn}
            />

            <LightButton
                on_click={() => props.cancel_action()}
                cls={`cancel_btn ${ml} ${hided_class_canc}`}
                text={props.text_cancel_btn}
                style_text={{fontSize: 24, fontWeight:600, fontStyle:'normal'}}
            />
        </div>
    );
};

export default ActionsModalButtons;
