import React, { useRef, useEffect } from 'react'
import { Tooltip } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../redux/actions/env.actions";
import { Fade } from '@material-ui/core';
import uuid from 'react-uuid'
import ShemeModal from '../modals/sheme_element_modal/shemeElementModal';
import useMousePosition from '../../hooks/useMousePosition';
const values  ={
  'blue_trumpet': 'F1-OUT',
  'purple_trumpet': 'F1-IN',
  'stock1': 'R1-T5',
  'stock2': 'R1-T6',
  'stock3': 'R2-T5',
  'stock4': 'R2-T6',
  'stock5': 'R3-T5',
  'stock6': 'R3-T6',
}
const debounce = require('debounce');
const Circle = props => {
  const dispatch = useDispatch();

  const bakRef = useRef(null);
  const overModal = useSelector((state) => state.envReducer.overModal);
  const blurStatus = useSelector((state) => state.envReducer.blurElems);
  const canvasRef = useRef(null);
  const canvasRefShadow = useRef(null);
  const [hover, setHover] = React.useState(false);
  const [highlightColor, setHighlightColor] = React.useState('');
  const currTrumpet = useSelector((state) => state.envReducer.currTrumpet);

  const { x, y } = useMousePosition();

  
  const setShemeModal = React.useCallback((color) => dispatch(actions.setShemeModal(color)), [
    dispatch,
  ]);
  const [coord, setCoord] = React.useState(null);
  
  const setShemeModalData = React.useCallback((color) => dispatch(actions.setShemeModalData(color)), [
    dispatch,
  ]);
  const setCurrTrumpet = React.useCallback((color) => dispatch(actions.setCurrTrumpet(color)), [
    dispatch,
  ]);
  const setBlur = React.useCallback((status) => dispatch(actions.setBlur(status)), [
    dispatch,
  ]);
  const [idShadow, setIdShadow] = React.useState(uuid());
  // useEffect(() => {
  //   const canvas = canvasRef.current
  //   const ctx = canvas.getContext('2d')

  //   ctx.beginPath();
  //   ctx.fillStyle = props.color;
  //   ctx.arc(ctx.canvas.width/2, ctx.canvas.height/2, ctx.canvas.width/2-1, 0, 2 * Math.PI);
  //   // ctx.stroke();
  //   ctx.fill();
  // }, [])

  useEffect(() => {
    if (overModal) {
      return
    }
    if (hover&&coord) {
      // setHighlightColor("#3ecd83");
      setShemeModalData({...props, x:coord.x, y: coord.y});
      setShemeModal(true);
      setCurrTrumpet(props.id);
      setBlur(true)
    } else {
   
      setShemeModal(false);
      // setHighlightColor("");
      setCurrTrumpet(props.id);
      setBlur(false)
    }

  }, [hover,coord,overModal])

  
    

  
  return <div style={{...props.styleWrap, cursor: 'pointer'}}
  ref={bakRef}
  onMouseOver={debounce((e) => {
    setCoord({x:e.clientX, y: e.clientY})
    if (overModal) {
      return
    }
  setHover(true)
  },300)}
onMouseLeave={debounce(() => {
setHover(false)
},200)}
  > 
{/* {hover&&props.id ? (
     <ShemeModal {...props}/>
      ) : null} */}

      
    <div style={{transition: 'all 0.2s',width: props.width, height: props.height, background: props.color, borderRadius: '50%',filter: blurStatus&&currTrumpet!==props.id?'blur(2px) contrast(30%)':'none'}}></div>
     {/* <canvas ref={canvasRef} {...props} style={{ filter: blurStatus&&currTrumpet!==props.id?'blur(2px)':'none'}}/> */}
  
 </div> 
}

export default Circle;