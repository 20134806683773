
import {formatSI} from 'format-si-prefix';
import get_beautiful_date
    from "../components/plugins/calendar/services/calendar_component_service/get_beautiful_serial_number";

let MobileDetect = require('mobile-detect')

export let format_to_beautiful_date_ = (date, lang) =>  {
    let day = get_beautiful_date(date.getDate());
    let month_num_val = date.getMonth();
    let month = date.toLocaleString(lang ? lang : 'default', {month: 'long'});
    // let month = monthes[month_num_val].full_name;
    let year = date.getFullYear();

    let hours = get_beautiful_date(date.getHours());
    let mins = get_beautiful_date(date.getMinutes());

    return `${day}/${month}/${year} ${hours}:${mins}`;
}

export function custom_assoc_path(path, value, obj) {
    let obj_copy = {...obj};

    if (path.length) {
        let path_temporary_res = {};
        path.forEach((key, index)=>{
            if (index===path.length-1){
                path_temporary_res[key]=value;
                return
            }

            path_temporary_res = obj_copy[key]
        })
    }
}


export function get_cheked_val(val, max, min) {
    let checked_val =val>max? max :
        val<min?min :
            val;

    return checked_val
}

export function get_corrected_text_value(value, is_min_max) {
    if (value===null||value===undefined) {
        return '0'
    }
    let is_minus = value<0? true : false;
    let value_ = Math.abs(value);

    let return_val = ()=>{
        if (is_minus) {
            value_='-'+value_
        }
        return value_.replace('.', ',')
    }

    if (value_<10) {
        if (is_min_max){
            value_= value_.toFixed(0)
        } else {
            value_= value_.toFixed(2)
        }

        return return_val();
    }
    if (value_<100) {
        if (is_min_max){
            value_= value_.toFixed(0)
        } else {
            value_= value_.toFixed(1)
        }
        return return_val();
    }
    if (value_<1000) {
        value_= value_.toFixed(0)
        return return_val();
    }

    if (value_>=1000) {
        value_=  formatSI(Number(value_.toFixed(0)));
        return return_val();
    }

}

export const detect_device = () =>{
   let detect  = new MobileDetect(window.navigator.userAgent);
   return detect.mobile();
}

export const minus_hours = (date_, hours) =>{
    let date = date_;
    date.setHours(date.getHours()-hours);
    return date
}

export function formatDate(date, format) {
    const map = {
        mm: date.getMonth() + 1 < 10 ? '0'+ (date.getMonth() + 1) : date.getMonth() + 1,
        dd: date.getDate() < 10 ? '0'+ date.getDate() :date.getDate() ,
        yy: date.getFullYear(),

        hh: date.getHours() < 10 ? '0'+ date.getHours() :date.getHours() ,
            // date.getMonth() + 1 < 10 ? '0'+ (date.getMonth() + 1) : date.getMonth() + 1,
        mn: date.getMinutes() < 10 ? '0'+ date.getMinutes() :date.getMinutes() ,
        ss:  date.getSeconds() < 10 ? '0'+ date.getSeconds() :date.getSeconds() ,

        yyyy: date.getFullYear(),
    }

    return format.replace(/mm|dd|yy|hh|mn|ss/gi, matched => map[matched])
}

export function get_max_length(arr){
    let max_ = 0;
    arr.forEach((arr_)=>{
        if (arr_.length>max_) {
            max_ = arr_.length;
        }
    })

    return max_>3  ? 3 : max_;
}

export function create_arr_keys_for_rend_indicators(arr, vals, dimensions, is_all_indicators, two_column){
    let rend_arr = arr.map(item_arr=>item_arr);

    // console.log(rend_arr, 'asddvfsd')
    let del_coff = is_all_indicators? two_column ? 3 : 1 : get_max_length(rend_arr);
    if(!rend_arr.length){
        rend_arr = [];
        let temp_arr = [];
        let ityy=0;
        Object.keys(vals).map((key, index)=>{
            ityy++;
            temp_arr.push(key)

            if (ityy === del_coff) {
                rend_arr.push(temp_arr)
                ityy=0;
                temp_arr=[];
            }

        })
        if (temp_arr.length) {
            rend_arr.push(temp_arr)
        }
    }


    const {width, height, ratio} = dimensions;

    let sum_indicators = Object.keys(vals).length
    let del = sum_indicators/del_coff;

    let rows=del<=1 ? 1 : Math.ceil(del);

    let w = width/del_coff ;
    w -= 4;

    let check_  = height<w;
    let f_cof = w/60;
    rend_arr =
        // is_all_indicators ? rend_arr.flat().map(item=>[item]) :
            rend_arr;
    // console.log(rend_arr)
    return {
        f_cof,
        arr_to_rend: rend_arr
    };
}

export function ColorToHex(color) {
    let hexadecimal = color.toString(16);
    return hexadecimal.length == 1 ? "0" + hexadecimal : hexadecimal;
}

export function ConvertRGBtoHex(red, green, blue) {
    return "#" + ColorToHex(red) + ColorToHex(green) + ColorToHex(blue);
}

export function get_coff(width, height) {

    let w =
        // indow.innerWidth
        // del<1? width/sum_indicators :
        width/1 ;
    // if ( w -4>0){
    w -= 3;
    // }

    let f_cof = w/30;
    return f_cof
}

export function isEqualObjects(object1, object2) {
    const props1 = Object.getOwnPropertyNames(object1);
    const props2 = Object.getOwnPropertyNames(object2);

    if (props1.length !== props2.length) {
        return false;
    }

    for (let i = 0; i < props1.length; i += 1) {
        const prop = props1[i];
        const bothAreObjects = typeof(object1[prop]) === 'object' && typeof(object2[prop]) === 'object';

        if ((!bothAreObjects && (object1[prop] !== object2[prop]))
            || (bothAreObjects && !isEqualObjects(object1[prop], object2[prop]))) {
            return false;
        }
    }

    return true;
}