import React from "react";
import '../../styles/calendar/day_block.css'

const DayBlock = (props) => {
    let is_selected_class = props.is_selected ? ' selected_day' : '';
    let disabled_class = props.is_disabled ? ' disabled_day' : '';
    let not_curr_month = props.is_curr_month ? '' : ' not_is_curr_month';
    // let animation_type=props.animation_trigger==='prev' ? 'showBlockPrev'
    //     : props.animation_trigger==='next' ?  'showBlockNext' :'showBlockCurr';

    return (
        <div
            year={props.year}
            month={props.month}
            onClick={disabled_class ? () => {
            } : props.on_click}
            key={props.id}
            // style={{animation: `${animation_type} 5s  forwards`}}
            className={'wrap_day' + disabled_class + is_selected_class + not_curr_month}
        >
            <span className={`day_text ${is_selected_class}`}>{props.day_text}</span>
        </div>
    )
}

export default DayBlock;