import React from "react";

import { useSelector } from "react-redux";
import './styles/vd_header.css';
import HeaderBarVD from "./header_bar_vd";
import HeaderMenuBlockVD from "./menu_block_vd";
import {glv} from "../../app_components/helper_functions/helper_functions";
import {open_lang_modal_context} from "../../../App";
import {LeftArrow, RightArrow} from "../../../assets/svg_images";





const HeaderVD = (props) => {
    //vertical side bar


   const [hided, set_hided] = props.hided_data;

    return (
        <HeaderBarVD position="static" style={{background: "#5E5AB9"}} is_hided={hided}>
            <div
                className={'wrap_logo_and_title'}
                style={{
                     padding: hided ? 16 : '',
                     transition: 'padding 0.4s'
                 }}
            >
                <div
                    onClick={()=>{
                        window.location.href = '/router_page'
                    }}
                    className={'wrap_header_logo_vd'} style={{

                }}>
                    <img
                        style={{
                            width: hided ? 32 : '',
                            height: hided ? 32 : '',
                            // display: hided ? 'none' : ''
                        }}
                        className={'header_logo_vd'}
                        src={`${process.env.PUBLIC_URL}/favicon.ico`}
                        onClick={() => {
                            // window.location.reload();
                        }}
                    />
                </div>
                <div className={'wrap_header_title_vd'} style={{}}>
                    <span className={'header_title_vd'}
                          style={{
                              marginLeft: hided ? 100 : '',
                              transition: 'margin 0.4s',
                              // display: hided ? 'none' : ''
                          }}
                    > {
                         props.title
                     }</span>
                </div>
                {/*<HeaderTitle title_text={props.title}/>*/}
            </div>


            <div className={'wrap_menu_struct_blocks_vd'}>

                <div className={'wrap_menu_blocks_vd'}>
                    {
                        props.punkts.map(punkt=>{
                            return (
                                <HeaderMenuBlockVD
                                    child={punkt.child ? punkt.child : null}
                                    hided={hided}
                                    id_name={punkt.id}
                                    action={()=>punkt.action()}
                                    title={glv('header.menu_buttons.'+punkt.id)}
                                />
                            )
                        })
                    }
                </div>
                <div className={'wrap_menu_hide_btn_vd'}  onClick={()=>{set_hided(!hided)}}>
                    <div className={'menu_hide_btn_vd'}>
                        <div className={'wrap_icon_menu_hide_btn_vd'}>
                            <LeftArrow class={`left_arr_icon_vd ${hided ? 'opened' : ''}`}/>
                        </div>

                        <div className={'wrap_menu_hide_btn_title_vd'}>

                            <span className={'menu_hide_btn_title_vd'}>
                                         {
                                              hided ? glv('header.menu_buttons.collapse')
                                                 : glv('header.menu_buttons.uncollapse')
                                          }
                        </span>
                        </div>

                    </div>

                </div>

            </div>

        </HeaderBarVD>

    );
}
export default HeaderVD;
