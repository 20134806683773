import {assocPath, path} from "ramda";
import {localization_tree} from "../../../App";
import Cookies from "js-cookie";
import moment from "moment";
import sha1 from "sha1";



// функция записи ошибок в буфер обмена локалсторэйджа

export const setlogos = (error, point) => {
    let login = Cookies.get("hash_key", { domain: ".bregus.eu" });

    let errforLog ={
        time: moment().unix(),
        login: login ? login : "",
        point,
        error
    }
        // window.constructor_error(moment().unix(), login ? login : "", point, error)

    let logos = JSON.parse(localStorage.getItem("logos"));
    if(!logos) {
        logos = {key: '', arr: []}
    }

    logos.arr.push(errforLog);
    localStorage.setItem(
        "logos",
        JSON.stringify({ key: sha1(logos.arr), arr: logos.arr })
    );
};


// функция получения значения с языкового дерева

export function glv(path_local, type='str', can_send_log=false) {
    // console.log(path_local)
    let parsed_path = path_local.split('.');
    let path_value = path(parsed_path, localization_tree);
    let error_r = false;

    if (path_value===undefined) {
        switch (type) {
            case 'arr':
                path_value = [];
                break;
            case  'obj':
                path_value = {};
                break;
            case  'str':
                path_value = path_local;
                break;
            default :
                path_value = "NaN";
        }

        error_r = true;

    }else{
        if(type === 'arr' && !Array.isArray(path_value) ){ error_r = true; path_value = [];}
        else if(type === 'obj' && typeof path_value != 'object' ){ error_r = true; path_value = {};}
        else if(type === 'str' && typeof path_value != 'string' ){ error_r = true; path_value = path_local;}

    }

    if (!can_send_log && error_r) setlogos(
        {description: 'по заданному пути нет значения', path: path_local, type: type},
        'ошибка при попытке получить языковое значение по заданному пути'
    );


    return path_value;
}

