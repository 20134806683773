import React from 'react';

const LaunchRegenModalContentComponent = ({sys_list, select_sys, current_sys}) => {
    return (
        <div
            style={
                {}
            }
        >
            {sys_list.map((item, index) => {
                let is_selected = current_sys&&current_sys.id_ === item.id_;
                  return  (
                        <button
                            key={index + 'lang' + item.id_}
                            onClick={() => {
                                select_sys(item)
                            }}
                            className={`light_button lang_btn lang_to_select_btn ${ is_selected? 'selected_lang' : ''}`}
                            style={{position: 'relative'}}
                        >

                    <span className={'light_button_text'}>
                             {item.name}
                     </span>

                        </button>
                    )
                }
            )}


        </div>
    );
};

export default LaunchRegenModalContentComponent;