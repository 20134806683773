import React, {useRef, useEffect, useCallback, useState} from "react";

import {
    Events,
    animateScroll as scroll,
} from "react-scroll";
import ProService from '../../../../services/ProPageService';
import {useDispatch, useSelector} from "react-redux";
import ReportModal from '../../../modals/report_modal/ReportModal';
import * as actions from "../../../../redux/actions/env.actions";
import Backdrop from "@material-ui/core/Backdrop";
import CustomImageLoader from "react-custom-image-loader.";
import Cookies from 'js-cookie';
import moment from 'moment';
import useMediaQuery from '@mui/material/useMediaQuery';
import HelpModal from '../../../modals/help_modal/HelpModal';
import appInfo from "../../../../../package.json";
import ShemeModal from '../../../modals/sheme_element_modal/shemeElementModal';
import {IS_DESKTOP_CONTEXT, lang_conf, open_lang_modal_context, set_env_urls,} from "../../../../App";
import {glv, setlogos} from "../../../../components/app_components/helper_functions/helper_functions";
import ManomentryComponent from "../../../manometrs_block/Manometrs";
import ChartComponent from "../../../charts_components/chart_block/Chart";
import ShemeComponent from "../../../shemes/svg_shemes/Sheme";
import StatisticComponent from "../../../statistic_components/Statistic";
import TableComponent from "../../../tables/Table";
import ConfirmModal from "../../../modals/confirm_modal/ConfirmModal";
import GagPub from "../../../pubs/gag_pub";
import {isPartiallyVisible} from "../../../../helper_functions/scroll_service";
import PointScrollRef from "../../../scroll_ref_service_components/scroll_ref_point";
import HeaderVD from "../../../header_components/vertical_desktop/header_vd";
import HeaderHD from "../../../header_components/horizontal_desktop/header_hd";
import SelectLangModal from "../../../modals/select_lang_modal/select_lang_modal";
import {useSwipeable} from "react-swipeable";
import HeaderVM from "../../../header_components/vertical_mobile/header_vm";
import HeaderHM from "../../../header_components/horizontal_mobile/header_hm";
import SettingModal from "../../../modals/setting_modal/SettingModal";
import useWindowSize from "../../../../hooks/useWindowSize";
import {blocks_ids_list, bufer_indicators, set_bufer_indicators, template_setting_form} from "./static_datas";
import ChartParametrsModal from "../../../modals/chart_settings_modal/chart_setting_modal";
import SchemeComponent from "../../../shemes/scheme.component";




const ProPage772238 = (props) => {
    const {width, height} = useWindowSize();
    const is_mobile_width = useMediaQuery('(max-width:600px)');
    const dispatch = useDispatch();
    const [selectedscrollPoint, setSelectedScrollPoint] = React.useState('');
    const [open_setting_modal, set_open_setting_modal] = React.useState(false);
    const [open_lang_modal, set_open_lang_modal] = React.useContext(open_lang_modal_context);

    const R1ref = useRef(null);
    const R2ref = useRef(null);

    const [header_menu_punkts, set_header_menu_punkts] = useState([
        {
            id: 'report', action: () => {
                setOpenReportModal(true)
            }
        },
        // {
        //     id: 'setting', action: () => {
        //         set_open_setting_modal(true)
        //     }
        // },
        {
            id: 'info',
            action: () => {
                setOpenHelpModal(true)
            },
        },
        {
            id: 'select_lang', action: () => {
                set_open_lang_modal(true)
            }
        },
        {
            id: 'logout', action: () => {
                set_open_confirm_modal(true)
            }
        },
    ]);

    const [setting_form, set_setting_form] = useState({...template_setting_form});

    const is_desktop = React.useContext(IS_DESKTOP_CONTEXT);
    const swipe_handlers = useSwipeable({
        trackMouse: true,
        onSwipedRight: () => set_hided(false),
        onSwipedLeft: () => set_hided(true)
    });

    const [openBackdrop, setOpenBackdrop] = React.useState(false);

    const [openReportModal, setOpenReportModal] = React.useState("");
    const [open_confirm_modal, set_open_confirm_modal] = React.useState(false);
    const [openHelpModal, setOpenHelpModal] = React.useState("");

    const [ids, set_ids] = React.useState(null);
    const [added_padding_left_container, set_added_padding_left_container] = React.useState(0);
    const [indicatorsData, setIndicatorsData] = React.useState(null);
    const [hided, set_hided] = React.useState(true);
    const [accessData, setAccessData] = React.useState(null);
    const [online_offline_inds_statuts, set_online_offline_inds_statuts] = React.useState(null);
    const hash = useSelector((state) => state.envReducer.hash);
    const urls = useSelector((state) => state.envReducer.urls);



    const handleScroll =useCallback( (e) => {

        // функция вызывающаяся при событии скролла
        // устанавливает значение блока к которому пользователь доскроллил

        blocks_ids_list.forEach((id_, index)=>{
            if (isPartiallyVisible(document.getElementById(id_))) {
                setSelectedScrollPoint(id_)
                return
            }
        })
    }, []);


    const create_bufer_indicator = (data) => {
        // создание буфера индикатора по шаблону

        let new_bufer_obj = {};
        let new_bufer_temp = {
            min: 0,
            max: 1000,
            val: 0,
            old_val: 0,
            trig_movie: {
                stars: false,
                animac: false
            }
        };
        Object.keys(data).forEach(item => {
            new_bufer_obj = {...new_bufer_obj, [item]: new_bufer_temp}
        })

        set_bufer_indicators(new_bufer_obj);
    }

    const set_indicators_data = (data) => {
        // устанавливаются значения индикатора

        let copy_data_values = {...data.values};
        if (!bufer_indicators) {
            // console.log('created bufer maniak')
            create_bufer_indicator(copy_data_values);
        }
        // console.log(bufer_indicators)

        Object.keys(copy_data_values).forEach(item => {
            // console.log(bufer_indicators, item)
            let val = bufer_indicators[item].val;
            let max = bufer_indicators[item].max;
            let min = bufer_indicators[item].min;

            if (copy_data_values[item].value !== null
                && copy_data_values[item].value !== undefined) {
                bufer_indicators[item].old_val = val;
                bufer_indicators[item].val = copy_data_values[item].value;
            }
            if (copy_data_values[item].sys_info.max !== null
                && copy_data_values[item].sys_info.max !== undefined) {

                bufer_indicators[item].max = copy_data_values[item].sys_info.max;
            }
            if (copy_data_values[item].sys_info.min !== null
                && copy_data_values[item].sys_info.min !== undefined) {

                bufer_indicators[item].min = copy_data_values[item].sys_info.min;
            }

            if (bufer_indicators[item].val !== val) {
                bufer_indicators[item].trig_movie.stars = true;
            }
            if (bufer_indicators[item].max !== max) {
                bufer_indicators[item].trig_movie.stars = true;
            }
            if (bufer_indicators[item].min !== min) {
                bufer_indicators[item].trig_movie.stars = true;
            }

            // if(bufer_indicators[item].trig_movie.stars && !bufer_indicators[item].trig_movie.animac){
            //   bufer_indicators[item].trig_movie.animac = true;
            // }
        })
        // console.log(bufer_indicators)
        setIndicatorsData({...data})
    }

    window.set_ind = (nw) => {
        set_indicators_data({
            ...indicatorsData,
            values: {
                ...indicatorsData.values,
                '4697ayej7jb65p9z': {
                    ...indicatorsData.values['4697ayej7jb65p9z'],
                    value: nw
                }
            }
        })
    }

    function getData() {
        // запрашивается данные индикатора

        ProService.getIndicatorsData((data) => set_env_urls(data), hash,
            // 772238
            props.match.params.id
        ).then((res) => {
            if (res && (res.code === 200 || res.code === 201) && res?.info?.constructor === Object) {
                let o_of = {};

                Object.keys(res.info.values).map((item) => {
                    let rf_ = res.info.values[item];
                    let status = (moment().unix() - rf_.timestamp_upd) / 1000 / 60 <= 20;
                    o_of[item] = status;
                    o_of[rf_.name] = status;
                })
                set_online_offline_inds_statuts(o_of)
                set_ids(Object.keys(res.info.values).map(item => ({id_: item, name: res.info.values[item].name})))
                set_indicators_data({...res.info})

            }
        }).catch((error) => {
            setlogos(JSON.stringify({
                error: error.name,
                info: error.message
            }), "request error on pro page when get indicators data");
        });
        setTimeout(() => {
            getData();
        }, 5000);
    }

    function getAccessedData() {


        ProService.getAccessData((data) => set_env_urls(data),
            // 772238
            hash,
            props.match.params.id
        ).then((res) => {

            if (res.code === 400 || res.code === 409) {
                window.location.href = 'https://i.bregus.eu/page_info#404';
            }

            setAccessData({...res.info, datetime: res.datetime})
        }).catch((error) => {
            setlogos(JSON.stringify({
                error: error.name,
                info: error.message
            }), "request error on pro page when get access data");
        });

    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, true);

        getAccessedData();

        return ()=> {
            window.removeEventListener('scroll', handleScroll);
        }
    }, []);

    useEffect(() => {
        let get_header_setting_ls = localStorage.getItem('setting_form')
        if (get_header_setting_ls) {
            let old_saved_setting_form = JSON.parse(get_header_setting_ls);
            set_setting_form({...old_saved_setting_form})
        } else {
            localStorage.setItem('setting_form', JSON.stringify(template_setting_form))
        }

        window.addEventListener('scroll', handleScroll, true);

        getAccessedData();

        return ()=> {
            window.removeEventListener('scroll', handleScroll);
        }
    }, []);

    useEffect(() => {

        if (accessData) {
            document.title = 'Bregus | pro: ' + accessData.name;
            getData();

        }
    }, [
        accessData
    ]);

    useEffect(() => {
        if (is_desktop&& !setting_form.header_position.desktop_is_horizontal) {
            if (hided) {
                set_added_padding_left_container(27)
            } else {
                set_added_padding_left_container(165)
            }
        } else {
            set_added_padding_left_container(0)
        }
    }, [
        is_desktop ,
        setting_form.header_position.desktop_is_horizontal,
            hided
    ]);


    let padding_sum_content_blocks = !is_desktop ? 50 : 100;
    let max_width_blocks = width-padding_sum_content_blocks-added_padding_left_container;

    let swipe_data = (!is_desktop
        && !setting_form.header_position.mobile_is_horizontal) ? swipe_handlers : {}
    return indicatorsData && accessData && online_offline_inds_statuts && ids ? (
            <div style={{
                // minWidth: 1200,
            }}
                 {...swipe_data}
            >

                {/* loader */}
                <Backdrop style={{zIndex: 55455}} open={openBackdrop}>
                    <CustomImageLoader
                        image={`${process.env.PUBLIC_URL}/favicon.ico`}
                        isLoaded={true}
                        circle={false}
                        speed={2}
                        animationType={"pulse"}
                    />
                </Backdrop>

                <>


                {is_desktop ?
                    setting_form.header_position.desktop_is_horizontal ? (
                    <HeaderHD
                        punkts={header_menu_punkts}
                        title={accessData.name}
                        report_btn_action={() => {
                            setOpenReportModal(true)
                        }}
                        info_btn_action={() => {
                            setOpenHelpModal(true)
                        }}
                        lang_btn_action={() => {
                        }}
                        logaut_btn_action={() => {
                            set_open_confirm_modal(true)
                        }}
                    />
                ) : (
                    <HeaderVD
                        hided_data={[hided, set_hided]}
                        punkts={header_menu_punkts}
                        title={accessData.name}
                        report_btn_action={() => {
                            setOpenReportModal(true)
                        }}
                        info_btn_action={() => {
                            setOpenHelpModal(true)
                        }}
                        lang_btn_action={() => {
                        }}
                        logaut_btn_action={() => {
                            set_open_confirm_modal(true)
                        }}
                    />
                ) : setting_form.header_position.mobile_is_horizontal ?
                    <HeaderHM
                        hided_data={[hided, set_hided]}
                        punkts={header_menu_punkts}
                        title={accessData.name}
                        report_btn_action={() => {
                            setOpenReportModal(true)
                        }}
                        info_btn_action={() => {
                            setOpenHelpModal(true)
                        }}
                        lang_btn_action={() => {
                        }}
                        logaut_btn_action={() => {
                            set_open_confirm_modal(true)
                        }}
                    /> : (
                        <HeaderVM
                            hided_data={[hided, set_hided]}
                            punkts={header_menu_punkts}
                            title={accessData.name}
                            report_btn_action={() => {
                                setOpenReportModal(true)
                            }}
                            info_btn_action={() => {
                                setOpenHelpModal(true)
                            }}
                            lang_btn_action={() => {
                            }}
                            logaut_btn_action={() => {
                                set_open_confirm_modal(true)
                            }}
                        />
                    )
                }

                </>
                {/*модалочки*/}

                <>
    <SettingModal
        is_desktop={is_desktop}
        setting_form={[setting_form, set_setting_form]}
        open={open_setting_modal}
        close={() => set_open_setting_modal(false)}
    />



    <ConfirmModal
        open={open_confirm_modal}
        close={() => set_open_confirm_modal(false)}
        content={glv('second_level_blocks.confirm_modal.logaut_text')}
        f_to_will_confirm={
            async () => {
                // let hash = await Cookies.get("hash_key", {
                //     domain: ".bregus.eu",
                // });
                ProService.log_out(set_env_urls, hash)
                    .then(() => {
                        Cookies.remove("hash_key", {
                            domain: ".bregus.eu",
                        });
                        window.location.href = urls.login;
                    });
            }
        }/>

    <ShemeModal
        current_state={
            moment(accessData.dates.now).diff(moment.unix(accessData.dates.last_upd), "minute") >= 1
                ? 'offline'
                : moment.unix(accessData.dates.last_upd).format('ll HH:mm')
        }
        onl_offl={online_offline_inds_statuts}
        indicatorsData={indicatorsData}
    />
    <HelpModal
        current_state={
            moment(accessData.dates.now).diff(moment.unix(accessData.dates.last_upd), "minute") >= 1
                ? 'offline'
                : moment.unix(accessData.dates.last_upd).format('ll HH:mm')
        } sys_name={accessData.name}
        sys_id={props.match.params.id}

        use_date={moment.unix(accessData.dates.install).format("ll")}
        open={openHelpModal}
        close={() => setOpenHelpModal(false)}
        lat={accessData?.coordinate?.n ? Number(accessData.coordinate.n.replace(/#/g, '')) : 0}
        lng={accessData?.coordinate?.e ? Number(accessData.coordinate.e.replace(/#/g, '')) : 0}
    />
    <ReportModal open={openReportModal} close={() => setOpenReportModal(false)}
                 id={props.match.params.id} {...props} openOvl={(status) => {

        setOpenBackdrop(status)
    }}/>
</>


                {/*scroll refs*/}

                <div style={{position: 'fixed', right: 20, top: '50%', display: is_desktop ? 'flex' : 'none', flexDirection: 'column'}}>
                    {
                        blocks_ids_list.map((item, index)=>{
                          return           accessData.access_control[item].visible ?
                              <PointScrollRef
                                  tooltip={
                                      glv(`first_level_blocks.block_titles.${item}`)
                                  }
                                  what_bg={selectedscrollPoint === item}
                                  click_action={() => {
                                      setSelectedScrollPoint(item)
                                      document.getElementById(item).scrollIntoView({block: "center", behavior: "smooth"})
                                  }}
                              /> : null
                        })
                    }
                </div>

                {/*main blocks*/}
                <>


                <div style={{
                    // paddingTop: !is_desktop&&is_horizontal ? 80 : '',
                    paddingLeft: added_padding_left_container,
                    transition: 'padding 0.4s'
                }}>

                    {
                        accessData.access_control.manometry.visible ?
                                <ManomentryComponent
                                    max_width={max_width_blocks}
                                    manomentry_block_max_width={max_width_blocks}
                                    setting_data={setting_form}
                                    is_desktop={is_desktop}
                                    onl_offl={online_offline_inds_statuts}
                                    accessData={accessData}
                                    indicatorsData={indicatorsData}/>
                            : null
                    }

                    {
                        accessData.access_control.chart.visible ?
                                <ChartComponent
                                    max_width={max_width_blocks}
                                    is_desktop={is_desktop}
                                    sys_id={props.match.params.id}
                                    accessData={accessData}
                                    ids={ids}
                                    indicatorsData={indicatorsData}
                                />
                            : null}

                    {accessData.access_control.scheme.visible&&
                    accessData?.sheme_obj?.status
                        ?
                        <SchemeComponent
                            max_width={max_width_blocks}
                            accessData={accessData}
                            onl_offl={online_offline_inds_statuts}
                            indicatorsData={indicatorsData}
                        />
                        : null}

                    {accessData.access_control.static.visible ?
                            <StatisticComponent
                                max_width={max_width_blocks}
                                is_desktop={is_desktop}
                                indicatorsData={indicatorsData}
                                onl_offl={online_offline_inds_statuts}
                                accessData={accessData} R1ref={R1ref} R2ref={R2ref} R2ref={R2ref}/>
                        : null
                    }


                    {accessData.access_control.table.visible ?
                            <TableComponent
                                max_width={max_width_blocks}
                                is_desktop={is_desktop}
                                accessData={accessData}
                                            ind_data={indicatorsData}
                                            sys_id={props.match.params.id}
                                            ids={ids}
                            />
                        : null
                    }
                </div>
                </>
            </div>)
        : <Backdrop style={{zIndex: 55455}} open={true}>
            <CustomImageLoader
                image={`${process.env.PUBLIC_URL}/favicon.ico`}
                isLoaded={true}
                circle={false}
                speed={2}
                animationType={"pulse"}
            />
        </Backdrop>
};

export default ProPage772238;
