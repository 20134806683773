import React from "react";
import ContentSelectLangs from "./content/content_select_lang_modal";
import Wrap_Overlay from "../modal_components/wrappers/overlay_wrappers/Wrap_Overlay";
import WrapModal from "../modal_components/wrappers/Wrap_Modal";
import Title_Modal from "../modal_components/Title_Modal";
import {glv} from "../../app_components/helper_functions/helper_functions";

const SelectLangModal = (props) => {
    // console.log(props)
    // const [opened_modal, set_opened_modal] = React.useContext(Opened_Modal);
    if (props.open) {
        // set_opened_modal(true)
    }
    return (
        <Wrap_Overlay
            z_index={1025}
            open={props.open}
            hidden_body={true}
            handle_click_overlay={()=>{
                // set_opened_modal(false)

                props.close()
            }}
            class_position_children={'overlay_children_position'}
        >
            <WrapModal>
                <Title_Modal
                    title_style={{overflow:'hidden', whiteSpace:'nowrap', textOverflow: 'ellipsis'}}
                    title_text={
                        glv(`first_level_blocks.select_lang_modal.title` )
                    }
                />
                <ContentSelectLangs
                    close={()=> {
                        // set_opened_modal(false);
                        props.close();
                    }}/>
            </WrapModal>
        </Wrap_Overlay>
    );
};

export default SelectLangModal;
