import React, { useRef, useEffect, useCallback } from 'react'

import { useToasts } from "react-toast-notifications";

import {glv} from '../../../app_components/helper_functions/helper_functions'
import Input from "../../../form_components/Input";

const FormReportModal = props => {
//   const dispatch = useDispatch();
    let {form, on_change_form, errors} = props;
    const { addToast } = useToasts();


    const handle_change_input = (e)=>{
        let val = e.target.value;
        let name = e.target.name;
        on_change_form(name,val);
    }


    let custom_style= props.style ? props.style : {};

    return        (
        <div>
            <form
                className={`wrap_report_modal_form `}
                style={{...custom_style}}
            >


                <Input
                    wrap_style={{marginTop: 30}}
                    autocomplete={'username'}
                    value={form.name}
                    disabled={false}
                    errors={errors.name}
                    name={'name'}
                    on_change={handle_change_input}
                    ph={ glv(`first_level_blocks.report_modal.label_name`)}
                    type={'text'}
                />
                <Input
                    wrap_style={{marginTop: 30}}
                    autocomplete={'on'}
                    value={form.contact}
                    disabled={false}
                    errors={errors.contact}
                    name={'contact'}
                    on_change={handle_change_input}
                    ph={glv(`first_level_blocks.report_modal.label_contacts`)}
                    type={'text'}
                />

                <Input
                    wrap_style={{marginTop: 30, paddingTop: 14, paddingBottom: 14}}
                    autocomplete={'on'}
                    value={form.text}
                    disabled={false}
                    errors={errors.text}
                    name={'text'}
                    on_change={handle_change_input}
                    rows={5}
                    multiline={true}
                    ph={glv(`first_level_blocks.report_modal.label_message`)}
                    type={'text'}
                />

            </form>
        </div>
    )



}

export default FormReportModal;