import React from "react";

const HeaderBarHD = (props) => {

    let custom_style = props.style ? props.style : {};
    return  (
        <div className={'wrap_header_bar_hd'} style={{...custom_style}}>
            <div className={'wrap_tools_header_hd'}>
                {props.children}
            </div>
        </div>
    );
}

export default HeaderBarHD;