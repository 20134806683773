import React from "react";
import {InfoIcon, LogautIcon, MailIcon, OpenChildMenuIcon, SelectLangIcon} from "../../../assets/svg_images";
import IconMenu from "../GetIconMenu";
import Collapse from "@material-ui/core/Collapse";
import {glv} from "../../app_components/helper_functions/helper_functions";

const HeaderMenuBlockVM = (props) => {
    const [child_list_open, set_clild_list_open] = React.useState(false)
    let custom_style = props.style ? props.style : {};
    let is_child = props.is_child_block ? ' is_child_true' : '';
    return  (
        <>
        <div className={'wrap_menu_block_vm'+is_child}
             style={{...custom_style}}
             onClick={()=>{
                 if (props.child) {
                     set_clild_list_open(!child_list_open)
                 }
                 else {
                     props.action()
                 }
        }}
        >
            <div className={'menu_block_content_vm'+is_child}>
                    <div className={'wrap_icon_menu_block_vm'+is_child}>
                        <IconMenu hided={props.hided} id={props.id_name} mod={'vm'+is_child}/>
                    </div>
                    <div className={'wrap_title_menu_block_vm'+is_child}
                         style={{
                             marginLeft: props.hided ? 100 : '',
                             transition: 'margin 0.4s'
                         }}
                    >
                        <span className={'title_menu_block_vm'+is_child}>{props.title}</span>
                    </div>
                {
                    props.child ? (
                        <div className={'wrap_open_menu_child_icon_vm'+is_child}>
                            <OpenChildMenuIcon
                                class={ `open_menu_child_icon_vm ${child_list_open ? 'opened': ''}`}
                            />
                        </div>
                    ) : null
                }
            </div>
        </div>
            {
                props.child ? (
                    <Collapse in={child_list_open}>
                        <div className={'wrap_menu_child_blocks_vm'}>
                            {
                                props.child.map(punkt=>{
                                    return (
                                        <HeaderMenuBlockVM
                                            is_child_block={true}
                                            child={punkt.child ? punkt.child : null}
                                            hided={child_list_open}
                                            id_name={punkt.id}
                                            action={()=>punkt.action()}
                                            title={glv('header.menu_buttons.'+punkt.id)}
                                        />
                                    )
                                })
                            }
                        </div>
                    </Collapse>
                ) : null
            }
        </>
    );
}

export default HeaderMenuBlockVM;
