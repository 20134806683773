import React from 'react';
import cl from './small_loader.style.module.css';

const SmallLoaderComponent = () => {
    return (
        <div className={cl.lds_ring}>
            {/*<div></div>*/}
            {/*<div></div>*/}
            {/*<div></div>*/}
            {/*<div></div>*/}
        </div>
    );
};

export default SmallLoaderComponent;