
import React from "react";
import CrashIcon from "./title_crash_icon";
import {glv, setlogos} from "../../app_components/helper_functions/helper_functions";
import Wrap_Overlay from "../modal_components/wrappers/overlay_wrappers/Wrap_Overlay";
import WrapModal from "../modal_components/wrappers/Wrap_Modal";
import Title_Modal from "../modal_components/Title_Modal";
import ActionsModalButtons from "../modal_components/Actions_Buttons_Modal";

const CrashModal = (props) => {
    React.useEffect(()=>{
        let {title, point} = props.crash_modal_data;
        setlogos(title, point);
    }, [props.crash_modal_data])

    return (
        <Wrap_Overlay
            open={props.crash_modal_data.open}
            z_index={950}
            hidden_body={true}
            handle_click_overlay={()=>{
                // set_opened_modal(false)
                // props.close()
            }}
            class_position_children={'overlay_children_position'}
        >
            <WrapModal>
                <CrashIcon/>
                <Title_Modal
                    title_text={props.crash_modal_data.title}
                />

                <ActionsModalButtons
                    disabled_acc_btn={false}
                    has_cancel={true}
                    has_accept={true}
                    text_accept_btn={'Reload page'}
                    text_cancel_btn={'Go back'}
                    style={{marginTop:30}}
                    accept_action={()=>{
                        props.crash_modal_data.try_action()
                    }}
                    cancel_action={()=>{
                        props.goback_action()
                    }}
                />
            </WrapModal>
        </Wrap_Overlay>
    );
};

export default CrashModal;

