import React, {useCallback, useState} from 'react';
import LightButton from "../../components/custom_components/light_button";
import TimeInput from "../clock/clock_block";
import {get_beautiful_date} from "../helpers/time_input_helpers";
import PurpleButton from "../../components/custom_components/purple_button";
import './detached_time_input_with_accept.css';

const DetachedTimeInputWithAccept = (props) => {

    const [current_time, set_current_time] = useState(props.current_date);

    const change_time_handler = useCallback((time)=>{

        let time_split = time.time.split(':');
        let hours = Number(time_split[0]);
        let minutes = Number(time_split[1]);
        let new_date = new Date(current_time);

        if (props._is_am_pm_[0]&&time.ampm==='pm') {
            hours+=12;
        }
        new_date.setHours(hours);
        new_date.setMinutes(minutes);

        set_current_time(new_date)
    }, [current_time, props._is_am_pm_])

    let init_time = `${get_beautiful_date(current_time.getHours())}:${get_beautiful_date(current_time.getMinutes())}`

    return (
        <div className={'wrap_detached_input_with_accept'}>
            <TimeInput
                _is_am_pm_={props._is_am_pm_}
                initTime={init_time}
                className={'clock_input'}
                // readonly={true}
                onTimeChange={change_time_handler}
            />
            <div className={'wrap_detached_access_btns_wrap'}>
                <LightButton
                    on_click={
                        ()=>props.cancel_change()
                    }
                    text={`Cancel`}
                    cls={'cancel_change_time_input_btn'}
                    cls_text={'cancel_change_time_input_btn_text'}
                />
                <PurpleButton
                    on_click={()=>props.accept_change(current_time)}
                    text={'Ok'}
                    cls_outer={'accept_change_time_input_btn'}
                    cls_text={'accept_change_time_input_btn_text'}
                />
            </div>
        </div>
    );
};

export default DetachedTimeInputWithAccept;