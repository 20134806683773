import React, {useRef, useEffect, useCallback, useState} from 'react'
import {glv} from '../../../components/app_components/helper_functions/helper_functions'
import Wrap_Overlay from "../../../components/modals/modal_components/wrappers/overlay_wrappers/Wrap_Overlay";
import WrapModal from "../../../components/modals/modal_components/wrappers/Wrap_Modal";
import Title_Modal from "../../../components/modals/modal_components/Title_Modal";
import ActionsModalButtons from "../../../components/modals/modal_components/Actions_Buttons_Modal";
import SettingModalContent from "./content/SettingContent";
import './styles/setting_modal_style.css';
import './styles/look_manometrs_setting.css'
import {isEqualObjects} from "../../../helper_functions/helpers";
import {
    current_setting_form,
    get_local_strage_current_sett_form,
    set_current_setting_form, set_local_strage_current_sett_form
} from '../../pages/pro/current/static_datas'
import {is_rel_b} from "../../../App";
import GagPub from "../../pubs/gag_pub";


const SettingModal = props => {
    const [show_save_btn, set_show_save_btn] = useState(false);
    const [setting_form, set_setting_form] = props.setting_form;





    useEffect(()=>{

        let curr_ls_sett_form = get_local_strage_current_sett_form();
        if (isEqualObjects(setting_form, curr_ls_sett_form)) {
            set_show_save_btn(false)
        }
        if (!isEqualObjects(setting_form, curr_ls_sett_form)) {
            set_show_save_btn(true)
        }
    }, [setting_form])

    return (
        <Wrap_Overlay
            open={props.open}
            hidden_body={true}
            handle_click_overlay={()=>{
                // props.close()
                // set_opened_modal(false)
            }}
            class_position_children={'overlay_children_position'}
        >
            <WrapModal full_w={!props.is_desktop ? 'full_width' : ''}>
                {
                    !is_rel_b  ? <>
                        <Title_Modal
                            title_text={glv(`first_level_blocks.setting_modal.title`)}
                        />

                        <SettingModalContent
                            {...props}
                        />
                    </> : <GagPub
                            style={{
                                background: 'transparent',
                                borderRadius: 0,
                                position: 'initial',
                                flex:1
                            }}
                            style_text = {{
                                padding: '12px',
                                fontSize: '20px',
                                fontWeight: 'bold',
                                 color: '#2f2a61',
                                position: 'initial',
                                top: 0,
                                left: 0,
                            '-webkit-transform': 'none',
                                transform: 'none'
                        }}
                />
            }
            <ActionsModalButtons
                disabled_acc_btn={
                    false
                }
                has_cancel={true}
                has_accept={show_save_btn}
                text_accept_btn={
                    glv(`first_level_blocks.setting_modal.btns.accept`)
                    // glv(`first_level_blocks.setting_modal.accept_btn_title`)
                }

                text_cancel_btn={
                    glv(`first_level_blocks.setting_modal.btns.canc`)
                }
                style={{marginTop:30}}
                accept_action={()=>{
                    set_local_strage_current_sett_form({...setting_form})
                    props.close()
                }}
                cancel_action={()=>{
                    let curr_ls_sett_form = get_local_strage_current_sett_form();
                    set_setting_form({...curr_ls_sett_form});
                    props.close()
                }}
            />
        </WrapModal>
    </Wrap_Overlay>
)
}

export default SettingModal;