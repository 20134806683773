import React, {useEffect, useCallback, useState, useRef} from "react";
import Collapse from '@material-ui/core/Collapse';
import useWindowSize from "../../hooks/useWindowSize";

const TextOption = (props) => {
    let is_selected_class = props.is_selected ? 'selected_option' : '';
    let first_child = props.index===0 ? 'first_child' : '';
    let is_disabled_option = props.is_disabled ? ' disabled_option' : ''
    return (
        <div
            name={props.name}
            onClick={props.on_click}
             key={props.id + props.text}
             className={'wrap_option_text '+ first_child + is_disabled_option}
        >
            <span className={`option_text ${is_selected_class}`} value={props.id}>{props.text}</span>
        </div>
    )
}

const InputSelect = (props) => {
    let cls = props.classes ? props.classes : '';

    const size = useWindowSize();
    const block_ref = useRef(null);
    const [open_options, set_open_options] = useState(false);
    const [max_width_text, set_max_width_text] = useState(400);

    let struct_style= props.struct_style ? props.struct_style : {};
    let current_value = props.options.find((option)=>props.value===option.id);
    let text_value = current_value ? current_value.text :  props.placeholder;


    useEffect(()=>{
        if (block_ref.current) {
            set_max_width_text(block_ref.current.offsetWidth-85)
        }

    }, [size.width])


    return (
        <div
            ref={block_ref}
            style={{...struct_style,  }}
            className={`pos_rel ${props.no_mt? '' : 'mt_30'} inset_bg_shadow flex_1_ fl_ fl_d_c wrap_select_input`}>
            <div

                onClick={() => set_open_options(!open_options)}
                className={'div_input_select'}
            >
                <div
                    style={{maxWidth:max_width_text}}
                    className={'ellipsis_text_select'}>
                    <span className={`text_select_input ${current_value ? '': 'placeholder'}`}>{
                        text_value
                    }</span>
                </div>

                <div
                    className={'wrap_icon  input_select_icon'}
                    onClick={() => set_open_options(!open_options)}
                >

                    <svg
                        style={{
                            transform: `rotate(${open_options?180: 0}deg)`,
                            transition: 'transform 0.4s'
                        }}
                        width="22" height="12" viewBox="0 0 22 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M21.6484 1.00295L20.9277 0.332829C20.7009 0.123552 20.3992 0.00825896 20.0767 0.00825896C19.7544 0.00825896 19.4523 0.123552 19.2255 0.332829L11.0049 7.92462L2.77488 0.324405C2.54844 0.115127 2.24634 0 1.92403 0C1.60172 0 1.29944 0.115127 1.07282 0.324405L0.352003 0.990392C-0.117334 1.42348 -0.117334 2.12895 0.352003 2.56204L10.1508 11.6435C10.3773 11.8527 10.679 12 11.0042 12H11.008C11.3304 12 11.6322 11.8525 11.8586 11.6435L21.6484 2.58665C21.8752 2.37754 21.9996 2.09046 22 1.79281C22 1.495 21.8752 1.21189 21.6484 1.00295Z"
                            fill="#5E5AB9"/>
                    </svg>

                </div>
            </div>



            <div className={`divider ${open_options && props.options?.length ? '' : 'hided'}`}></div>
            <Collapse in={open_options && props.options?.length}>
                <div className={'wrap_options options_scrollbar'}>
                    {
                        props.options.map((option, index) => {
                            let is_selected = props.value === option.id;
                            return (
                                <TextOption
                                    index={index}
                                    key={option.id + option.text}
                                    name={props.name}
                                    on_click={() => {
                                        if (option.is_disabled||is_selected) {
                                            return
                                        }
                                        set_open_options(false)
                                        props.on_change(props.name, option.id)
                                    }}
                                    id={option.id}
                                    text={option.text}
                                    is_disabled={option.is_disabled}
                                    is_selected={ is_selected}
                                />
                            )
                        })
                    }
                </div>
            </Collapse>
        </div>
    );
};

export default InputSelect;
