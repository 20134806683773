import React from "react";
import CanvasIndicator from "../indicator_components/manometrs/current/CanvasIndicator";
import moment from "moment";


const RowBlockIndicators = (props) => {
    const {item_data, index_, vals,f_cof, ratio} = props.data_for_indicator;
    return (

        <div className={'wrap_indicator_block'} key={index_}>
            {
                item_data.map((key, index) => {

                    let block = vals[key];
                    return (
                        <CanvasIndicator
                            isOnline={
                                (moment().unix() - block.timestamp_upd) / 60 <= 4
                            }
                            key_id={key}
                            key={key + index + block.name}
                            id={block.name}
                            k={f_cof}
                            ratio={ratio}
                            value={block.value}
                        />

                    )
                })
            }
        </div>
    )
}

export default RowBlockIndicators;