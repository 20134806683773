import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './styles/general/general_styles.css';
import './styles/buttons/general_buttons.css';
import './styles/modal_components/wrap_modal.css';
import './styles/modal_components/action_btns.css';
import './styles/modal_components/overlay.css';
import './styles/modal_components/title.css';
import './styles/modals/help_modal.css';
import './styles/scroll/scroll.css';
import './styles/form_components/input.css';
import './styles/form_components/errors.css';
import './styles/form_components/input_select.css';
import './styles/crash_modal/crash_modal.css';
import './styles/header_componets/header_bar.css';
import './styles/header_componets/header_logo.css';
import './styles/header_componets/header_title.css';
import './styles/header_componets/header_btns.css';
import './styles/header_componets/header_icons.css';
import './styles/manometrs/manometrs.css';
import './styles/pubs/gag_pub.css';
import './styles/pubs/chart_pub.css';
import './styles/chart_block/color_list.css';
import './styles/chart_block/chart_block.css';
import './styles/loaders/chart_loader.css';
import './styles/statistic_block/statistic_block.css';
import './styles/header_componets/header_block.css';
import './styles/header_componets/header_menu_block.css';
import './styles/slect_lang_modal/select_lang_modal.css';
import './styles/slider/slider.css';
import './styles/general/general_media_querys.css';
import './styles/table_block/table_block.css';
import './styles/scroll_ref_service_components/point_scoll_ref.css';
import './styles/statistic_block/media_quaries_stat_block.css';
import './styles/table_block/media_quaeries_table-block.css';
import './styles/sheme/sheme_styles.css';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import App from './App';
import reportWebVitals from './reportWebVitals';
import inter_scriptik from './interactive_demo/inter_script';
import { ToastProvider, useToasts } from 'react-toast-notifications';
import { Provider } from "react-redux";
import ErrorBoundary from "./exeption_handlers/GlobalExeption";
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import DateAdapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { createStore, applyMiddleware } from 'redux';
import thunk from "redux-thunk";
import rootReducer from './redux/reducers/index';
import WrapperApp from "./components/app_components/providers/wrapper_app";

export const store = createStore(rootReducer, applyMiddleware(thunk));

const theme1 = createMuiTheme({
    overrides: {
        MuiSelect: {
            select: {
                "&:focus": {
                    background: "white"
                }
            }
        }
    }
});
const theme = createTheme({
    palette: {
        primary: {
            // Purple and green play nicely together.
            main: '#5E5AB9',
        },
        secondary: {
            // This is green.A700 as hex.
            main: '#11cb5f',
        },
    },
});

ReactDOM.render(

      <LocalizationProvider dateAdapter={DateAdapter}>
          <ThemeProvider theme={theme}>
      <MuiThemeProvider theme={theme1}>
          <ErrorBoundary >
     <ToastProvider>
        <Provider store={store}>
            <WrapperApp/>


    </Provider></ToastProvider>
    </ErrorBoundary>
      </MuiThemeProvider>
          </ThemeProvider>
      </LocalizationProvider>,

  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// inter_scriptik();
reportWebVitals();
