import React from "react";
import {
    AddingPlusIcon,
    InfoIcon,
    LogautIcon,
    MailIcon,
    SelectLangIcon,
    SettingIcon,
    ShemeMenuIcon
} from "../../assets/svg_images";

const IconMenu = (props) => {
    switch (props.id) {
        case 'report': {
            return <MailIcon class={'mail_icon_'+props.mod}
                             // style={{width: props.hided ? 32 : 24}}
            />
            break;
        }
        case 'info': {
            return <InfoIcon class={'info_icon_'+props.mod}
                             // style={{width: props.hided ? 32 : 24}}
            />
            break;
        }
        case 'select_lang': {
            return <SelectLangIcon class={'select_lang_icon_'+props.mod}
                                   // style={{width: props.hided ? 32 : 24}}
            />
            break;
        }
        case 'logout': {
            return <LogautIcon class={'logaut_icon_'+props.mod}
                               // style={{width: props.hided ? 32 : 24}}
            />
            break;
        }
        case 'setting': {
            return <SettingIcon class={'setting_icon_'+props.mod}
                // style={{width: props.hided ? 32 : 24}}
            />
            break;
        }
        case 'scheme': {
            return <ShemeMenuIcon class={'sheme_icon_'+props.mod}
                // style={{width: props.hided ? 32 : 24}}
            />
            break;
        }

        case 'add_new_system': {
            return <AddingPlusIcon class={'add_new_sys_icon_'+props.mod}/>
            break;
        }
        default: {
            return <div></div>
        }
    }
}

export default IconMenu;
