import React, { useRef, useEffect } from 'react'
import { Tooltip } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../redux/actions/env.actions";
import useMousePosition from '../../hooks/useMousePosition';
import ShemeModal from '../modals/sheme_element_modal/shemeElementModal';
const debounce = require('debounce');
const degs  ={
    left: 90,
    right: 270,
    bottom: 0,
    top: 180
}

const ArcAngle = props => {
    const blurStatus = useSelector((state) => state.envReducer.blurElems);
    const dispatch = useDispatch();
  const canvasRef = useRef(null)
  const [hover, setHover] = React.useState(false);
  const setShemeModal = React.useCallback((color) => dispatch(actions.setShemeModal(color)), [
    dispatch,
  ]);
  const [coord, setCoord] = React.useState(null);
  const overModal = useSelector((state) => state.envReducer.overModal);
  const setShemeModalData = React.useCallback((color) => dispatch(actions.setShemeModalData(color)), [
    dispatch,
  ]);
    const currTrumpet = useSelector((state) => state.envReducer.currTrumpet);
  const setCurrTrumpet = React.useCallback((color) => dispatch(actions.setCurrTrumpet(color)), [
    dispatch,
  ]);
  const setBlur = React.useCallback((status) => dispatch(actions.setBlur(status)), [
    dispatch,
  ]);

  useEffect(() => {
    if (overModal) {
      return
    }
    if (hover&&props.id&&coord) {
      setShemeModalData({...props, x:coord.x, y: coord.y});
      setShemeModal(true);
      setCurrTrumpet(props.id);
      setBlur(true)
    } else {
      // setShemeModalData({...props, x:coord.x, y: coord.y});
      setShemeModal(false);
      setCurrTrumpet('');
      setBlur(false)
    }

  }, [hover,coord,overModal])

  useEffect(() => {
    const canvas = canvasRef.current
    const ctx = canvas.getContext('2d')
    //Our first draw
    ctx.beginPath();
    ctx.strokeStyle = props.color;
    ctx.lineWidth = 3;

    ctx.moveTo(0, 0);               // Create a starting point
              // Create a horizontal line
ctx.arcTo(ctx.canvas.width, 0, ctx.canvas.height, ctx.canvas.height, ctx.canvas.width); 
    // ctx.moveTo(2, 0);
    // ctx.bezierCurveTo(0, ctx.canvas.height, ctx.canvas.width, ctx.canvas.height, ctx.canvas.width-2, 0);
    ctx.stroke();
  }, [])
  
  return <div style={{cursor: 'pointer'}}
  onMouseOver={debounce((e) => {
    setCoord({x:e.clientX, y: e.clientY})
    if (overModal) {
      return
    }
  setHover(true)
  },300)}
onMouseLeave={debounce(() => {
setHover(false)
},200)}
  >
{/* {hover&&props.id ? (
     <ShemeModal {...props}/>
      ) : null} */}

      
        <canvas ref={canvasRef} {...props} style={{...props.styleWrap,transform: `rotate(${degs[props.direction]}deg)`,
        transition: 'all 0.2s',
        filter:blurStatus&&currTrumpet!==props.id?'blur(2px)':blurStatus&&currTrumpet===props.id?'none':'none'
        }}/>

  </div>
  

}

export default ArcAngle