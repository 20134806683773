import {useSelector} from "react-redux";
import React, {useContext} from "react";
import useHyphen from "react-use-hyphen";
import {glv} from "../app_components/helper_functions/helper_functions";
import LineProgress from "../indicator_components/linear_progress/LineProgress";
import CanvasRadial from "../indicator_components/radial_progress/current/CanvasRadial";
import {
    hyphenateSync as en_sync
} from "hyphen/en";
import {
    hyphenateSync as ru_sync
} from "hyphen/ru";
import {
    hyphenateSync as pl_sync
} from "hyphen/pl";
import {
    hyphenateSync as de_sync
} from "hyphen/de";
import {
    hyphenateSync as cs_sync
} from "hyphen/cs";
import {get_coff} from "../../helper_functions/helpers";
import useWindowSize from "../../hooks/useWindowSize";
import {IS_DESKTOP_CONTEXT} from "../../App";
import {check_is_number_with_pub} from "../pages/pro/current/helpers_pro";

const hypen_syncs = {
    en: en_sync,
    ru: ru_sync,
    pl: pl_sync,
    de: de_sync,
    cs: cs_sync,
}


const get_dimensions_stat_block = (container_sizes, full_width, is_one_block, is_desktop) => {

    // console.log(full_width, is_desktop)
     if (full_width>800&&is_desktop){
         return is_one_block? container_sizes.width -30 :  (container_sizes.width/2)-30
     }
     else {
         return container_sizes.width -26
     }
}

const get_dimensions_radial = (container_sizes, full_width, is_desktop) => {

    if (full_width>800&&is_desktop){
        let del_cof = full_width>1400 ? 2.5 : 2;
        let width_block = ((container_sizes.width/2)-24)/2.5/30

        return width_block > 5.5 ? 5.5 : width_block
    }
    else {
        let del_cof = full_width<500 ? 1.2 : 2;
        let width_block = (container_sizes.width/del_cof -24)/2.5/30

        return width_block > 5.5 ? 5.5 : width_block

    }
}

function StatisticIndicator({id_, borderNone, isOnl, rf, index, is_one_block, container_sizes}) {
    // компонент одного блока статистики
    const is_desktop = useContext(IS_DESKTOP_CONTEXT);
    const language = useSelector((state) => state.envReducer.language);
    const container_sizes_r1 = React.useRef(null);
    const container_sizes_r2 = React.useRef(null);
    const size_r1_ref = React.useRef(null);
    const size_r2_ref = React.useRef(null);
    const {width, height} = useWindowSize();
    const isOnline = isOnl;
    const [dimensions_container, set_dimensions_container] = React.useState({});
    const [rw, set_rw] = React.useState([]);
    const {Hyphen} = useHyphen();
    let block_width = get_dimensions_stat_block(
        container_sizes,
        width,
        is_one_block,
        is_desktop
    );


    const get_container_dimensions = () => {
            let ratio = window.devicePixelRatio;
            ratio = ratio < 0 ? 1 : ratio;


            set_rw([get_dimensions_radial(container_sizes, width, is_desktop), ratio])
    }

    React.useEffect(() => {
        get_container_dimensions();

        // window.addEventListener('resize', () => {
        //     get_container_dimensions()
        // });

    }, [width]);



    let p_side =width>800&&!is_one_block ? index === 0 ? 'paddingRight' : 'paddingLeft' : '';
    // let k = Math.min(get_coff(dimensions_container.r1.width), )
    return (
        <div
            style={{
                marginTop: index ===1&&(width<801||!is_desktop) ? 30 : ''
        }}
            className={'statistic_blocks_container'}
            >
            <div
                style={{width:block_width}}
                className={'wrap_statistic_blocks'}
            >
                <div className={'wrap_id_block '}>

                    <div
                        // style={{fontSize: width/35>28?28 : width/35}}
                        className={`${isOnline ? 'online_id_bg' : 'offline_id_bg'} wrap_id_text`}>
                        <span>{id_}</span>
                    </div>


                    <div className={'wrap_fpos_text'}>
                  <span style={{fontSize: 20}}>
                      {glv('first_level_blocks.statistic.f_pos.' +
                          rf['f_pos'])}
                  </span>
                    </div>

                </div>

                <div style={{width: '100%',}}>
                    <div className={'wrap_linears_and_radials'}>
                        <div className={'wrap_linears'}>

                            {
                                rf.statuts ? Object.keys(rf.statuts).map((key) => {
                                    if (key.includes("l")) {
                                        return <div style={{width: '100%'}} key={key + id_}>
                                            <LineProgress
                                                number={key[1]}
                                                color={key === 'l2' || key === 'l3' ? 'blue' : 'red'}
                                                valProg={rf.statuts[key]}

                                                min={rf.sys_info.limits[key].min}
                                                max={rf.sys_info.limits[key].max}
                                            />
                                        </div>

                                    }

                                }) : null
                            }
                        </div>
                        <div
                            style={{flexDirection: is_one_block ? 'row' : ''}}
                            className={'wrap_radials'}>

                            {
                                rf.statuts.hasOwnProperty('r1') ?
                                    <div
                                        ref={container_sizes_r1}
                                        className={'wrap_radial_in_block_stat wrap_r1'}>
                                        <div  style={{ flex: width > 600 ? 1 : ''}}>
                                                  <span
                                                      className="title_oval" style={{
                                                      // wordBreak: 'break-word',
                                                      // color: '#2f2a61'
                                                  }}>
                                                      {
                                                          hypen_syncs.hasOwnProperty(language) ?
                                                              hypen_syncs[language]
                                                              (glv('first_level_blocks.statistic.radial.' + 'r1' + '.name'))
                                                              : ''
                                                      }
                                                  </span>
                                        </div>
                                        <div
                                            ref={size_r1_ref}
                                            style={{ flex: width > 600 ? 1 : ''}}
                                        >
                                            {
                                                rw.length ?
                                                    <CanvasRadial
                                                        key_id={id_}
                                                        key_name={'r1'}
                                                        min={
                                                            check_is_number_with_pub(rf.sys_info.limits['r1'].min)
                                                        }
                                                        max={
                                                            check_is_number_with_pub(rf.sys_info.limits['r1'].max, 100)
                                                        }
                                                        value={
                                                            check_is_number_with_pub(rf.statuts['r1'])
                                                        }
                                                        k={rw[0]}
                                                        ratio={rw[1]}
                                                    /> : null
                                            }

                                        </div>
                                    </div>
                                    :
                                    rf.statuts.hasOwnProperty('r3') ?
                                        <div className={'wrap_radial_in_block_stat wrap_r3'}>
                                            <div
                                                style={{ flex: width > 600 ? 1 : ''}}
                                            >
                                                  <span className="title_oval" style={{
                                                      // wordBreak: 'break-word',
                                                      // color: '#2f2a61'
                                                  }}>
                                                      {
                                                          hypen_syncs.hasOwnProperty(language) ?
                                                              hypen_syncs[language]
                                                              (glv('first_level_blocks.statistic.radial.' + 'r3' + '.name'))
                                                              : ''
                                                      }
                                                  </span>
                                            </div>
                                            <div
                                                ref={size_r1_ref}
                                                style={{ flex: width > 600 ? 1 : ''}}
                                            >
                                                {
                                                    rw.length ?
                                                        <CanvasRadial
                                                            key_id={id_}
                                                            key_name={'r3'}
                                                            min={
                                                                check_is_number_with_pub(rf.sys_info.limits['r3'].min)
                                                            }
                                                            max={
                                                                check_is_number_with_pub(rf.sys_info.limits['r3'].max, 100)
                                                            }
                                                            value={
                                                                check_is_number_with_pub(rf.statuts['r3'])
                                                            }
                                                            k={rw[0]}
                                                            ratio={rw[1]}
                                                        /> : null
                                                }

                                            </div>

                                        </div>
                                        : null

                            }

                            {
                                rf.statuts.hasOwnProperty('r2') ?
                                    <div
                                        ref={container_sizes_r2}
                                        className={' wrap_radial_in_block_stat wrap_r2'}>
                                        <div       style={{ flex: width > 600 ? 1 : ''}}>
                            <span className="title_oval">

                        {
                            hypen_syncs.hasOwnProperty( language) ?
                                hypen_syncs[language]
                                (glv('first_level_blocks.statistic.radial.' + 'r2' + '.name'))
                                : ''
                        }

                            </span>
                                        </div>
                                        <div
                                            ref={size_r2_ref}
                                            style={{ flex: width > 600 ? 1 : ''}}
                                        >
                                            {
                                                rw.length ?

                                                    <CanvasRadial
                                                        key_id={id_}
                                                        key_name={'r2'}
                                                        min={
                                                            check_is_number_with_pub(rf.sys_info.limits['r2'].min)
                                                        }
                                                        max={
                                                            check_is_number_with_pub(rf.sys_info.limits['r2'].max, 100)
                                                        }
                                                        value={
                                                            check_is_number_with_pub(rf.statuts['r2'])
                                                        }
                                                        k={rw[0]}
                                                        ratio={rw[1]}
                                                    />: null
                                            }

                                        </div>

                                    </div>
                                    : null
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>


    )
}

export default StatisticIndicator