import React, {useEffect, useCallback, useState} from "react";
import './calender.css';
import {monthes, week_days} from "./data_consts";
import {ArrowChangeMonthCalendar} from "../../assets/svg_images";
import {get_beautiful_date} from "../helpers/time_input_helpers";
import DetachedTimeInputWithAccept from "./detached_time_input_with_accept";


function create_dates_text_arr(date, id, lang) {
    let day = get_beautiful_date(date.getDate());
    let month_num_val = date.getMonth();
    let month = date.toLocaleString(lang ? lang : 'default', {month: 'long'});
    // let month = monthes[month_num_val].full_name;
    let year = date.getFullYear();

    let hours = get_beautiful_date(date.getHours());
    let mins = get_beautiful_date(date.getMinutes());
    return [
        {
            id: `day_${id}`,
            type: 'day',
            period: id,
            num_value: day,
            text: `${day} `,
            full_date: new Date(date)
        },
        {
            id: `month_${id}`,
            type: 'month',
            period: id,
            num_value: month_num_val,
            text: `${month} `,
            full_date: new Date(date)

        },
        {
            id: `year_${id}`,
            type: 'year',
            period: id,
            num_value: year,
            text: `${year} `,
            full_date: new Date(date)
        },
        {
            id: `time_${id}`,
            type: 'time',
            period: id,
            text: `${hours}:${mins}`,
            full_date: new Date(date)
        },
    ]
}

function get_text_selected_date(date, is_range) {
    let sub_to = is_range ? ' - ' : '';
    let res_arr = create_dates_text_arr(date[0], 'start')

    if (sub_to) {
        res_arr.push(
            {
                id: 'sub_to',
                type: 'sub',
                text: sub_to
            }
        )
    }

    if (date.length > 1) {
        res_arr = res_arr.concat(create_dates_text_arr(date[1], 'finish'))
    }
    return res_arr;
}

function date_with_null_time(date) {
    let y = date.getFullYear();
    let m = date.getMonth();
    let d_ = date.getDate();

    let new_date = new Date(y, m, d_, 0, 0, 0, 0)

    return new_date.getTime();
}

function get_offset_day_month(fwd, fdi) {
    if (fdi === 0) {
        return -fwd + 1;
    }
    if (fdi === 1) {
        return -fwd - fdi
    }
}


function generateDateTableBody(curr_props_date, is_range_date, first_day_week_index) {
    let is_true_range = curr_props_date.selected_date.length > 1;
    let curr_date_start = curr_props_date.selected_date[0];
    let curr_date_finish = curr_props_date.selected_date[1] ? curr_props_date.selected_date[1] : new Date();

    let proper_date = curr_props_date.curr_view_date;

    let first_date_of_proper_month = new Date(proper_date.getFullYear(), proper_date.getMonth(), 1);
    let week_day_fd = first_date_of_proper_month.getDay();

    let result_arr = [];
    let counter = 0;

    let startDatOffset = week_day_fd * (-1) + 1;

    let y = proper_date.getFullYear();
    let m = proper_date.getMonth();
    let d_ = proper_date.getDate();

    const check_is_selected = (view_date) => {

        if (is_range_date && is_true_range) {
            let same_date = date_with_null_time(view_date) >= date_with_null_time(curr_date_start)
                &&
                date_with_null_time(view_date) <= date_with_null_time(curr_date_finish);

            if (same_date) {
                return true
            }
        }
        return view_date.getFullYear() === curr_date_start.getFullYear() &&
            view_date.getMonth() === curr_date_start.getMonth() &&
            view_date.getDate() === curr_date_start.getDate()
    }

    const check_is_disabled = (view_date) => {
        if (is_range_date && !is_true_range) {
            let not_same_y = view_date.getFullYear() < curr_date_start.getFullYear();
            let not_same_m = view_date.getMonth() < curr_date_start.getMonth();
            let not_same_d = view_date.getDate() < curr_date_start.getDate();

            if (not_same_y && not_same_m && not_same_d) {
                return true
            }
        }
        return false
    }

    for (let week = 0; week < 5; week++) {
        let temp_arr = [];

        for (let day = 0; day < 7; day++) {

            let day_ = counter + startDatOffset;
            // startDatOffset;
            // console.log('day:',day_, 'counter:',counter , 'startDatOffset:', startDatOffset)
            let d = new Date(y, m, day_, 0, 0, 0, 0);
            let is_selected = check_is_selected(d);
            let is_disabled = check_is_disabled(d)
            let new_day_obj = {
                id: d.getTime() + 'ymd',
                day_text: d.getDate(),
                date_value: d,
                year: d.getFullYear(),
                month: d.getMonth(),
                is_selected,
                is_curr_month: d.getMonth() == m,
                is_disabled,
                week_day: d.getDay()

            }
            temp_arr.push({...new_day_obj})
            counter++;
        }

        result_arr.push([...temp_arr]);
        temp_arr = [];
    }

    return result_arr;
}


const DayBlock = (props) => {
    let is_selected_class = props.is_selected ? ' selected_day' : '';
    let disabled_class = props.is_disabled ? ' disabled_day' : '';
    let not_curr_month = props.is_curr_month ? '' : ' not_is_curr_month';
    // let animation_type=props.animation_trigger==='prev' ? 'showBlockPrev'
    //     : props.animation_trigger==='next' ?  'showBlockNext' :'showBlockCurr';

    return (
        <div
            year={props.year}
            month={props.month}
            onClick={disabled_class ? () => {
            } : props.on_click}
            key={props.id}
            // style={{animation: `${animation_type} 5s  forwards`}}
            className={'wrap_day' + disabled_class + is_selected_class + not_curr_month}
        >
            <span className={`day_text ${is_selected_class}`}>{props.day_text}</span>
        </div>
    )
}

const CalendarBlock = (props) => {
    const [is_am_pm, set_is_am_pm] = useState(true);
    const [this_date, set_this_date] = useState(
        {
            selected_date: [...props.current_selected_date],
            curr_view_date: props.current_selected_date[0]
        }
    )
    const [current_changing, set_current_changing] = useState('');
    const [options_monthes, set_options_monthes] = useState([]);
    const [options_year, set_options_year] = useState([]);
    let cls = props.classes ? props.classes : '';
    const [trigger_rerender_animate, set_trigger_rerender_animate] = useState([1, 'curr']);
    const [arr_of_days_rend, set_arr_of_days_rend] = useState([]);
    const [first_day_week, set_first_day_weeek] = useState(0);

    const has_got_full_date = props.has_got_full_date ? props.has_got_full_date : true;

    let struct_style = props.struct_style ? props.struct_style : {};

    const generate_year_options = useCallback((disabled_years = []) => {
        let res_arr = [];
        for (let i = 1995; i <= 2055; i++) {
            res_arr.push({
                id: i,
                text: i,
                is_disabled: disabled_years.find((year_index) => year_index === i)
            })
        }
        return res_arr
    }, [])

    const generate_monthes_options = useCallback((disabled_monthes = []) => {
        return monthes.map((month) => {
            return {
                id: month.index_date,
                text: month.full_name,
                is_disabled: disabled_monthes.find((month_index) => month_index === month.index_date)
            }
        })
    }, [])

    const change_one_month = useCallback((is_next) => {
        let new_month = is_next ? this_date.curr_view_date.getMonth() + 1 : this_date.curr_view_date.getMonth() - 1;
        handle_date_chenge(new_month, 'month')
    }, [this_date])


    const handle_date_chenge = useCallback((date, mod = 'day') => {
        let new_date = new Date();
        let is_curr_month = true;
        let is_curr_year = true;
        let {selected_date, curr_view_date} = this_date;

        let copy_curr_view_date = new Date(curr_view_date);
        let copy_selected_date = [...selected_date];

        if (mod === 'day') {
            new_date = date.date_value;

            is_curr_month = date.is_curr_month;
            let only_one_day_selected = selected_date.length === 1;
            let is_current_day = date_with_null_time(new_date) === date_with_null_time(selected_date[0]);
            if (only_one_day_selected && is_current_day) {
                return
            }

            let is_nextly_day = date_with_null_time(new_date) > date_with_null_time(selected_date[0]);
            new_date.setHours(copy_selected_date[0].getHours(), copy_selected_date[0].getMinutes())

            if (props.is_range && only_one_day_selected) {

                let new_range_arr = is_nextly_day ? [selected_date[0], new_date]
                    : [new_date, selected_date[0]];
                set_this_date({
                    curr_view_date: new_date,
                    selected_date: [...new_range_arr]
                })
            } else {

                let new_range_arr = [new_date];
                set_this_date({
                    curr_view_date: new_date,
                    selected_date: [...new_range_arr]
                })
            }
        }

        if (mod === 'month') {
            new_date = new Date(copy_curr_view_date);
            new_date.setMonth(date);

            is_curr_month = false;

            set_this_date({
                curr_view_date: new_date,
                selected_date: copy_selected_date
            })
        }

        if (mod === 'year') {
            new_date = new Date(copy_curr_view_date);
            new_date.setYear(date);

            is_curr_year = false;

            set_this_date({
                curr_view_date: new_date,
                selected_date: copy_selected_date
            })
        }

        if (!is_curr_month || !is_curr_year) {
            let animation_type = date_with_null_time(copy_curr_view_date) < date_with_null_time(new_date)
                ? 'next' : 'prev';
            set_trigger_rerender_animate([Math.random(), animation_type])
        }
    }, [this_date, props.is_range])

    React.useEffect(() => {
        if (
            this_date
        ) {
            // console.log(this_curr_date.getFullYear())
            // let disabled_monthes_ =props.disabled_monthes ? props.disabled_monthes : [];
            // let create_optins_monthes = generate_monthes_options(disabled_monthes_)
            // set_options_monthes(create_optins_monthes)


            //
            // let year = this_curr_date.getFullYear();
            // let month = this_curr_date.getMonth();
            // let day = this_curr_date.getDate()

            let new_arr = generateDateTableBody(this_date, props.is_range, first_day_week)
            set_arr_of_days_rend([...new_arr])
        }

    }, [this_date, props.is_range, first_day_week])

    React.useEffect(() => {
        if (
            props.disabled_monthes
        ) {
            let disabled_monthes_ = props.disabled_monthes ? props.disabled_monthes : [];
            let create_optins_monthes = generate_monthes_options(disabled_monthes_)
            set_options_monthes(create_optins_monthes)
        }

    }, [props.disabled_monthes])

    React.useEffect(() => {
        if (
            props.disabled_years
        ) {
            let disabled_years_ = props.disabled_years ? props.disabled_years : [];
            let create_optins_years = generate_year_options(disabled_years_)
            set_options_year(create_optins_years)
        }

    }, [props.disabled_years])

    let animation_type = trigger_rerender_animate[1] === 'prev' ? 'showBlockPrev'
        : trigger_rerender_animate[1] === 'next' ? 'showBlockNext' : 'showBlockCurr';

    let current_date_for_detach_time_input = current_changing ?
        current_changing.period==='start' ? this_date.selected_date[0] : this_date.selected_date[1] : null;

    return (
        <div>
            <div className={'title_calendar_block'}>
                <span className={'title_calendar_text'}>Calendar</span>
            </div>

                <div
                    className={'wrap_title_calendar'}
                    style={{display: 'flex', justifyContent: 'flex_start', alignItems: 'center', position: 'relative'}}>
                    {
                        get_text_selected_date(this_date.selected_date, props.is_range).map((item, index) => {

                            return (
                                    <div
                                        key={item.id + index}
                                        onClick={() => {
                                            if (item.type === "sub"||item.type === "day") {
                                                return
                                            }
                                            if (current_changing.id===item.id) {
                                                set_current_changing('')
                                            } else {
                                                set_current_changing(item)
                                            }

                                        }}
                                    >
                                <span

                                    style={{color:item.id===current_changing.id ? ' #5e5ab9' : '' }}
                                    className={'title_calendar_full_date'}>{
                                    item.text
                                }</span>
                                    </div>
                                )
                        })
                    }
                    {
                        current_changing.type === 'time' ? (
                            <div className={'changing_time_block'}>
                                <DetachedTimeInputWithAccept
                                    _is_am_pm_={[is_am_pm, set_is_am_pm]}
                                    current_date={current_date_for_detach_time_input}
                                    cancel_change={(time) => {
                                        set_current_changing('')
                                    }}
                                    accept_change={(new_date)=>{

                                        let new_selected_date =[...this_date.selected_date]

                                        if (current_changing.period==='start') {
                                            new_selected_date[0] = new_date;
                                        } else
                                        {
                                            new_selected_date[1] = new_date;
                                        }
                                        set_this_date({
                                            ...this_date,
                                            selected_date: new_selected_date,
                                        })
                                        set_current_changing('')
                                    }}
                                />
                            </div>
                        ) :
                            current_changing.type === 'month' ? (
                                    <div className={'changing_month_block '}>
                                        <div className={'changing_month_block_wrap_list options_scrollbar'}>
                                            {
                                                options_monthes.map((month)=>{
                                                    let is_selected =
                                                        month.id===current_changing.full_date.getMonth() ?
                                                            'is_selected' : '';
                                                    return (
                                                        <div
                                                            onClick={()=>{
                                                                if (is_selected) {
                                                                    set_current_changing('')
                                                                    return
                                                                }
                                                                let new_date = new Date(current_changing.full_date);
                                                                new_date.setMonth(month.id);
                                                                let new_data_to_change ={
                                                                    date_value:new_date
                                                                };
                                                                handle_date_chenge(new_data_to_change, 'day')
                                                                set_current_changing('')
                                                            }}
                                                            className={
                                                                'changing_month_block_wrap_list_item ' +
                                                                is_selected
                                                            }>
                                                            <span>{month.text}</span>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                ) :
                                current_changing.type === 'year' ? (
                                        <div className={'changing_month_block '}>
                                            <div className={'changing_month_block_wrap_list options_scrollbar'}>
                                                {
                                                    options_year.map((year)=>{
                                                        return (
                                                            <div
                                                                onClick={()=>{
                                                                    let new_date = new Date(current_changing.full_date);
                                                                    new_date.setFullYear(year.id);
                                                                    let new_data_to_change ={
                                                                        date_value:new_date
                                                                    };
                                                                    handle_date_chenge(new_data_to_change, 'day')
                                                                    set_current_changing('')
                                                                }}
                                                                className={'changing_month_block_wrap_list_item'}>
                                                                <span>{year.text}</span>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    ) :

                            null
                    }

                </div>
            <div className={'block_functional_calendar'}>
                <div className={'wrap_selects_month_year'}>

                    <span>
                        {
                            this_date.curr_view_date.toLocaleString('default', {month: 'long'}).toUpperCase()
                        }
                    </span>
                    <span style={{marginLeft: 10}}>

                            {
                                this_date.curr_view_date.getFullYear()
                            }

                    </span>

                    {/*<InputSelect*/}
                    {/*    struct_style={{flex: 1}}*/}
                    {/*    value={this_date.curr_view_date.getMonth()}*/}
                    {/*    options={options_monthes}*/}
                    {/*    name={'select_month'}*/}
                    {/*    on_change={(name, id) => {*/}
                    {/*        handle_date_chenge(id, 'month')*/}
                    {/*    }}*/}
                    {/*/>*/}
                    {/*<div style={{flex: 1}}></div>*/}
                    {/*<div style={{flex: 1}}>*/}
                    {/*    <span></span>*/}
                    {/*</div>*/}
                    {/*<InputSelect*/}
                    {/*    struct_style={{flex: 1}}*/}
                    {/*    value={this_date.curr_view_date.getFullYear()}*/}
                    {/*    options={options_year}*/}
                    {/*    name={'select_year'}*/}
                    {/*    on_change={(name, id) => {*/}
                    {/*        handle_date_chenge(id, 'year')*/}
                    {/*    }}*/}
                    {/*/>*/}
                </div>

                <div className={'wrap_week_days_names_calendar'}>
                    {
                        arr_of_days_rend.length ?
                            arr_of_days_rend[0].map((day) => {
                                return (
                                    <div className={'week_day_calendar'}>
                        <span className={'week_day_calendar_text'}>
                            {
                                week_days[day.week_day].short_name
                            }
                        </span>
                                    </div>
                                )
                            }) : null
                    }

                </div>
                <div className={'wrap_calendar_block_with_arrows'}>

                    <div
                        onClick={() => change_one_month()}
                        className={'change_month_btn prev_month_arrow_btn'}>

                        <ArrowChangeMonthCalendar
                            style={{
                                transform: `rotate(${90}deg)`,
                                transition: 'transform 0.4s'
                            }}
                        />
                    </div>

                    <div
                        style={{...struct_style,}}
                        className={`wrap_calendar_block`}>

                        {
                            arr_of_days_rend.map((week_arr, index) => {
                                return (

                                    <div
                                        style={{animation: `${animation_type} 0.4s  forwards`}}
                                        key={'wrap_week_' + index + trigger_rerender_animate[0]}
                                        className={'wrap_week_days'}
                                    >

                                        {
                                            week_arr.map((day) => {
                                                return (
                                                    <DayBlock
                                                        animation_trigger={trigger_rerender_animate[1]}
                                                        on_click={() => {
                                                            handle_date_chenge(day, 'day')
                                                        }}
                                                        key={day.id + trigger_rerender_animate[0]}
                                                        {...day}
                                                    />
                                                )
                                            })
                                        }

                                    </div>
                                )
                            })
                        }


                    </div>

                    <div
                        onClick={() => change_one_month(true)}
                        className={'change_month_btn next_month_arrow_btn'}>
                        <ArrowChangeMonthCalendar
                            style={{
                                transform: `rotate(${-90}deg)`,
                                transition: 'transform 0.4s'
                            }}
                        />
                    </div>

                    {/*<CustomKeyboard*/}
                    {/*    is_show={true }*/}
                    {/*    // handle_change={change_state}*/}
                    {/*    // enable_change_ampm={enable_changing_ampm}*/}
                    {/*/>*/}
                </div>
            </div>


        </div>
    );
};

export default CalendarBlock;
