import React, {useMemo, useState} from 'react';
import Input from "../../form_components/Input";
import cl from './select_time_zone.style.module.css';
import Grow from "@mui/material/Grow";
import {TimezonesContext} from "../../pages/main_home/MainPage";

const SelectTimeZoneComponent = (props) => {
    const timezones = React.useContext(TimezonesContext)
    const [open_popup, set_open_popup] = useState(false)
    const [value, set_value] = useState(timezones.tz_obj[props.value]||'')
    const [current_tz, set_current_tz] = useState(props.value||'')
    const timezones_arr = useMemo(()=>{
        if (current_tz) {
            return [{id:current_tz, value: timezones.tz_obj[current_tz]},
                ...timezones.tz_arr.filter(tz=>tz.id!==current_tz)
            ]
        }
    }, [current_tz, timezones])

    return (
        <div style={{position: 'relative'}}>
        <div
            style={{cursor: 'pointer', position: 'relative'}}
            onClick={()=>{
            set_open_popup(!open_popup)
        }}>
            <div className={cl.clickable_pub}></div>
            <div className={cl.fake_input}>
               <span>{value||'select timezone'}</span>
            </div>
            {/*<Input*/}
            {/*    wrap_style={{margin: 0, cursor: 'pointer', boxSizing: 'border-box', paddingLeft: 16}}*/}
            {/*    value={value}*/}
            {/*    disabled={true}*/}
            {/*    errors={[]}*/}
            {/*    name={'name'}*/}
            {/*    ph={ 'select timezone'}*/}
            {/*    type={'text'}*/}
            {/*/>*/}
        </div>
            <Grow in={open_popup}
                  mountOnEnter={true}
                  unmountOnExit={true}>
            <div className={[cl.timezones_popup, cl.hide_scroll].join(' ')}>
                {timezones_arr.map(tz=>{
                    let classes = [cl.tz_list_item]
                    if (tz.id===current_tz) {
                        classes.push(cl.current)
                    }
                    return (
                        <div
                            key={tz.id}
                            onClick={()=>{
                                props.set_tz(tz.id)
                                set_value(tz.value)
                                set_current_tz(tz.id)
                                set_open_popup(false)
                            }}
                            className={classes.join(' ')}>
                            <span>{tz.value}</span>
                        </div>
                    )
                })}
            </div>
            </Grow>
        </div>
    );
};

export default SelectTimeZoneComponent;