import React from "react";
import {InfoIcon, LogautIcon, MailIcon, SelectLangIcon, SettingIcon} from "../../assets/svg_images";
import RowBlockIndicators from "../manometrs_block/row_indicator_block";

const GetSlide = (props) => {
    switch (props.id) {
        case 'row_block_indicator': {
            return              <RowBlockIndicators
                data_for_indicator={{...props.data_to_slide_component}}
            />
        }
        case 'info': {
            return <InfoIcon class={'info_icon_'+props.mod}
                // style={{width: props.hided ? 32 : 24}}
            />
        }

        default: {
            return <div></div>
        }
    }
}

export default GetSlide;
