import React, { useRef, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from "react-redux";

import withStyles from "@material-ui/core/styles/withStyles";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";

import { makeStyles } from "@material-ui/core/styles";
import ProService from '../../../services/ProPageService';
import { set_env_urls} from "../../../App";

import {glv} from '../../../components/app_components/helper_functions/helper_functions'
import Wrap_Overlay from "../../../components/modals/modal_components/wrappers/overlay_wrappers/Wrap_Overlay";
import WrapModal from "../../../components/modals/modal_components/wrappers/Wrap_Modal";
import Title_Modal from "../../../components/modals/modal_components/Title_Modal";
import ActionsModalButtons from "../../../components/modals/modal_components/Actions_Buttons_Modal";
import useWindowSize from "../../../hooks/useWindowSize";
import FormReportModal from "./content/content_report_modal";
import {usePortal} from "../../../hooks/usePortal";


let template_report_form = {
  name: '',
  contact: '',
  text: '',
}

const ReportModal = props => {
    const Portal = usePortal('report_modal_portal');
  const size = useWindowSize();

  const language = useSelector((state) => state.envReducer.language);

  const [form, set_form] = React.useState(
      template_report_form
  );

  const [errors, set_errors] = React.useState(
      {
        name: [],
        contact: [],
        text: [],
      }
  );
  const validate_ = React.useCallback((name, value)=>{
    let new_arr_errs = [];
    const callback_set = ()=>set_errors({...errors, [name]: new_arr_errs});

    switch (name) {
      case 'name': {
        if (value.length < 6) {
          new_arr_errs.push('min_length_6')
        }
        if ( value.length >= 40) {
          new_arr_errs.push('max_length')
        }
        let re = /^[a-zA-Z0-9!?\^&\]*\:()\;[{}(|+=._-]*$/

        if ( !re.test(value)) {
          new_arr_errs.push('spec_sym_allow')
        }
        return callback_set()
      }
      case 'contact': {
        if (value.length < 6) {
          new_arr_errs.push('min_length_6')
        }
        if ( value.length >= 40) {
          new_arr_errs.push('max_length')
        }
        return callback_set()
      }
    }
  }, [form])

  const handle_change_form =React.useCallback(
      (name, value)=>{
        validate_(name, value)
        set_form({...form, [name]:value})
      },
      [form]
  )

  const check_allow_submit =React.useCallback(
      ()=>{
        if(!form.name||!form.contact||!form.text) {
          return false;
        }
        let has_some_error =

            errors.name.length||
            errors.contact.length||
            errors.text.length;
        if (has_some_error) {
          return false
        }
        return true;
      },
      [form, errors]
  )


  const submit_report = useCallback(async () => {
    await ProService.contactForm(
        (data)=>set_env_urls(data) ,
        props.id,
        {
      ...form,
      lang: language,
    });
    props.close();
    // set_opened_modal(false)
    // showToast(glv(`contact_us.alert.info` ), 300);
    set_form(template_report_form)
  }, [form, language, props.close])



  
  return     (
      <Portal>
      <Wrap_Overlay
          open={props.open}
          hidden_body={true}
          handle_click_overlay={()=>{
            props.close()
            // set_opened_modal(false)
          }}
          class_position_children={'overlay_children_position'}
      >
        <WrapModal>
          <Title_Modal
              title_text={glv(`first_level_blocks.report_modal.title`)}
          />
          <FormReportModal
              style={{maxHeight: size.h-210}}
              form={form}
              on_change_form={(name, value)=>handle_change_form(name, value)}
              errors={errors}
          />
          <ActionsModalButtons
              disabled_acc_btn={
                !check_allow_submit()
              }
              has_cancel={false}
              has_accept={true}
              text_accept_btn={glv(`first_level_blocks.report_modal.submit`)}
              style={{marginTop:30}}
              accept_action={()=>{
                submit_report()
              }}
              cancel_action={()=>{
                props.close()
                // set_opened_modal(false)
              }}
          />
        </WrapModal>
      </Wrap_Overlay>
      </Portal>
  )
}

export default ReportModal;