import get_beautiful_date from "./get_beautiful_serial_number";

export default function create_dates_text_arr(date, id, lang) {
    let day = get_beautiful_date(date.getDate());
    let month_num_val = date.getMonth();
    let month = date.toLocaleString(lang ? lang : 'default', {month: 'long'});
    // let month = monthes[month_num_val].full_name;
    let year = date.getFullYear();

    let hours = get_beautiful_date(date.getHours());
    let mins = get_beautiful_date(date.getMinutes());
    return [
        {
            id: `day_${id}`,
            type: 'day',
            period: id,
            num_value: day,
            text: `${day} `,
            full_date: new Date(date)
        },
        {
            id: `month_${id}`,
            type: 'month',
            period: id,
            num_value: month_num_val,
            text: `${month} `,
            full_date: new Date(date)

        },
        {
            id: `year_${id}`,
            type: 'year',
            period: id,
            num_value: year,
            text: `${year} `,
            full_date: new Date(date)
        },
        {
            id: `time_${id}`,
            type: 'time',
            period: id,
            text: `${hours}:${mins}`,
            full_date: new Date(date)
        },
    ]
}