import create_dates_text_arr from "./create_full_date_arr_for_render";

export  default function get_text_selected_date(date, is_range) {
    let sub_to = is_range ? ' - ' : '';
    let res_arr = create_dates_text_arr(date[0], 'start')

    if (sub_to) {
        res_arr.push(
            {
                id: 'sub_to',
                type: 'sub',
                text: sub_to
            }
        )
    }

    if (date.length > 1) {
        res_arr = res_arr.concat(create_dates_text_arr(date[1], 'finish'))
    }
    return res_arr;
}