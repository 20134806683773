import React from "react";
import { glv} from "../../components/app_components/helper_functions/helper_functions";
import Collapse from '@material-ui/core/Collapse';


const TextError = (props) => {
    return (
        <span className={'err_text'}>{glv(`jointFormData.errors.${props.error_name}`)}</span>
    )
}

const Input = (props) => {
    let cls = props.classes ? props.classes : '';
    let wrap_style_cust = props.wrap_style ? props.wrap_style : {};
    let has_icon_class = props.has_icon ? 'pd_inp_with_eye' : 'pd_inp';
    let has_errors = props.errors?.length ? true : false;
    let with_err_class = has_errors ? 'with_errs' : '';
    return (
        <div
            className={`wrap_input ${with_err_class} w100 pos_rel ${props.no_mt ? '' : 'mt_30'} inset_bg_shadow fl_ fl_d_c`}
            style={{...wrap_style_cust}}
        >
            {
                props.multiline ?
                    <textarea
                        className={`text_area_style`+cls}
                        value={props.value}
                        onChange={props.on_change}
                        name={props.name}
                        placeholder={
                            props.ph
                        }
                        rows={props.rows? props.rows : ''}
                        cols={props.cols? props.cols : ''}
                    /> :
                    <input
                        style={{
                            opacity: props.disabled ? 0.6 : '',
                            color: props.disabled ? '#2F2A61' : '',
                            borderBottom:  props.errors?.length ? '1px solid rgba(0,0,0,0.2)':'',
                            borderRadius: '0px'
                    }}
                        autoComplete={props.autocomplete}
                        type={props.type}
                        className={`regist_input_style  ${has_icon_class} `+cls}
                        disabled={props.disabled?true : false}
                        name={props.name}
                        placeholder={
                            props.ph
                        }
                        value={props.value}
                        onChange={props.on_change}
                    />
            }

            {
                props.has_icon ?
                    <div
                        className={'pos_abs wrap_icon wrap_icon_input'}
                         onClick={props.icon_press}
                    >
                        {props.icon}
                    </div> : null
            }
            <Collapse in={has_errors} >
                <div className={'wrap_err_text'}>
                    {props.errors?.length ?
                        props.errors.map(error=>{
                            return (
                                <TextError error_name={error}/>
                            )
                        }) : null
                    }
                </div>
            </Collapse>


        </div>
    );
};

export default Input;
