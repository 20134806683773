import React, {useRef, useEffect, useCallback} from 'react'
import RadioBtn from "../../../custom_components/radioBtn";
import {glv} from "../../../app_components/helper_functions/helper_functions";
import {states_look_manometrs} from "../../../pages/pro/current/static_datas";
import Checkbox from "../../../custom_components/checkbox";
import {Collapse} from "@material-ui/core";


const SettingModalContent = props => {
    const [setting_form, set_setting_form] = props.setting_form;

    const change_header_position =useCallback((path, val)=>{
        let new_val = val === 'hor';
        let copy_sett_form = {...setting_form};
        copy_sett_form.header_position[path] = new_val;
        set_setting_form({
            ...copy_sett_form
        })
    }, [setting_form])

    const change_look_manometrs =useCallback( (path, val)=>{
        let copy_sett_form = {...setting_form};
        if (path==='is_two_column_all_column_d'||path==='is_two_column_all_column_m') {
            copy_sett_form.look_manometrs[path] = val;
            set_setting_form({
                ...copy_sett_form
            })
            return
        }
        let is_d_change = path==='desktop_look';
        let two_column_val = false;

        if (val==='all_column') {
            two_column_val= true;
        }
        if (is_d_change) {
            copy_sett_form.look_manometrs['is_two_column_all_column_d'] = two_column_val;
        } else {
            copy_sett_form.look_manometrs['is_two_column_all_column_m'] = two_column_val;
        }
        copy_sett_form.look_manometrs[path] = val;
        set_setting_form({
            ...copy_sett_form
        })
    },[setting_form])


    return (
        <div className={'wrap_setting_content'}>
            <div className={'wrap_block_header_position_setting'}>
                <div className={'wrap_title_block_header_position_setting'}>
                    <span>{glv(`first_level_blocks.setting_modal.header_position.title`)}</span>
                </div>

                <div className={'wrap_blocks_d_and_m_header_setting'}>
                    <div className={'wrap_block_desktop_header_setting'}>
                        <div className={'wrap_title_desktop_header_setting'}>
                            <span>{glv(`first_level_blocks.setting_modal.header_position.for_desktop`)}</span>
                        </div>
                        <div className={'wrap_select_header_position'}>
                            {
                                ['hor', 'ver'].map((item, index) => {
                                    return (
                                        <RadioBtn
                                            key={item + index + 'desk'}
                                            id={item}
                                            checkedId={setting_form.header_position.desktop_is_horizontal
                                                ? 'hor' : 'ver'}
                                            setCheckedId={
                                                (val) => change_header_position('desktop_is_horizontal', val)
                                            }
                                            mt={12}
                                            label={glv(`first_level_blocks.setting_modal.header_position.${item}`)}
                                        />
                                    )
                                })
                            }
                        </div>
                    </div>
                    {/*<div className={'vertical_divider_blocks'}></div>*/}
                    <div className={'wrap_block_mobile_header_setting'}>
                        <div className={'wrap_title_mobile_header_setting'}>
                            <span>{glv(`first_level_blocks.setting_modal.header_position.for_mobile`)}</span>
                        </div>

                        <div className={'wrap_select_header_position'}>
                            {
                                ['hor', 'ver'].map((item, index) => {
                                    return (
                                        <RadioBtn
                                            key={item + index + 'mob'}
                                            id={item}
                                            checkedId={
                                                setting_form.header_position.mobile_is_horizontal
                                                    ? 'hor' : 'ver'}
                                            setCheckedId={
                                                (val) => change_header_position('mobile_is_horizontal', val)
                                            }
                                            mt={12}
                                            label={glv(`first_level_blocks.setting_modal.header_position.${item}`)}
                                        />
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>

            <div className={'wrap_block_look_manometrs_setting'}>
                <div className={'wrap_title_block_look_manometrs_setting'}>
                    <span>{glv(`first_level_blocks.setting_modal.look_manometrs.title`)}</span>
                </div>

                <div className={'wrap_blocks_d_and_m_look_manometrs_setting'}>
                    <div className={'wrap_block_desktop_look_manometrs_setting'}>
                        <div className={'wrap_title_desktop_look_manometrs_setting'}>
                            <span>{glv(`first_level_blocks.setting_modal.look_manometrs.for_desktop`)}</span>
                        </div>
                        <div className={'wrap_select_look_manometrs'}>
                            {
                                states_look_manometrs.map((item, index) => {
                                    return (
                                        <RadioBtn
                                            key={item + index + 'desk'}
                                            id={item}
                                            checkedId={setting_form.look_manometrs.desktop_look}
                                            setCheckedId={
                                                (val) => change_look_manometrs('desktop_look', val)
                                            }
                                            mt={12}
                                            label={glv(`first_level_blocks.setting_modal.look_manometrs.${item}`)}
                                        />
                                    )
                                })
                            }
                            <Collapse in={setting_form.look_manometrs.desktop_look==='all_column'}>
                                <div className={'wrap_checkbox_two_column'}>
                                    <Checkbox
                                        checked={setting_form.look_manometrs.is_two_column_all_column_d}
                                        on_change={
                                            () => {
                                                change_look_manometrs(
                                                    'is_two_column_all_column_d',
                                                    !setting_form.look_manometrs.is_two_column_all_column_d
                                                )
                                            }
                                        }
                                    />
                                    <span className={'title_checkbox_two_col'}>две колонки индикаторов</span>
                                </div>
                            </Collapse>
                        </div>
                    </div>
                    {/*<div className={'vertical_divider_blocks'}></div>*/}
                    <div className={'wrap_block_mobile_look_manometrs_setting'}>
                        <div className={'wrap_title_mobile_look_manometrs_setting'}>
                            <span>{glv(`first_level_blocks.setting_modal.look_manometrs.for_mobile`)}</span>
                        </div>

                        <div className={'wrap_select_look_manometrs'}>
                            {
                                states_look_manometrs.map((item, index) => {
                                    return (
                                        <RadioBtn
                                            key={item + index + 'mob'}
                                            id={item}
                                            checkedId={setting_form.look_manometrs.mobile_look}
                                            setCheckedId={
                                                (val) => change_look_manometrs('mobile_look', val)
                                            }
                                            mt={12}
                                            label={glv(`first_level_blocks.setting_modal.look_manometrs.${item}`)}
                                        />
                                    )
                                })
                            }
                            <Collapse in={setting_form.look_manometrs.mobile_look==='all_column'}>
                                <div className={'wrap_checkbox_two_column'}>
                                    <Checkbox
                                        checked={setting_form.look_manometrs.is_two_column_all_column_m}
                                        on_change={
                                            () => {
                                                change_look_manometrs(
                                                    'is_two_column_all_column_m',
                                                    !setting_form.look_manometrs.is_two_column_all_column_m
                                                )
                                            }
                                        }
                                    />
                                    <span className={'title_checkbox_two_column'}>две колонки индикаторов</span>
                                </div>
                            </Collapse>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default SettingModalContent;