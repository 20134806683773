import React, {useState} from 'react';
import Wrap_Overlay from "../modal_components/wrappers/overlay_wrappers/Wrap_Overlay";
import WrapModal from "../modal_components/wrappers/Wrap_Modal";
import CrashIcon from "../crash_modal/title_crash_icon";
import Title_Modal from "../modal_components/Title_Modal";
import ActionsModalButtons from "../modal_components/Actions_Buttons_Modal";
import Input from "../../form_components/Input";
import {glv} from "../../app_components/helper_functions/helper_functions";
import cl from './add_new_sys_modal.module.css';
import {CorrectIcon} from "../../../assets/svg_images";
import Grow from "@mui/material/Grow";
import ProService from "../../../services/ProPageService";
import SmallLoaderComponent from "../../ui/small_loader/small_loader.component";


const AddNewSysModal = ({open, title, close}) => {
    const [is_fetching, set_is_fetching] = useState(false)
    const [is_success, set_is_success] = useState(false)
    const [ref_cod, set_ref_cod] = useState('')
    const [errors, set_errors] = useState([])

    const handle_change_ref_code = (e) => {
        set_errors([])
        set_ref_cod(e.target.value)
    }

    const add_new_system_ = () => {
        set_is_fetching(true)
        ProService.add_new_system(ref_cod).then(
            (res)=>{
                if (res.code===404) {
                    set_errors(
                        errs=>
                            [...errs.filter(el=>el!=='not_found'),
                                'not_found'])
                }

                if (res.code===400) {
                    set_errors(errs=>[...errs, 'wrong_ref'])
                }

                if (res.code===200) {
                    set_errors([])
                    set_is_success(res.info?.rez?.add_system)
                }
                set_is_fetching(false)

            }
        )
    }

    return (
        <Wrap_Overlay
            open={open}
            z_index={950}
            hidden_body={true}
            handle_click_overlay={()=>{
                // set_opened_modal(false)
                // props.close()
            }}
            class_position_children={'overlay_children_position'}
        >
            <WrapModal>

                <Title_Modal
                    title_text={glv(`first_level_blocks.new_sys_modal.title`)}
                />
                <div className={cl.wrap_input_ref_code}>
                    <Input
                        has_icon={
                        is_fetching||is_success
                    }
                        icon={
                            is_fetching ?
                                <SmallLoaderComponent />
                                :                    is_success ?
                                    <CorrectIcon

                                        class={cl.success_icon}/>
                                    : null
                        }
                        wrap_style={{marginTop:0}}
                        autocomplete={'off'}
                        value={ref_cod}
                        disabled={is_success}
                        errors={errors}
                        name={'name'}
                        on_change={handle_change_ref_code}
                        ph={ glv(`first_level_blocks.new_sys_modal.plh_ref_code`)}
                        type={'text'}
                    />

                </div>

                <ActionsModalButtons
                    disabled_acc_btn={!ref_cod||is_fetching ? true :false}
                    has_cancel={true}
                    has_accept={true}
                    text_accept_btn={is_success ? glv('joint_buttons.go_to') :glv('joint_buttons.add')}
                    text_cancel_btn={glv('joint_buttons.close')}
                    style={{marginTop:30}}
                    accept_action={()=>{
                        if (is_success) {
                            window.location.href = is_success;
                            return
                        }
                        add_new_system_()
                    }}
                    cancel_action={()=>{

                        close()
                        set_ref_cod('')
                        set_errors([])
                    }}
                />
            </WrapModal>
        </Wrap_Overlay>
    );
};

export default AddNewSysModal;