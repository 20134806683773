import React, { useEffect, useCallback, useState } from "react";
import Grow from '@material-ui/core/Grow';



const Wrap_Overlay = (props) => {
    let z_index = props.z_index ? props.z_index : 1015;

    let class_position_children = props.class_position_children ? props.class_position_children : '';
    return (
        <Grow in={props.open} mountOnEnter unmountOnExit>
            <div
                className={`overlay_clear ${class_position_children}`}
                style={{zIndex: z_index}}
            >
                <div
                    className={`overlay_`}
                    onClick={props.handle_click_overlay}>
                    {/*<div className={'pos_rel w100 h100'}>*/}

                    {/*</div>*/}

                </div>
                {/*<div className={'pos_rel w100 h100'}>*/}
                    {props.children}
                {/*</div>*/}

            </div>
        </Grow>
    );
};

export default Wrap_Overlay;
