

export const monthes = [
    {
    id: 'jan.0',
    index_date: 0,
    short_name: 'jan',
    full_name: "January"
},
    {
        id: 'feb.1',
        index_date: 1,
        short_name: 'feb',
        full_name: "February"
    },
    {
        id: 'mar.2',
        index_date: 2,
        short_name: 'mar',
        full_name: "March"
    },
    {
        id: 'apr.3',
        index_date: 3,
        short_name: 'apr',
        full_name: "April"
    },
    {
        id: 'may.4',
        index_date: 4,
        short_name: 'may',
        full_name: "May"
    },
    {
        id: 'jun.5',
        index_date: 5,
        short_name: 'jun',
        full_name: "June"
    },

    {
        id: 'jul.6',
        index_date: 6,
        short_name: 'jul',
        full_name: "July"
    },

    {
        id: 'aug.7',
        index_date: 7,
        short_name: 'aug',
        full_name: "August"
    },
    {
        id: 'sep.8',
        index_date: 8,
        short_name: 'sep',
        full_name: "September"
    },
    {
        id: 'oct.9',
        index_date: 9,
        short_name: 'oct',
        full_name: "October"
    },
    {
        id: 'nov.10',
        index_date: 10,
        short_name: 'nov',
        full_name: "November"
    },
    {
        id: 'dec.11',
        index_date: 11,
        short_name: 'dec',
        full_name: "December"
    },
 ];

export const week_days = [
    {
        short_name: 'sun'
    },
    {
        short_name: 'mon'
    },
    {
        short_name: 'tue'
    },
    {
        short_name: 'wed'
    },
    {
        short_name: 'thu'
    },
    {
        short_name: 'fri'
    },
    {
        short_name: 'sat'
    },

]

