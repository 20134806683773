import React, { useEffect, useCallback } from "react";
import LightButton from "../../custom_components/light_button";
import Backdrop from "@material-ui/core/Backdrop";
import CustomImageLoader from "react-custom-image-loader.";
import {Notifications_History_Context} from "../../wrappers/notification_wrapper/notifications_wrapper";
import LightSelectButton from "../../custom_components/light_select_button";
import ProService from "../../../services/ProPageService";
import {setlogos} from "../../app_components/helper_functions/helper_functions";
import LanguageIcon from '@material-ui/icons/Language';
import './router_page.style.css';
import RouterHeaderComponent from "./header/router_header.component";


const RouterPage = (props) => {

    const [showToast, close_all_notification] = React.useContext(Notifications_History_Context);
    const [urls, setUrls] = React.useState(null);

    const getUrls = async () => {

        let res = await ProService.get_accesseed_links();

        if (!res) {
            // window.location.href= '/page_info#404'
            return
        }
        if(res.code ===200 || res.code ===201){
            if (res?.info.constructor !== Object) {
                window.location.href = 'https://i.bregus.eu/page_info#404';
                return
            }
            if (!res?.info?.hasOwnProperty('home')) {
                window.location.href = 'https://i.bregus.eu/page_info#404';
                return
            }
            if (!Object.keys(res.info).length) {
                // window.location.href= '/page_info#404'
                window.location.href = "i.bregus.eu/page_info#404"
                return
            }
            let res_home = res.info.home;
            if (Object.keys(res_home.all_dev).length===1) {
                if(res_home.url_path.constructor !== String){
                    showToast('С бека в ссылке пришла не строка', 'error')
                    setlogos({text: 'С бека в ссылке пришла не строка'}, 'select redirect page')
                    setTimeout(()=>{
                        window.location.href = 'https://i.bregus.eu/page_info#404';
                    }, 2000)
                    return
                }

                let domain_ = res_home.domain;

                if(domain_.constructor !== String) {
                    domain_ = "home.bregus.eu";
                }
                let url_path_ = res_home.url_path;
                window.location.href='https://'+domain_+url_path_;
                return
            }

            let copy_res_home = {...res_home.all_dev};
            Object.keys(res_home.all_dev).forEach(key=>{
                let dev = res_home.all_dev[key];
                let domain = "home.bregus.eu";
                let url_path = dev.type === 'fiz' ? `/${key}` : `/pro/${key}`;

                copy_res_home={...copy_res_home, [key]:{
                        ...copy_res_home[key],
                        domain,
                        url_path,
                    }
                };

            })
            // console.log(copy_res_info)
            await setUrls(copy_res_home);
            return;
        }
        window.location.href = 'https://i.bregus.eu/page_info#404';
        // window.location.href= '/page_info#404'
    };

    useEffect(()=>{
        if (!urls){
            getUrls();
        }

    },[urls])

    return urls?  (
            <div className={'center_container'}>
                <RouterHeaderComponent/>
                {/*<div style={{width: '100%', height: 60}}></div>*/}
                <div className={'router_page_container_wrap'}>
                    <div className={'router_page_container'}>

                        <h1
                            className={'select_page_title'}
                        >
                            {
                                'Select route'
                            }
                        </h1>

                        <div
                            className={'wrap_routes'}
                        >
                            {
                                Object.keys(urls).map((item, index)=> {
                                    if (urls[item].has_col_select) {
                                        return (
                                            <LightSelectButton
                                                key={item+index}
                                                text={ item}
                                                cols={Object.keys(urls[item].all_dev).map((key)=>{
                                                    let dev_ = urls[item].all_dev[key];
                                                    return {
                                                        ...dev_,
                                                        id_dev: key
                                                    }
                                                })}
                                                domain = {urls[item].domain}
                                                main_redirect = {
                                                    'https://' + urls[item].domain + urls[item].url_path
                                                }
                                            />
                                        )
                                    }
                                    return <LightButton
                                        key={item+index}
                                        on_click={
                                            () => {
                                                window.location.href = 'https://' + urls[item].domain + urls[item].url_path
                                            }
                                        }
                                        cls={'route_btn'}
                                        text={
                                            <span>
                                        <span className={'route_btn_key_text'}>
                                            {urls[item].type==='fiz' ?null :
                                                <span>{item}:{' '}</span>}
                                        </span>
                                        <b>{urls[item].name}</b>
                                    </span>
                                        }
                                    />
                                })
                            }
                            <div className={'to_home_btn_select_page'}>
                    <span
                        onClick={() => {
                           window.location.href = 'https://i.bregus.eu/select_page'
                        }}
                        className={' text_btn'}
                    >
                    {
                        'На страницу глобальных роутов'
                    }
                    </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

) :  <Backdrop open={true}>
        <CustomImageLoader
            image={`${process.env.PUBLIC_URL}/favicon.ico`}
            isLoaded={true}
            circle={false}
            speed={2}
            animationType={"pulse"}
        />
    </Backdrop>
}


export default RouterPage;