import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/core/styles';
import { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import {format} from 'mathjs'
import {glv} from "../../../../components/app_components/helper_functions/helper_functions";
import useMediaQuery from '@material-ui/core/useMediaQuery';

let perc_ids = {
  r1f1r1: 0, 
  r1f1r2: 0, 
  r1f2r1: 0, 
  r1f2r2: 0,
  r2f1r1: 0, 
  r2f1r2: 0,
  r2f2r1: 0, 
  r2f2r2: 0,
  r3f1r1: 0, 
  r3f1r2: 0,
  r3f2r1: 0, 
  r3f2r2: 0,
}

function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min)) + min; 
  
  //Максимум не включается, минимум включается
}

let globProc = 0;
const RadialProgress = (props) => {
  
    const [perc, setPerc] = React.useState(0);
    const canvasRef = React.useRef(null)

    const radial_query = useMediaQuery('(max-width:500px)');

    let globPerc_rad_1 = 0;
let globPerc_rad_2 = 0;
const lineWid = 8;

useEffect(() => {

  const canvas = canvasRef.current;
  const ctx = canvas.getContext("2d");

}, [perc]);


useEffect(() => {

  if (props.max&&props.percents &&props.idPr ) {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    let chPr = props.percents> props.max ? 100 : props.percents*100/props.max;
    // globProc = chPr;
    animRad(canvas, Math.round(chPr), props.idPr);
  }

}, [
  props.percents, props.idPr, props.max, radial_query
]);
// получение цвета для радиалки по айдишнику

function getGrd(id, ctx) {
    let grd = ctx.createLinearGradient(0,0,244,0);
    grd.addColorStop(0.01,"#17C8FF");
    grd.addColorStop(0.8,"#7EE6FF");
    return grd;
}


// сама функция выполняющая анимацию
function animRad(canvas, protsy, id, isAnim) {
    const ctx = canvas.getContext("2d");
    var grd = getGrd(id, ctx);
    let checkedProts = protsy;

    function asd(p, canv, color, holst) {

        let pr = p;
        rec();
        function rec() {

            // сама отрисовка
            canv.clearRect(0, 0, holst.width, holst.height);
            canv.beginPath();
            canv.lineWidth = lineWid;
            canv.arc(holst.width/2,holst.height/2, holst.width/2-4.5,-Math.PI/2,(- Math.PI/2-(Math.PI*2-Math.PI*2*((100-perc_ids[id])/100))), true);

            // 2*Math.PI*globProc/100
            canv.strokeStyle = color;
            canv.stroke();
            if(perc_ids[id] === pr) {
                // console.log('stop'+id);
                return;
            }
            // прогрессия скорости анимации
            if(perc_ids[id] > pr) {
                // globProc-=1;
                // console.log('up'+id);
                perc_ids={...perc_ids, [id]: perc_ids[id]-1}
                // setGlobProc(globProc-1)
                setTimeout(rec ,4);
            }

            if(perc_ids[id] < pr) {
              // console.log('down'+id);
                // globProc+=1;
                perc_ids={...perc_ids, [id]: perc_ids[id]+1}
                // setGlobProc(globProc+1)
                setTimeout(rec ,4);
            }

        }
    }

    if(isAnim) {

    } else {

        asd(checkedProts,ctx,grd, canvas);
    }

}
let cust_style = props.style? props.style :{};
    // const [degTear, setDegTear] = React.useState(233);
    return (
        <div className="wrap_oval" style={{...cust_style, marginTop: 10, }}>
          {/* {props.max} */}
          <span className="title_oval" style={{wordBreak: 'break-word', color: '#2f2a61'}}>{
              glv('first_level_blocks.statistic.radial.'+props.id+'.name')

          }</span>
          <div className="oval" id="first_rad" style={{marginLeft: 10}}>
            <div className="in_oval">
              <div className="in_oval_two">

                <canvas  ref={canvasRef}  id={props.id+Math.random()} width={radial_query ? 48 : 80} height={radial_query ? 48 : 80} className="canvRad" />
                <div className="final_oval">
                  <ui>
                    {/* {props.percents*100/ props.max} */}
                  {
                      format(props.percents, { precision: 3 })
                  }
                  </ui>
                  <a>{
                      glv('first_level_blocks.statistic.radial.'+props.id+'.quantity')
                  }</a>
                </div>
              </div>
            </div>
          </div>
        </div>
    )
}

export default RadialProgress;