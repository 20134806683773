import React from "react";

import { useSelector } from "react-redux";
import './styles/vm_header.css';

import {glv} from "../../app_components/helper_functions/helper_functions";
import {open_lang_modal_context} from "../../../App";
import {LeftArrow, RightArrow} from "../../../assets/svg_images";
import * as PropTypes from "prop-types";
import HeaderMenuBlockVM from "./menu_block_vm";
import HeaderBarVM from "./header_bar_vm";





const HeaderVM = (props) => {
    //vertical side bar

   const [hided, set_hided] = props.hided_data;

    return (
        <HeaderBarVM position="static" style={{background: "#5E5AB9"}} is_hided={hided}>
          {/*<div className={'bg_overlay_menu_vm'}  style={{display: hided? 'none' : ''}}>*/}

          {/*</div>*/}


            <div className={'wrap_menu_struct_blocks_vm'}>

                <div className={'wrap_menu_blocks_vm'}>
                    {
                        props.punkts.map(punkt=>{
                            return (
                                <HeaderMenuBlockVM
                                    child={punkt.child ? punkt.child : null}
                                    hided={hided}
                                    id_name={punkt.id}
                                    action={()=>punkt.action()}
                                    title={glv('header.menu_buttons.'+punkt.id)}
                                />
                            )
                        })

                    }
                </div>
                <div className={'wrap_menu_hide_btn_vm'}  onClick={()=>{set_hided(!hided)}}>
                    <div className={'menu_hide_btn_vm'}>
                        <div className={'wrap_icon_menu_hide_btn_vm'}>
                            <LeftArrow class={`left_arr_icon_vm ${hided ? 'opened' : ''}`}/>
                        </div>

                        <div className={'wrap_menu_hide_btn_title_vm'}>

                            <span className={'menu_hide_btn_title_vm'}>
                                         {
                                             hided ? glv('header.menu_buttons.collapse')
                                                 : glv('header.menu_buttons.uncollapse')
                                         }
                        </span>
                        </div>

                    </div>

                </div>

            </div>

        </HeaderBarVM>

    );
}
export default HeaderVM;
