import React from "react";
import {glv} from "../app_components/helper_functions/helper_functions";
import GagPub from "../pubs/gag_pub";

const BlocksWrapper = (props) => {
    const container_sizes = React.useRef(null);
    const get_container_dimensions = () => {
        if (container_sizes.current) {
            let ratio = window.devicePixelRatio;
            ratio= ratio<0? 1: ratio

            props.set_dimensions_container ? props.set_dimensions_container({
                width: container_sizes.current.offsetWidth,
                height: container_sizes.current.offsetHeight,
                ratio
            }) : console.log();
        }
    }

    React.useEffect(() => {
        get_container_dimensions();
        window.addEventListener('resize', ()=>{
            get_container_dimensions()
        });

    }, []);

    let wrap_cls = props.wrap_cls ? props.wrap_cls : '';
    let max_width = props.max_width ? props.max_width : '';
    let id_content_wrap = props.id_content_wrap ? props.id_content_wrap : '';
    return (
        <div
            style={{
                padding: props.no_pd ? '' :`0px ${props.is_desktop ? 50 : 25}px`,
                marginTop:props.no_mt ? 0 : 50}}
            id={props.id_block}
        >
        <>
            {/*{*/}
            {/*    !props.is_desktop ?*/}
            {/*        <div className={'wrap_mobile_header_title_block'}>*/}
            {/*            <span className={'mobile_header_title_block'}>*/}
            {/*                {*/}
            {/*                    props.header_title*/}
            {/*                  }*/}
            {/*            </span>*/}
            {/*        </div>*/}
            {/*        : null*/}
            {/*}*/}

            <div
                id={id_content_wrap}
                style={{
                    maxWidth: max_width,
                    paddingTop: !props.is_desktop? 0 : ''
            }}
                className={`content_wrap hide_scroll ${!props.is_desktop? 'mobile_view': ''} ${wrap_cls}`}
                ref={container_sizes}
            >
                {
                    props.is_desktop ?
                        <div className={'desktop_header_title_block'}>  {
                            glv(`first_level_blocks.block_titles.${props.id_block}`)
                        }</div> : null
                }
                {
                    props.has_gag ?
                        <GagPub/>
                        : null
                }
                {props.children}
            </div>

        </>
        </div>
    );
};

export default BlocksWrapper;
