import './App.css';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import appInfo from "../package.json";
import {
    BrowserRouter,
    Router,
} from "react-router-dom";
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import * as actions from "./redux/actions/env.actions";
import {useDispatch, useSelector} from "react-redux";
import Cookies from "js-cookie";
import Backdrop from "@material-ui/core/Backdrop";
import CustomImageLoader from "react-custom-image-loader.";
import {assocPath, dissocPath, drop, remove, pathOr, path} from "ramda";
import {createBrowserHistory} from "history";
import ProService from './services/ProPageService';
import {detect_device} from "./helper_functions/helpers";
import AppRouter from "./router/router";
import {crash_modal_is_open} from "./components/app_components/providers/wrapper_app";
import {glv} from "./components/app_components/helper_functions/helper_functions";
import SelectLangModal from "./components/modals/select_lang_modal/select_lang_modal";
import useWindowSize from "./hooks/useWindowSize";

const history = createBrowserHistory();

am4core.useTheme(am4themes_animated);

export const Values_to_MP_Context = React.createContext();
export const IS_DESKTOP_CONTEXT = React.createContext();
export const MP_ID_Context = React.createContext();
export const get_lang_tree_context = React.createContext();
export const open_lang_modal_context = React.createContext();

export const local_hash = '22da20a00d047bf4662422b247de845339055946';

export let localization_tree = {}

export let is_rel_b = true;

export let env_urls = null;

export let hash_key = null;

export let is_mobile = null;

export let set_hash_key = (hash) => {
    hash_key = hash
}

export let set_env_urls = (urls) => {
    env_urls = {...urls}
}

let init_steps = {
    load_external_script: false
}

export let current_language = 'ru';

export const set_curr_lang = (lang)=>{
    current_language = lang;
}

export let lang_conf = {};

export const  check_goshan = (url, hash, api_key, path) => {
    if (window.location.pathname === 'test_page') {
        return true
    }

    //проверяем все доступные ссылки и смотрим на валидность переходимой ссылки
    return ProService.get_accesseed_links( url,  api_key).then((res) => {

        if (res?.info.constructor !== Object) {
            window.location.href = 'https://i.bregus.eu/page_info#404';
            return
        }
        if (!res?.info.hasOwnProperty('home')) {
            window.location.href = 'https://i.bregus.eu/page_info#404';
            return
        }
        // if (is_rel_b) {
        //     let arr_for_check = [res.info.home.url_path, '/router_page'];
        //     let home_obj = res.info.home;
        //     Object.keys(home_obj.all_dev).forEach((key) => {
        //         let dev = home_obj.all_dev[key];
        //         let url_path_ = dev.type === 'fiz' ? `/${key}` : `/pro/${key}`;
        //         arr_for_check.push(url_path_)
        //     })
        //     let checked_curr_url_index = arr_for_check.findIndex((url_path) => url_path === window.location.pathname);
        //     if (checked_curr_url_index !== -1) {
        //         let str_ = arr_for_check[checked_curr_url_index];
        //         // set_mp_id(str_.slice(1, str_.length))
        //         return true
        //     }
        //     else {
        //         window.location.href = '/router_page';
        //         return
        //     }

        return true

    })
}


function App(props) {
    const {width, height}  = useWindowSize()
    const ref_inited = useRef(false);
    const ref_is_mobile = useRef(true);
    const dispatch = useDispatch();
    // const { id } = useParams();
    const [rs_to_mp, set_rs_to_mp] = React.useState(null);
    const [mp_id, set_mp_id] = React.useState(null);
    const [using_mob, set_using_mob] = React.useState(false);
    const [inited, set_inited] = useState(false);
    const [changed_tree, set_changed_tree] = useState(null);
    const [open_lang_modal, set_open_lang_modal] = useState(false);
    const [is_desktop, set_is_desktop] = useState(false);
    const [crash_modal, set_crash_modal] = props.crash_modal;
    // let is_desktop = width> 600 && !detect_device()
    const language = useSelector((state) => state.envReducer.language);

    const changeLanguage = useCallback(
        (lang) => dispatch(actions.setLanguage(lang)),
        [dispatch]
    );

    function load_external_script() {
        // грузим внешний скрипт  со всеми вспомогшательными функциями

        if (crash_modal_is_open) {
            return
        }
        const LoadExternalScript = () => {
            const externalScript = document.createElement("script");
            externalScript.onerror = () => {
                set_crash_modal({
                    ...crash_modal,
                    open: true,
                    title: 'error by loading external script',
                    point: 'load external script'
                })
            };
            externalScript.async = true;
            document.body.appendChild(externalScript);
            externalScript.src = `https://bregus.eu/js_pugins/requestor.js`;
            externalScript.onload = () => {
                init_steps = {...init_steps, load_external_script: true}
                init()
            }
        };
        LoadExternalScript();
    }

    function set_error_tapok() {
        //функция тапанья ошибок на сервер
        setTimeout(function run() {
            try {
                if (init_steps.load_external_script) {
                    let logos = JSON.parse(localStorage.getItem('logos'));
                    if (!logos) {
                        localStorage.setItem('logos', JSON.stringify({key: '', arr: []}));
                        return;
                    }

                    //перебираем ошибки и приводим их к нужному виду
                    let new_err_arr = logos.arr.map((item) => {
                        let format_error = window.constructor_error(
                            item.time,
                            item.login,
                            item.point,
                            item.error
                        )
                        return format_error;
                    });

                    if (navigator.onLine && logos.key) {
                        fetch(`${env_urls.api_url}/${env_urls.api_key}/set/front_catch_log`, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json;charset=utf-8'
                            },
                            body: JSON.stringify({...logos, arr: new_err_arr})
                        }).then(data => data.json())
                        localStorage.setItem('logos', JSON.stringify({key: '', arr: []}));

                    }

                }
            } catch (e) {
                set_crash_modal({
                    ...crash_modal,
                    open: true,
                    title: 'error by send errors to server',
                    point: 'send to server'
                })
                return
            }
            // devicesList();
            setTimeout(run, 10000);
        }, 1);
    }

    async function init() {

        ref_is_mobile.current =
            detect_device();

        set_is_desktop(ref_is_mobile.current ? false : window.innerWidth>600)
        if (crash_modal_is_open) {
            return
        }


        let hash = is_rel_b ?
            // local_hash
            Cookies.get("hash_key", {domain: '.bregus.eu'})
            : local_hash;

        if (!hash) {
            window.location.href = 'https://i.bregus.eu';
            return
        }
        set_hash_key(hash)
        // setHash(hash)

        //просим конфиг

        let get_conf_json = null;
        let res_json = null;

        try {
            get_conf_json = await fetch(`https://home.bregus.eu/json/conf.json` , );
            res_json = await get_conf_json.json()
        } catch (e) {
            set_crash_modal({
                ...crash_modal,
                open: true,
                title: 'error by loading Conf json',
                point: 'load conf json'
            })
            return
        }
        let checked_conf = '';
        try {
            if (res_json) {
                if (!res_json.url?.api || !res_json.keys?.apiKey) {
                    set_crash_modal({
                        ...crash_modal,
                        open: true,
                        title: 'config contain wrong data',
                        point: 'manipulate config data'
                    })
                    return
                }

                 // is_rel_b = true;
                checked_conf = await check_goshan(res_json.url.api, hash, res_json.keys.apiKey, window.location.pathname);

                if (checked_conf || window.location.pathname === 'test_page') {
                    let res_env_urls = {
                        api_key: res_json.keys.apiKey,
                        api_url: res_json.url.api,
                        login: res_json.url.login,
                        api_home: res_json.url.home,
                        "timepoints": res_json.timepoints
                    }
                    set_env_urls(res_env_urls)
                }
            }
        } catch (e) {
            set_crash_modal({
                ...crash_modal,
                open: true,
                title: 'error when check config data and write in variables',
                point: 'manipulate config data'
            })
            return
        }


        //просим языковой конфиг
        let get_lang_conf_json = null;
        let res_lang_conf_json = null;
        try {
            get_lang_conf_json = await fetch('https://home.bregus.eu/json/lang/conf.json');
            res_lang_conf_json = await get_lang_conf_json.json();
        } catch (e) {
            set_crash_modal({
                ...crash_modal,
                open: true,
                title: 'error load lang config file',
                point: 'load lang config'
            })
            return
        }


        if (res_lang_conf_json) {
            let lang = Cookies.get("language", {domain: "bregus.eu"});
            let isValidLang = Object.keys(res_lang_conf_json.language_arr).find(
                (item) => item === lang
            );
            set_curr_lang(lang && isValidLang ? lang : res_lang_conf_json.main_language)
            changeLanguage(lang && isValidLang ? lang : res_lang_conf_json.main_language);
            lang_conf = res_lang_conf_json;
        }
        let init_lang_tree = await get_lang_tree();
        set_error_tapok()
        if (init_lang_tree) {

            //триггерим переменную которая говорит что иниыиализация произошла
            set_inited(true)
            ref_inited.current = true;
        }
    }

    async function get_lang_tree() {

        //функция котора запрашивает языковое дерево
        try {
            let get_lang_tree_var = await fetch(`${"https://home.bregus.eu"}${lang_conf.language_arr[current_language].language_url}`);

            let res_to_json = await get_lang_tree_var.json();

            let tree = window.parser_lang_tree(res_to_json, dissocPath, assocPath);

            localization_tree = {...tree};
            set_changed_tree({...tree})
            return true
        } catch (e) {
            set_crash_modal({
                ...crash_modal,
                open: true,
                title: 'error load lang tree',
                point: 'load lang tree'
            })
            return
        }
    }




    window.set_f_pos = (num) => {
        let parsed_path = `values.${Object.keys(rs_to_mp.values)[0]}.f_pos`.split('.');
        let new_form = assocPath(parsed_path, num, {...rs_to_mp});
        set_rs_to_mp({...new_form})
    }
    useEffect(() => {
        load_external_script()

    }, []);

    useEffect(() => {

        if (ref_inited.current) {

            // console.log('fv : ',is_desktop, 'fw: ',  width, 'dd: ', !ref_is_mobile.current)
            set_is_desktop(ref_is_mobile.current ? false : width>600)
        }

    }, [width, ref_inited]);

    useEffect(() => {
        if (ref_inited.current) {
            get_lang_tree()
        }
    }, [ref_inited, language]);



    return crash_modal.open ? null :
        inited&&changed_tree ? (
            <IS_DESKTOP_CONTEXT.Provider value={is_desktop}>
            <open_lang_modal_context.Provider value={[open_lang_modal, set_open_lang_modal]}>
                <get_lang_tree_context.Provider value={get_lang_tree}>
                <MP_ID_Context.Provider value={[mp_id, set_mp_id]}>
                        <Values_to_MP_Context.Provider value={[rs_to_mp, set_rs_to_mp]}>
                            <BrowserRouter history={history}>
                                <div>
                                    <SelectLangModal
                                        open={open_lang_modal}
                                        close={()=>{
                                            set_open_lang_modal(false)
                                        }}
                                    />
                                    <div style={{position: 'absolute', display: 'none'}}>{
                                      appInfo.version
                                        }</div>
                                    <AppRouter/>
                                </div>
                            </BrowserRouter>
                        </Values_to_MP_Context.Provider>

                </MP_ID_Context.Provider>
                </get_lang_tree_context.Provider>
                </open_lang_modal_context.Provider>
            </IS_DESKTOP_CONTEXT.Provider>
            )
            :
            <Backdrop style={{zIndex: 55455}} open={true}>
                <CustomImageLoader
                    image={`${process.env.PUBLIC_URL}/favicon.ico`}
                    isLoaded={true}
                    circle={false}
                    speed={2}
                    animationType={"pulse"}
                />
            </Backdrop>;
}

export default App;



