import React, {useRef, useEffect, useCallback, useState} from 'react'
import {glv} from '../../../components/app_components/helper_functions/helper_functions'
import Wrap_Overlay from "../../../components/modals/modal_components/wrappers/overlay_wrappers/Wrap_Overlay";
import WrapModal from "../../../components/modals/modal_components/wrappers/Wrap_Modal";
import Title_Modal from "../../../components/modals/modal_components/Title_Modal";
import ActionsModalButtons from "../../../components/modals/modal_components/Actions_Buttons_Modal";
// import SettingModalContent from "./content/SettingContent";

import {isEqualObjects} from "../../../helper_functions/helpers";
import {useForm} from "../../../hooks/useForm";
import moment from "moment";
import SelectParametrsChart from "../../charts_components/chart_block/selects_parametrs";
import {CSSTransition} from "react-transition-group";
import ChartDatePickers from "../../charts_components/chart_block/date_pickers";
import RangeDatePicker from "../../charts_components/chart_block/range_date_picker";
import useWindowSize from "../../../hooks/useWindowSize";


const ChartParametrsModal = props => {
    const size = useWindowSize();
    const [form_temporary, change_form_temporary] = useForm({
        ...props.form
    })
    const [show_save_btn, set_show_save_btn] = useState(false);
    const [open_date_picker, set_open_date_picker] = React.useState(
        false
    )

    useEffect(()=>{
        if (props.form) {
            if (isEqualObjects(form_temporary, props.form)&&
             JSON.stringify(form_temporary.dates)===JSON.stringify(props.form.dates)
            ) {
                set_show_save_btn(false)
            }
            if (!isEqualObjects(form_temporary, props.form)
                ||
                JSON.stringify(form_temporary.dates)!==JSON.stringify(props.form.dates)
            ) {
                set_show_save_btn(true)
            }
        }

    }, [form_temporary, props.form])

    return     (
        <Wrap_Overlay
            open={props.open}
            hidden_body={true}
            handle_click_overlay={()=>{
                // props.close()
                // set_opened_modal(false)
            }}
            class_position_children={'overlay_children_position'}
        >
            <WrapModal
                custom_style={{padding: 0}}
                full_w={!props.is_desktop ? 'full_width' : ''}>
                <Title_Modal
                    title_style={{margin: 34, marginBottom: 0, paddingBottom:12, borderBottom: '1px solid #2F2A61' }}
                    title_text={glv(`first_level_blocks.chart_parametrs_modal.title`)}
                />
                <div
                    style={{maxHeight: size.height-210}}
                    className={'wrap_chart_parametrs_modal_content options_scrollbar'}>
                    <SelectParametrsChart
                        inputs={props.inputs}
                        form={form_temporary}
                        change_form={change_form_temporary}
                    />

                    <RangeDatePicker
                        style={{marginTop:24}}
                        set_open_date_picker={set_open_date_picker}
                        open_date_picker={open_date_picker}
                        form={form_temporary}
                        // is_popper={true}
                        change_form={change_form_temporary}
                    />
                </div>


                <ActionsModalButtons
                    disabled_acc_btn={
                        false
                    }
                    has_cancel={true}
                    has_accept={show_save_btn}
                    text_accept_btn={
                        glv(`first_level_blocks.setting_modal.btns.accept`)
                        // glv(`first_level_blocks.setting_modal.accept_btn_title`)
                    }

                    text_cancel_btn={
                        glv(`first_level_blocks.setting_modal.btns.canc`)
                    }
                    style={{
                        margin: 24,
                        marginTop:0,
                    }}
                    accept_action={()=>{
                        props.change_form({...form_temporary})
                        props.close()
                    }}
                    cancel_action={()=>{
                        change_form_temporary('any', {...props.form}, true);
                        props.close()
                    }}
                />
            </WrapModal>
        </Wrap_Overlay>
    )
}

export default ChartParametrsModal;