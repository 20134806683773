import {useCallback, useState} from "react";

export const useForm = initial_form => {

    const [form, set_form] = useState(initial_form);

    const change_form = useCallback((name, value, is_full_overwrite)=>{
        if (is_full_overwrite) {
            set_form(value);
            return
        }
        let form_copy = {...form};
        form_copy[name] = value;
        set_form(form_copy);
    }, [form])

    return [form, change_form];
};