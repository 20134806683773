import React, {useEffect, useCallback, useState} from "react";
import '../../styles/calendar/calendar.css';
import {monthes, week_days} from "../../data_consts";
import {ArrowChangeMonthCalendar} from "../../../../../assets/svg_images";
import DetachedTimeInputWithAccept from "./detached_time_input_with_accept";
import generateDateTableBody from "../../services/calendar_component_service/generate_calendar_body";
import get_text_selected_date from "../../services/calendar_component_service/get_full_date_arr_for_render";
import date_with_null_time from "../../services/calendar_component_service/format_date_with_null_time";
import DayBlock from "./day_block";


const CalendarBlock = (props) => {
    const [is_am_pm, set_is_am_pm] = useState(false);
    const [this_date, set_this_date] = useState(
        {
            selected_date: [...props.current_selected_date],
            curr_view_date: props.current_selected_date[0]
        }
    )
    const [current_changing, set_current_changing] = useState('');
    const [options_monthes, set_options_monthes] = useState([]);
    const [options_year, set_options_year] = useState([]);
    let cls = props.classes ? props.classes : '';
    const [trigger_rerender_animate, set_trigger_rerender_animate] = useState([1, 'curr']);
    const [arr_of_days_rend, set_arr_of_days_rend] = useState([]);
    const [first_day_week, set_first_day_weeek] = useState(0);

    const has_got_full_date = props.has_got_full_date ? props.has_got_full_date : true;

    let struct_style = props.struct_style ? props.struct_style : {};

    const generate_year_options = useCallback((disabled_years = []) => {
        let res_arr = [];
        for (let i = 1995; i <= 2055; i++) {
            res_arr.push({
                id: i,
                text: i,
                is_disabled: disabled_years.find((year_index) => year_index === i)
            })
        }
        return res_arr
    }, [])

    const generate_monthes_options = useCallback((disabled_monthes = []) => {
        return monthes.map((month) => {
            return {
                id: month.index_date,
                text: month.full_name,
                is_disabled: disabled_monthes.find((month_index) => month_index === month.index_date)
            }
        })
    }, [])

    const change_one_month = useCallback((is_next) => {
        let new_month = is_next ? this_date.curr_view_date.getMonth() + 1 : this_date.curr_view_date.getMonth() - 1;
        handle_date_chenge(new_month, 'month')
    }, [this_date])


    const handle_date_chenge = useCallback((date, mod = 'day') => {
        let new_date = new Date();
        let is_curr_month = true;
        let is_curr_year = true;
        let {selected_date, curr_view_date} = this_date;

        let copy_curr_view_date = new Date(curr_view_date);
        let copy_selected_date = [...selected_date];

        if (mod === 'day') {
            new_date = date.date_value;

            is_curr_month = date.is_curr_month;
            let only_one_day_selected = selected_date.length === 1;
            let is_current_day = date_with_null_time(new_date) === date_with_null_time(selected_date[0]);
            if (only_one_day_selected && is_current_day) {
                return
            }

            let is_nextly_day = date_with_null_time(new_date) > date_with_null_time(selected_date[0]);
            new_date.setHours(copy_selected_date[0].getHours(), copy_selected_date[0].getMinutes())

            if (props.is_range && only_one_day_selected) {

                let new_range_arr = is_nextly_day ? [selected_date[0], new_date]
                    : [new_date, selected_date[0]];
                set_this_date({
                    curr_view_date: new_date,
                    selected_date: [...new_range_arr]
                })
            } else {

                let new_range_arr = [new_date];
                set_this_date({
                    curr_view_date: new_date,
                    selected_date: [...new_range_arr]
                })
            }
        }

        if (mod === 'month') {
            new_date = new Date(copy_curr_view_date);
            new_date.setMonth(date);

            is_curr_month = false;

            set_this_date({
                curr_view_date: new_date,
                selected_date: copy_selected_date
            })
        }

        if (mod === 'year') {
            new_date = new Date(copy_curr_view_date);
            new_date.setYear(date);

            is_curr_year = false;

            set_this_date({
                curr_view_date: new_date,
                selected_date: copy_selected_date
            })
        }

        if (!is_curr_month || !is_curr_year) {
            let animation_type = date_with_null_time(copy_curr_view_date) < date_with_null_time(new_date)
                ? 'next' : 'prev';
            set_trigger_rerender_animate([Math.random(), animation_type])
        }
    }, [this_date, props.is_range])

    React.useEffect(() => {
        if (
            this_date
        ) {
            let new_arr = generateDateTableBody(this_date, props.is_range, first_day_week)
            set_arr_of_days_rend([...new_arr])
        }

    }, [this_date, props.is_range, first_day_week])

    React.useEffect(() => {
        if (
            props.disabled_monthes
        ) {
            let disabled_monthes_ = props.disabled_monthes ? props.disabled_monthes : [];
            let create_optins_monthes = generate_monthes_options(disabled_monthes_)
            set_options_monthes(create_optins_monthes)
        }

    }, [props.disabled_monthes])

    React.useEffect(() => {
        if (
            props.disabled_years
        ) {
            let disabled_years_ = props.disabled_years ? props.disabled_years : [];
            let create_optins_years = generate_year_options(disabled_years_)
            set_options_year(create_optins_years)
        }

    }, [props.disabled_years])

    React.useEffect(() => {
        if (
            props.change_dates
        ) {
            props.change_dates([...this_date.selected_date])
        }
    }, [this_date.selected_date])

    let animation_type = trigger_rerender_animate[1] === 'prev' ? 'showBlockPrev'
        : trigger_rerender_animate[1] === 'next' ? 'showBlockNext' : 'showBlockCurr';

    let current_date_for_detach_time_input = current_changing ?
        current_changing.period==='start' ? this_date.selected_date[0] : this_date.selected_date[1] : null;

    return (
        <div>
            {/*<div className={'title_calendar_block'}>*/}
            {/*    <span className={'title_calendar_text'}>Calendar</span>*/}
            {/*</div>*/}

                <div
                    className={'wrap_title_calendar'}
                    style={{display: 'flex', justifyContent: 'flex_start', alignItems: 'center', position: 'relative',
                        flexWrap: 'wrap'
                    }}>
                    {
                        get_text_selected_date(this_date.selected_date, props.is_range).map((item, index) => {
                            let is_right_class = index>5 ? ' right_side' : '';
                            return (
                                    <div
                                        style={{position: 'relative'}}
                                        key={item.id + index}
                                        onClick={() => {
                                            if (item.type === "sub"||item.type === "day") {
                                                return
                                            }
                                            if (current_changing.id===item.id) {
                                                set_current_changing('')
                                            } else {
                                                set_current_changing(item)
                                            }

                                        }}
                                    >

                                        {
                                            current_changing.id===item.id&&current_changing.type === 'time' ? (
                                                    <div

                                                        className={'changing_time_block'+is_right_class}>
                                                        <DetachedTimeInputWithAccept
                                                            _is_am_pm_={[false, set_is_am_pm]}
                                                            current_date={current_date_for_detach_time_input}
                                                            cancel_change={(time) => {
                                                                set_current_changing('')
                                                            }}
                                                            accept_change={(new_date)=>{

                                                                let new_selected_date =[...this_date.selected_date]

                                                                if (current_changing.period==='start') {
                                                                    new_selected_date[0] = new_date;
                                                                } else
                                                                {
                                                                    new_selected_date[1] = new_date;
                                                                }
                                                                set_this_date({
                                                                    ...this_date,
                                                                    selected_date: new_selected_date,
                                                                })
                                                                set_current_changing('')
                                                            }}
                                                        />
                                                    </div>
                                                ) :
                                                current_changing.id===item.id&&current_changing.type === 'month' ? (
                                                        <div className={'changing_month_block '+is_right_class}>
                                                            <div className={'changing_month_block_wrap_list options_scrollbar'}>
                                                                {
                                                                    options_monthes.map((month)=>{
                                                                        let is_selected =
                                                                            month.id===current_changing.full_date.getMonth() ?
                                                                                'is_selected' : '';
                                                                        return (
                                                                            <div
                                                                                onClick={()=>{
                                                                                    if (is_selected) {
                                                                                        set_current_changing('')
                                                                                        return
                                                                                    }
                                                                                    let new_date = new Date(current_changing.full_date);
                                                                                    new_date.setMonth(month.id);
                                                                                    let new_data_to_change ={
                                                                                        date_value:new_date
                                                                                    };
                                                                                    handle_date_chenge(new_data_to_change, 'day')
                                                                                    set_current_changing('')
                                                                                }}
                                                                                className={
                                                                                    'changing_month_block_wrap_list_item ' +
                                                                                    is_selected
                                                                                }>
                                                                                <span>{month.text}</span>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                    ) :
                                                    current_changing.id===item.id&&current_changing.type === 'year' ? (
                                                            <div className={'changing_month_block '+is_right_class}>
                                                                <div className={'changing_month_block_wrap_list options_scrollbar'}>
                                                                    {
                                                                        options_year.map((year)=>{
                                                                            return (
                                                                                <div
                                                                                    onClick={()=>{
                                                                                        let new_date = new Date(current_changing.full_date);
                                                                                        new_date.setFullYear(year.id);
                                                                                        let new_data_to_change ={
                                                                                            date_value:new_date
                                                                                        };
                                                                                        handle_date_chenge(new_data_to_change, 'day')
                                                                                        set_current_changing('')
                                                                                    }}
                                                                                    className={'changing_month_block_wrap_list_item'}>
                                                                                    <span>{year.text}</span>
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                        ) :

                                                        null
                                        }

                                <span

                                    style={{color:item.id===current_changing.id ? ' #5e5ab9' : '' }}
                                    className={'title_calendar_full_date'}>{
                                    item.text
                                }</span>
                                    </div>
                                )
                        })
                    }


                </div>
            <div className={'block_functional_calendar'}>
                <div className={'wrap_selects_month_year'}>

                    <span>
                        {
                            this_date.curr_view_date.toLocaleString('default', {month: 'long'}).toUpperCase()
                        }
                    </span>

                </div>

                <div className={'wrap_week_days_names_calendar'}>
                    {
                        arr_of_days_rend.length ?
                            arr_of_days_rend[0].map((day) => {
                                return (
                                    <div className={'week_day_calendar'}>
                        <span className={'week_day_calendar_text'}>
                            {
                                week_days[day.week_day].short_name
                            }
                        </span>
                                    </div>
                                )
                            }) : null
                    }

                </div>
                <div className={'wrap_calendar_block_with_arrows'}>

                    <div
                        onClick={() => change_one_month()}
                        className={'change_month_btn prev_month_arrow_btn'}>

                        <ArrowChangeMonthCalendar
                            style={{
                                transform: `rotate(${90}deg)`,
                                transition: 'transform 0.4s'
                            }}
                        />
                    </div>

                    <div
                        style={{...struct_style,}}
                        className={`wrap_calendar_block`}>

                        {
                            arr_of_days_rend.map((week_arr, index) => {
                                return (

                                    <div
                                        style={{animation: `${animation_type} 0.4s  forwards`}}
                                        key={'wrap_week_' + index + trigger_rerender_animate[0]}
                                        className={'wrap_week_days'}
                                    >

                                        {
                                            week_arr.map((day) => {
                                                return (
                                                    <DayBlock
                                                        animation_trigger={trigger_rerender_animate[1]}
                                                        on_click={() => {
                                                            handle_date_chenge(day, 'day')
                                                        }}
                                                        key={day.id + trigger_rerender_animate[0]}
                                                        {...day}
                                                    />
                                                )
                                            })
                                        }

                                    </div>
                                )
                            })
                        }


                    </div>

                    <div
                        onClick={() => change_one_month(true)}
                        className={'change_month_btn next_month_arrow_btn'}>
                        <ArrowChangeMonthCalendar
                            style={{
                                transform: `rotate(${-90}deg)`,
                                transition: 'transform 0.4s'
                            }}
                        />
                    </div>
                </div>
            </div>


        </div>
    );
};

export default CalendarBlock;
