import React, {useState} from "react";
import {DesktopDateTimePicker} from "@mui/lab";
import moment from "moment";

import {old_start, old_finish} from "./Chart";
import {withStyles} from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import TimeInput from "../../../components_in_development/clock/clock_block";
import LightButton from "../../custom_components/light_button";
import PurpleButton from "../../custom_components/purple_button";
import CalendarBlock from "../../plugins/calendar/components/calendar_block/calendar_component";

const CssTextField = withStyles({
    root: {
        "& label.Mui-focused": {
            color: "#5e5ab9",
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: "#5e5ab9",
        },
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: "#5e5ab9",
            },
            "&:hover fieldset": {
                borderColor: "#5e5ab9",
            },
            "&.Mui-focused fieldset": {
                borderColor: "#5e5ab9",
            },
        },
    },
})(TextField);

//выбор дат для графика

const ChartDatePickers = (props) => {
    // компонент заглушки если график не имеет значений
    const [current_dates, set_current_dates] = useState(props.current_dates);
    // const {
    //     set_open_finish, open_finish,
    //     set_open_start, open_start,
    //     set_date_start,
    //     set_date_finish,
    //     date_finish,
    //     getData,
    //     date_start } = props;
    let custom_style = props.style ? props.style : {};
    return  (
        <div style={{}}>
            <div className={'wrap_detached_input_with_accept'}>

                <CalendarBlock
                    is_range={true}
                    current_selected_date={current_dates}
                    select_date={(new_date)=>{set_current_dates(new_date)}}
                    change_dates={(dates)=>{
                        set_current_dates(dates)
                    }}
                    disabled_years={[]}
                    disabled_monthes={[]}
                />
                <div className={'wrap_detached_access_btns_wrap'}>
                    <LightButton
                        on_click={
                            ()=>props.cancel_change()
                        }
                        text={`Cancel`}
                        cls={'cancel_change_time_input_btn'}
                        cls_text={'cancel_change_time_input_btn_text'}
                    />
                    {
                        current_dates&&current_dates.length>1 ?
                            <PurpleButton
                                on_click={()=> {
                                    props.accept_change(current_dates)
                                }}
                                text={'Ok'}
                                cls_outer={'accept_change_time_input_btn'}
                                cls_text={'accept_change_time_input_btn_text'}
                            />
                   : null
                    }


                </div>
            </div>


        </div>

    );
}

export default ChartDatePickers;
