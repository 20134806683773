import React, {useState, useEffect, useRef, useCallback} from 'react'
import {isValid} from "./helpers_clock";
import './clock.css';
import FullCustomInput from "../full custom input/full_custom_input";
import Checkbox from "../../components/custom_components/checkbox";
import {is_mobile} from "../../App";
import Input from "../../components/form_components/Input";
import {glv} from "../../components/app_components/helper_functions/helper_functions";

const convert_time_dictionary_map = {
    '01pm': '13',
    '02pm': '14',
    '03pm': '15',
    '04pm': '16',
    '05pm': '17',
    '06pm': '18',
    '07pm': '19',
    '08pm': '20',
    '09pm': '21',
    '10pm': '22',
    '11pm': '23',
    '13': '01',
    '14': '02',
    '15': '03',
    '16': '04',
    '17': '05',
    '18': '06',
    '19': '07',
    '20': '08',
    '21': '09',
    '22': '10',
    '23': '11',
    '00': '00',

}

const TimeInput =
    ({
                       initTime,
                       disabled,
                       mountFocus,
                       onTimeChange,
                       _is_am_pm_
                   }) => {

    const [time, setTime] = useState(initTime || '00:00');
    const [is_am_pm, set_is_am_pm] = _is_am_pm_;
    const [am_pm_val, set_am_pm_val] = useState('am');


    const _input = useRef(null)

    useEffect(() => {
        convert_time_value_by_type()
    }, [is_am_pm]);

    useEffect(() => {
        if (!disabled && mountFocus) {
            setTimeout(() => {
                _input.current.focus();
            }, 0);
        }
    }, [mountFocus, disabled]);

    const convert_time_value_by_type = () => {
        let splited_val = time.split(':')
        if (!is_am_pm && am_pm_val === 'pm' && splited_val[0].length === 2) {
            let new_hours_res = convert_time_dictionary_map[splited_val[0] + am_pm_val];
            setTime(`${new_hours_res}:${splited_val[1]}`)
        }
        if (is_am_pm && splited_val[0].length === 2 && Number(splited_val[0]) > 12) {
            let new_hours_res = convert_time_dictionary_map[splited_val[0]];
            setTime(`${new_hours_res}:${splited_val[1]}`)
            set_am_pm_val('pm')
        }
    }

    const onChangeHandler = (val, mod) => {
        if (mod === 'desktop') {
            if (!isValid(val)) {
                return
            }
        }
        if (val.indexOf(':') === -1) {
            return;
        }
        let val_ = val;
        let splited_val = val_.split(':')
        let hours = splited_val[0];
        let mins = splited_val[1];

        if (hours.length > 2) {
            hours = hours.slice(0, 2);
        }
        if (mins.length > 2) {
            mins = mins.slice(0, 2);
        }

        if (hours.length === 1) {
            let num_val = Number(hours)
            let is_bigger_hours = is_am_pm ? num_val > 1 : num_val > 2;
            hours = is_bigger_hours ? '0' + hours : hours;
        }
        if (hours.length === 2 && hours[0] !== '0') {
            let num_val = Number(hours)
            let is_bigger_hours =
                is_am_pm ? num_val > 12 ? 12 : num_val
                    : num_val > 23 ? '00' : num_val;
            hours = is_bigger_hours;
        }
        if (mins.length === 1) {
            let num_val = Number(mins)
            let is_bigger_mins = num_val > 5;
            mins = is_bigger_mins ? '5' : mins;
        }
        if (mins.length === 2 && mins[0] !== '0') {
            let num_val = Number(mins)
            let is_bigger_mins = num_val > 59 ? '59' : num_val;
            mins = is_bigger_mins;
        }

        if (is_am_pm && am_pm_val === 'am' && String(hours) === '12') {
            hours = '00'
        }


        let result = hours + ":" + mins;
        let ampm_additional = is_am_pm ? `${am_pm_val}` : '';
        setTime(result);
        if (result.length === 5) {
            onTimeChange({
                time: result,
                ampm: ampm_additional});
        }
        return result;
    }

    const change_ampm = useCallback(() => {
        let new_val_ampm = am_pm_val === 'am' ? 'pm' : 'am';
        set_am_pm_val(new_val_ampm)
    }, [am_pm_val])


    return (
        <div style={{}}>

            <div style={{display: 'flex'}}>
                {/*<div style={{display: 'flex', marginLeft: 10}}>*/}
                {/*    <Checkbox*/}
                {/*        checked={is_am_pm}*/}
                {/*        on_change={*/}
                {/*            () => {*/}
                {/*                set_is_am_pm(!is_am_pm)*/}
                {/*            }*/}
                {/*        }*/}
                {/*    />*/}
                {/*    <span className={'label_checkbox_time_type'}>*/}
                {/*    {*/}
                {/*        is_am_pm ? '12h' : '24h'*/}
                {/*    }*/}
                {/*</span>*/}
                {/*</div>*/}
            </div>
            {
                is_mobile ?
                    <FullCustomInput
                        type={'time'}
                        main_input_class={'time_input_style inset_bg_shadow'}
                        time_type={is_am_pm ? '12' : '24'}
                        val_am_pm={am_pm_val}
                        out_value={time}
                        change_ampm={change_ampm}
                        id={'test_time_input_'}
                        time_mode={true}
                        init_state={':'}
                        handle_change={onChangeHandler}
                    />
                    :

                    <Input
                        no_mt={true}

                        value={time}
                        has_icon={true}
                        icon={

                                is_am_pm ?
                                    <span>{am_pm_val}</span>
                                    : null

                        }
                        icon_press={change_ampm}
                        disabled={false}
                        errors={[]}
                        name={'name'}
                        on_change={(e) => {
                            onChangeHandler(e.target.value, 'desktop')
                        }
                        }
                        ph={'00:00'}
                        type={'tel'}
                    />

            }

        </div>
    );

}


export default TimeInput