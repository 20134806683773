import React from 'react';
import LightButton from "../../../custom_components/light_button";
import PurpleButton from "../../../custom_components/purple_button";

const HlpsBtnsAftManomsComponent = (props) => {
    const {ratio, width, } = props.dimensions_container;
    let kof_del = props.is_desktop ? 15 : 10



    return (
        <div
            // id="c_f_click"
            style={{
                position: 'absolute',
                bottom: 20,
                right: 20,
                display: 'flex',
                zIndex: 940,
                top: '20px',
                flexDirection: 'column',
                justifyContent: 'space-between'
            }}
        >

            <LightButton
                is_icon_button={true}
                icon={
                    <svg fill="#5E5AB9" style={{width: '50%', maxWidth: '120px', maxHeight: '80px'}}
                        // height={26}
                         viewBox="0 0 31 26"
                        // width={31}
                         xmlns="http://www.w3.org/2000/svg"><path d="M1.79999 1V25" stroke="#5E5AB9" strokeLinecap="round" strokeMiterlimit={10} strokeWidth={2} />
                        <path d="M1.79999 25H29.2" stroke="#5E5AB9" strokeLinecap="round" strokeMiterlimit={10} strokeWidth={2} /><path d="M6.5 19C7.60457 19 8.5 18.1046 8.5 17C8.5 15.8954 7.60457 15 6.5 15C5.39543 15 4.5 15.8954 4.5 17C4.5 18.1046 5.39543 19 6.5 19Z" stroke="#5E5AB9" strokeLinecap="round" strokeMiterlimit={10} strokeWidth={2} /><path d="M12.5 8.29999C13.6046 8.29999 14.5 7.40456 14.5 6.29999C14.5 5.19542 13.6046 4.29999 12.5 4.29999C11.3954 4.29999 10.5 5.19542 10.5 6.29999C10.5 7.40456 11.3954 8.29999 12.5 8.29999Z" stroke="#5E5AB9" strokeLinecap="round" strokeMiterlimit={10} strokeWidth={2} /><path d="M19.2 18.3C20.3046 18.3 21.2 17.4046 21.2 16.3C21.2 15.1954 20.3046 14.3 19.2 14.3C18.0954 14.3 17.2 15.1954 17.2 16.3C17.2 17.4046 18.0954 18.3 19.2 18.3Z" stroke="#5E5AB9" strokeLinecap="round" strokeMiterlimit={10} strokeWidth={2} /><path d="M27.2 10.3C28.3046 10.3 29.2 9.40456 29.2 8.29999C29.2 7.19542 28.3046 6.29999 27.2 6.29999C26.0954 6.29999 25.2 7.19542 25.2 8.29999C25.2 9.40456 26.0954 10.3 27.2 10.3Z" stroke="#5E5AB9" strokeLinecap="round" strokeMiterlimit={10} strokeWidth={2} /><path d="M2.5 17.7H4.5" stroke="#5E5AB9" strokeLinecap="round" strokeMiterlimit={10} strokeWidth={2} /><path d="M7.79999 15.7L11.2 7.70001" stroke="#5E5AB9" strokeLinecap="round" strokeMiterlimit={10} strokeWidth={2} /><path d="M13.8 7.70001L19.2 14.3" stroke="#5E5AB9" strokeLinecap="round" strokeMiterlimit={10} strokeWidth={2} /><path d="M21.2 15L25.2 9.70001" stroke="#5E5AB9" strokeLinecap="round" strokeMiterlimit={10} strokeWidth={2} /></svg>
                }
                on_click={()=>{
                    props.chart_btn_click()
                }}
                style={{
                    // marginRight: !props.regen_btn_visible ? 16 : 0,
                    marginRight: 0,
                    justifyContent: 'center',
                    width: width/kof_del,
                    height: width/kof_del,
                    padding: 5,
                    maxWidth: 90,
                    maxHeight: 90,
                }}
            />

            {/*<div onClick={*/}
            {/*    ()=> {*/}
            {/*        */}

            {/*        // window.opInter()*/}
            {/*    }*/}
            {/*}*/}
            {/*     className="btn info"*/}
            {/*     style={{minWidth: '50px', minHeight: '50px',*/}
            {/*         display:props.info_btn_visible  ? '' : 'none',*/}
            {/*         maxWidth: '80px', maxHeight: '80px',*/}
            {/*         marginRight: props.margin_btn_info*/}
            {/*     }}>*/}


            {/*</div>*/}
            {
                props.regen_btn_visible
                    ? null :
                    <PurpleButton
                        style={{
                            justifyContent: 'center',
                            width: width/kof_del,
                            height: width/kof_del,

                            maxWidth: 90,
                            maxHeight: 90,
                            marginLeft: 0
                        }}
                        on_click={()=>{
                            props.regen_btn_click()
                        }}
                        is_icon_button={true}
                        icon={
                            <svg
                                style={{width:props.is_desktop? width/30 :width/20, maxWidth: '80px', fill:'#E3E9F7'}}
                                viewBox="0 0 24 24" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M12.6776 3.78262C12.3775 3.26175 11.6224 3.26175 11.3223 3.78262C6.96422 11.3296 6.52172 11.6644 6.52172 13.5652C6.52172 16.5859 8.9791 19.0435 12 19.0435C15.0209 19.0435 17.4783 16.5859 17.4783 13.5652C17.4783 11.6629 17.0348 11.3272 12.6776 3.78262ZM12 15.913C10.1907 15.913 9.06849 13.9444 9.96352 12.3953C10.18 12.0211 10.6595 11.894 11.033 12.1097C11.4069 12.3261 11.5348 12.8049 11.3188 13.179C11.0258 13.6842 11.3919 14.3478 12 14.3478C12.432 14.3478 12.7826 14.6981 12.7826 15.1305C12.7826 15.5627 12.432 15.913 12 15.913Z"
                                    fill="white"/>
                                <path
                                    d="M23.5246 8.65076C23.4034 8.23578 22.9682 7.99859 22.5545 8.11808C22.1393 8.23906 21.9008 8.67345 22.0215 9.08844C22.2957 10.03 22.4348 11.0095 22.4348 12C22.4348 17.7536 17.7539 22.4348 12 22.4348C9.05358 22.4348 6.27825 21.1872 4.31335 19.0432H5.84968C6.28172 19.0432 6.6323 18.6929 6.6323 18.2606C6.6323 17.8283 6.28172 17.4832 5.84968 17.4832H2.71927C2.25001 17.4832 1.83249 17.9129 1.93665 18.4269V21.3911C1.93665 21.8234 2.28722 22.1737 2.71927 22.1737C3.15132 22.1737 3.5019 21.8234 3.5019 21.3911V20.4643C5.73507 22.7077 8.77974 24 12 24C18.617 24 24 18.6168 24 12C24 10.8615 23.84 9.73447 23.5246 8.65076Z"
                                    fill="white"/>
                                <path
                                    d="M21.3913 1.82611C20.9593 1.82611 20.6087 2.17641 20.6087 2.60873V3.65002C18.3661 1.33659 15.2749 0 12 0C5.38299 0 0 5.38327 0 12C0 13.1387 0.159984 14.2656 0.475359 15.349C0.597609 15.7674 1.03439 16.0021 1.44548 15.8814C1.86075 15.7607 2.09916 15.3263 1.97845 14.9113C1.70433 13.9701 1.56525 12.9905 1.56525 12C1.5652 6.24638 6.24609 1.5652 12 1.5652C14.947 1.5652 17.7218 2.81236 19.6864 4.95652H18.2609C17.8288 4.95652 17.4782 5.30681 17.4782 5.73914C17.4782 6.17147 17.8288 6.52177 18.2609 6.52177H21.3023C21.3034 6.52177 21.3046 6.52177 21.3056 6.52177C21.8663 6.52177 22.1739 6.15755 22.1739 5.73914V2.60869C22.1739 2.17641 21.8234 1.82611 21.3913 1.82611Z"
                                    fill="white"/>
                            </svg>
                        }
                    />
                    // <div
                    //     id={'regen_btn'}
                    //     style={{minWidth: '50px', minHeight: '50px',
                    //         marginRight: 0,
                    //         opacity:props.regen_btn_opacity,
                    //         maxWidth: '80px', maxHeight: '80px',
                    //         background:props.regen_btn_bg}}
                    //     className={
                    //         props.is_class_regen ? '' :
                    //             "btn regen"}>
                    //     {
                    //         props.visible_regen_svg ? null :
                    //
                    //     }
                    //
                    // </div>
            }

        </div>
    );
};

export default HlpsBtnsAftManomsComponent;