import React, { useEffect, useRef } from "react";
import L from "leaflet";

const style = {
  width: "100%",
  height: "300px",
  position: 'relative',
//   marginLeft: 10
};

function Map({ markerPosition,popupContent }) {
  // create map
  const mapRef = useRef(null);
  useEffect(() => {

    mapRef.current = L.map("map", {
      center: [markerPosition.lat, markerPosition.lng],
      zoom: 16,
      layers: [
        L.tileLayer("http://{s}.tile.osm.org/{z}/{x}/{y}.png", {
          attribution:
            '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        })
      ],
      zoomControl: false,
      dragging: false,
      doubleClickZoom: false,
      scrollWheelZoom: false
    });
  }, []);

  // add marker
  const markerRef = useRef(null);
  useEffect(
    () => {
      if (markerRef.current) {
        markerRef.current.setLatLng(markerPosition);
      } else {
        markerRef.current = L.marker(markerPosition).addTo(mapRef.current).bindPopup(popupContent).openPopup()
        .on('click', function(e) {
          window.open(`https://www.google.com/maps/search/?api=1&query=${markerPosition.lat},${markerPosition.lng}`);
      });
        
      }
    },
    [markerPosition]
  );

  return <div id="map" style={style} />;
}

export default Map;