import React from "react";
import InputSelect from "../../form_components/Input_Select";
import {glv} from "../../app_components/helper_functions/helper_functions";

const SelectParametrsChart = (props) => {

    // список цветов линий графика
    let custom_style = props.style ? props.style : {};
    return (
        <div className={'wrap_selects_of_parametrs_chart'}>
            {
                props.inputs.map((item, index)=>{
                    return (
                        <div className={'wrap_input_select_chart_block'}>
                        <InputSelect
                            struct_style={{...item.struct_style, }}
                            name={item.name}
                            value={props.form[item.name]}
                            options={
                                item.options
                            }
                            placeholder={' '}
                            on_change={props.change_form}
                        />
                            </div>
                    )
                })
            }
            {/*<div style={{width: 10}}></div>*/}

        </div>
    );
}

export default SelectParametrsChart;
