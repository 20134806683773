import React, { useRef, useEffect } from "react";
import { Tooltip } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { Fade } from '@material-ui/core';
import uuid from 'react-uuid'
import * as actions from "../../redux/actions/env.actions";
import ShemeModal from '../modals/sheme_element_modal/shemeElementModal';
import useMousePosition from '../../hooks/useMousePosition';
const degs  ={
    left: 90,
    right: 270,
    bottom: 0,
    top: 180
}



const debounce = require('debounce');

const ShipConnector = (props) => {
  const canvasRef = useRef(null);
  const [hover, setHover] = React.useState(false);
  const canvasRefShadow = useRef(null);
  const [highlightColor, setHighlightColor] = React.useState("");
  const blurStatus = useSelector((state) => state.envReducer.blurElems);
  const dispatch = useDispatch();
  const [idShadow, setIdShadow] = React.useState(uuid());
  const currTrumpet = useSelector((state) => state.envReducer.currTrumpet);
  const setCurrTrumpet = React.useCallback((color) => dispatch(actions.setCurrTrumpet(color)), [
    dispatch,
  ]);
  const [idSvg, setIdSvg] = React.useState(uuid());
  const bakRef = useRef(null);
  const overModal = useSelector((state) => state.envReducer.overModal);
  const { x, y } = useMousePosition();

  const setShemeModal = React.useCallback((color) => dispatch(actions.setShemeModal(color)), [
    dispatch,
  ]);

  const [coord, setCoord] = React.useState(null);
  const setShemeModalData = React.useCallback((color) => dispatch(actions.setShemeModalData(color)), [
    dispatch,
  ]);
  // useEffect(() => {
  //   const canvas = canvasRef.current;
  //   const ctx = canvas.getContext("2d");

  //   ctx.clearRect(0, 0, canvas.width, canvas.height);
  //   let image = new Image();
  //   image.onload = function () {
  //     ctx.drawImage(image, 0, 0, 25, 28);
  //   };
  //   image.src = data[props.type];
  // }, []);

  // useEffect(() => {
  //   const canvasShadow = canvasRefShadow.current;
  //   const ctxShadow = canvasShadow.getContext("2d");
  //   console.log(props.type==='green_ship');
  //   ctxShadow.shadowColor = highlightColor;
  //   ctxShadow.shadowBlur = 10;
  //   if (highlightColor && hover) {
  //     let image = new Image();
  //     image.onload = function () {
  //       ctxShadow.drawImage(image,
          
  //         props.direction==='bottom'?5:
  //         props.direction==='left'?2:
  //         props.direction==='right'?8:
  //         0,
        
  //       props.direction==='bottom'?2:
        
  //       props.direction==='left'?6:
  //       props.direction==='right'?4:
  //       0 ,
        
        
  //       25, 28
        
  //       );
  //     };
  //     image.src = data[props.type];
  //   } else {
  //     ctxShadow.clearRect(0, 0, canvasShadow.width, canvasShadow.height);
  //   }
  // }, [highlightColor, hover]);

  const setBlur = React.useCallback((status) => dispatch(actions.setBlur(status)), [
    dispatch,
  ]);

  useEffect(() => {
    if (overModal) {
      return
    }
    if (hover&&props.id&&coord) {

      setShemeModalData({...props, x:coord.x, y: coord.y});
      setShemeModal(true);

        setCurrTrumpet( props.id ? props.id : 'fdfh' );
  
   
      setHighlightColor("#3ecd83");
      setBlur(true)
    } else {
      setShemeModal(false);
      setCurrTrumpet("");
      setHighlightColor("");
      setBlur(false)
    }

  }, [hover,coord,overModal])



  useEffect(() => {



    // let image = new Image();
    // image.onload = function() {
    //   ctx.drawImage(image, 0, 0,25, 2.8);
    // };
    
    // // ctx.drawImage(image, 0, 0, props.width, props.height);
    
    // image.src= data[props.type];

    //Our first draw

    // let bigPercent = (ctx.canvas.height*23)/100

    // let probel = (ctx.canvas.height*8)/100

    // let del = (ctx.canvas.width/3)/3

    // // ctx.lineWidth = 6;
    // ctx.strokeStyle  = props.color;
    // // ctx.beginPath();
    // ctx.fillStyle = props.color;
    // ctx.fillRect(ctx.canvas.width/3, 4, ctx.canvas.width/3, 2);
    // ctx.fillRect(ctx.canvas.width/3+ del, 6, del, 2);
    // ctx.fillRect(ctx.canvas.width/3+2, 8, (ctx.canvas.width/3)-4, 4)
    // ctx.moveTo(6,18);
    // ctx.bezierCurveTo(6, 11, ctx.canvas.width-6, 11, ctx.canvas.width-6, 18);
    // ctx.lineTo(ctx.canvas.width-6,ctx.canvas.height-7);
    // ctx.bezierCurveTo(ctx.canvas.width-6, ctx.canvas.height, 6, ctx.canvas.height, 6, ctx.canvas.height-7);
    // ctx.lineTo(6,18);

    // ctx.moveTo(3,14);
    // ctx.lineTo(6,15);
    // ctx.lineTo(6,20);
    // ctx.lineTo(3,22);

    // ctx.moveTo(ctx.canvas.width-3,14);

    // ctx.lineTo(ctx.canvas.width-6,15);

    // ctx.lineTo(ctx.canvas.width-6,20);
    // ctx.lineTo(ctx.canvas.width-3,22);
    // ctx.fill();
    // ctx.stroke();

    //     ctx.beginPath();
    // ctx.fillStyle = props.bg;
    // ctx.arc(ctx.canvas.width/2, 20, 4, 0, 2 * Math.PI);
    // ctx.fill();


  }, []);

  return (

    <div style={{...props.styleWrap, cursor: 'pointer'}} 
    ref={bakRef}
    onMouseOver={debounce((e) => {
      setCoord({x:e.clientX, y: e.clientY})
      if (overModal) {
        return
      }
    setHover(true)
    },300)}
onMouseLeave={debounce(() => {
  setHover(false)
},200)}
    >
              <div style={{ position: "relative" }}>
              {/* {hover ? (
     <ShemeModal {...props}/>
      ) : null} */}

      

<svg height="40" width="42"  version="1.1" xmlns="http://www.w3.org/2000/svg"  viewBox="4 -2 8 22" style={{transition: 'all 0.2s', transform: `rotate(${degs[props.direction]}deg)`,        filter:blurStatus&&currTrumpet!==props.id&&!hover||blurStatus&&!hover&&props.type===('green_ship'||'red_ship_no_stock')?'blur(2px) contrast(30%)':blurStatus&&currTrumpet===props.id?'none':'none'}}>
<defs>
  <filter id={idShadow} >
    <feDropShadow 

        dx="0" 
        dy="0" 
        stdDeviation="0" 
        floodColor="#39cb58" 
        
    >
    	   <animate   attributeName="stdDeviation"   values="0;2"  begin={idSvg.replace(/-/g, '')+'.mouseover'}  dur="0.4s" fill="freeze"> </animate>
  	   <animate  attributeName="stdDeviation"   values="2;0"  begin={idSvg.replace(/-/g, '')+'.mouseout'}  dur="0.25s" fill="freeze"> </animate>
    </feDropShadow>
</filter>
</defs>
<g   id={idSvg.replace(/-/g, '')} xmlns="http://www.w3.org/2000/svg" style={{fill:"none",filter: `url(#${idShadow})`}}  >
      <path id="path3712-0-0-3-2-5-0" d="M 7,4 H 9 V 2 H 7 Z"  style={{fill:"#2f2a61"}}/>
      <path id="path3714-3-4-6-0-6-6" d="M 0,15 H 16 V 9 H 0 Z"  style={{fill:"#2f2a61"}}/>
      <path id="path3716-9-8-1-6-3-0" d="m 8,18 c 4.4183,0 8,-2.6863 8,-6 C 16,8.68629 12.4183,6 8,6 3.58172,6 0,8.68629 0,12 c 0,3.3137 3.58172,6 8,6 z" style={{fill:"#2f2a61"}}/>
      <path id="path3718-1-7-0-8-9-8" d="m 6,4 v 9 h 4 V 4 Z"  style={{fill:"#2f2a61"}}/>
      <path id="path3720-9-0-6-9-4-3" d="m 8,16 c 2.2092,0 4,-1.7909 4,-4 C 12,9.79086 10.2092,8 8,8 5.79084,8 4,9.79086 4,12 c 0,2.2091 1.79084,4 4,4 z"  style={{fill:props.bg}}/>
      <path id="path3722-6-8-3-2-8-7" d="m 5,2 h 6 V -2.38419e-7 l -6,6.87e-10 z"  style={{fill:"#2f2a61"}}/>
    </g>
</svg>

          {/* <canvas
            ref={canvasRef}
            {...props}
            width={25}
            height={28}
            style={{ transform: `rotate(${degs[props.direction]}deg)`,        filter:blurStatus&&currTrumpet!==props.id?'blur(2px)':blurStatus&&currTrumpet===props.id?'none':'none'}}
          />
 
          <canvas
            ref={canvasRefShadow}
            {...props}
            width={35}
            height={38}
            style={{
              transform: `translate(-50%,-50%) rotate(${degs[props.direction]}deg)`,
              position: "absolute",
              left: "50%",
              top: "50%",
        
              // zIndex: -1,
            }}
          /> */}

        </div>


    {/* <img src={data[props.type]} alt="" width={25} height={29.8} style={{transform: `rotate(${degs[props.direction]}deg)`}}/> */}

 
    </div>

  );
};

export default ShipConnector;
