import React from "react";

const HeaderBarVD = (props) => {

    let custom_style = props.style ? props.style : {};
    return  (
        <div className={'wrap_header_bar_vd'} style={{...custom_style, width: props.is_hided ? 64 : ''}}>
            <div className={'wrap_tools_header_vd'}>
                {props.children}
            </div>
        </div>
    );
}

export default HeaderBarVD;