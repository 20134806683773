import React, {useState} from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/core/styles';
import RadioBtn from '../../../../custom_components/radioBtn';
import Fade from '@mui/material/Fade';
import Grow from '@mui/material/Grow';
import {glv} from "../../../../../components/app_components/helper_functions/helper_functions";
import moment from 'moment';
import { IMaskInput,IMask, useIMask } from 'react-imask';
// import ReactTimezonePicker from 'timezone-react';
import TimezonePicker from 'react-bootstrap-timezone-picker';
import 'react-bootstrap-timezone-picker/dist/react-bootstrap-timezone-picker.min.css';
import TimePicker from '@mui/lab/TimePicker';
import TextField from "@material-ui/core/TextField";
import TimezoneSelect, { allTimezones } from "react-timezone-select"
import Wrap_Overlay from "../../../../modals/modal_components/wrappers/overlay_wrappers/Wrap_Overlay";
import WrapModal from "../../../../modals/modal_components/wrappers/Wrap_Modal";
import ActionsModalButtons from "../../../../modals/modal_components/Actions_Buttons_Modal";
import Title_Modal from "../../../../modals/modal_components/Title_Modal";
import SelectTimeZoneComponent from "../../../../custom_components/selecte_timezone/select_time_zone.component";
import Input from "../../../../form_components/Input";


let Blocked = (props)=>(
    <svg
        style={{position: 'absolute', left: -60, top: '50%', height: '25px', transform: 'translate(0, -50%)'}}
        // style={{...props.style}}
        enableBackground="new 0 0 91 91" height="91px" id="Layer_1" version="1.1" viewBox="0 0 91 91" width="91px" xmlSpace="preserve" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"><g><g><path d="M64.639,44.64c-2.307,0-4.176-1.869-4.176-4.174V20.782c0-6.598-6.729-11.963-15-11.963h-0.541    c-8.275,0-15.004,5.365-15.004,11.963v19.684c0,2.305-1.869,4.174-4.176,4.174s-4.176-1.869-4.176-4.174V20.782    c0-11.201,10.477-20.313,23.355-20.313h0.541c12.873,0,23.35,9.111,23.35,20.313v19.684C68.813,42.771,66.943,44.64,64.639,44.64z    " fill="#647F94" /><path d="M45.547,30.593h-0.715c-15.26,0-27.678,10.625-27.678,23.688v12.416c0,13.063,12.418,23.689,27.678,23.689    h0.715c15.262,0,27.682-10.627,27.682-23.689V54.28C73.229,41.218,60.809,30.593,45.547,30.593z M49.367,69.505    c0,2.307-1.869,4.176-4.176,4.176s-4.174-1.869-4.174-4.176V53.272c0-2.305,1.867-4.174,4.174-4.174s4.176,1.869,4.176,4.174    V69.505z" fill="#95AEC2" /></g></g></svg>
)

const customStyles = {
  menu:(provided, state)=>({
    ...provided,
    // background:'#e3e9f7',
    // padding: '5px 0px',
    borderRadius: 12,
    background: `linear-gradient(
        0deg, #E3E9F7, #E3E9F7)`,
    boxShadow: '-5px -5px 7px rgb(255 255 255 / 50%), 5px 5px 10px rgb(23 23 23 / 12%)',
  '-ms-overflow-style': 'none',  /* IE and Edge */
  scrollbarWidth: 'none',
"&::-webkit-scrollbar": {
      display: 'none'
    }

  }),
  menuList:(provided, state)=>({
    ...provided,
    // background:'#e3e9f7',
    // padding: '5px 0px',
    borderRadius: 12,
    background: `linear-gradient(
        0deg, #E3E9F7, #E3E9F7)`,
    boxShadow: '-5px -5px 7px rgb(255 255 255 / 50%), 5px 5px 10px rgb(23 23 23 / 12%)',
    '-ms-overflow-style': 'none',  /* IE and Edge */
    scrollbarWidth: 'none',
    "&::-webkit-scrollbar": {
      display: 'none'
    },
    zIndex: 1200

  }),

  option: (provided, state) => {
    // console.log(state)
   return ({
     ...provided,
     // borderBottom: '1px dotted pink',
     color: state.isSelected ? '#2F2A61' : '#2F2A61',
         background: state.isSelected ?  '#d1d7e4' : state.isFocused ?'#dae0ed'  : 'transparent',
   })
  },
  control: (provided) => ({
    ...provided,
    cursor: 'pointer',
    background: "linear-gradient(0deg, #DFE6F6, #DFE6F6)",
    boxShadow:
        "inset -4px -5px 17px rgba(255, 255, 255, 0.699055), inset 6px 8px 19px rgba(0, 0, 0, 0.08)",
    borderRadius: 12,
border: 'none',
    padding: '10px',

    // width: '100%'
    // none of react-select's styles are passed to <Control />
    // width: 200,
  }),
  // singleValue: (provided, state) => {
    // const opacity = state.isDisabled ? 0.5 : 1;
    // const transition = 'opacity 300ms';
    //
    // return { ...provided, opacity, transition };
  // }
}

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#5e5ab9",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#5e5ab9",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#5e5ab9",
      },
      "&:hover fieldset": {
        borderColor: "#5e5ab9",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#5e5ab9",
      },
    },
  },
})(TextField);

let old_time = null;

const time_mask = function (value) {
  var pattern = new RegExp("^(0?[1-9]|1[012]):[0-5][0-9]$");
  // console.log(value, pattern.test(value));
  return pattern.test(value);
};
const SettingPage = (props) => {
  const [form, set_form] = useState(

  )
  const [dimensions_container, set_dimensions_container] = useState()
    const [regenNotifCheked, setRegenNotifCheked] = React.useState(props.bef_wash_not);
    const [regenRadCheked, setRegenRadCheked] = React.useState('');

  const [open_pick_time, set_open_pick_time] = React.useState(
      false
  )

  const [wash_to_day, set_wash_to_day] = React.useState(props.wash_to_day);
  const [time_wash, set_time_wash] = React.useState(moment(props.time_wash));
  const [time_zone, set_time_zone] = React.useState(props.time_zone);
  const [day_wash, set_days_wash] = React.useState(props.day_wash);
  const [wash_notification, set_wash_notification] = React.useState(props.wash_notification);
  const [dop_info, set_dop_info] = React.useState(false);
  const [salt_tank, set_salt_tank] = React.useState(props.salt_tank);
  const [delays, set_delays] = React.useState(props.delays);
  const [vacation, set_vacation] = React.useState(props.vacation);

  const [email_not, set_email_not] = React.useState(props.email);
  const [telegram_not, set_telegram_not] = React.useState(props.teleg);
  const [sms_not, set_sms_not] = React.useState(props.sms);

  const [disabled_save_btn, set_disabled_save_btn] = React.useState(true);
  // console.log(props.wash_to_day)
  // console.log(allTimezones)

    // const [degTear, setDegTear] = React.useState(233);
  // console.log(wash_to_day)

  const save = () => {
    let form =       {
      "wash_to_day": {
        "status": wash_to_day,
        "vacation": vacation,
        "days_week": day_wash,
        "time_wash": {
          "h": time_wash.hours(),
          "m": time_wash.minutes(),
          "time_zone": time_zone
        }
      },
      "alarms": {
        "status": wash_notification,
        "before_wash": regenNotifCheked,
        "e_mail": email_not,
        "sms": sms_not,
        "telegram": telegram_not
      },
      "sys": {
        "salt": salt_tank,
        "delays": {
          ...delays
        }
      }
    }
     props.saveSetting(form)
  }

  React.useEffect(()=>{
    if (!props.access){return}
    let check_ = 0;

    if (wash_to_day!==props.wash_to_day){
      check_=1;
    }

    if (JSON.stringify(day_wash)!==JSON.stringify(props.day_wash)) {
      check_=1;
    }
    if (
        moment(time_wash).minutes()!==moment(props.time_wash).minutes()
        ||
        moment(time_wash).hours()!==moment(props.time_wash).hours()
    )
    {
      check_=1;
    }
    if (time_zone!==props.time_zone){
      check_=1;
    }
    if (vacation!==props.vacation){
      check_=1;
    }
    if (wash_notification!==props.wash_notification){
      check_=1;
    }
    if (regenNotifCheked!==props.bef_wash_not){
      check_=1;
    }
    if (sms_not!==props.sms){
      check_=1;
    }
    if (email_not!==props.email){
      check_=1;
    }
    if (telegram_not!==props.teleg){
      check_=1;
    }
    if (salt_tank!==props.salt_tank){
      check_=1;
    }
    let check_washstyle = Object.keys(delays).find((item)=> {
      if (item==='name_asoc') {
        return
      }
      return delays[item] !== props.delays[item]
    });
    if (check_washstyle) {
      check_ = 1
    }
    if (check_) {
      set_disabled_save_btn(false)
    } else {
      set_disabled_save_btn(true)
    }
    }, [props,wash_to_day, vacation, time_wash, time_zone, day_wash, wash_notification, dop_info, salt_tank, delays ])

    return props.access ?(

        <Wrap_Overlay
            z_index={1025}
            open={props.open}
            hidden_body={true}
            handle_click_overlay={()=>{

            }}
            class_position_children={'overlay_children_position'}
        >
          <WrapModal
              full_w={props.full_w }
              set_dimensions_container={set_dimensions_container}
          >

            <Title_Modal
                title_style={{overflow:'hidden', whiteSpace:'nowrap', textOverflow: 'ellipsis'}}
                title_text={
                  props.dev_?.name ? props.dev_?.name : ''
                }
            />
              <div className="setting_frame options_scrollbar" style={{width: '100%',fontSize: 19, marginBottom: 0,
                  overflowY: 'auto',
                maxHeight: dimensions_container?.height-180}}>
  <section className="wrap_blocks_settings" >
    {/* <div class="header_title_block_sett">######</div> */}

    <div className="setting_block vacation  ">
      <div className="wrap_tit_switch">
        <span className="title_sett_block air_cush_tit"> {glv('second_level_blocks.setting_page.vacation')}</span>
        <div className="wrap_switch">
          <label className="switch pos_rel" style={{}}>
            {
              !props.access_write ?
                  <Blocked
                      style={{position: 'absolute', left: -90, top: '50%', height: '33px', transform: 'translate(0, -50%)'}}/>
                  : null
            }
            <input type="checkbox"
                   checked={vacation}
                   disabled={!props.access_write}
                   value={vacation}
                   onChange={(e)=>{
                     set_vacation(!vacation)
                   }}
            />
            <span className="slider round"  />
          </label>
        </div>
      </div>
    </div>

    <div className="setting_block air_cush" style={{display: vacation&&!wash_to_day ? 'nonw' : ''}}>
      {vacation? null :
      <div className="wrap_tit_switch">
        <span className="title_sett_block air_cush_tit"> {glv('second_level_blocks.setting_page.wash_to_day')}</span>
        <div className="wrap_switch">
          <label className="switch pos_rel" style={{}}>
            {
              !props.access_write ?
                  <Blocked style={{position: 'absolute', left: -90, top: '50%', height: '33px', transform: 'translate(0, -50%)'}}/> : null
            }
            <input type="checkbox"
                   checked={wash_to_day}
                   disabled={!props.access_write}
                   value={wash_to_day}
                   onChange={(e)=>{

              set_wash_to_day(!wash_to_day)
            }}
            />
            <span className="slider round"  />
          </label>
        </div>
      </div>
      }
      {
        wash_to_day&&!vacation ?
            <Grow in={true}>
            <div className="air_cu_btns">
              {
                ['mo', 'tu', 'we', 'th', 'fr', 'sa', 'su'].map((item, index)=>{
                  return (
                      <div
                          onClick={()=> {
                            if (     day_wash
                                .find((item) => item === index + 1)){
                              set_days_wash(days => [...days.filter(item=>item!== index + 1)])
                              return
                            }
                            set_days_wash(days => [...days, index + 1])
                          }}
                          style={{marginLeft:index===0 ? 0 : 10}}
                          className={`week_day_btn  ${
                        day_wash
                        .find((item) => item === index + 1) ? 'week_day_btn_active' : ''
                      }`}>{glv(`second_level_blocks.setting_page.days_week.${item}${
                        // !props.using_mob? '_d' : 
                            ''
                      }`)}</div>
                  )
                })
              }
            </div></Grow> : null
      }

      {
        wash_to_day||vacation ?
            <Grow in={true}>
              <div>
              <div className="setting_block regen_time"
                   style={{border: 'none', paddingBottom: 0, marginTop:vacation? 0 : 10, paddingTop:vacation? 0 : ''}}>
                <label htmlFor="fname" className="title_sett_block" style={{flex: 1}}> {glv('second_level_blocks.setting_page.time_wash')}</label>

                {/*{*/}
                {/*  !props.using_mob  ?*/}
                {/*      <div style={{flex: 2}}></div>*/}
                {/*      : null*/}
                {/*}*/}

                  <div style={{  position: 'relative',
                    flex: 1
                  }} className={'wrap_date_picker'}>
                    {
                      !props.access_write ?
                          <Blocked
                              style={{
                                position: 'absolute',
                                left: -90,
                                top: '50%',
                                height: '33px',
                                transform: 'translate(0, -50%)'
                              }}
                          />
                          : null
                    }
                    <div
                        style={{
                          position: 'absolute',
                          width: '100%',
                          height: '100%',
                          zIndex: 900,
                          cursor: 'pointer',
                          background: "linear-gradient(0deg, #DFE6F6, #DFE6F6)",
                          boxShadow:
                              "inset -4px -5px 17px rgba(255, 255, 255, 0.699055), inset 6px 8px 19px rgba(0, 0, 0, 0.08)",
                          borderRadius: 12,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center'

                          // background: 'red'
                        }}
                        onClick={()=> {
                          if (!props.access_write){
                           return
                          }
                          set_open_pick_time(!open_pick_time)
                        }}
                    >
                      <span style={{color:!props.access_write ? '#99aacc' : ''}}>{moment(time_wash).format('HH:mm')}</span>
                    </div>



                    <TimePicker

                        disabled={!props.access_write}
                        // dateRangeIcon={()=>null}
                        open={open_pick_time}
                        components={{
                          OpenPickerIcon: ()=>null
                        }}
                        PopperProps={{style: {
                            background: '#E3E9F7',
                            borderRadius: 12
                          }}}
                        PaperProps={{style: {
                            background: '#E3E9F7',
                            borderRadius: 12
                          }}}
                        // orientation={'landscape'}
                        onChange={(newValue) => {

                          set_time_wash(newValue);
                        }}
                        ampm={false}
                        ampmInClock={false}
                        onAccept={(newValue)=>{

                          old_time = newValue;
                          set_time_wash(old_time)
                          // set_open_start(!open_start)
                        }}
                        onClose={(newValue)=>{

                          old_time = newValue;
                          set_time_wash(old_time)
                          set_open_pick_time(!open_pick_time)
                        }}

                        onOpen={()=>{
                          old_time = time_wash;
                        }}
                        renderInput={(params) => (


                            <CssTextField
                                style={{
                                  padding: '10px ',
                                  cursor: 'pointer',
                                  // paddingRight: '16px',

                                  // maxWidth: 130,
                                  color: "#2F2A61",
                                  // marginLeft: 100,
                                  flex: 1,
                                  alignItems: 'center',
                                  // marginLeft: 100,
                                  // borderRight: '1px solid',
                                  display: 'flex',
                                }}
                                // className={'wrap_inp_dt'}

                                {...params} />

                        )}

                        value={time_wash}

                    />
                  </div>
              </div>
                <div className="setting_block regen_time"
                     style={{border: 'none',
                       paddingBottom: 0,
                       marginTop: 10,

                }}>
                  <label htmlFor="fname" className="title_sett_block" style={{flex:1,}}>{
                    glv('second_level_blocks.setting_page.time_zone')
                  }</label>
                  {/*{*/}
                  {/*  !props.using_mob  ?*/}
                  {/*      <div style={{flex: 2}}></div> : null*/}
                  {/*}*/}

                  <div style={{cursor:'pointer', position: 'relative',  flex: 1,maxWidth: '50%'}}>
                    {
                      !props.access_write ?
                          <Blocked style={{position: 'absolute', left: -90, top: '50%', height: '33px', transform: 'translate(0, -50%)'}}/> : null
                    }
                      <SelectTimeZoneComponent
                          value={time_zone}
                          set_tz={(tz)=>{
                            set_time_zone(tz)
                          }}
                      />
                  </div>



                </div>

              </div>
            </Grow>
            :
            <Grow in={true}>
              <div className="setting_block regen_time" style={{border: 'none', paddingBottom: 0, paddingTop: vacation? 0 : ''}}>
                <label htmlFor="fname" className="title_sett_block" style={{flex: 1}}>
                  {glv('second_level_blocks.setting_page.max_l2')}
                </label>

                {/*<div style={{flex: 2}}></div>*/}

                <span  className={' flex_1_ color_purple prots_state ta_end'} style={{whiteSpace: 'nowrap'}}>{props.l2_max}
                  <span className={'prots_max'}>{glv('first_level_blocks.statistic.linear.'+ 'l2' + '.curr_val')}</span></span>
                {/*<input maxLength={5} type="text" value={props.l2_max} disabled={true} autoComplete="off" id="ftime" name="firstname" placeholder className="regen_t_inp" />*/}
              </div>
            </Grow>
      }

    </div>
    <div className="setting_block dry_wet" style={{border: 'none'}}>
      <div className="wrap_tit_switch">
        <span className="title_sett_block dry_wet_tit">  {glv('second_level_blocks.setting_page.has_notif')}</span>
        <div className="wrap_switch">
          <label className="switch" style={{position: 'relative'}}>
            {
              !props.access_write ?
                  <Blocked style={{position: 'absolute', left: -90, top: '50%', height: '33px', transform: 'translate(0, -50%)'}}/> : null
            }


              <input type="checkbox"
                     checked={wash_notification}
                     disabled={!props.access_write}
                     value={wash_notification} onChange={
                (e)=>{

                  set_wash_notification(!wash_notification)
                }
              } />


            <span className="slider round" />
          </label>
        </div>
      </div>
    </div>




    <Grow in={wash_notification} unmountOnExit={true}>
      <div className="setting_block regen_notif" style={{paddingTop: 0}}>
        {/*<span className="title_sett_block regen_notif_tit">*/}
        {/*       {glv('second_level_blocks.setting_page.regen_notif')}*/}
        {/*</span>*/}
        <RadioBtn
            disabled={!props.access_write}
            label={glv('alarms.before')}
                  id={true} setCheckedId={(id) => {

          if(props.access_write) {
            setRegenNotifCheked(true)
          }

        }}
                  checkedId={regenNotifCheked}
                  mt={'0px'}/>
        <RadioBtn
            disabled={!props.access_write}
            label={glv('alarms.after')}
                  id={false}
                  setCheckedId={(id) => {
          if(props.access_write) {
            setRegenNotifCheked(false)
          }

        }} checkedId={regenNotifCheked}/>

      </div>
    </Grow>

    {
      props.access.settings.alerts_visible.e_mail&&wash_notification ?
    <div className="setting_block dry_wet" style={{border: 'none'}}>
      <div className="wrap_tit_switch">
        <span className="title_sett_block dry_wet_tit">  {glv('second_level_blocks.setting_page.e_mail')}</span>
        <div className="wrap_switch">
          <label className="switch" style={{position: 'relative'}}>
            {
              !props.access_write ?
                  <Blocked style={{position: 'absolute', left: -90, top: '50%', height: '33px', transform: 'translate(0, -50%)'}}/> : null
            }


            <input type="checkbox"
                   checked={email_not}
                   disabled={!props.access_write}
                   value={email_not}
                   onChange={
              (e)=>{

                set_email_not(!email_not)
              }
            } />


            <span className="slider round" />
          </label>
        </div>
      </div>
    </div> : null
    }

    {
      props.access.settings.alerts_visible.sms&&wash_notification ?
          <div className="setting_block dry_wet" style={{border: 'none'}}>
            <div className="wrap_tit_switch">
              <span className="title_sett_block dry_wet_tit">  {glv('second_level_blocks.setting_page.sms')}</span>
              <div className="wrap_switch">
                <label className="switch" style={{position: 'relative'}}>
                  {
                    !props.access_write ?
                        <Blocked style={{position: 'absolute', left: -90, top: '50%', height: '33px', transform: 'translate(0, -50%)'}}/> : null
                  }


                  <input type="checkbox"
                         checked={sms_not}
                         disabled={!props.access_write}
                         value={sms_not}
                         onChange={
                           (e)=>{

                             set_sms_not(!sms_not)
                           }
                         } />


                  <span className="slider round" />
                </label>
              </div>
            </div>
          </div> : null
    }

    {
      props.access.settings.alerts_visible.telegram&&wash_notification ?
          <div className="setting_block dry_wet" style={{border: 'none'}}>
            <div className="wrap_tit_switch">
              <span className="title_sett_block dry_wet_tit">  {glv('second_level_blocks.setting_page.telegram')}</span>
              <div className="wrap_switch">
                <label className="switch" style={{position: 'relative'}}>
                  {
                    !props.access_write ?
                        <Blocked style={{position: 'absolute', left: -90, top: '50%', height: '33px', transform: 'translate(0, -50%)'}}/> : null
                  }


                  <input type="checkbox"
                         checked={telegram_not}
                         disabled={!props.access_write}
                         value={telegram_not}
                         onChange={
                           (e)=>{

                             set_telegram_not(!telegram_not)
                           }
                         } />


                  <span className="slider round" />
                </label>
              </div>
            </div>
          </div> : null
    }
    <div style={{}} className={'w100 ta_center pt_20  pb_20'} >
      <span className={'text_btn cur_p'}  onClick={()=>{set_dop_info(!dop_info)}}>
        {glv(`second_level_blocks.setting_page.dop_info${dop_info? '_opened': ''}`)}
      </span>
    </div>


    <Grow in={dop_info} unmountOnExit={true}>
      <div className="setting_block dry_wet " style={{}}>
        <div className="wrap_tit_switch">
          <span className="title_sett_block dry_wet_tit">
               {glv('second_level_blocks.setting_page.dry_wet')}
          </span>
          <div className="wrap_switch">
            <label className="switch">

              {
                props.access_admin&&props.access_write? null :
                    <Blocked
                        style={{position: 'absolute', left: -90, top: '50%', height: '33px', transform: 'translate(0, -50%)'}}/>
              }
              <input type="checkbox"
                     checked={salt_tank}
                     disabled={!props.access_admin||!props.access_write}
                     value={salt_tank}
                     onChange={(e)=>{
                       set_salt_tank(!salt_tank)
                     }}

              />
              <span className="slider round" />
            </label>
          </div>
        </div>
        <div className="wrap_diapason">
          <span className="diap_value min_val">0</span>
          <span className="diap_value max_val">100</span>
        </div>
        <input type="range" className="range_polz" />
      </div>
    </Grow>

    <Grow in={dop_info} unmountOnExit={true}>
      <div className={'w100'}>
      {
        Object.keys(delays).map(id_=>{
          if (id_==='name_asoc') {
            return
          }
          return (
              <div className="setting_block regen_time" style={{border: 'none', paddingBottom: 0, marginTop: 10}}>
                <label htmlFor="fname" className="title_sett_block" style={{flex: 1, whiteSpace: 'normal'}}>
                  {glv('first_level_blocks.statistic.f_pos.'+
                      delays.name_asoc[id_])}
                </label>

                {/*<div style={{flex: 2}}></div>*/}


                <div style={{  position: 'relative',
                  flex: 1
                }} className={'wrap_date_picker'}>
                  {
                    props.access_admin&&props.access_write?null :
                        <Blocked style={{position: 'absolute', left: -90, top: '50%', height: '33px', transform: 'translate(0, -50%)'}}/>
                  }
                  <div
                      className={'pt_16 pb_16'}
                      style={{
                        position: 'absolute',
                        width: '100%',
                        // height: '100%',
                        zIndex: 900,
                        cursor: props.access_admin&&props.access_write ?  'text' : 'default',
                        background: "linear-gradient(0deg, #DFE6F6, #DFE6F6)",
                        boxShadow:
                            "inset -4px -5px 17px rgba(255, 255, 255, 0.699055), inset 6px 8px 19px rgba(0, 0, 0, 0.08)",
                        borderRadius: 12,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'

                        // background: 'red'
                      }}

                  >
                    {
                      props.access_admin&&props.access_write?
                      <input type={'number'}
                             className={'dop_info_inputs'}
                             style={{
                               width: '100%',
                               border: 'none', fontSize: 19, outline: 'none', textAlign: 'center',
                               background: 'transparent'}}
                             maxLength={2}
                             size={2}
                             value={delays[id_]}
                             onChange={(e)=>{
                               if (e.target.value.length>2 ) {return}
                        set_delays({...delays, [id_]: e.target.value })
                      }}/> :            <span>{props.delays[id_]}</span>
                    }

                  </div>
                  <div className={'pt_16 pb_16'} style={{ whiteSpace: 'nowrap'}}> hidden block</div>
                </div>
                </div>


          )
        })
      }
      </div>
    </Grow>
    {/*<div className="setting_block wash">*/}
    {/*  <span className="title_sett_block wash_tit">Washing</span>*/}
    {/*  <div className="wrap_diapason">*/}
    {/*    <span className="diap_value min_val">0</span>*/}
    {/*    <span className="diap_value max_val">100</span>*/}
    {/*  </div>*/}
    {/*  <input type="range" className="range_polz" />*/}
    {/*</div>*/}
    {/*<div className="setting_block regen">*/}
    {/*  <span className="title_sett_block regen_tit">Regeneration</span>*/}



    {/*  <div className="wrap_rad_btns">*/}
    {/*  <RadioBtn label={'Instant'} id={'instant_regen'} setCheckedId={(id) =>setRegenRadCheked(id)} checkedId={regenRadCheked}/>*/}
    {/*  <RadioBtn label={'With delay'} id={'del_regen'} setCheckedId={(id) =>setRegenRadCheked(id)} checkedId={regenRadCheked}/>*/}
    {/*  <RadioBtn label={'Smart'} id={'smart_regen'} setCheckedId={(id) =>setRegenRadCheked(id)} checkedId={regenRadCheked}/>*/}

    {/*  </div>*/}
    {/*</div>*/}



    {/*<div className="ok_cnc_btns">*/}
    {/*  {*/}
    {/*    !disabled_save_btn&&props.access_write ?*/}
    {/*        <div className="ok_sett_btn" onClick={()=>props.saveSetting()}>*/}
    {/*          {glv('second_level_blocks.setting_page.save')}*/}
    {/*        </div> :*/}
    {/*          <div className="close_info_frame_btn mobBtnInfoFrameClose"*/}
    {/*               style={{width: 200, display: 'block', marginBottom: 0}}*/}
    {/*               onClick={()=>props.cancelSetting()}>Close</div>*/}
    {/*  }*/}
    {/*  {*/}
    {/*    !disabled_save_btn&&props.access_write ?*/}
    {/*        <div className="canc_sett_btn" onClick={()=>props.cancelSetting()}>*/}
    {/*          {glv('second_level_blocks.setting_page.cancel')}*/}
    {/*        </div> : null*/}
    {/*  }*/}

    {/*</div>*/}
  </section>
</div>



            <ActionsModalButtons
                disabled_acc_btn={
                    disabled_save_btn
                }
                has_cancel={true}
                has_accept={props.access_write }
                text_accept_btn={glv(`joint_buttons.save`)}
                text_cancel_btn={glv(`joint_buttons.cancel`)}
                style={{marginTop:30}}
                accept_action={()=>{
                  save()
                }}
                cancel_action={()=>{
                  props.cancelSetting()
                  // set_opened_modal(false)
                }}
            />
          </WrapModal>

        </Wrap_Overlay>
    ) : null
}

export default React.memo(SettingPage);