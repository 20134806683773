import React from "react";

function RouteError(props) {
    return (
        <div>
            Route error
        </div>
    )
}
export default RouteError;

