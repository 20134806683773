import React, {useRef, useEffect} from 'react'
import {Tooltip} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import * as actions from "../../../redux/actions/env.actions";
import {Fade} from '@material-ui/core';
import uuid from 'react-uuid'
import useMousePosition from '../../../hooks/useMousePosition';
import LineProgress from '../../indicator_components/linear_progress/LineProgress';
import RadialProgress from '../../indicator_components/radial_progress/old/RadialProgress';
import Indicator from '../../indicator_components/manometrs/old/Indicator';
import useWindowSize from '../../../hooks/useWindowSize';
import {glv} from "../../../components/app_components/helper_functions/helper_functions";



let path_info_ = 'second_level_blocks.info_page';


const values = {
    'blue_trumpet': 'L1-OUT',
    'purple_trumpet': 'L1-IN',
    'stock1': 'R1-T5',
    'stock2': 'R1-T6',
    'stock3': 'R2-T5',
    'stock4': 'R2-T6',
    'stock5': 'R3-T5',
    'stock6': 'R3-T6',
}

let ids = {
    ball_valve: ['R1-T1', 'R1-T2', 'R1-T4', 'R1-T3', 'R2-T1', 'R2-T2', 'R2-T4', 'R2-T3', 'R3-T1', 'R3-T2', 'R3-T4', 'R3-T3'],
    faucet_for_taking_water_samples: ['R1-T5', 'R1-T6', 'R2-T5', 'R2-T6', 'R3-T5', 'R3-T6'],
    bypass_valve: ['L1-T1', 'L1-T2', 'L1-T3'],
    brine_tank: ['R1-S1', 'R2-S1', 'R3-S1'],
    measuring_pressure_gauge: ['L1-Y1', 'L1-Y2'],
    input_dirty_water: ['L1-IN'],
    outlet_filtered_water: ['L1-OUT'],
    outlet_another_water: ['J2-OUT'],
    mech_filter: ['J1-F2', 'J1-F1']
}

function getContent(currId, path) {

    let res_key = '';
    Object.keys(ids).map((key) => {
        if (ids[key].find((key) => key === currId)) {
            res_key = key;
        }
    })
    return res_key
}

const ShemeModal = (props) => {
    // console.log(props)
    const dispatch = useDispatch();
    const {x, y} = useMousePosition();
    const blurStatus = useSelector((state) => state.envReducer.blurElems);
    const canvasRef = useRef(null);
    const canvasRefShadow = useRef(null);
    const [hover, setHover] = React.useState(false);
    const [highlightColor, setHighlightColor] = React.useState('');
    const currTrumpet = useSelector((state) => state.envReducer.currTrumpet);
    const setCurrTrumpet = React.useCallback((color) => dispatch(actions.setCurrTrumpet(color)), [
        dispatch,
    ]);

    const shemeModal = useSelector((state) => state.envReducer.shemeModal);

    const shemeModalData = useSelector((state) => state.envReducer.shemeModalData);

    const size = useWindowSize();

    const setBlur = React.useCallback((status) => dispatch(actions.setBlur(status)), [
        dispatch,
    ]);
    const [idShadow, setIdShadow] = React.useState(uuid());
    const drop_scroll = React.useCallback(() => {
        setOverModal(false)
    }, [])


    useEffect(() => {
if (shemeModal) {
    document.body.style.overflow = 'hidden';
}
 else {
    document.body.style.overflow = 'auto';
}
    }, [shemeModal]);

    const setShemeModal = React.useCallback((color) => dispatch(actions.setShemeModal(color)), [
        dispatch,
    ]);
    const setOverModal = React.useCallback((status) => dispatch(actions.setOverModal(status)), [
        dispatch,
    ]);

    const setShemeModalData = React.useCallback((color) => dispatch(actions.setShemeModalData(color)), [
        dispatch,
    ]);

    // let is_online =

    if (shemeModal) {

    }

    return shemeModal ? <Fade in={true} timeout={1000} style={{zIndex: 120202516516510225454}}>
            <div

                id={'modalka'}
                onMouseOver={(e) => {
                    // console.log("true");
                    // debugger
                    setOverModal(true)
                    // setShemeModalData({...props, x:e.clientX, y: e.clientY});
                    // setShemeModal(props.tooltip.id);
                }}
                onMouseLeave={(e) => {
                    // console.log("true");
                    // debugger
                    setOverModal(false)
                    // setShemeModalData({...props, x:e.clientX, y: e.clientY});
                    // setShemeModal(props.tooltip.id);
                }}
                style={{
                    // transform: size.width< 1700 ?  `scale(${size.width/1700})`  : 'none',
                    position: "fixed",
                    [shemeModalData.x - 450 < 5 ? 'left' : 'right']: shemeModalData.x - 450 < 5 ? shemeModalData.x + 1 : size.width - shemeModalData.x + 1,
                    // transform: size.width< 1700 ?  `scale(${size.width/1700})`  : 'none',
                    [shemeModalData.y + 450 > size.height ? 'bottom' : 'top']: shemeModalData.y + 450 > size.height ? size.height - shemeModalData.y - 2 : shemeModalData.y + 2,
                    // top: shemeModalData.y+2,
                    //  top: y+50,
                    width: 'max-content',

                    height: 'max-content',

                    // maxHeight: 250,
                    // display: "flex",
                    // flexDirection: "column",
                    borderRadius: '2.4vh',
                    background: "linear-gradient(0deg, #E3E9F7, #E3E9F7)",
                    boxShadow:
                        "-0.5vh -0.5vh 1.4vh rgb(255 255 255 / 57%), 1vh 1vh 2vh rgb(0 0 0 / 8%)",
                    // padding: "20px ",
                    // zIndex: 12020251651651022,
                    overflow: 'hidden'
                }}
            >

                {
                    props.onl_offl.hasOwnProperty(shemeModalData.tooltip.id) ?
                        <div style={{position: 'relative', }}
                        className={'flex_ column_flex flex_end_jc'}
                        >

                                <div
                                    style={{position:'absolute', left: 0,width: 40}}
                                    className={`${props.onl_offl.hasOwnProperty(shemeModalData.tooltip.id) && !props.onl_offl[shemeModalData?.tooltip?.id] ? 
                                        'offline_id_bg' : 'online_id_bg'} 
            f_sz_24 
            color_wh
            h100
            center_flex
            `}

                                >
                                    {/*
            bor_rad_id
            padd_id
            mw_122
            w100
            mh_29
            center_flex
                                     */}
                                    <div style={{transform: 'rotate(90deg)', }} className={'ws_nw'}>
                                                                        {
                                                                            shemeModalData.tooltip.id === 'id' ?
                                                                                values[shemeModalData.id] : shemeModalData.tooltip.id
                                                                        }
                                    </div>
                                </div>



                            <div style={{display: 'flex', flexDirection: 'column',
                                marginLeft: 60,
                                marginTop:10,
                                marginRight: 20
                                // position: 'absolute', left: 50, top: 5
                            }}>


                                {
                                    props.onl_offl.hasOwnProperty(shemeModalData.tooltip.id) ? null :
                                        <div style={{marginLeft: 5, textAlign: 'center', fontWeight: 600, fontSize: 18}}>
                                            {
                                                glv('first_level_blocks.sheme.types.' +
                                                    getContent(shemeModalData.tooltip.id === 'id' ?
                                                        values[shemeModalData.id] : shemeModalData.tooltip.id, 'first_level_blocks.sheme.types.'))
                                            }
                                        </div>
                                }
                                <div>
                                    <div className={'flex_ column_flex '}>

                                        <div className={'flex_ wh_sp_no_wr marg_top_10'}>
                                            <span className={'fw_bold'}>{glv(path_info_ + '.balon')}:</span>
                                            <span className={'fw_norm mg_l_10 sheme_modal_text'}>
                      {props.indicatorsData.values[shemeModalData.tooltip.id_key].settings.sys.ballon.id
                      + " [" + props.indicatorsData.values[shemeModalData.tooltip.id_key].settings.sys.ballon.size.h + 'x'
                      + props.indicatorsData.values[shemeModalData.tooltip.id_key].settings.sys.ballon.size.w + ']'}
                  </span>
                                        </div>
                                        <div className={'flex_ wh_sp_no_wr marg_top_10'}>
                                            <span className={'fw_bold'}>{glv(path_info_ + '.backfill')}:</span>
                                            <span className={'fw_norm mg_l_10 sheme_modal_text'}>
                      {props.indicatorsData.values[shemeModalData.tooltip.id_key].settings.sys.filler.id +
                      " - " + props.indicatorsData.values[shemeModalData.tooltip.id_key].settings.sys.filler.name}
                  </span>
                                        </div>
                                        <div className={'flex_ wh_sp_no_wr marg_top_10'}>
                                            <span className={'fw_bold'}>{glv(path_info_ + '.install')}:</span>
                                            <span className={'fw_norm mg_l_10 sheme_modal_text'}>
                      {props.indicatorsData.values[shemeModalData.tooltip.id_key].settings.sys.head.id +
                      " [" +
                      props.indicatorsData.values[shemeModalData.tooltip.id_key].settings.sys.head.holes.b.replace(/[^\d.-]/g, '') + "x" +
                      props.indicatorsData.values[shemeModalData.tooltip.id_key].settings.sys.head.holes.i.replace(/[^\d.-]/g, '') + ']'}
                  </span>
                                        </div>
                                        <div className={'flex_ wh_sp_no_wr marg_top_10'}>
                                            <span className={'fw_bold'}>{glv(path_info_ + '.flow_meter')}:</span>
                                            <span className={'fw_norm mg_l_10 sheme_modal_text'}>
                      {props.indicatorsData.values[shemeModalData.tooltip.id_key].settings.sys.tyrb.id +
                      " [" +
                      props.indicatorsData.values[shemeModalData.tooltip.id_key].settings.sys.tyrb.probe +
                      ']'}
                  </span>
                                        </div>

                                        <div className={'flex_ wh_sp_no_wr marg_top_10'}>
                                            <span className={'fw_bold'}>{glv(path_info_ + '.controller')}:</span>
                                            <span className={'fw_norm mg_l_10 sheme_modal_text'}>
                      {props.indicatorsData.values[shemeModalData.tooltip.id_key].settings.sys.control.id +
                      " [" + props.indicatorsData.values[shemeModalData.tooltip.id_key].settings.sys.control.ver + ']'
                      }
                  </span>
                                        </div>
                                        {
                                            props.indicatorsData.values[shemeModalData.tooltip.id_key].settings?.salt_tank ?
                                                <div className={'flex_ wh_sp_no_wr marg_top_10'}
                                                     style={{justifyContent: 'center'}}>
                                                    <span className={'sheme_modal_text'}>{glv(path_info_ + '.has_salt_back')}</span>
                                                </div> : null
                                        }

                                    </div>
                                </div>
                                <div className={
                                    '' +
                                    // 'flex_ flex_end_jc font_fam_bold ' +
                                    'color_gray '
                                    // +
                                    // 'center_flex'
                                }
                                     style={{
                                         marginTop: 10,
                                         zIndex: 23423,
                                         marginBottom: 10,
                                         textAlign: 'center'


                                     }}
                                >


                                <span style={{ fontSize: 18 }}>
                                    {/*sdfsdf sdfsdfd sdfsfd sdsfssdfghdfgdfg*/}
                                    {glv('first_level_blocks.statistic.f_pos.'+
                                        props.indicatorsData.values[shemeModalData.tooltip.id_key]['f_pos'])}</span>
                                </div>
                                {/*{*/}
                                {/*    shemeModalData.tooltip.speedometr_values ? <Indicator*/}
                                {/*        stat_width = {1500}*/}
                                {/*        mod_modalka={true}*/}
                                {/*        not_show_status_onl={true}*/}
                                {/*        small={true} percents={{*/}
                                {/*        ...shemeModalData.tooltip.speedometr_values*/}
                                {/*    }}/> : null*/}
                                {/*}*/}

                            </div>

                        </div>
                        : <div style={{display: 'flex', alignItems: 'center', flex: 1}}>

                            <div style={{
                                width: 'max-content',
                                paddingLeft: 10,
                                paddingRight: 10,
                                height: '41px',
                                fontSize: 20,
                                fontWeight: 600,
                                color: 'white'
                            }} className={'center_flex online_id_bg'}>
                            <span>
                                                            {
                                                                shemeModalData.tooltip.id === 'id' ?
                                                                    values[shemeModalData.id] : shemeModalData.tooltip.id
                                                            }
                            </span>
                            </div>
                            <div style={{
                                marginLeft: 15,
                                marginRight:15,
                                textAlign: 'center', fontWeight: 600, fontSize: 18}}>
                                {
                                    glv('first_level_blocks.sheme.types.' +
                                        getContent(shemeModalData.tooltip.id === 'id' ?
                                            values[shemeModalData.id] : shemeModalData.tooltip.id, 'first_level_blocks.sheme.types.'))
                                }
                            </div>

                        </div>
                }


            </div>
        </Fade>
        : null

}

export default ShemeModal;