import { Tooltip } from "@material-ui/core";
import React, { useRef, useEffect } from "react";
// import * as actions from "../redux/actions/env.actions";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../redux/actions/env.actions";
import uuid from 'react-uuid'
import { Fade } from '@material-ui/core';
import ShemeModal from '../modals/sheme_element_modal/shemeElementModal';
import useMousePosition from '../../hooks/useMousePosition';

const degs = {
  left: 90,
  right: 270,
  bottom: 0,
  top: 180,
};

const debounce = require('debounce');

const data =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFoAAADICAMAAABf2AZ4AAABaFBMVEUAAAAAAAAAAIBVAFVAQIArK1U5HFUzM2YrK2o3JFswMGAtLVorK2MoKF4vJl4uLmQwKGAuJ2QtLWIsLF8wKWAvKF4tLWAxK2EtKF8yLWQxLGIvK2MwLGAuK2EuKl8tKWIxKWIwKWAwKmEvLGAwKmAwKmIvKmAuKmEvKWEvK2IwKmIvKmEvKWIvK2EwKmEvKWEuKmEvKmEvKmIvKWAuK2IvKmAvKmEuKWIwK2EvKmAuKmEwKWEvK2IvKmEvKmIwK2IvKWEvKWIvK2EuKWEvKmEvKmEuKWEwK2IvKmEwKWAvKmEvKmEwKmEvKmEvKmEvKmEvK2EvKmEvKmEvK2EuKmEvKmEvKmIvKmEvKmEvKmEvK2AvK2EvKmEfpP8gm/QgnPQgnfUhkechlOshl+4hl+8pU5YqS4sqS4wqTo4qUJIrQ4IrRYMrR4YrSYksPHgsQn8tPHktPXkuLGMuLmcvKmAvKmEvLmb///9TZcqgAAAAXXRSTlMAAQIDBAYJCgwOEBESExscICEiIyUmKCozMzQ2QEJDRERKT1JVW2J0fH2AgYiJi46RkZKUlZeZmpukpaanqaqxs7S6wMPHy8zQ0dPb3N3f4+Tl6Ors7e73+fr8/P7w7z27AAAAAWJLR0R3RmT51wAAAfxJREFUeNrt3Ns/VFEYxvG9ySk0jkXSQURsFCpmLBQhRg6hHLYUORVyePv7zWB9Zsvax/XOBZ/nd7UuZr5Xs/dcrccwUKjMd/OLqebfmobR8SV9XOi/w0OX02VlRpE8PuSh66T3pKJWHp+xyI++0fWWG7TdnM7R96Tqw9ibPD36MdGwkh4hatSjGzzoF6BvCl3jQT/V/GE/txJKetBqigbmF2d6paT7HJ8oCOwWtn6mcM1YxYHk2MTV750Gwaeqg8hJon/7v8K0fUI062/HJomOftrh2jwMYKflv5t22Nb3iOYeZEMOYKflgyiyr30ub9g2v60le9qasoetLbvaDLKLzSIrbSZZYd+L+qRcby39zFdl3s6f2OQLO3lX0s2Rn0E3u13S43T8w+Zr44SmJf2VDm3OtojkfxrRH1Z6l6gUNGjQoEGDBg0aNGjQoEGDBg0aNGjQoEGDBg0aNGjQoEGDBg0aNGjQoEGDBg0aNGjQoEGDBg0a9O2gf3/nbMdJsyfpVX665JJeYJdX5C37IXb6o7ybVM9OZ+6TW8xyr2OT4DWrLJx7Bqy2uLqUwGiL/zcYzK5syWy2UO1GsNhCvUjBYAu3rQttW7ivaGjawmufQ8sW3ssfGrbw2xQxuyPK8Vz/jZOebMkp++VS+Fd/exA5VWVLfCBMibb7LtIZV8EzIICkrIwAAAAASUVORK5CYII=";

const BakOne = (props) => {
  const dispatch = useDispatch();
  const blurStatus = useSelector((state) => state.envReducer.blurElems);
  const bakRef = useRef(null);
  const canvasRefShadow = useRef(null);
  const [hover, setHover] = React.useState(false);
  const [highlightColor, setHighlightColor] = React.useState("");
  const [idShadow, setIdShadow] = React.useState(uuid());
  const [idSvg, setIdSvg] = React.useState(uuid());
  const [coord, setCoord] = React.useState(null);
  const { x, y } = useMousePosition();
  const shemeModal = useSelector((state) => state.envReducer.shemeModal);

  const shemeModalData = useSelector((state) => state.envReducer.shemeModalData);

  const setShemeModal = React.useCallback((color) => dispatch(actions.setShemeModal(color)), [
    dispatch,
  ]);

  
  const setShemeModalData = React.useCallback((color) => dispatch(actions.setShemeModalData(color)), [
    dispatch,
  ]);
  const setBlur = React.useCallback(
    (status) => dispatch(actions.setBlur(status)),
    [dispatch]
  );
  const currTrumpet = useSelector((state) => state.envReducer.currTrumpet);
  const overModal = useSelector((state) => state.envReducer.overModal);
  const setCurrTrumpet = React.useCallback((color) => dispatch(actions.setCurrTrumpet(color)), [
    dispatch,
  ]);

  useEffect(() => {
    if (overModal) {
      return
    }
    if (hover&&coord) {
      

          setShemeModalData({...props, x:coord.x, y: coord.y});
          setShemeModal(props.tooltip.id);
      

      setCurrTrumpet('bak')
      setHighlightColor("#3ecd83");
      setBlur(true); 
    } else { 

        setShemeModal(hover);     
        setCurrTrumpet('')
  
        setHighlightColor("");
        setBlur(false);
      
      // setShemeModalData(null);

    }
  }, [hover,coord, overModal]);

  useEffect(() => {
    


  }, [ x, y, bakRef]);


  useEffect(() => {


  }, [shemeModal]);

  return (

      <div
        ref={bakRef}
        style={props.alertMsg ? {...props.styleWrap, cursor: 'pointer'} : {...props.styleWrap, cursor: 'pointer'}}
        // onClick={() => props.handleScrollToStat()}
        onMouseOver={debounce((e) => {
          // console.log("true");


          setCoord({x:e.clientX, y: e.clientY})

          if (overModal) {
            // setTimeout(() => {
            //   if (!overModal) {
            //     setHover(true)
            //   }
            // }, 00);
            return
          }
        setHover(true)
        // setShemeModalData({...props, x:e.clientX, y: e.clientY});
          // setShemeModal(props.tooltip.id);
        },300)}
        // id={props.tooltip.id}
        onMouseLeave={debounce(() => {
          if (overModal) {return}
          setHover(false)
        },200)}
        // className={'hover_shadow'}
      >
        {/* <animate attributeName="stdDeviation" values="0;4;0" dur="2s" 
              repeatCount="indefinite" />  */}

{/* <animate  attributeName="stdDeviation" values="0;5"
        dur="1s" fill="freeze" begin={`${idSvg}.mouseover`}/>
      <animate  attributeName="stdDeviation" values="5;0"
        dur="1s" fill="freeze"  begin={`${idSvg}.mouseout`}/> */}

                {/* <animate attributeName="stdDeviation" from="0" to="5"
        dur="5s" fill="freeze"  repeatCount="1"  begin="bak"/> */}

                        {/* <animate attributeName="stdDeviation" from="5" to="0"
        dur="5s" fill="freeze"  repeatCount="1" begin={!hover} end={hover}/> */}

                {/* <animate attributeType="XML" attributeName="stdDeviation" to="3"
        dur="2s" fill="freeze" /> */}
        <div style={{ position: "relative" }}>
        {/* {hover ? (
     <ShemeModal {...props} x={860} y={50}/>
      ) : null} */}

      


      <svg style={{transition: 'all 0.2s', }}  className={blurStatus&&!hover?'blur_mode':''}
version="1.1" xmlns="http://www.w3.org/2000/svg" 
      width="110" height="220" viewBox="0 -3 60 150" >  
 <defs>
  <filter id={idShadow} >
    <feDropShadow 

        dx="0" 
        dy="0" 
        stdDeviation="0" 
        floodColor="#39cb58" 
        
    >
    	   <animate   attributeName="stdDeviation"   values="0;5"  begin={idSvg.replace(/-/g, '')+'.mouseover'}  dur="0.4s" fill="freeze"> </animate>
  	   <animate  attributeName="stdDeviation"   values="5;0"  begin={idSvg.replace(/-/g, '')+'.mouseout'}  dur="0.25s" fill="freeze"> </animate>
    </feDropShadow>
</filter>
</defs>

{/* <g xmlns="http://www.w3.org/2000/svg"    filter="url(#filter_shadow)" id="bak" >
	  <path id="path1" fill="#39cb58" d="M332.8,20.3c-40.4,0-73.2,32.8-73.2,73.2l-0.1,1.6c0,51.3-41.6,93-93,93s-93-41.6-93-93l0-1.6c0-40.4-32.8-73.2-73.2-73.2l0-19.8c51.3,0,93,41.6,93,93l0,1.6c0,40.4,32.8,73.2,73.2,73.2s73.2-32.8,73.2-73.2l0.1-1.6c0-15,3.6-29.2,9.9-41.7C265,21.4,296.5,0.5,332.8,0.5V20.3z" >
     
	 </path>
   </g>   */}

          <g
          id={idSvg.replace(/-/g, '')}
          xmlns="http://www.w3.org/2000/svg"
      
          style={{ transition: 'filter 2s',fill:"none",filter: `url(#${idShadow})`}}
          // filter="url(#filter_shadow)"  
        >
          <path
          
            id="path1106-3-6"
            d="m 59,128.364 c 0,0.79 -0.3121,1.534 -0.8783,2.1 l -9.6529,9.657 C 47.9062,140.684 47.1429,141 46.3471,141 H 13.6528 c -0.7958,0 -1.559,-0.316 -2.1217,-0.879 L 1.87829,130.464 C 1.31592,129.902 1,129.139 1,128.344 V 23.6564 C 1,22.861 1.31592,22.0981 1.87829,21.5355 L 11.5311,11.8791 C 12.0938,11.3162 12.857,11 13.6528,11 h 32.6943 c 0.7958,0 1.5591,0.3162 2.1217,0.8791 l 9.6529,9.6564 C 58.6864,22.1004 59,22.8519 59,23.6441 v 0.428 c -1e-4,11.5545 -2e-4,68.7804 0,104.2919 z"
            style={{ fill: "#1fa4ff", stroke: "#2f2a61", strokeWidth: 2 }}
          />
          <path
            id="path1108-8-8"
            d="M 59,127 H 1 v 3 l 11,10.5 H 48 L 58.5,130 Z"
            style={{ fill: "#2f2a61" }}
          />
          <path
            id="path1110-8-4"
            d="M 37,9.53916 C 37,9.79355 36.7487,10 36.4402,10 H 25.5598 C 25.2513,10 25,9.79355 25,9.53916 V 4 c 0,-0.5 0.5,-1 1,-1 h 10 c 0.5,0 1,0.5 1,1 z"
            style={{ fill: "#2f2a61" }}
          />
          <path
            id="path1112-31-8"
            d="M 38,11 H 22 V 3 c 0,-0.5 0.5,-1 1,-1 h 1 c 2,0 10.5,0 12,0 h 1 c 0.5,0 1,0.5 1,1 z"
            style={{ fill: "#2f2a61" }}
          />
          <path
            id="path1114-8-8"
            d="M 29,2.8025 C 29,2.91152 28.8743,3 28.7201,3 H 23.2799 C 23.1257,3 23,2.91152 23,2.8025 V 1 c 0,-0.5 0.5,-1 1,-1 h 4 c 0.5,0 1,0.5 1,1 z"
            style={{ fill: "#2f2a61" }}
          />
          <path
            id="path1116-9-3"
            d="M 37,2.8025 C 37,2.91152 36.8743,3 36.7201,3 H 31.2799 C 31.1257,3 31,2.91152 31,2.8025 V 1 c 0,-0.5 0.5,-1 1,-1 h 4 c 0.5,0 1,0.5 1,1 z"
            style={{ fill: "#2f2a61" }}
          />
          <path
            id="path1118-6-8"
            d="m 22,3 h -1 c -0.5,0 -1,0.5 -1,1 v 4 c 0,0.5 0.5,1.00002 1,1 h 1 z"
            style={{ fill: "#2f2a61" }}
          />
          <path
            id="path1120-4-7"
            d="m 38,3 h 1 c 0.5,0 1,0.5 1,1 v 4 c 0,0.5 -0.5,1.00002 -1,1 h -1 z"
            style={{ fill: "#2f2a61" }}
          />
        </g>   
     

</svg>	
{/* <svg  width={props.width} height={props.height} viewBox="0 -3 60 150" style={{transition: 'all 0.4s', }}  className={blurStatus&&!hover?'blur_mode':''}
version="1.1" xmlns="http://www.w3.org/2000/svg" 
>
<defs>

    <filter id={idShadow}>
      <feDropShadow 
      id='anim'
      dx="0" dy="0" 
      // stdDeviation="0"
          floodColor="#3ecd83">
            {
              hover ?    <animate attributeName="stdDeviation"   values="0;5"  dur="0.4s" fill="freeze"> </animate> :
              <animate  attributeName="stdDeviation"   values="5;0"   dur="0.25s" fill="freeze"> </animate>
            }
 


</feDropShadow>
    </filter>

  </defs>


  <defs>
  <filter id={idShadow} >
    <feDropShadow 

        dx="0" 
        dy="0" 
        stdDeviation="0" 
        floodColor="gray" 
        
    >

    	  <animate   attributeName="stdDeviation"   values="0;5"  begin="bak.mouseover"  dur="0.4s" fill="freeze"> </animate>
  	   <animate  attributeName="stdDeviation"   values="5;0"  begin="bak.mouseout"  dur="0.25s" fill="freeze"> </animate>
    </feDropShadow>
</filter>
</defs>

<g xmlns="http://www.w3.org/2000/svg"    style={{ transition: 'filter 2s',fill:"none",filter: `url(#${idShadow})`}} 
// id={idSvg}    
id="bak">
      <path id="path1106-3-6" d="m 59,128.364 c 0,0.79 -0.3121,1.534 -0.8783,2.1 l -9.6529,9.657 C 47.9062,140.684 47.1429,141 46.3471,141 H 13.6528 c -0.7958,0 -1.559,-0.316 -2.1217,-0.879 L 1.87829,130.464 C 1.31592,129.902 1,129.139 1,128.344 V 23.6564 C 1,22.861 1.31592,22.0981 1.87829,21.5355 L 11.5311,11.8791 C 12.0938,11.3162 12.857,11 13.6528,11 h 32.6943 c 0.7958,0 1.5591,0.3162 2.1217,0.8791 l 9.6529,9.6564 C 58.6864,22.1004 59,22.8519 59,23.6441 v 0.428 c -1e-4,11.5545 -2e-4,68.7804 0,104.2919 z"  
      style={{fill:"#1fa4ff",stroke:'#2f2a61',strokeWidth:2 }}/>
      <path id="path1108-8-8" d="M 59,127 H 1 v 3 l 11,10.5 H 48 L 58.5,130 Z"  style={{fill:"#2f2a61"}}/>
      <path id="path1110-8-4" d="M 37,9.53916 C 37,9.79355 36.7487,10 36.4402,10 H 25.5598 C 25.2513,10 25,9.79355 25,9.53916 V 4 c 0,-0.5 0.5,-1 1,-1 h 10 c 0.5,0 1,0.5 1,1 z"  style={{fill:"#2f2a61"}}/>
      <path id="path1112-31-8" d="M 38,11 H 22 V 3 c 0,-0.5 0.5,-1 1,-1 h 1 c 2,0 10.5,0 12,0 h 1 c 0.5,0 1,0.5 1,1 z"  style={{fill:"#2f2a61"}}/>
      <path id="path1114-8-8" d="M 29,2.8025 C 29,2.91152 28.8743,3 28.7201,3 H 23.2799 C 23.1257,3 23,2.91152 23,2.8025 V 1 c 0,-0.5 0.5,-1 1,-1 h 4 c 0.5,0 1,0.5 1,1 z"  style={{fill:"#2f2a61"}}/>
      <path id="path1116-9-3" d="M 37,2.8025 C 37,2.91152 36.8743,3 36.7201,3 H 31.2799 C 31.1257,3 31,2.91152 31,2.8025 V 1 c 0,-0.5 0.5,-1 1,-1 h 4 c 0.5,0 1,0.5 1,1 z"  style={{fill:"#2f2a61"}}/>
      <path id="path1118-6-8" d="m 22,3 h -1 c -0.5,0 -1,0.5 -1,1 v 4 c 0,0.5 0.5,1.00002 1,1 h 1 z"  style={{fill:"#2f2a61"}}/>
      <path id="path1120-4-7" d="m 38,3 h 1 c 0.5,0 1,0.5 1,1 v 4 c 0,0.5 -0.5,1.00002 -1,1 h -1 z" style={{fill:"#2f2a61"}}/>
    </g>



</svg> */}

      {/* {
        hover ? 
        <animate   attributeName="stdDeviation"   values="0;5"  begin="1s"  dur="0.4s" fill="freeze"> </animate> :  
        <animate   attributeName="stdDeviation"   values="5;0"  begin="1s"  dur="0.4s" fill="freeze"> </animate>
      } */}
          {/* <canvas
            ref={canvasRef}
            {...props}
            width={props.width}
            height={props.height}
            style={{
              transform: `rotate(${degs[props.direction]}deg)`,
              filter: blurStatus && !hover ? "blur(2px)" : "none",
            }}
          /> */}

          {/* <canvas
            ref={canvasRefShadow}
            {...props}
            width={props.width + 10}
            height={props.height + 10}
            style={{
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: `translate(-50%,-50%)`,
              zIndex: -1,
            }}
          /> */}
        </div>
      </div>

  );
};

export default BakOne;
