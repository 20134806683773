import React from "react";
import CanvasIndicator from "../indicator_components/manometrs/current/CanvasIndicator";
import moment from "moment";
import {create_arr_keys_for_rend_indicators, get_max_length} from "../../helper_functions/helpers";
import Slider from "react-slick";
import RowBlockIndicators from "./row_indicator_block";
import CustomSlider from "../Slider/slider";
import TransitionSlider from "../TransitionSlider/slider.component";

const RenderIndicators = (props) => {
    const {look_manometrs}=props.setting_data;
    //компонент рендера массива индикаторов
    let settings = {
        dots: true
    };
    let vals = props.indicatorsData.values;
    let rend_arr =props.indicatorsData?.law_and_order?.speedometers ?
        props.indicatorsData?.law_and_order?.speedometers : [];
    let trigger_for_create_arr_rend_look_check  =
        look_manometrs[props.is_desktop ? 'desktop_look' : 'mobile_look'] !== 'rows_slider';
    let is_two_column = look_manometrs[props.is_desktop ? 'is_two_column_all_column_d' : 'is_two_column_all_column_m']
    let { f_cof, arr_to_rend} = create_arr_keys_for_rend_indicators(
            rend_arr,
            vals,
            props.dimensions_container,
            trigger_for_create_arr_rend_look_check,
            is_two_column
        )
    const { ratio} = props.dimensions_container;
    // console.log(arr_to_rend, 'arr_to_rend')
    let trigger_key_look = props.is_desktop ? 'desktop_look' : 'mobile_look';
    let is_all_column =props.is_all_column ||  look_manometrs[trigger_key_look]==='all_column';
    let is_another_look =props.is_another_look ||  look_manometrs.mobile_look==='all_slider'||
        look_manometrs.mobile_look==='rows_slider';
    arr_to_rend =trigger_key_look==='mobile_look'&&look_manometrs.mobile_look==='all_slider' ?
        arr_to_rend.flat().map(item=>[item]) : arr_to_rend;
    return (
        <div
            style={{
                // ...(props.style||{}),
                padding: is_all_column ? '36px 0px' : ''}}
            className={'wrap_render_indicators'}>

            {
                is_all_column ?
                        <div style={{display: 'flex', flexDirection:'column'}}>
                            {
                                arr_to_rend.map((item_data, index_)=>{

                                    return (
                                        <RowBlockIndicators
                                            key={'row_indicators_'+index_}
                                            data_for_indicator={{item_data, index_ , vals,f_cof, ratio}}
                                        />
                                    )
                                })
                            }
                        </div> :
                    is_another_look ?
                            <CustomSlider
                                manomentry_block_max_width={props.manomentry_block_max_width}
                                id_scroll_wrapper={props.id_scroll_wrapper}
                                slides={arr_to_rend}
                                id_slide_component={'row_block_indicator'}
                                data_to_slide_component={{ vals,f_cof, ratio}}
                    /> : null
            }
        </div>
    )
}

export  default RenderIndicators;