import React, {useContext, useRef} from 'react';
import Wrap_Overlay from "../modal_components/wrappers/overlay_wrappers/Wrap_Overlay";
import WrapModal from "../modal_components/wrappers/Wrap_Modal";
import {IS_DESKTOP_CONTEXT} from "../../../App";
import ShemeElModal from "../sheme_element_modal/sheme_el_modal";
import moment from "moment";
import Title_Modal from "../modal_components/Title_Modal";
import {glv} from "../../app_components/helper_functions/helper_functions";
import ActionsModalButtons from "../modal_components/Actions_Buttons_Modal";

const ShemeMainHomeModalComponent = ({title, open, close, accessData, onl_offl, indicatorsData}) => {
    const ref_img_scheme = useRef(null);
    const is_desktop = useContext(IS_DESKTOP_CONTEXT)
    const [dimensions_container, set_dimensions_container ] = React.useState(null);
    const [overed_id, set_overed_id] = React.useState('');
    const [data_to_modal, set_data_to_modal] = React.useState({open: false});
    const [coffs, set_coffs] = React.useState({
        x: 1,
        y: 1
    });



    const get_container_dimensions = () => {
        if (ref_img_scheme.current) {
            let ratio = window.devicePixelRatio;
            ratio= ratio<0? 1: ratio

            let w_ = ref_img_scheme.current.offsetWidth;
            let h_ = ref_img_scheme.current.offsetHeight;

            set_coffs({
                x:  w_<accessData?.sheme_obj?.size?.x ?
                    w_/accessData?.sheme_obj?.size?.x : 1,
                y:  w_<accessData?.sheme_obj?.size?.x ?
                    (accessData?.sheme_obj?.size?.y/
                        accessData?.sheme_obj?.size?.x*
                            w_)/accessData?.sheme_obj?.size?.y:
                    1
            })


        }
    }

    React.useEffect(() => {
        get_container_dimensions();
        window.addEventListener('resize', ()=>{
            get_container_dimensions()
        });

    }, [ref_img_scheme.current]);



    const mouse_over = React.useCallback((e)=>{
        set_overed_id(e.currentTarget.id)
        let ov_id = e.currentTarget.id;
        let rf_ =  Object.values(indicatorsData.values).find(item=>item.name===ov_id);

        set_data_to_modal({
            open: true,
            current_state:
                moment(accessData.dates.now).diff(moment.unix(accessData.dates.last_upd), "minute") >= 1
                    ?'offline'
                    :  moment.unix(accessData.dates.last_upd).format('ll HH:mm'),
            x: e.clientX,
            y: e.clientY,
            onl_offl: onl_offl,
            id:indicatorsData.values[ov_id]?.name||ov_id,
            id_key:ov_id,
            name_id: indicatorsData.values[ov_id]?.name||ov_id,
            indicatorsData: indicatorsData,
            speedometr_values: {
                min:  rf_?.sys_info?.min,
                max: rf_?.sys_info?.max,
                value: rf_?.value
            }
        })
    }, [indicatorsData, onl_offl,accessData]);

    const mouse_leave = React.useCallback((e)=>{
        set_overed_id('')
        set_data_to_modal({
            open: false,

        })
    }, [])

    return (
        <Wrap_Overlay
            open={open}
            hidden_body={true}
            handle_click_overlay={()=>{
                close()
                // set_opened_modal(false)
            }}
            class_position_children={'overlay_children_position'}
        >

            <WrapModal
                custom_style={{padding: is_desktop ? 34 : 16}}
                full_w={is_desktop ?'': 'full_width' }
                // set_dimensions_container={set_dimensions_container}
            >
                <Title_Modal
                    title_text={title  ||glv(`first_level_blocks.scheme_modal.title`)}
                />
                <ShemeElModal {...data_to_modal} />
                {
                    <div style={{
                        marginTop: 12,
                        position: 'relative',
                        // width:dimensions_container.width
                    }}>
                        <img
                            ref={ref_img_scheme}
                            style={{width: '100%', height: '100%'}}
                            src={accessData?.sheme_obj?.shem||'#'}/>
                        {accessData?.sheme_obj?.blocks.map((item, index)=>{
                            return (
                                <div
                                    onMouseOver={mouse_over}
                                    onMouseLeave={mouse_leave}
                                    id={item.vp_id}
                                    className={'scheme_el_block_over'}
                                    key={item.vp_id}
                                    style={
                                        {
                                            position: 'absolute',
                                            left: item.pos.x*coffs.x,
                                            top: item.pos.y*coffs.y,
                                            width:item.size.w*coffs.x,
                                            height:item.size.h*coffs.y,
                                            zIndex: 1100
                                        }}

                                >

                                </div>
                            )
                        })

                        }
                    </div>

                }

                <ActionsModalButtons
                    disabled_acc_btn={
                       false
                    }
                    has_cancel={true}
                    has_accept={false}
                    text_cancel_btn={ glv(`first_level_blocks.setting_modal.btns.canc`)}
                    style={{marginTop:30}}
                    accept_action={()=>{

                    }}
                    cancel_action={()=>{
                       close()
                        // set_opened_modal(false)
                    }}
                />
            </WrapModal>
        </Wrap_Overlay>
    );
};

export default ShemeMainHomeModalComponent;