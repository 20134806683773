
//ls - localstorage

import {template_setting_form} from "./static_datas";

export const check_setting_form_ls = (set_form_from_ls) =>{
    let get_header_setting_ls = localStorage.getItem('setting_form')
    if (get_header_setting_ls) {
        let old_saved_setting_form = JSON.parse(get_header_setting_ls);
        set_form_from_ls ?
            set_form_from_ls({...old_saved_setting_form})
            : console.log()
    } else {
        localStorage.setItem('setting_form', JSON.stringify(template_setting_form))
    }
}


export const check_is_number_with_pub = (val_to_check, pub_number=0) => {
  if (isNaN(Number(val_to_check))) {
      return pub_number
  }
  return val_to_check
}
