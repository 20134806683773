// import axios from "axios";
import {current_language, env_urls, hash_key, is_rel_b, local_hash, set_env_urls, set_hash_key} from "../App";
import {setlogos} from '../components/app_components/helper_functions/helper_functions'

import {minus_hours} from "../helper_functions/helpers";
import Cookies from "js-cookie";
// import {env_urls} from "../../../LoginBuild/src/App";
// import {env_url, set_env_urls} from "../../../bregus-admin/src/App";

const superagent = require('superagent');


let walid_apikey = false;
let counter_apikey = 0;

export function CheckWrap(
    setUrls,
    url,
    body,
    type,
    point,
    returned_type,
    prevFunc,
    postFunc,
    sha1,
    moment,
    Cookies,
) {

    let newApiKey = url.apiKey;

    if (prevFunc) {
        prevFunc()
    }


    let recursive_update = ()=> {
        if (!walid_apikey) {
            return CheckWrap(
                setUrls,
                {...url,apiKey: newApiKey },
                body,
                type,
                point,
                returned_type,
                prevFunc,
                postFunc,
                sha1,
                moment,
                Cookies,
            )
        }
        return setTimeout(()=>recursive_update(), 4)
    }

    let update_confs =  () => {

        let curr_url = `${process.env.PUBLIC_URL}/json/conf.json`;
        superagent.get(curr_url)
            .then((res) => {
                let body = res.body;
                // обновляется ключ
                newApiKey = body.keys.apiKey;

                let new_urls = {
                    api_key: body.keys.apiKey,
                    api_url: body.url.api,
                    login: body.url.login,
                    api_home: body.url.home,
                    timepoints: body.timepoints,
                };
                set_env_urls({...new_urls})
                walid_apikey =  false;
                return true
            })
            .catch((error) => {

                setlogos(
                    JSON.stringify({error: error.name, info: error.message}),
                    "error when get new api key",
                    sha1,
                    moment,
                    Cookies
                );
                walid_apikey =  false;
                return  false
            });

    }


    let push_req =  () => {


        return  superagent(type, `${url.url}/${newApiKey}${url.path}${url.params}`)
            .send(type === "GET" ? null : JSON.stringify(body))
            .set('Accept', 'application/json')
            .set('HashKey', hash_key)
            .set('UserLang', current_language)
            .set('ScreenSize', `${window.innerWidth}x${window.innerHeight}`)
            .then( (res)=>{
            return res
        }).catch((error) => {
            setlogos(
                JSON.stringify({error: error.name, info: error.message}),
                point,
                sha1,
                moment,
                Cookies
            );
            return {status: 'error'}
        });

    }


    // обертка трай для отлова ошибок
    let future_res =null;
    try {
        if (!walid_apikey) {
            return  push_req().then((res_req)=> {
                if (res_req.status === 200) {
                    if (postFunc) {
                        postFunc()
                    }

                    counter_apikey = 0;
                    future_res =  res_req.body;
                    if (!future_res) {
                        future_res= JSON.parse(res_req.text);
                    }
                    if (returned_type==='blob'){
                        future_res =  res_req.blob();
                    }

                    return future_res;
                }

                if (res_req.status !== 200) {
                    walid_apikey = true;

                    if (counter_apikey + 1 > 10) {
                        window.location.href = "https://i.bregus.eu/page_info#404";
                        return;
                    }
                    counter_apikey = counter_apikey + 1;
                    let  resres = update_confs();
                    return recursive_update();
                }

            });

        }
    } catch (error) {
        // вызов функции записи ошибок
        setlogos(
            JSON.stringify({error: error.name, info: error.message}),
            point,
            sha1,
            moment,
            Cookies
        );
    }
}

export default class ProService {

    static getIndicatorsData = async (setUrls, hash, id) => {
        let params = `?hash_key=${hash_key}&system_id=${id}`;
        let req_url = {
            url: env_urls.api_url,
            apiKey: env_urls.api_key,
            path: '/load/dev_pro_loop_info',
            params: params
        };
        let req_body = null;
        let req_type = "GET";
        let req_point = "Page: Pro/ Type: request error/ URL: load/dev_pro_loop_info";
        return await CheckWrap(
            setUrls,
            req_url,
            req_body,
            req_type,
            req_point
    )

    };

    // static getAccessedLinks = async (hash) => {
    //
    //     let params = `?hash_key=${hash}`;
    //
    //     let req_url = {
    //         ...env_urls,
    //         path: '/load/goshan',
    //         params: params
    //     };
    //     let req_body = null;
    //     let req_type = "GET";
    //     let req_point = " Page: Any/ Type: request error/ URL: /load/goshan function: getAccessedLinks";
    //
    //     return await CheckWrap(
    //         req_url,
    //         req_body,
    //         req_type,
    //         req_point
    //     );
    // };

    static get_accesseed_links = async (url, api_key) => {
        let params = `?hash_key=${hash_key}&domain=home`;

        // console.log(minus_hours(new Date(), 2).valueOf())
        let req_url = {
            url: url ? url : env_urls.api_url,
            apiKey: api_key ||  env_urls.api_key,
            path: '/load/goshan',
            params: params
        };
        let req_body = null;
        let req_type = "GET";
        let req_point = "Page: Pro/ Type: request error/ URL: load/goshan";
        return await CheckWrap(
            '',
            req_url,
            req_body,
            req_type,
            req_point
        )

    };

    static log_out = async (
        setUrls,
        hash
    ) => {
        let params = `hash_key=${hash_key}`;
        let req_url = {
            url: env_urls.api_url,
            apiKey: env_urls.api_key,
            path: '/user/logaut',
            params: params
        };
        let req_body = null;

        let req_type = "GET";
        let req_point = " Page: / Type: request error/ URL: /user/logaut function: logout";
        return await CheckWrap(
            setUrls,
            req_url,
            req_body,
            req_type,
            req_point
        );
    };


    static get_chart_table_data = async (setUrls,  hash,  id, lims, type, date_start, date_finish) => {
        let params = `?hash_key=${hash_key}&system_id=${id}&limits=${lims}&type=${type}&time_begin=${
            date_start
            // minus_hours(new Date(), 2).valueOf()/1000
        }&time_end=${
            date_finish
            // Date.now()/1000
        }`;
        // console.log(minus_hours(new Date(), 2).valueOf())
        let req_url = {
            url: env_urls.api_url,
            apiKey: env_urls.api_key,
            path: '/load/pro_graph',
            params: params
        };
        let req_body = null;
        let req_type = "GET";
        let req_point = "Page: Pro/ Type: request error/ URL: load/pro_graph";
        return await CheckWrap(
            setUrls,
            req_url,
            req_body,
            req_type,
            req_point
        )

    };

    static getAccessData = async (setUrls, hash, id) => {
        let params = `?hash_key=${hash_key}&system_id=${id}`;
        let req_url = {
            url: env_urls.api_url,
            apiKey: env_urls.api_key,
            path: '/load/dev_pro_pos_info',
            params: params
        };
        let req_body = null;
        let req_type = "GET";
        let req_point = ' Pro/ Type: request error/ URL: load/dev_pro_pos_info';
        return await CheckWrap(
            setUrls,
            req_url,
            req_body,
            req_type,
            req_point
        )
    };

    static contactForm = async (setUrls, hash,  id, form, prevFunc, postFunc) => {
        let params = `?hash_key=${hash_key}&system_id=${id}`;
        let req_url = {
            url: env_urls.api_url,
            apiKey: env_urls.api_key,
            path: '/set/home_pro_callback',
            params: params
        };
        let req_body = form;
        let req_type = "POST";
        let req_point = ' Page: send report/ Type: request error/ URL: set/home_pro_callback';
        return await CheckWrap(
            setUrls,
            req_url,
            req_body,
            req_type,
            req_point,
            '',
            prevFunc,
            postFunc
        )
    };

    static add_new_system = async ( ref_id) => {
        let params = `?hash_key=${hash_key}&ref_id=${ref_id}`;
        let req_url = {
            url: env_urls.api_url,
            apiKey: env_urls.api_key,
            path: '/set/home_referal',
            params: params
        };
        let setUrls = ()=>{};
        let req_body = null;
        let req_type = "GET";
        let req_point = ' Page: send report/ Type: request error/ URL: set/home_referal';
        return await CheckWrap(
            setUrls,
            req_url,
            req_body,
            req_type,
            req_point,
        )
    };

    static start_regen = async ( dev_id) => {
        let params = `?hash_key=${hash_key}&dev_id=${dev_id}`;
        let req_url = {
            url: env_urls.api_url,
            apiKey: env_urls.api_key,
            path: '/set/dev_start_regen',
            params: params
        };
        let setUrls = ()=>{};
        let req_body = null;
        let req_type = "GET";
        let req_point = ' Page: send report/ Type: request error/ URL: set/dev_start_regen';
        return await CheckWrap(
            setUrls,
            req_url,
            req_body,
            req_type,
            req_point,
        )
    };

    static save_settings_dev = async (dev_id, form) => {
        let params = `?hash_key=${hash_key}&dev_id=${dev_id}`;
        let req_url = {
            url: env_urls.api_url,
            apiKey: env_urls.api_key,
            path: '/set/dev_settings',
            params: params
        };
        let setUrls = ()=>{};
        let req_body = form;
        let req_type = "PUT";
        let req_point = ' Page: send report/ Type: request error/ URL: set/dev_start_regen';
        return await CheckWrap(
            setUrls,
            req_url,
            req_body,
            req_type,
            req_point,
        )
    };
}
