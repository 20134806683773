

const inits_vals_by_type = {
    'time': {
        guard_clean: (curr_index, state)=>{
            let sympol_for_clean = state[curr_index-1];
            let result = sympol_for_clean===':' ?
                state.slice(0,curr_index-2) + state.slice(curr_index-1)  :
                state.slice(0,curr_index-1) + state.slice(curr_index)
            let new_index = sympol_for_clean===':' ? curr_index-2 : curr_index-1
            return [result, new_index];
        },
        init_const_val: ':'
    }
}

export default inits_vals_by_type;