import React from "react";

import { useSelector } from "react-redux";
import './styles/hd_header.css';
import HeaderBarHD from "./header_bar_hd";
import HeaderMenuBlockHD from "./menu_block_hd";
import {glv} from "../../app_components/helper_functions/helper_functions";





const HeaderHD = (props) => {
    const language = useSelector((state) => state.envReducer.language);

    return (
        <HeaderBarHD position="static" style={{background: "#5E5AB9"}}>
            <div className={'wrap_logo_and_title_hd'}>
                <div className={'wrap_header_logo_hd'}
                     onClick={()=>{
                         window.location.href = '/router_page'
                     }}
                     style={{}}>
                    <img
                        className={'header_logo_hd'}
                        src={`${process.env.PUBLIC_URL}/favicon.ico`}
                        onClick={() => {
                            // window.location.reload();
                        }}
                    />
                </div>
                <div className={'wrap_header_title_hd'} style={{}}>
                    <span className={'header_title_hd'}> {
                        props.title
                    }</span>
                </div>
                {/*<HeaderTitle title_text={props.title}/>*/}
            </div>


            <div className={'wrap_menu_struct_blocks_hd'}>

                <div className={'wrap_menu_blocks_hd'}>
                    {
                        props.punkts.map(punkt=>{
                            return (
                                <HeaderMenuBlockHD
                                    key={punkt.id}
                                    child={punkt.child ? punkt.child : null}
                                    id_name={punkt.id}
                                    action={()=> {
                                        if (punkt.action) {
                                            punkt.action()
                                        }
                                    }}
                                    title={glv('header.menu_buttons.'+punkt.id)}
                                />
                            )
                        })

                    }
                </div>
                {/*<div className={'menu_hide_btn'}>*/}
                {/*    <span>Hide</span>*/}
                {/*</div>*/}

            </div>

        </HeaderBarHD>

    );
}
export default HeaderHD;
