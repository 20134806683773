import React, { useEffect, useCallback, useState } from "react";
import moment from 'moment';
import sha1 from 'sha1';


export const Notifications_History_Context = React.createContext([]);

let interval_notification_remove = null;

function create_notification(notification, delete_notification){
    const {id, text, code, time, } = notification;
    let new_element_notification = document.createElement("li");
    let fake_top_margin = document.createElement("div");
    let wrap_content_notification = document.createElement("div");
    let container_notification = document.createElement("div");
    let wrap_text_notification = document.createElement("div");
    let text_notification = document.createElement("span");
    let code_notification = document.createElement("span");
    let time_notification = document.createElement("span");
    let wrap_close_icon_notification = document.createElement("div");
    let close_icon_el_1 = document.createElement("div");
    let close_icon_el_2 = document.createElement("div");

    new_element_notification.id=id;

    new_element_notification.classList.add("li_wrap_notification");

    fake_top_margin.style.height='10px';

    wrap_content_notification.classList.add("wrap_container_notifications_modal");
    container_notification.classList.add("container_notification");
    wrap_text_notification.classList.add("wrap_text_notification");
    text_notification.classList.add("text_notification");
    code_notification.classList.add("code_notification");
    time_notification.classList.add("time_notification");
    wrap_close_icon_notification.classList.add("wrap_close_icon_notification");
    close_icon_el_1.classList.add("close_icon_el_1");
    close_icon_el_2.classList.add("close_icon_el_2");

    wrap_close_icon_notification.onclick = ()=>{
        delete_notification(id, true);
    }

    wrap_close_icon_notification.onmouseover = ()=>{
        close_icon_el_1.style.background = '#5e5ab9';
        close_icon_el_2.style.background = '#5e5ab9';
    }

    wrap_close_icon_notification.onmouseleave = ()=>{
        close_icon_el_1.style.background = '';
        close_icon_el_2.style.background = '';
    }

    container_notification.onclick=()=>{
        delete_notification(id);
    }

    text_notification.innerHTML = text;
    code_notification.innerHTML = code;

    wrap_text_notification.appendChild(text_notification);
    close_icon_el_1.appendChild(close_icon_el_2);
    wrap_close_icon_notification.appendChild(close_icon_el_1);

    container_notification.appendChild(wrap_text_notification);
    container_notification.appendChild(code_notification);
    container_notification.appendChild(wrap_close_icon_notification);

    wrap_content_notification.appendChild(container_notification);

    new_element_notification.appendChild(fake_top_margin);
    new_element_notification.appendChild(wrap_content_notification);
    return new_element_notification;
}

function delay(ms) {
    return new Promise((res, rej)=>{
        setTimeout(res, ms)
    })
}

const NotificationWrapper = (props) => {
    const [list, setList] = useState([]);

    const close_all_notification = ()=>{
        let container_notification = document.getElementById('list_notification_conainer');
        container_notification.innerHTML = '';
    }

    const delete_notification =  (id, trigger_remove_from_session_store)=>{
        let container_notification = document.getElementById('list_notification_conainer');
        let notification_for_remove = document.getElementById(id);
        if (!notification_for_remove) {
            return
        }
        // notification_for_remove.style.width = '0px';
        notification_for_remove.classList.add('closing')

       return delay(500).then(()=>{
            if (trigger_remove_from_session_store) {
                let history_notification = JSON.parse(sessionStorage.getItem('notifications'))
                const history_notification_index = history_notification.findIndex(e => e.id === id);
                if (history_notification_index!==-1){
                    history_notification.splice(history_notification_index, 1);
                }
                sessionStorage.setItem('notifications', JSON.stringify([...history_notification]))
            }
            notification_for_remove.remove();
            if (!container_notification.childNodes.length) {
                clearInterval(interval_notification_remove);
                interval_notification_remove = null;
            }
            return true
        })
    }

    const check_sizes = ()=>{
      let main_notification_container =  document.getElementById('main_notification_container');
        let container_notification = document.getElementById('list_notification_conainer');
      let sizes_container = main_notification_container.getBoundingClientRect();
      if (sizes_container.height >window.innerHeight&&  container_notification.childNodes.length>1) {
          delete_notification(container_notification.childNodes[0].id).then((res)=>{
              if (res){
                  check_sizes()
              }
          })

      }
    }

    const showToast = (text, code) => {
        const id = sha1(text+ code +Date.now());

        let toastProperties = {
            id,
            text,
            code,
            time: moment().format('ll HH:mm')
        }

        let sess_store_arr = JSON.parse(sessionStorage.getItem('notifications'));
        sessionStorage.setItem('notifications', JSON.stringify([...sess_store_arr, toastProperties]))
        // setList([...list, toastProperties]);
        let container_notification = document.getElementById('list_notification_conainer');
        let new_notification = create_notification(toastProperties, delete_notification)
        container_notification.appendChild(new_notification);
        check_sizes()
        if (!interval_notification_remove) {
            interval_notification_remove = setInterval(() => {
                if (container_notification.childNodes.length) {
                    delete_notification(container_notification.childNodes[0].id)
                }
            }, 2000);
        }
    }


    const check_notifications = React.useCallback(()=>{
        let notification_from_sess_stor = sessionStorage.getItem('notifications');
        if (!notification_from_sess_stor) {
            sessionStorage.setItem('notifications', JSON.stringify([]))
        }
    }, [])

    useEffect(()=>{
        check_notifications()
    }, [])
    useEffect(()=>{

    }, [list, ])
    return (

        <Notifications_History_Context.Provider
            value={[showToast, close_all_notification]}>

            <div
                id={'main_notification_container'}
                className={`notification-container top-right`}
            >
                <ul id={'list_notification_conainer'}>

                </ul>
            </div>
                {props.children}
        </Notifications_History_Context.Provider>

    );
};

export default NotificationWrapper;
