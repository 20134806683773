import React, { useRef, useEffect } from 'react'
import { Tooltip } from "@material-ui/core";
import * as actions from "../../redux/actions/env.actions";
import { useDispatch, useSelector } from "react-redux";
import { Fade } from '@material-ui/core';
import useMousePosition from '../../hooks/useMousePosition';
import ShemeModal from '../modals/sheme_element_modal/shemeElementModal';
const debounce = require('debounce');

const degs  ={
  left: 90,
  right: 270,
  bottom: 0,
  top: 180
}

const values  ={
  'blue_trumpet': 'F1-OUT',
  'purple_trumpet': 'F1-IN',
  'stock1': 'R1-T5',
  'stock2': 'R1-T6',
  'stock3': 'R2-T5',
  'stock4': 'R2-T6',
  'stock5': 'R3-T5',
  'stock6': 'R3-T6',
}

const Line = props => {
  const dispatch = useDispatch();
  const canvasRef = useRef(null)
  const [hover, setHover] = React.useState(false);
  const blurStatus = useSelector((state) => state.envReducer.blurElems);
    const currTrumpet = useSelector((state) => state.envReducer.currTrumpet);
  const setCurrTrumpet = React.useCallback((color) => dispatch(actions.setCurrTrumpet(color)), [
    dispatch,
  ]);
  const bakRef = useRef(null);
  const [coord, setCoord] = React.useState(null);
  const { x, y } = useMousePosition();
  const setShemeModal = React.useCallback((color) => dispatch(actions.setShemeModal(color)), [
    dispatch,
  ]);

  const overModal = useSelector((state) => state.envReducer.overModal);
  const setShemeModalData = React.useCallback((color) => dispatch(actions.setShemeModalData(color)), [
    dispatch,
  ]);
  const setBlur = React.useCallback((status) => dispatch(actions.setBlur(status)), [
    dispatch,
  ]);
  useEffect(() => {
    if (overModal) {
      return
    }
    if (hover&&props.id&&coord) {
      setShemeModalData({...props, x:coord.x, y: coord.y});
      setShemeModal(true);
      setCurrTrumpet(props.id);
      setBlur(true)
    } else {
   
      setShemeModal(false);
      setCurrTrumpet('');
      setBlur(false)
    }

  }, [hover,coord,overModal])



  
  return <div style={{...props.styleWrap, cursor: 'pointer'}}         ref={bakRef}>
<div style={{position:'relative'}}>

{/* {hover&&props.id ? (
     <ShemeModal {...props}/>
      ) : null} */}



    <div 
            onMouseOver={debounce((e) => {
              setCoord({x:e.clientX, y: e.clientY})
              if (overModal) {
                return
              }
            setHover(true)
            },300)}
        onMouseLeave={debounce(() => {
          setHover(false)
        },200)}
    style={{ width:props.direction==='hor'? props.width : props.height , height: props.direction==='hor'? props.height : props.width, background: props.color,
    transition: 'all 0.2s',
    filter: blurStatus&&currTrumpet!==props.id?'blur(2px) contrast(30%)':blurStatus&&currTrumpet===props.id?'none':'none'
    }}>
     {/* <canvas ref={canvasRef} {...props} style={{}}/> */}
  </div>
  </div>
   </div>

}

export default Line